import { ProcessedFacility } from 'src/types/processedFacility';
import { DEFAULT_CHART } from './utils';

export function revenueRiskChartDataGenerator({
  attributes,
}: ProcessedFacility['charts']['revenueRiskChart']): Highcharts.Options {
  return {
    ...DEFAULT_CHART,
    chart: {
      ...DEFAULT_CHART.chart,
      type: 'column',
    },
    xAxis: {
      categories: ['1 Year', '3 Year', '5 Year', '10 Year'],
    },
    yAxis: {
      softMax: 1.25,
      title: {
        text: 'USD per USD',
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Revenue at Risk',
        data: [
          attributes['year1-revenue-risk'],
          attributes['year3-revenue-risk'],
          attributes['year5-revenue-risk'],
          attributes['year10-revenue-risk'],
        ],
        color: '#f09a48',
      },
    ],
  };
}
