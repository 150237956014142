import classnames from 'classnames/bind';
import React, { FC } from 'react';

import { mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { Copy } from 'src/components/Copy';
import { AggregateResult } from 'src/types/aggregates';
import styles from './NoAssessment.module.css';

const cx = classnames.bind(styles);

type NoAssessmentProps = {
  aggregate: AggregateResult;
};

export const NoAssessment: FC<NoAssessmentProps> = ({ aggregate }) => {
  return (
    <div className={cx('noAssessment')}>
      <Copy as="h2" color="blueLight" className={cx('header')}>
        Start your Action Plan
      </Copy>
      <Copy align="center" className={cx('subheader')}>
        You currently do not have assessments for this facility. Take the
        assessment and start your action plan today.
      </Copy>
      <div className={cx('actions')}>
        <Link
          to={`/app/facilities/${aggregate.facility.facilityId}/reporting-years/${aggregate.facilityReportingYearId}?tab=water-action-assessment`}
          className={cx('action')}
        >
          <Icon
            path={mdiPlusCircleOutline}
            size="24"
            style={{ marginRight: '5px' }}
          />
          <span>Take Assessment</span>
        </Link>
      </div>

      <Copy className={cx('help')}>
        Need Help? For tips on this or any other topic,{' '}
        <a
          href="/take-action"
          className={cx('link')}
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>
        .
      </Copy>
    </div>
  );
};

NoAssessment.displayName = 'NoAssessment';
