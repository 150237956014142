import { AggregateResult } from 'src/types/aggregates';
import { ReferenceDataWaterUnit } from 'src/types/referenceData';
import { CashFlowModel } from './CashFlowModel';

export class CalculatorResults {
  initialInvestment: number;
  discountRate: number;
  waterSaved: number;
  waterReductionImpact: number;
  expenses: Array<any>;
  savings: Array<any>;
  expensesTotal: number;
  savingsTotal: number;
  initialYear: number;
  incomingIncrease: number;
  outgoingIncrease: number;
  investmentName: string;

  outgoingPriceRiskAdjusted: number;
  incomingPriceRiskAdjusted: number;
  combinedPriceRiskAdjusted: number;

  twoYearIRRConventional: number | 'ERR';
  fiveYearIRRConventional: number | 'ERR';
  tenYearIRRConventional: number | 'ERR';
  twentyYearIRRConventional: number | 'ERR';
  twoYearIRRRiskAdjusted: number | 'ERR';
  fiveYearIRRRiskAdjusted: number | 'ERR';
  tenYearIRRRiskAdjusted: number | 'ERR';
  twentyYearIRRRiskAdjusted: number | 'ERR';
  npvArrayConventional: Array<number>;
  npvArrayRiskAdjusted: Array<number>;
  paybackPeriodConventional: number | null;
  paybackPeriodRiskAdjusted: number | null;

  constructor(
    expenses: Array<any>,
    savings: Array<any>,
    initialInvestment: number,
    initialYear: number,
    waterSaved: number,
    discountRate: number,
    aggregate: AggregateResult,
    incomingIncrease: number,
    outgoingIncrease: number,
    investmentName: string,
    waterUnitsMap: Record<number, ReferenceDataWaterUnit>
  ) {
    const cashFlow = new CashFlowModel(
      expenses,
      savings,
      initialInvestment * -1,
      initialYear,
      waterSaved,
      discountRate,
      aggregate,
      incomingIncrease,
      outgoingIncrease,
      waterUnitsMap
    );
    //Conventional
    try {
      this.twentyYearIRRConventional = getIRRForCashFlow(
        cashFlow.cashFlowArray.slice(0, 21)
      );
    } catch (e) {
      this.twentyYearIRRConventional = 'ERR';
    }
    try {
      this.tenYearIRRConventional = getIRRForCashFlow(
        cashFlow.cashFlowArray.slice(0, 11)
      );
    } catch (e) {
      this.tenYearIRRConventional = 'ERR';
    }
    try {
      this.fiveYearIRRConventional = getIRRForCashFlow(
        cashFlow.cashFlowArray.slice(0, 6)
      );
    } catch (e) {
      this.fiveYearIRRConventional = 'ERR';
    }
    try {
      this.twoYearIRRConventional = getIRRForCashFlow(
        cashFlow.cashFlowArray.slice(0, 3)
      );
    } catch (e) {
      this.twoYearIRRConventional = 'ERR';
    }

    //Risk Adjusted
    try {
      this.twentyYearIRRRiskAdjusted = getIRRForCashFlow(
        cashFlow.cashFlowArrayRiskAdjusted.slice(0, 21)
      );
    } catch (e) {
      this.twentyYearIRRRiskAdjusted = 'ERR';
    }
    try {
      this.tenYearIRRRiskAdjusted = getIRRForCashFlow(
        cashFlow.cashFlowArrayRiskAdjusted.slice(0, 11)
      );
    } catch (e) {
      this.tenYearIRRRiskAdjusted = 'ERR';
    }
    try {
      this.fiveYearIRRRiskAdjusted = getIRRForCashFlow(
        cashFlow.cashFlowArrayRiskAdjusted.slice(0, 6)
      );
    } catch (e) {
      this.fiveYearIRRRiskAdjusted = 'ERR';
    }
    try {
      this.twoYearIRRRiskAdjusted = getIRRForCashFlow(
        cashFlow.cashFlowArrayRiskAdjusted.slice(0, 3)
      );
    } catch (e) {
      this.twoYearIRRRiskAdjusted = 'ERR';
    }

    this.npvArrayConventional = cashFlow.npvArray;
    this.npvArrayRiskAdjusted = cashFlow.npvArrayRiskAdjusted;
    this.paybackPeriodConventional = getPaybackPeriod(cashFlow.npvArray);
    this.paybackPeriodRiskAdjusted = getPaybackPeriod(
      cashFlow.npvArrayRiskAdjusted
    );

    this.initialInvestment = initialInvestment;
    this.discountRate = discountRate || 0;
    this.waterSaved = waterSaved;
    this.waterReductionImpact =
      (waterSaved / aggregate.annualizedIncomingWaterQuantity) * 100;

    this.expenses = [...expenses];
    this.savings = [...savings];

    this.expensesTotal = getTotalAmount(expenses);
    this.savingsTotal = getTotalAmount(savings);

    this.outgoingPriceRiskAdjusted =
      aggregate.charts.outgoingWaterRiskChart.attributes[
        'year1-combined-unit-price'
      ];
    this.incomingPriceRiskAdjusted =
      aggregate.charts.incomingWaterRiskChart.attributes[
        'year1-quantity-unit-price'
      ] +
      aggregate.charts.incomingWaterRiskChart.attributes[
        'year1-waterbill-unit-price'
      ];
    this.combinedPriceRiskAdjusted =
      this.outgoingPriceRiskAdjusted + this.incomingPriceRiskAdjusted;

    this.initialYear = initialYear;
    this.incomingIncrease = incomingIncrease;
    this.outgoingIncrease = outgoingIncrease;
    this.investmentName = investmentName;
  }
}

function getIRRForCashFlow(cashFlow: Array<number>) {
  let numberOfTries = 1;
  let positive, negative;

  cashFlow.forEach((value: number) => {
    if (value > 0) positive = true;
    if (value < 0) negative = true;
  });

  if (!positive || !negative) {
    throw new Error(
      'IRR requires at least one positive value and one negative value'
    );
  }

  return Math.round(seekZero(npv) * 100) / 100;

  function npv(rate: number) {
    numberOfTries++;
    if (numberOfTries > 10000) {
      throw new Error("IRR can't find a result");
    }
    let rrate = 1 + rate / 100;
    let npv = cashFlow[0];
    for (let i = 1; i < cashFlow.length; i++) {
      npv += cashFlow[i] / Math.pow(rrate, i);
    }
    return npv;
  }

  function seekZero(fn: (x: number) => number) {
    let x = 1;
    while (fn(x) > 0) {
      x += 1;
    }
    while (fn(x) < 0) {
      x -= 0.01;
    }
    return x + 0.01;
  }
}

function getPaybackPeriod(npvArray: Array<number>) {
  for (var i = 0; i < npvArray.length; i++) {
    if (npvArray[i] === 0) {
      return i - 1;
    } else if (npvArray[i] > 0) {
      return (
        i -
        1 +
        Math.abs(npvArray[i - 1]) / (Math.abs(npvArray[i - 1]) + npvArray[i])
      );
    }
  }
  return null;
}

function getTotalAmount(amountList: Array<{ amount: number }>) {
  var totalAmount = 0;
  for (var i = 0; i < amountList.length; i++) {
    totalAmount += amountList[i].amount;
  }
  return totalAmount;
}
