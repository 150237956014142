import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { addFacilityFolderAssociation } from 'src/clients/api/v5.facility';
import { AsyncState } from 'src/types/asyncState';
import { Folder } from 'src/types/folder';
import { Facility } from 'src/types/v5.facility';

type AddFolderFacilityModalProps = {
  folder: Folder;
  isOpen: boolean;
  onClose: () => void;
  facilities: Array<Facility>;
};

export const AddFolderFacilityModal: FC<PropsWithChildren<
  AddFolderFacilityModalProps
>> = ({ facilities, folder, isOpen, onClose }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useState<AsyncState>({ state: 'idle' });
  const [selectedFacilityId, setSelectedFacilityId] = useState<number>();

  const selectedFacility = useMemo(() => {
    return facilities?.find((f) => f.facilityId === Number(selectedFacilityId));
  }, [facilities, selectedFacilityId]);

  const filteredFacilities = useMemo(
    () =>
      facilities?.filter(
        (f) =>
          !folder.facilitiesInFolder
            .map((ff) => ff.facilityId)
            .includes(f.facilityId)
      ),
    [facilities, folder.facilitiesInFolder]
  );

  const onAddFacility = async () => {
    if (selectedFacility) {
      try {
        setAsyncState({ state: 'loading' });

        const result = await addFacilityFolderAssociation(
          selectedFacility.facilityId,
          folder.folderId
        );

        if (result.status !== 'success') {
          throw Error(result.value.errors[0].message);
        }

        toast({
          title: `Facility added successfully!`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

        queryClient.refetchQueries(['folders']);
        queryClient.refetchQueries(['aggregates']);

        onClose();
      } catch (e) {
        toast({
          title: `Failed to add facility to folder`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      } finally {
        setAsyncState({ state: 'idle' });
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl as={Stack} spacing="1">
              <Flex direction="column">
                <FormLabel m="0" fontSize="sm" marginLeft="2">
                  Add facility to folder
                </FormLabel>
                <Stack direction="row" spacing={2}>
                  <Select
                    placeholder="Select Facility"
                    size="md"
                    onChange={(e) => {
                      const val = Number(e.target.value);

                      if (val) {
                        setSelectedFacilityId(val);
                      }
                    }}
                  >
                    {(filteredFacilities ?? []).map((facility) => (
                      <option
                        key={facility.facilityId}
                        value={facility.facilityId}
                        defaultChecked={
                          facility.facilityId === selectedFacilityId
                        }
                      >
                        {facility.facilityName}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </Flex>
              <FormHelperText as={Flex} fontSize="smaller" alignItems="center">
                This will remove the facility from its current folder.
              </FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent={'space-between'}>
            <Button
              color="brand.primary"
              variant="ghost"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="brand"
              isDisabled={!selectedFacilityId}
              isLoading={asyncState.state === 'loading'}
              onClick={onAddFacility}
            >
              Add Facility
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

AddFolderFacilityModal.displayName = 'AddFolderFacilityModal';
