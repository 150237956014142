import { Button } from '@chakra-ui/react';
import { mdiChevronDoubleLeft } from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  getFacilityReportingYear,
  updateFacilityReportingYear,
} from 'src/clients/api/v5.facility';
import { getUserIdInStorage } from 'src/clients/helpers';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { FacilityFormSchema } from 'src/components/FacilityForm/validation';
import { WrmForm } from 'src/components/FacilityForm/WrmForm';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { REPORTING_YEARS } from 'src/constants/years';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import styles from './ReportingYearEdit.module.css';

const cx = classnames.bind(styles);

type ReportingYearEditProps = {};

export const ReportingYearEdit: FC<
  ReportingYearEditProps &
    RouteComponentProps<{ id: string; reportingYearId: string }>
> = ({ match, history }) => {
  const { id, reportingYearId } = match.params;
  const { setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (resetState) {
      resetState();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ['facilities', id, 'reportingYear', reportingYearId],
    () => getFacilityReportingYear(id, reportingYearId)
  );

  if (isLoading) {
    return (
      <div className={cx('createFacility', 'loading')}>
        <LoadingSpinner block />
      </div>
    );
  }

  if (error || !data || data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading reporting years"
        message="We were unable to load your reporting years successfully"
      />
    );
  }

  const onSave = async (values: FacilityFormSchema) => {
    if (setState) {
      try {
        setState('saveState', { status: 'loading' });

        const data = {
          ...values,
          userId: `${getUserIdInStorage()}`,
          projectedFacilityOutputIncrease: Number(
            values.projectedFacilityOutputIncrease
          ),
          assessment: {
            ...values.assessment,
            assessmentAnswers: Object.entries(
              values.assessment.assessmentAnswers
            ).map(([questionId, questionChoiceId]) => ({
              questionId,
              questionChoiceId,
            })),
          },
        };

        const result = await updateFacilityReportingYear(
          id,
          reportingYearId,
          data
        );

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState('saveState', {
          status: 'success',
          message: 'Facility created successfully!',
        });

        queryClient.invalidateQueries(['facilities', id]);
        queryClient.invalidateQueries(['aggregates']);

        history.push(`/app/facilities/${id}`);
      } catch (e) {
        setState('saveState', {
          status: 'error',
          message: 'There was an error saving your facility.',
        });
      }
    }
  };

  return (
    <>
      <header className={cx('header')}>
        <Button variant="link" as={Link} to={`/app/facilities/${id}`} mb="10px">
          <Icon
            path={mdiChevronDoubleLeft}
            size="14px"
            style={{ display: 'inline-block' }}
          />{' '}
          Back to Facility
        </Button>

        <Copy as="h2">Edit Reporting Year</Copy>

        <Copy as="p">
          Your privacy is important to us. Precautions are in place to protect
          your information against any misuse. Please refer to the{' '}
          <a href="https://www.ecolab.com/privacy-policy">PRIVACY NOTICE</a> for
          additional details.
        </Copy>
      </header>
      <WrmForm
        availableYears={REPORTING_YEARS}
        reportingYear={data.value.data}
        isEditing={true}
        onSave={onSave}
      />
    </>
  );
};

ReportingYearEdit.displayName = 'ReportingYearEdit';
