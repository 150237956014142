import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  useDisclosure,
  useBoolean,
  useToast,
  Text,
} from '@chakra-ui/react';
import { mdiTrashCan } from '@mdi/js';
import React from 'react';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import { deleteFolder } from 'src/clients/api/v5.folders';
import { Icon } from 'src/components/Icon';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folder } from 'src/types/folder';

type FolderDeletePopoverProps = {
  folderId: Folder['folderId'];
  isDisabled: boolean;
  onDelete: () => void;
};

export const FolderDeletePopover: FC<FolderDeletePopoverProps> = ({
  folderId,
  isDisabled,
  onDelete,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const removeDisclosure = useDisclosure();
  const { setFilterState } = useFilterAndSort();
  const [isLoading, setIsLoading] = useBoolean();

  const handleDeleteFolder = async () => {
    try {
      setIsLoading.on();
      const result = await deleteFolder(folderId);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      toast({
        title: `Folder deleted successfully!`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      setFilterState!('folderId', null);
      queryClient.refetchQueries(['folders']);
      queryClient.invalidateQueries(['aggregates']);

      onDelete();
    } catch (e) {
      toast({
        title: e.message ?? 'There was an error deleting your folder.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading.off();
    }
  };

  return (
    <Popover
      placement="left"
      closeOnBlur={false}
      closeOnEsc={false}
      isOpen={removeDisclosure.isOpen}
      onClose={removeDisclosure.onClose}
    >
      <>
        <PopoverTrigger>
          <Button
            size="sm"
            disabled={isDisabled}
            variant="solid"
            leftIcon={<Icon path={mdiTrashCan} size="24px" />}
            colorScheme="red"
            borderRadius={{ base: 'none', md: 'md' }}
            isLoading={isLoading}
            onClick={removeDisclosure.onOpen}
          >
            Delete Folder
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent outline="none">
            <PopoverArrow />
            <PopoverHeader bg="red.400" alignItems="center">
              Confirm Delete
            </PopoverHeader>
            <PopoverCloseButton />

            <PopoverBody>
              <Text mb="2" textAlign="center" width="full">
                Are you sure you want to delete this folder?
              </Text>
              <Button
                colorScheme="red"
                width="full"
                onClick={handleDeleteFolder}
              >
                Yes, delete this folder
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </>
    </Popover>
  );
};

FolderDeletePopover.displayName = 'FolderDeletePopover';
