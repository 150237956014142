import {
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import { mdiMerge, mdiTrashCan } from '@mdi/js';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { deleteActiveBatchUploadRow } from 'src/clients/api/upload';
import { Icon } from 'src/components/Icon';
import { UploadFlowError } from 'src/constants/errors';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { ActiveUploadRow } from 'src/types/upload';
import { UPLOAD_STATUS_COLOR } from './RowResultsList';

type RowResultsListItemProps = {
  variant: 'error' | 'success' | 'processing';
  row: ActiveUploadRow;
  uploadId: string;
};

type RowErrorType = { field: string; message: string };

const parseErrors = (errorJson: string): Array<RowErrorType> => {
  const parsed = JSON.parse(errorJson);

  return parsed.errors;
};

export const RowResultsListItem: FC<RowResultsListItemProps> = ({
  row: { id, errorJson, rowJson },
  variant,
  uploadId,
}) => {
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState();
  const tableColor = UPLOAD_STATUS_COLOR[variant];

  let rowErrors: {
    errors: Array<RowErrorType>;
  } = {
    errors: [],
  };

  let rowResults;

  let parseError;

  try {
    rowErrors.errors = parseErrors(errorJson);
    rowResults = JSON.parse(rowJson);
  } catch (e) {
    parseError = e;
  }

  const isAssessmentError = rowErrors.errors?.some(
    (e) => e.field === 'waterActionAssessment'
  );

  const handleDeleteUpload = async () => {
    try {
      setAsyncState({ status: 'loading' });
      const result = await deleteActiveBatchUploadRow(uploadId, id);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: `Upload deleted successfully!`,
      });

      queryClient.refetchQueries('activeUploads');
      queryClient.invalidateQueries('facilities');
      queryClient.invalidateQueries('folders');
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: e.message ?? 'There was an error deleting your upload.',
      });
    }
  };

  return (
    <Stack
      key={id}
      direction={{ base: 'column', md: 'row' }}
      p="2"
      _hover={{ bg: `${tableColor}.100` }}
    >
      {parseError ? (
        <Flex flex={{ base: '1 1 100%', md: '1 1 auto' }} alignItems="center">
          <Text>Could not process this row's data.</Text>
        </Flex>
      ) : (
        <>
          <Flex flex={{ base: '1 1 100%', md: '1 1 auto' }} alignItems="center">
            <Text isTruncated>{rowResults.facilityName}</Text>
          </Flex>

          {variant === 'error' ? (
            <Flex alignItems="center" justifyContent="end">
              <Button
                as={Link}
                size="sm"
                variant="solid"
                colorScheme={tableColor}
                leftIcon={<Icon path={mdiMerge} size="18px" />}
                width="100%"
                to={{
                  pathname: '/app/facilities/new',
                  state: {
                    uploadResults: {
                      rowErrors,
                      rowResults,
                      rowId: id,
                      errorType: isAssessmentError
                        ? UploadFlowError.Assessment
                        : UploadFlowError.General,
                    },
                  },
                }}
              >
                Resolve
              </Button>
            </Flex>
          ) : null}

          {variant === 'error' || variant === 'processing' ? (
            <Popover placement="left" closeOnBlur={false} closeOnEsc={false}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      size="sm"
                      icon={<Icon path={mdiTrashCan} size="24px" />}
                      colorScheme="red"
                      aria-label="Delete Upload Row"
                      isLoading={asyncState.status === 'loading'}
                      ml="2"
                    >
                      Delete
                    </IconButton>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent outline="none">
                      <PopoverArrow />
                      <PopoverHeader bg="red.400" alignItems="center">
                        Confirm Delete
                      </PopoverHeader>
                      <PopoverCloseButton />

                      <PopoverBody>
                        <Text mb="2" textAlign="center" width="full">
                          Are you sure you want to delete this active upload
                          row?
                        </Text>
                        <Button
                          colorScheme="red"
                          width="full"
                          onClick={() => {
                            handleDeleteUpload();
                            onClose();
                          }}
                        >
                          Yes, delete this row
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : null}
        </>
      )}
    </Stack>
  );
};
