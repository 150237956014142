import { extendTheme } from '@chakra-ui/react';
import { colors, fonts, semanticTokens } from './foundations';
import { Button } from './components';

export const theme = extendTheme({
  colors,
  fonts,
  semanticTokens,
  components: {
    Button,
  },
});
