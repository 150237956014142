import React, { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './CalculatorOptionalForm.module.css';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextInput } from 'src/components/TextInput';
import { SelectInput } from 'src/components/SelectInput';
import { Button } from 'src/components/Button';
import currency from 'currency.js';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect } from 'react';

const cx = classnames.bind(styles);

const calculatorOptionalFormSchemaProps = {
  name: yup.string().trim().required('Required'),
  value: yup.string().trim().required('Required'),
  year: yup.string().trim().required('Required'),
};
const calculatorOptionalFormSchema = yup.object(
  calculatorOptionalFormSchemaProps
);
type CalculatorOptionalFormSchema = yup.InferType<
  typeof calculatorOptionalFormSchema
>;

export type StoredOptionalFormSchema = CalculatorOptionalFormSchema & {
  id: string;
};

type CalculatorOptionalFormProps = {
  minYear: number;
  list: Array<StoredOptionalFormSchema>;
  onChange: (list: Array<StoredOptionalFormSchema>) => void;
};

const MAX_YEARS = 20;

export const CalculatorOptionalForm: FC<CalculatorOptionalFormProps> = ({
  minYear,
  list,
  onChange,
}) => {
  const startingYear = Number(
    minYear && !isNaN(+minYear) ? minYear : new Date().getFullYear()
  );

  const years = useMemo(() => {
    return Array(MAX_YEARS)
      .fill(null)
      .map((_, i) => `${i + startingYear}`);
  }, [startingYear]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: '',
      value: '',
      year: `${startingYear}`,
    },
    resolver: yupResolver(calculatorOptionalFormSchema),
  });

  useEffect(() => {
    reset({
      name: '',
      value: '',
      year: `${startingYear}`,
    });
  }, [reset, startingYear]);

  const onSubmit = handleSubmit((values) => {
    onChange([
      ...list,
      { ...values, id: Math.random().toString(36).substr(2, 5) },
    ]);
  });

  const handleRemove = (value: string) => {
    onChange(list.filter((l) => l.id !== value));
  };

  return (
    <div className={cx('calculatorOptionalForm')}>
      {list.length > 0 ? (
        <div className={cx('list')}>
          {list.map((e) => (
            <div key={e.id} className={cx('listItem')}>
              <span>
                {e.name} - {e.year} - {currency(e.value).format()}
              </span>
              <div className={cx('remove')} onClick={() => handleRemove(e.id)}>
                <Icon path={mdiClose} className={cx('icon')} size="12px" />
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <div className={cx('row')}>
        <TextInput
          name="name"
          placeholder="Expense Name"
          ref={register}
          error={errors.name}
        />
      </div>
      <div className={cx('row')}>
        <TextInput
          type="number"
          name="value"
          placeholder="$"
          ref={register}
          error={errors.value}
        />
        <Controller
          name="year"
          control={control}
          defaultValue={`${startingYear}`}
          render={(props) => (
            <SelectInput
              {...props}
              items={years.map((y) => ({
                label: y,
                value: y,
              }))}
              placeholder="&nbsp;"
              error={errors.year}
            />
          )}
        />
      </div>
      <div className={cx('row')} style={{ justifyContent: 'flex-end' }}>
        <Button className={cx('button')} onClick={onSubmit} disabled={!isDirty}>
          Add
        </Button>
      </div>
    </div>
  );
};

CalculatorOptionalForm.displayName = 'CalculatorOptionalForm';
