import React, { FC, forwardRef } from 'react';
import classnames from 'classnames/bind';
import { Flex, Text } from '@chakra-ui/react';

import styles from './TextInput.module.css';
import { FieldError } from 'react-hook-form';

const cx = classnames.bind(styles);

export interface TextInputProps extends React.ComponentPropsWithRef<'input'> {
  error?: FieldError;
}

export const TextInput: FC<TextInputProps> = forwardRef(
  ({ className, error, disabled, readOnly, ...props }, ref) => {
    return (
      <div className={cx(className)}>
        <input
          ref={ref}
          {...props}
          disabled={disabled}
          readOnly={readOnly}
          className={cx('textInput', {
            disabled: disabled || readOnly,
            inputError: error,
          })}
        />
        {error && (
          <Flex justifyContent="flex-end">
            <Text as="span" size="sm" color="red.500">
              {error.message}
            </Text>
          </Flex>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
