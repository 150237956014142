import classnames from 'classnames/bind';
import React, { FC } from 'react';

import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getAggregates } from 'src/clients/api/v5.aggregates';
import { getReferenceData } from 'src/clients/api/v5.referenceData';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { InvestmentCalculator } from 'src/components/InvestmentCalculator';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import styles from './Calculator.module.css';

const cx = classnames.bind(styles);

type CalculatorProps = {};

export const Calculator: FC<
  CalculatorProps & RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const { id } = match.params;

  const {
    isLoading: isLoadingFacilities,
    data: aggregates,
    error: facilitiesError,
  } = useQuery(['aggregates'], () => getAggregates());

  const {
    data: referenceData,
    isLoading: isLoadingReferenceData,
    error: referenceDataError,
  } = useQuery(['referenceData'], () => getReferenceData());

  if (isLoadingFacilities || isLoadingReferenceData) {
    return <LoadingSpinner />;
  }

  const aggregate =
    (aggregates?.status === 'success' &&
      aggregates.value.data.find((f) => `${f.facilityId}` === id)) ??
    undefined;

  if (
    facilitiesError ||
    !aggregates ||
    aggregates?.status === 'error' ||
    referenceDataError ||
    !referenceData ||
    referenceData?.status === 'error' ||
    !aggregate
  ) {
    return (
      <BlockError
        title="Error loading facilities"
        message="We were unable to load your facilities successfully"
      />
    );
  }

  return (
    <div className={cx('calculator')}>
      <header className={cx('header')}>
        <Copy as="h2">Risk Analysis Investment Calculator</Copy>
        <br />
        <Copy as="p">
          Based on the risk factors of business growth and water scarcity,
          different actions can be taken. This Risk Analysis Investment
          Calculator provides results based on financial investment inputs that
          help guide decisions according to the conventional (local water price)
          and risk adjusted factors. Comparing these two factors can help
          support the business case to prioritize water reduction efforts,
          optimize water efficiency or monitor conditions. These types of
          projects can deliver additional operational benefits besides water
          savings and this tool can help move the needle on a proactive water
          management strategy.
        </Copy>
      </header>
      <div className={cx('content')}>
        <InvestmentCalculator
          aggregate={aggregate}
          referenceData={referenceData.value.data}
        />
      </div>
    </div>
  );
};

Calculator.displayName = 'Calculator';
