import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  FlexProps,
  Hide,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import {
  mdiCheck,
  mdiCloudCheck,
  mdiCloudCheckOutline,
  mdiCloudRemove,
  mdiCloudRemoveOutline,
  mdiCloudSyncOutline,
  mdiFileDocumentOutline,
  mdiRefresh,
  mdiTrashCan,
  mdiWrenchCog,
} from '@mdi/js';
// import Icon from '@mdi/react';
import React, { FC, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { Icon } from 'src/components/Icon';
import { deleteActiveBatchUpload } from 'src/clients/api/upload';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { ActiveUpload } from 'src/types/upload';

type ActiveUploadCardProps = {
  upload: ActiveUpload;
  canInspect?: boolean;
} & FlexProps;

enum STATUS_COLOR {
  SUCCESS = 'green.400',
  ERROR = 'red.400',
  INIT = 'gray.300',
}

function convertColorKeyToVarName(colorKey: string) {
  return `--chakra-colors-${colorKey.replace('.', '-')}`;
}

export const ActiveUploadCard: FC<ActiveUploadCardProps> = ({
  upload: {
    processedCount,
    totalRowCount,
    errorCount,
    id,
    processed,
    fileName,
    fileHash,
  },
  canInspect = false,
  children,
  ...props
}) => {
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState();

  const isNotProcessed = !processed;
  const percent = Number(processedCount / totalRowCount) * 100;
  const hasErrors = errorCount > 0;

  const statusColor = useMemo(() => {
    if (hasErrors) {
      return STATUS_COLOR.ERROR;
    }

    if (!isNotProcessed) {
      return STATUS_COLOR.SUCCESS;
    }

    return STATUS_COLOR.INIT;
  }, [hasErrors, isNotProcessed]);

  const handleDeleteUpload = async () => {
    try {
      setAsyncState({ status: 'loading' });
      const result = await deleteActiveBatchUpload(id);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: `Upload deleted successfully!`,
      });

      queryClient.refetchQueries('activeUploads');
      queryClient.invalidateQueries('facilities');
      queryClient.invalidateQueries('folders');
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: e.message ?? 'There was an error deleting your upload.',
      });
    }
  };

  return (
    <Flex direction="column" key={id} boxShadow="base" {...props}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        p={{ base: 0, md: 4 }}
        pt={{ base: 2, md: 4 }}
        alignItems="center"
      >
        <Box />

        <Flex
          boxSize="20"
          position="relative"
          alignItems="center"
          justifyContent="center"
          mb={{ base: 4, md: 0 }}
          py={{ base: 2, md: 0 }}
        >
          <CircularProgress
            value={percent}
            color={STATUS_COLOR.SUCCESS}
            trackColor={STATUS_COLOR.INIT}
            thickness={6}
            size="20"
            capIsRound
          >
            <CircularProgressLabel
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              boxSize="14"
            >
              <Icon
                path={
                  hasErrors
                    ? mdiCloudRemove
                    : isNotProcessed
                    ? mdiCloudSyncOutline
                    : mdiCloudCheck
                }
                size="36px"
                color={`var(${convertColorKeyToVarName(statusColor)})`}
              />
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>

        <Stack
          p={{ base: 4, md: 0 }}
          flex="1 1 100%"
          direction="column"
          maxWidth="calc(100% - 1px)"
          width="100%"
          spacing={{ base: 2, md: 1 }}
        >
          <Flex>
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              colorScheme={isNotProcessed ? 'gray' : 'green'}
            >
              <TagLeftIcon
                as={() => (
                  <Icon
                    path={isNotProcessed ? mdiRefresh : mdiCheck}
                    size="14px"
                    color="white"
                    spin={isNotProcessed}
                  />
                )}
              />
              <TagLabel ml="1">
                {isNotProcessed
                  ? 'Processing Facilities'
                  : 'Facilities Processed'}
              </TagLabel>
            </Tag>
          </Flex>
          <Flex alignItems="center">
            <Hide below="md">
              <Icon path={mdiFileDocumentOutline} size="32px" />
            </Hide>
            <Box
              ml={{ base: 0, md: '2' }}
              width="100%"
              maxWidth="100%"
              textAlign={{ base: 'center', md: 'left' }}
            >
              <Text
                isTruncated
                fontSize={{ base: 'sm', md: 'xl' }}
                fontWeight="semibold"
                lineHeight="1"
              >
                {fileName}
              </Text>
              <Text fontSize="xs">({fileHash})</Text>
            </Box>
          </Flex>

          <HStack
            spacing="8"
            justifyContent={{ base: 'space-between', md: 'flex-start' }}
          >
            <Flex alignItems="center">
              <Icon
                path={mdiCloudCheckOutline}
                size="18px"
                color={`var(${convertColorKeyToVarName(STATUS_COLOR.SUCCESS)})`}
              />
              <Hide below="md">
                <Text fontSize="sm" fontWeight="semibold" ml="1">
                  Imported:
                </Text>
              </Hide>
              <Text fontSize="sm" fontWeight="semibold" ml="1">
                {processedCount}
              </Text>
            </Flex>

            <Flex alignItems="center">
              <Icon
                path={mdiCloudRemoveOutline}
                size="18px"
                color={`var(${convertColorKeyToVarName(STATUS_COLOR.ERROR)})`}
              />
              <Hide below="md">
                <Text fontSize="sm" fontWeight="semibold" ml="1">
                  Errored:
                </Text>
              </Hide>
              <Text fontSize="sm" fontWeight="semibold" ml="1">
                {errorCount}
              </Text>
            </Flex>

            <Flex alignItems="center">
              <Icon path={mdiCloudSyncOutline} size="18px" />
              <Hide below="md">
                <Text fontSize="sm" fontWeight="semibold" ml="1">
                  Total:
                </Text>
              </Hide>
              <Text fontSize="sm" fontWeight="semibold" ml="1">
                {totalRowCount}
              </Text>
            </Flex>
          </HStack>
        </Stack>

        {canInspect ? (
          <Stack
            direction="row"
            width="full"
            gap={0}
            justifyContent="flex-end"
            flex="1 1 auto"
            p="2"
          >
            <Button
              as={Link}
              to={`/app/uploads/${id}`}
              size="lg"
              variant="solid"
              leftIcon={<Icon path={mdiWrenchCog} size="24px" />}
              colorScheme={errorCount > 0 ? 'red' : 'green'}
              borderRadius={{ base: 'none', md: 'md' }}
            >
              Inspect
            </Button>

            {isNotProcessed ? (
              <Popover placement="left" closeOnBlur={false} closeOnEsc={false}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        size="lg"
                        icon={<Icon path={mdiTrashCan} size="24px" />}
                        colorScheme="red"
                        borderRadius={{ base: 'none', md: 'md' }}
                        aria-label="Delete Upload"
                        isLoading={asyncState.status === 'loading'}
                      >
                        Delete
                      </IconButton>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent outline="none">
                        <PopoverArrow />
                        <PopoverHeader bg="red.400" alignItems="center">
                          Confirm Delete
                        </PopoverHeader>
                        <PopoverCloseButton />

                        <PopoverBody>
                          <Text mb="2" textAlign="center" width="full">
                            Are you sure you want to delete this active upload?
                          </Text>
                          <Button
                            colorScheme="red"
                            width="full"
                            onClick={() => {
                              handleDeleteUpload();
                              onClose();
                            }}
                          >
                            Yes, delete this upload
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
      {children ? (
        <Flex direction="column" p="2">
          {children}
        </Flex>
      ) : null}
    </Flex>
  );
};

ActiveUploadCard.displayName = 'ActiveUploadCard';
