import classnames from 'classnames/bind';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC } from 'react';
import { Copy } from 'src/components/Copy';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { AggregateResult } from 'src/types/aggregates';
import { asCommaSeparatedString } from 'src/utils/number';
import {
  COLOR_MAP,
  revenueRiskChartDataGenerator,
} from '../../../../utils/chart';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type RevenueRiskProps = {
  aggregate: AggregateResult;
};

const legend = {
  usdPerYear: [{ label: 'Quality Risk', rowKey: 'revenue-risk' }] as const,
};
const legendYears = [1, 3, 5, 10] as const;
const legendColors = ['#f09a48'];

export const RevenueRisk: FC<RevenueRiskProps> = ({ aggregate }) => {
  const attributes = aggregate.charts.revenueRiskChart.attributes;
  const chart = useDeepCompareMemo<Highcharts.Options>(
    () => revenueRiskChartDataGenerator(aggregate.charts.revenueRiskChart),
    [aggregate]
  );

  const score =
    COLOR_MAP[aggregate.individualRevenueRiskLevel] ?? COLOR_MAP['N'];

  return (
    <div className={cx('facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Revenue Risk</Copy>
        <Copy as="p">
          Revenue at risk compares the estimated amount of water a business
          requires to generate revenue (m<sup>3</sup> per USD of revenue) to the
          business' share of water available in the water basin if water were
          allocated among water users based on economic activity (contribution
          to basin-level GDP). If more water is required than the basin share of
          water allocated, then a proportion of the business' revenue is
          potentially at risk.
        </Copy>
        <Copy as="p">
          Revenue Risk Likelihood Score ={' '}
          <span className={cx('score', score.color)}>{score.text}</span>
        </Copy>
      </div>

      <HighchartsReact highcharts={Highcharts} options={chart} />

      <table className={cx('legendTable')}>
        <tbody>
          <tr className={cx('legendTableHeader')}>
            <td></td>
            <td>1 year</td>
            <td>3 year</td>
            <td>5 year</td>
            <td>10 year</td>
          </tr>
          {legend['usdPerYear'].map(({ label, rowKey }, index, arr) => (
            <tr
              className={cx({
                strong: index === arr.length - 1,
              })}
            >
              <td className={cx('label')}>
                <span
                  className={cx('icon')}
                  style={{ backgroundColor: legendColors[index] }}
                ></span>
                <span className={cx('value')}>{label}</span>
              </td>
              {legendYears.map((number) => {
                const key = `year${number}-${rowKey}` as const;
                return (
                  <td>
                    <span
                      className={cx('icon')}
                      style={{ backgroundColor: legendColors[index] }}
                    ></span>
                    <span className={cx('value')}>
                      {asCommaSeparatedString(attributes[key], 2)}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

RevenueRisk.displayName = 'RevenueRisk';
