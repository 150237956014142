import { WrappedResponse } from '../types';
import * as request from '../request';
import { Countries as CountriesType } from '../../types/country';
import { Regions as RegionsType } from '../../types/region';
import { Cities as CitiesType } from '../../types/city';
import { Env } from '../../constants/env';

export type CountriesResponseType = WrappedResponse<CountriesType>;

export function getCountries() {
  return request.get<CountriesResponseType>(
    `${Env.apiUrl}/v5/reference-data/countries`
  );
}

export type RegionsResponseType = WrappedResponse<RegionsType>;

export function getRegions(countryId: number | string) {
  return request.get<RegionsResponseType>(
    `${Env.apiUrl}/v5/reference-data/countries/${countryId}/regions`
  );
}

export type CitiesResponseType = WrappedResponse<CitiesType>;

export function getCities(
  countryId: number | string,
  regionId: number | string
) {
  return request.get<CitiesResponseType>(
    `${Env.apiUrl}/v5/reference-data/countries/${countryId}/regions/${regionId}/cities`
  );
}
