import { AggregateResults } from 'src/types/aggregates';
import { Folders } from 'src/types/folder';
import { ProcessedFacilities } from 'src/types/processedFacility';
import { urlWithAccountId } from '../helpers';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetFoldersResponseType = WrappedResponse<Folders>;
export function getFolders() {
  return request.get<GetFoldersResponseType>(urlWithAccountId(`folders`));
}

export type CreateFolderRequestType = {
  folderName: string;
};
export type CreateFolderResponseType = WrappedResponse<{}>;
export function createFolder(folderName: string) {
  return request.post<CreateFolderResponseType, CreateFolderRequestType>(
    urlWithAccountId(`folders`),
    { folderName }
  );
}

export type UpdateFolderRequestType = {
  folderName: string;
};
export type UpdateFolderResponseType = WrappedResponse<{}>;
export function updateFolder(folderId: number, folderName: string) {
  return request.put<UpdateFolderResponseType, UpdateFolderRequestType>(
    urlWithAccountId(`folders/${folderId}`),
    { folderName }
  );
}

export type DeleteFolderResponseType = WrappedResponse<{}>;
export function deleteFolder(folderId: number) {
  return request.del<DeleteFolderResponseType>(
    urlWithAccountId(`folders/${folderId}`)
  );
}

export type GetFolderFacilitiesResponseType = WrappedResponse<
  ProcessedFacilities
>;
export function getFolderFacilities(folderId: string) {
  return request.get<GetFolderFacilitiesResponseType>(
    urlWithAccountId(`folders/${folderId}/facilities`)
  );
}

export type GetAggregateResponseType = WrappedResponse<AggregateResults>;
export function getFolderAggregates(folderId: string) {
  return request.get<GetAggregateResponseType>(
    urlWithAccountId(`folders/${folderId}/aggregate-results`)
  );
}

export type GeneratePdfReportForFolderResponseType = WrappedResponse<string>;
export type GeneratePdfReportForFolderRequestType = {
  riskAnalysisChart: string;
  benchmarkingChart: string;
};
export function generateFolderPDFReport(
  folderId: string,
  data: GeneratePdfReportForFolderRequestType
) {
  return request.post<
    GeneratePdfReportForFolderResponseType,
    GeneratePdfReportForFolderRequestType
  >(urlWithAccountId(`folders/${folderId}/reports/pdf`), data);
}

export type GenerateFolderCsvReportResponseType = WrappedResponse<string>;
export function generateFolderCsvReport(folderId: string) {
  return request.post<never, GenerateFolderCsvReportResponseType>(
    urlWithAccountId(`folders/${folderId}/reports/csv`)
  );
}
