import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { Assessment, Question } from 'src/types/assessment';
import styles from '../Tabs/Tabs.module.css';
import { Carousel } from '../Tabs/Carousel';

const cx = classnames.bind(styles);

type AssessmentCarouselProps = {
  assessment: Assessment;
  reportingYearId?: string;
  onSave: (values: any) => void;
  onCancel: () => void;
  className?: string;
};

export const AssessmentCarousel: FC<AssessmentCarouselProps> = ({
  assessment,
  reportingYearId,
  onSave,
  onCancel,
  className,
}) => {
  const questions = useDeepCompareMemo(
    () =>
      assessment.categories
        .map((cat) => cat.questions)
        .flat(1)
        .reduce<Record<string, Question>>(
          (acc, q) => ({
            ...acc,
            [q.questionId]: q,
          }),
          {}
        ),
    [assessment]
  );

  return (
    <div className={cx('tabContent', className)}>
      <article className={cx('content')}>
        <Carousel
          assessmentId={assessment.assessmentId}
          reportingYearId={reportingYearId}
          questions={questions}
          className={cx({ active: true })}
          onSave={onSave}
          onCancel={onCancel}
        />
      </article>
    </div>
  );
};

AssessmentCarousel.displayName = 'AssessmentCarousel';
