import { Env } from './env';

const startingYear = 2010;
const endingYear = Env.maxReportingYear;

// Used to automatically add "current year" to the available years if the
// current month is greater than October, then the current year is added.
// new Date().getUTCFullYear() - (new Date().getUTCMonth() < 10 ? 1 : 0);

export const REPORTING_YEARS = Array(endingYear - startingYear + 1)
  .fill(null)
  .map((_v, k) => startingYear + k)
  .reverse();
