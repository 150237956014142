import classnames from 'classnames/bind';
import React, { FC } from 'react';

import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { LoaderIcon } from 'src/components/Icons';
import { Maintenance } from 'src/components/Maintenance';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useAuth } from 'src/hooks/useAuth';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { useMaintenance } from 'src/hooks/useMaintenance';
import styles from './VerifyAccount.module.css';

const cx = classnames.bind(styles);

export interface VerifyAccountProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const VerifyAccount: FC<VerifyAccountProps> = () => {
  const { hasActiveMaintenance } = useMaintenance();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState({
    status: 'idle',
  });
  const { verifyAccount } = useAuth();

  const { code } = useParams<{ code: string }>();

  useEffectOnce(() => {
    if (verifyAccount && !!code) {
      verifyAccount({ code: `${code}` }).then((result) => {
        if (result.status === 'success') {
          setAsyncState({
            status: 'success',
            message: 'Your account has been verified.',
          });
        } else {
          setAsyncState({
            status: 'error',
            message:
              result.value?.errors[0]?.message ??
              'There was an error verifying your account. Please try again.',
          });
        }
      });
    }
  });

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  let result;
  switch (asyncState.status) {
    case 'success': {
      result = (
        <animated.div style={animate}>
          <Alert
            variant="success"
            message={asyncState.message}
            className={cx('success')}
          />

          <Button
            onClick={() => {
              queryClient.refetchQueries(['user', 'current']).then(() => {
                history.push({ pathname: '/app/dashboard' });
              });
            }}
          >
            Home
          </Button>
        </animated.div>
      );
      break;
    }

    case 'error': {
      result = (
        <animated.div style={animate}>
          <Alert variant="danger" message={asyncState.message} />
        </animated.div>
      );
      break;
    }

    default: {
      result = <LoaderIcon height={32} width={32} className={cx('loader')} />;
      break;
    }
  }

  if (hasActiveMaintenance) {
    return <Maintenance />;
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('verifyAccount')} data-testid="verifyAccount">
          <section>
            <div className={cx('verifyAccountBox')}>
              <h2>Verify Account</h2>
              {result}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

VerifyAccount.displayName = 'VerifyAccount';
