import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { getReferenceData } from 'src/clients/api/v5.referenceData';
import { BlockError } from 'src/components/BlockError';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { AggregateResult } from 'src/types/aggregates';
import { InvestmentCalculator } from '../../../InvestmentCalculator';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type InvestmentCalculatorTabProps = {
  aggregate: AggregateResult;
};

export const InvestmentCalculatorTab: FC<InvestmentCalculatorTabProps> = ({
  aggregate,
}) => {
  const {
    isLoading: isLoadingReferenceData,
    data: referenceData,
    refetch,
    isFetching,
    error,
  } = useQuery(['referenceData'], () => getReferenceData());

  if (isLoadingReferenceData) {
    return <LoadingSpinner />;
  }

  if (error || (referenceData && referenceData.status === 'error')) {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading reporting years"
        message="We were unable to load your reporting years successfully"
      />
    );
  }

  if (referenceData && referenceData.status === 'success') {
    return (
      <div className={cx('facilityTargetTab')}>
        <InvestmentCalculator
          aggregate={aggregate}
          referenceData={referenceData.value.data}
        />
      </div>
    );
  }

  return <></>;
};

InvestmentCalculatorTab.displayName = 'InvestmentCalculatorTab';
