import {
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  Stack,
  Alert,
  keyframes,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getClassifications } from 'src/clients/api/classification';
import { getCountries } from 'src/clients/api/country';
import { getReferenceData } from 'src/clients/api/v5.referenceData';
import { BlockError } from 'src/components/BlockError';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { UploadFlowError } from 'src/constants/errors';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { MappedReferenceOptions } from 'src/types/referenceData';
import { ParsedUploadRowResults } from 'src/types/upload';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { AssessmentCarousel } from '../AssessmentCarousel';
import styles from '../FacilityForm.module.css';
import { FacilityTabNavigator } from '../Helpers/FacilityTabNavigator';
import { useTabNavigator } from '../Helpers/FacilityTabNavigator/useTabNavigator';
import { BusinessInformation } from '../Tabs/BusinessInformation';
import { FacilityDetails } from '../Tabs/FacilityDetails';
import { Pollutants } from '../Tabs/Pollutants';
import { RegulationReputation } from '../Tabs/RegulationReputation';
import { WaterQuality } from '../Tabs/WaterQuality';
import {
  mapDefaultReportingYearFormValues,
  TAB_INPUT_MAP,
  TAB_MAP,
} from '../utils';
import { FacilityFormSchema, facilityFormSchema } from '../validation';

const cx = classnames.bind(styles);

export type WrmFormProps = {
  availableYears: Array<number>;
  reportingYear?: ReportingYear;
  onSave: (values: FacilityFormSchema) => void;
  isEditing?: boolean;
  hydrateWith?: ParsedUploadRowResults;
  uploadErrorType?: UploadFlowError;
};

const jiggle = keyframes`
  8%, 41% { transform: translateX(-10px) }
  25%, 58% { transform: translateX(10px) }
  75% { transform: translateX(-5px) }
  92% { transform: translateX(5px) }
  0%, 100% { transform: translateX(0) }
`;

export const WrmForm: FC<WrmFormProps> = ({
  availableYears,
  reportingYear,
  onSave,
  isEditing,
  hydrateWith,
  uploadErrorType,
}) => {
  const history = useHistory();
  const params = useQueryParams();
  const initialTabKey = params.get('tab');

  const initialTab = useMemo(
    () => TAB_MAP.find((t) => t.urlQueryParam === initialTabKey)?.index,
    [initialTabKey]
  );

  const tabNavigator = useTabNavigator(TAB_MAP, initialTab);
  const { activeTabIndex } = tabNavigator;

  const isUploadResolutionFlow = !!hydrateWith;
  const isAssessmentError = uploadErrorType === UploadFlowError.Assessment;

  const { isLoading: isLoadingCountries } = useQuery('countries', () =>
    getCountries()
  );

  const { isLoading: isLoadingClassifications } = useQuery(
    ['classifications', 'levelOne'],
    () => getClassifications()
  );

  const { isLoading: isLoadingReferenceData, data: referenceData } = useQuery(
    ['referenceData'],
    () => getReferenceData()
  );

  const defaultValues = mapDefaultReportingYearFormValues(
    reportingYear,
    hydrateWith
  );

  console.log('defaulValues', {
    defaultValues,
    reportingYear,
    hydrateWith,
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(facilityFormSchema),
  });

  const { formState } = methods;
  const tabsInError = useMemo(
    () =>
      new Set([
        ...Object.keys(formState.errors).map(
          (key) => TAB_INPUT_MAP[key as keyof typeof TAB_INPUT_MAP]
        ),
        ...(isAssessmentError ? [5] : []),
      ]),
    [formState.errors, isAssessmentError]
  );

  useEffect(() => {
    if (!isUploadResolutionFlow) {
      return;
    }

    methods.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUploadResolutionFlow,
    isLoadingCountries,
    isLoadingClassifications,
    isLoadingReferenceData,
  ]);

  if (
    isLoadingCountries ||
    isLoadingClassifications ||
    isLoadingReferenceData
  ) {
    return (
      <div className={cx('createFacility', 'loading')}>
        <LoadingSpinner block />
      </div>
    );
  }

  const animation = `${jiggle} .5s linear`;

  const onCancel = () => history.go(-1);
  const handleSave = methods.handleSubmit(onSave, () => {
    document.getElementById('AppContent')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });

  if (referenceData && referenceData?.status === 'success') {
    const formReferenceData = {
      waterUnits: referenceData.value.data.waterUnits,
      currencies: referenceData.value.data.currencies,
      options: referenceData.value.data.options.reduce<MappedReferenceOptions>(
        (out, option) => ({
          ...out,
          [option.type]: option.options,
        }),
        {} as MappedReferenceOptions
      ),
    };

    const hasSubmitError =
      formState.isSubmitted && !formState.isSubmitSuccessful;
    const hasConflictError =
      isUploadResolutionFlow && (!formState.isValid || isAssessmentError);
    const hasFormError = hasSubmitError || hasConflictError;

    return (
      <FormProvider {...methods}>
        {hasFormError ? (
          <Stack direction="column" padding="4" gap="4">
            {hasSubmitError ? (
              <Alert
                status="error"
                alignItems="flex-start"
                animation={animation}
                sx={{
                  animationDelay: '.5s',
                }}
              >
                <AlertIcon />
                <Box>
                  <AlertTitle>Form could not be submitted</AlertTitle>
                  <AlertDescription>
                    Some form values are invalid.
                  </AlertDescription>
                </Box>
              </Alert>
            ) : null}
            {isUploadResolutionFlow && hasConflictError ? (
              <>
                {isAssessmentError ? (
                  <Alert status="error" alignItems="flex-start">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>You have upload conflicts</AlertTitle>
                      <AlertDescription>
                        Please complete your water action assessement.
                      </AlertDescription>
                    </Box>
                  </Alert>
                ) : (
                  <Alert status="error" alignItems="flex-start">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>You have upload conflicts</AlertTitle>
                      <AlertDescription>
                        Your upload data was not able to be mapped successfully.
                        Please resolve the conflicts below before continuing.
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}
              </>
            ) : null}
          </Stack>
        ) : null}
        <div className={cx('WrmForm')}>
          <FacilityTabNavigator
            tabNavigator={tabNavigator}
            tabsInError={tabsInError}
          />
          <article className={cx('content')}>
            <FacilityDetails
              isEditing={isEditing}
              availableYears={availableYears}
              referenceData={formReferenceData}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 0 })}
            />
            <BusinessInformation
              referenceData={formReferenceData}
              reportingYear={reportingYear}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 1 })}
            />
            <RegulationReputation
              referenceData={formReferenceData}
              reportingYear={reportingYear}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 2 })}
            />
            <WaterQuality
              referenceData={formReferenceData}
              reportingYear={reportingYear}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 3 })}
            />
            <Pollutants
              reportingYear={reportingYear}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 4 })}
            />
            <AssessmentCarousel
              assessment={referenceData.value.data.assessment}
              onSave={handleSave}
              onCancel={onCancel}
              className={cx({ active: activeTabIndex === 5 })}
            />
          </article>
        </div>
      </FormProvider>
    );
  }

  return <BlockError />;
};

WrmForm.displayName = 'WrmForm';
