import { Button, Flex, Heading, Stack, useBoolean } from '@chakra-ui/react';
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiPlusCircleOutline,
  mdiSync,
} from '@mdi/js';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/components/Icon';
import { FolderMutateModal } from './FolderMutateModal';
import { FolderList } from './FolderList';
import { TransfersGroup } from './TransfersGroup';
import {
  getTransfers,
  GetTransfersResponseType,
} from 'src/clients/api/v5.transfers';
import { useQuery, useQueryClient } from 'react-query';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import equal from 'fast-deep-equal';

type FolderManagerProps = {};

export const FolderManager: FC<FolderManagerProps> = () => {
  const previous = useRef<GetTransfersResponseType>();
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useBoolean();
  const [sortState, setSortState] = useState<'asc' | 'desc'>('asc');
  const [isViewingPast, setIsViewingPast] = useBoolean();

  const { data, refetch, isFetching } = useQuery(
    ['transfers'],
    () => getTransfers(),
    {
      refetchInterval: 15000,
      onSettled: () => {},
    }
  );

  const frozen = useDeepCompareMemo(() => {
    if (data?.status === 'success' && data?.value.data) {
      return new Set(
        data.value.data
          .filter((t) => t.status === 'queued' && !isNaN(Number(t.assetId)))
          .map((t) => Number(t.assetId))
      );
    }

    return new Set() as Set<number>;
  }, [data]);

  useEffect(() => {
    if (data?.status === 'success' && data?.value.data) {
      if (!equal(previous.current, data)) {
        queryClient.refetchQueries(['folders']);
      }

      previous.current = data?.value;
    }
  }, [data, queryClient]);

  return (
    <Stack direction="column" spacing="4" padding={6}>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Heading fontSize="32px" flex="1">
          Folder Manager
        </Heading>
        <Button
          variant="outline"
          borderColor="brand.primary"
          color="brand.primary"
          alignItems="center"
          leftIcon={<Icon path={mdiPlusCircleOutline} size="24px" />}
          onClick={setIsCreating.on}
        >
          Create Folder
        </Button>
        <FolderMutateModal isOpen={isCreating} onClose={setIsCreating.off} />
      </Stack>

      <Stack spacing="8">
        <Stack spacing="4">
          <Stack direction={{ base: 'column', md: 'row' }} alignItems="center">
            <Flex flex="1" w="full">
              <Heading size="md" flex={{ base: '1 1 auto', md: '0 0 auto' }}>
                Activity
              </Heading>
              <Button
                variant="outline"
                color="subtle"
                size="xs"
                onClick={setIsViewingPast.toggle}
                py="1"
                px="2"
                ml="2"
              >
                Show {isViewingPast ? 'Active Transfers' : 'Past Transfers'}
              </Button>
            </Flex>
            <Flex flex="1" w="full" justifyContent="flex-end">
              <Button
                variant="outline"
                bg="bg-subtle"
                color="subtle"
                size="xs"
                leftIcon={
                  <Icon
                    path={mdiSync}
                    color="subtle"
                    vertical
                    spin={isFetching}
                  />
                }
                onClick={() => refetch()}
                isDisabled={isFetching}
                py="1"
                px="2"
              >
                Refresh
              </Button>
            </Flex>
          </Stack>
          <TransfersGroup showPast={isViewingPast} />
        </Stack>

        <Stack spacing="4">
          <Stack direction="row" alignItems="center">
            <Heading size="md" flex="1">
              Folders
            </Heading>
            <Button
              variant="outline"
              bg="bg-subtle"
              color="subtle"
              size="xs"
              leftIcon={
                <Icon path={sortState === 'asc' ? mdiArrowUp : mdiArrowDown} />
              }
              onClick={() => setSortState(sortState === 'asc' ? 'desc' : 'asc')}
              py="1"
              px="2"
            >
              Sort ({sortState})
            </Button>
          </Stack>

          <FolderList sort={sortState} frozen={frozen} />
        </Stack>
      </Stack>
    </Stack>
  );
};

FolderManager.displayName = 'FolderManager';
