import {
  Alert,
  AlertIcon,
  Box,
  CloseButton,
  Flex,
  Heading,
  ScaleFade,
  SlideFade,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import natsort from 'natsort';
import React, { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getActiveBatchUploads } from 'src/clients/api/upload';
import { ActiveUploadCard } from 'src/components/ActiveUploadCard';
import { BlockError } from 'src/components/BlockError';
import { Uploader } from 'src/components/Uploader';
import { useAsyncState } from 'src/hooks/useAsyncState';

type UploadProps = {};

const sorter = natsort();

export const Uploads: FC<UploadProps> = () => {
  const [asyncUploadState, setAsyncUploadState] = useAsyncState();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, data, refetch, isFetching, isError } = useQuery(
    ['activeUploads'],
    () => getActiveBatchUploads()
  );

  const shouldShowAlertStatus =
    asyncUploadState.status === 'success' ||
    asyncUploadState.status === 'error';

  useEffect(() => {
    if (shouldShowAlertStatus) {
      onOpen();
    }
  }, [shouldShowAlertStatus, onOpen]);

  if (isLoading) {
    return (
      <Flex flex="1 1 auto" alignItems="center" justifyContent="center">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (isError || (data && data.status === 'error')) {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading active uploads"
        message="We were unable to load your active uploads successfully"
      />
    );
  }

  const uploads = data?.value.data ?? [];
  uploads.sort((a, b) => {
    if (a.status === 'processed' && b.status !== 'processed') {
      return -1;
    }

    return 0;
  });

  const shouldShowUploader = !uploads.find(
    (upload) => upload.status !== 'processed'
  );

  uploads.sort((a, b) => sorter(a.createdDate, b.createdDate));

  const completed = uploads.filter((upload) => upload.status === 'processed');
  const incomplete = uploads.filter((upload) => upload.status !== 'processed');

  return (
    <Stack direction="column" spacing="4" padding={6}>
      <Heading fontSize="32px">Batch Upload</Heading>

      {shouldShowAlertStatus && isOpen ? (
        <ScaleFade initialScale={0.95} in={true}>
          <Alert
            status={asyncUploadState.status}
            variant="left-accent"
            justifyContent="space-between"
          >
            <Flex flex="1 1 auto" alignItems="center">
              <AlertIcon />
              <Text>{asyncUploadState.message}</Text>
            </Flex>
            <CloseButton
              alignSelf="center"
              position="relative"
              flex="0 0 auto"
              onClick={onClose}
            />
          </Alert>
        </ScaleFade>
      ) : null}

      {shouldShowUploader ? (
        <ScaleFade initialScale={0.95} in={true}>
          <Uploader
            asyncUploadState={asyncUploadState}
            setAsyncUploadState={setAsyncUploadState}
          />
        </ScaleFade>
      ) : null}

      <Stack spacing="4">
        <Heading size="md">Active Upload</Heading>
        <Stack direction="column" spacing={4} shouldWrapChildren>
          {incomplete.length > 0 ? (
            incomplete.map((upload, index) => (
              <SlideFade
                key={upload.id}
                in={true}
                offsetX="10px"
                delay={(index + 2) / 10}
              >
                <ActiveUploadCard upload={upload} canInspect />
              </SlideFade>
            ))
          ) : (
            <Text>No active uploads</Text>
          )}
        </Stack>
      </Stack>

      {completed.length > 0 ? (
        <>
          <Box />

          <Stack>
            <Heading size="md">Past Uploads</Heading>
            <Stack direction="column" spacing={4} shouldWrapChildren>
              {completed.map((upload, index) => {
                return (
                  <SlideFade
                    key={upload.id}
                    in={true}
                    offsetX="10px"
                    delay={(index + 2) / 10}
                  >
                    <ActiveUploadCard upload={upload} canInspect />
                  </SlideFade>
                );
              })}
            </Stack>
          </Stack>
        </>
      ) : null}
    </Stack>
  );
};

Uploads.displayName = 'Uploads';
