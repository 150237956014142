export default {
  brand: {
    primary: '#005075',
    secondary: '#5EB6E4',
    secondaryLight: '#8acaeb',

    blue: '#007AC9',
    darkBlue: '#005075',
    lightBlue: '#5EB6E4',
    teal: '#70CACB',
    green: '#58A618',
    darkGreen: '#275E37',
  },
  primary: {
    50: '#dbf9ff',
    100: '#afe8ff',
    200: '#80d7ff',
    300: '#51c7fe',
    400: '#2bb6fc',
    500: '#1b9de3',
    600: '#0d7ab2',
    700: '#005780',
    800: '#00344f',
    900: '#00121f',
  },
};
