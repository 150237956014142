import { AggregateResult, AggregateResults } from 'src/types/aggregates';
import { urlWithAccountId } from '../helpers';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetAggregatesResponseType = WrappedResponse<AggregateResults>;
export function getAggregates() {
  return request.get<GetAggregatesResponseType>(
    urlWithAccountId(`aggregate-results`)
  );
}

export type GetAggregateResponseType = WrappedResponse<AggregateResult>;
export function getAggregate(id: string) {
  return request.get<GetAggregateResponseType>(
    urlWithAccountId(`aggregate-results/${id}`)
  );
}
