import { asCommaSeparatedString } from '../number';

export function paybackPeriodChartDataGenerator(
  conventional: Array<number>,
  riskAdjusted: Array<number>,
  initialYear: number
) {
  return {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Payback Period: Conventional and Risk Adjusted Methods',
    },
    xAxis: {
      title: {
        text: 'Year',
      },
      tickInterval: 1,
      labels: {
        formatter: function (this: { value: string }): string {
          return this.value + initialYear;
        },
      },
    },
    yAxis: {
      title: {
        text: 'Cash Flow Balance (NPV)',
      },
      plotLines: [
        {
          color: '#e6e6e6',
          dashStyle: 'Solid',
          width: 3,
          value: 0,
        },
      ],
    },
    plotOptions: {
      line: {
        enableMouseTracking: true,
      },
    },
    series: [
      {
        name: 'Conventional',
        data: conventional,
      },
      {
        name: 'Risk Adjusted',
        data: riskAdjusted,
      },
    ],
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '',
      pointFormatter: function (this: { y: number }): string {
        return asCommaSeparatedString(this.y, 2);
      },
    },
    exporting: { enabled: false },
  };
}
