import { Box, Flex, Image } from '@chakra-ui/react';
import React, { FC } from 'react';

type PageHeaderProps = {
  title: FC;
  subtitle?: FC;
  image: string;
  actions?: FC;
};

export const PageHeader: FC<PageHeaderProps> = ({
  title: Title,
  image,
  subtitle: Subtitle,
  actions: Actions,
}) => {
  return (
    <Flex height="300px" maxH="300px" position="relative" color="white">
      <Flex backgroundColor="#005075" position="relative" height="300px">
        <Image
          alt={image}
          height="300px"
          src={image}
          width="100%"
          fit={'cover'}
        />

        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          background="linear-gradient(to right, #005075 0%, transparent 75%)"
        />
      </Flex>

      <Flex
        height="300px"
        direction={'column'}
        p="20px 20px 20px 70px"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        flexDirection={'column'}
        justifyContent="space-around"
      >
        <Box width={{ base: '100%', lg: '30%' }}>
          <Title />
          {Subtitle ? <Subtitle /> : null}
          {Actions ? <Actions /> : null}
        </Box>
      </Flex>
    </Flex>
  );
};

PageHeader.displayName = 'PageHeader';
