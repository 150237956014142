import classnames from 'classnames/bind';
import React, { FC, useMemo, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import natsort from 'natsort';
import { useQuery } from 'react-query';
import { generateAccountCsvReport } from 'src/clients/api/account';
import {
  generateFolderCsvReport,
  generateFolderPDFReport,
  getFolderAggregates,
  getFolders,
} from 'src/clients/api/v5.folders';
import { BlockError } from 'src/components/BlockError';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { Dialog } from 'src/components/Dialog';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { RadioList } from 'src/components/RadioList';
import { SelectInput } from 'src/components/SelectInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import {
  benchmarkingChartDataGenerator,
  riskAnalysisChartDataGenerator,
} from 'src/utils/chart';
import { escapeQuotes } from 'src/utils/string';
import styles from './GenerateReportDialog.module.css';

const cx = classnames.bind(styles);
const sorter = natsort();

type GenerateReportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GenerateReportDialog: FC<GenerateReportDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const [state, setState] = useAsyncState();
  const [reportType, setReportType] = useState<'csv' | 'pdf'>('csv');
  const [selectedFolder, setSelectedFolder] = useState<string | ''>('');
  const riskAnalysisChart = useRef(null);
  const benchmarkingChart = useRef(null);

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const {
    isLoading: isLoadingFacilities,
    data: facilityData,
    error: facilityError,
  } = useQuery(
    ['aggregates', 'folders', selectedFolder],
    () => getFolderAggregates(selectedFolder),
    {
      enabled: selectedFolder !== '' && reportType === 'pdf',
    }
  );

  const handleGenerate = async () => {
    try {
      setState({ status: 'loading' });

      const result = await (reportType === 'csv'
        ? !selectedFolder
          ? generateAccountCsvReport()
          : generateFolderCsvReport(selectedFolder)
        : generateFolderPDFReport(selectedFolder, {
            riskAnalysisChart: escapeQuotes(
              // @ts-ignore
              riskAnalysisChart.current!.chart.getSVG()
            ),
            benchmarkingChart: escapeQuotes(
              // @ts-ignore
              benchmarkingChart.current!.chart.getSVG()
            ),
          }));

      if (result.status !== 'success') {
        throw new Error(result.value.errors[0].message);
      }

      setState({
        status: 'success',
        message: 'Report generated successfully.',
      });

      window.open(result.value.data, '_self');

      onClose();
    } catch (e) {
      setState({
        status: 'error',
        message: e.message ?? 'Something went wrong.',
      });
    }
  };

  const folders = useMemo(
    () =>
      (data?.status === 'success' ? data.value.data ?? [] : [])
        .filter((f) => f.facilitiesInFolder.length > 0)
        .sort((a, b) => sorter(a.folderName, b.folderName)),
    [data]
  );

  const facilities = useMemo(() => {
    return facilityData?.status === 'success'
      ? facilityData.value.data
          .sort((a, b) =>
            sorter(a.facility.facilityName, b.facility.facilityName)
          )
          .map((f, idx) => ({
            ...f,
            idx: idx + 1,
          }))
      : [];
  }, [facilityData]);

  const riskAnalysisChartOptions = useMemo(() => {
    if (facilities) {
      return riskAnalysisChartDataGenerator(facilities);
    }

    return undefined;
  }, [facilities]);

  const benchmarkingChartOptions = useMemo(() => {
    if (facilities) {
      return benchmarkingChartDataGenerator(facilities, undefined, {
        type: 'Incoming',
        intensity: 'Production',
        showOrgAvg: true,
        showSectorAvg: true,
      });
    }

    return undefined;
  }, [facilities]);

  return (
    <Dialog
      isOpen={isOpen}
      title="Generate Report"
      description="Report Type"
      className={cx('generateReportDialog')}
      onClose={onClose}
    >
      <div className={cx('content')}>
        {isLoading || isLoadingFacilities ? <LoadingSpinner /> : null}

        {!isLoading &&
        (facilityError ||
          error ||
          (data?.status !== 'success' && data?.code !== 404)) ? (
          <BlockError
            retry={refetch}
            isLoading={isFetching}
            title="Error loading folders"
            message="We were unable to load your folders successfully"
          />
        ) : null}

        {data?.status === 'success' || data?.code === 404 ? (
          <>
            <RadioList
              items={[
                { value: 'csv', label: 'CSV Report' },
                ...(!!folders.length
                  ? [{ value: 'pdf', label: 'PDF Summary Report' }]
                  : []),
              ]}
              value={reportType}
              onChange={(v) => (v === 'csv' || v === 'pdf') && setReportType(v)}
            />
            {reportType === 'csv' ? (
              <>
                <SelectInput
                  items={[
                    { value: '', label: 'All folders' },
                    ...folders.map((f) => ({
                      value: `${f.folderId}`,
                      label: f.folderName,
                    })),
                  ]}
                  value={`${selectedFolder}` ?? ''}
                  onChange={(value: string) => setSelectedFolder(value)}
                  className={cx('input')}
                />
                <div className={cx('actions')}>
                  <Button
                    onClick={handleGenerate}
                    isLoading={state.status === 'loading'}
                  >
                    Generate
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Copy>
                  Select a folder to generate a PDF report for your facilities.
                </Copy>
                <SelectInput
                  items={[
                    ...folders.map((f) => ({
                      value: `${f.folderId}`,
                      label: f.folderName,
                    })),
                  ]}
                  value={`${selectedFolder}`}
                  onChange={(value: string) => setSelectedFolder(value)}
                  className={cx('input')}
                />
                <div className={cx('actions')}>
                  <Button
                    onClick={handleGenerate}
                    disabled={selectedFolder === ''}
                    isLoading={state.status === 'loading'}
                  >
                    Generate
                  </Button>
                </div>
              </>
            )}
          </>
        ) : null}

        {!isLoading && !isLoadingFacilities ? (
          <div style={{ display: 'none' }}>
            <HighchartsReact
              ref={riskAnalysisChart}
              highcharts={Highcharts}
              options={riskAnalysisChartOptions}
            />
            <HighchartsReact
              ref={benchmarkingChart}
              highcharts={Highcharts}
              options={benchmarkingChartOptions}
            />
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};

GenerateReportDialog.displayName = 'GenerateReportDialog';
