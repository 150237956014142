import React, { FC } from 'react';
import { VerticalTabContainer } from '../../VerticalTabContainer';
import classnames from 'classnames/bind';
import { WaterWithdrawalRisk } from './Tabs/WaterWithdrawalRisk';
import { AggregateResult } from 'src/types/aggregates';
import styles from '../Tabs.module.css';

export type SetTargetsTabGroupProps = {
  aggregate: AggregateResult;
  className?: string;
};

const TABS = ['Water Withdrawal Risk'];

const cx = classnames.bind(styles);

export const SetTargetsTabGroup: FC<SetTargetsTabGroupProps> = ({
  aggregate,
  className,
}) => {
  return (
    <VerticalTabContainer
      tabs={TABS}
      className={cx('animate', 'fadeInDown', className)}
    >
      {(activeTabIndex) => (
        <>
          {activeTabIndex === 0 ? (
            <WaterWithdrawalRisk aggregate={aggregate} />
          ) : null}
        </>
      )}
    </VerticalTabContainer>
  );
};

SetTargetsTabGroup.displayName = 'SetTargetsTabGroup';
