import * as yup from 'yup';

const PASSWORD_VALIDATOR = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d].{7,}/;

export const loginFormSchema = yup
  .object({
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
  })
  .required();

export type LoginFormSchemaPayload = yup.InferType<typeof loginFormSchema>;

export const registrationFormSchema = yup
  .object({
    firstName: yup.string().trim().required('Required'),
    lastName: yup.string().trim().required('Required'),
    companyName: yup.string().trim().required('Required'),
    jobTitle: yup.string().trim().required('Required'),
    country: yup.string().trim().required('Required'),
    email: yup.string().trim().email().required('Required'),
    password: yup
      .string()
      .trim()
      .matches(
        PASSWORD_VALIDATOR,
        'Must contain 8 characters including 1 uppercase, 1 lowercase, and 1 number'
      )
      .required('Required'),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Required'),
    acceptTerms: yup.boolean().oneOf([true], 'Required').required('Required'),
    explicitOptIn: yup.boolean(),
  })
  .required();

export type RegisterFormSchemaPayload = yup.InferType<
  typeof registrationFormSchema
>;

export const forgotPasswordFormSchema = yup
  .object({
    email: yup.string().trim().email().required('Required'),
  })
  .required();

export type ForgotPasswordSchemaPayload = yup.InferType<
  typeof forgotPasswordFormSchema
>;

export const resetPasswordFormSchema = yup
  .object({
    resetPasswordCode: yup.string().trim().required('Required'),
    password: yup
      .string()
      .trim()
      .matches(
        PASSWORD_VALIDATOR,
        'Must contain 8 characters including 1 uppercase, 1 lowercase, and 1 number'
      )
      .required('Required'),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Required'),
  })
  .required();

export type ResetPasswordSchemaPayload = yup.InferType<
  typeof resetPasswordFormSchema
>;

export const verifyAccountFormSchema = yup
  .object({
    code: yup.string().trim().required('Required'),
  })
  .required();

export type VerifyAccountSchemaPayload = yup.InferType<
  typeof verifyAccountFormSchema
>;
