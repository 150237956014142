import { Tag, TagLeftIcon, TagLabel } from '@chakra-ui/react';
import {
  mdiCancel,
  mdiCheck,
  mdiCheckAll,
  mdiProgressAlert,
  mdiSync,
} from '@mdi/js';
import React, { FC } from 'react';
import { Icon } from 'src/components/Icon';
import { Transfer } from 'src/types/transfer';

type TransferStatusTagProps = {
  status: Transfer['status'];
};

enum STATUS_COLOR {
  transferred = 'green',
  failed = 'red',
  accepted = 'green',
  declined = 'red',
  queued = 'gray',
}

const STATUS_ICON = {
  transferred: mdiCheckAll,
  failed: mdiProgressAlert,
  accepted: mdiCheck,
  declined: mdiCancel,
  queued: mdiSync,
};

const getStatusText = (status: TransferStatusTagProps['status']) => {
  switch (status) {
    case 'queued': {
      return 'pending';
    }
    default: {
      return status;
    }
  }
};

export const TransferStatusTag: FC<TransferStatusTagProps> = ({ status }) => {
  return (
    <Tag
      size="lg"
      width={{ base: '100%', md: 'auto' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      variant="subtle"
      colorScheme={STATUS_COLOR[status]}
    >
      <TagLeftIcon
        as={Icon}
        path={STATUS_ICON[status]}
        {...(status === 'queued' ? { vertical: true, spin: true } : {})}
      />
      <TagLabel textAlign={{ base: 'center', md: 'left' }}>
        {getStatusText(status)}
      </TagLabel>
    </Tag>
  );
};

TransferStatusTag.displayName = 'TransferStatusTag';
