import { Box } from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { ParsedGuide } from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type ScenarioProps = {
  guide: ParsedGuide['Scenario'];
  maturity: string;
  graphUrl: string;
};

export const Scenario: FC<ScenarioProps> = ({ guide, maturity, graphUrl }) => {
  const { Header, Content } = guide;

  try {
    return (
      <div className={cx('facilityTargetTab')}>
        <div className={cx('header', 'row')}>
          <Copy as="h2">Scenario</Copy>
          <div className={cx('twoUp')}>
            <div className={cx('left')}>
              <Box
                my="4"
                className={cx('subHeader')}
                dangerouslySetInnerHTML={{
                  __html: Header.nodeValue,
                }}
              />
              <Box
                className={cx('subHeader')}
                dangerouslySetInnerHTML={{
                  __html: Content.nodeValue,
                }}
              />
            </div>
            <div className={cx('right')}>
              <img
                className={cx('img')}
                src={graphUrl}
                alt={`${maturity} graph`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } catch (error) {
    return <BlockError />;
  }
};

Scenario.displayName = 'Scenario';
