import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './Alert.module.css';
import { Copy } from '../Copy';

const cx = classnames.bind(styles);

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: 'danger' | 'success' | 'warning' | 'info';
  title?: string;
  message: string;
}

export const Alert: FC<AlertProps> = ({
  title,
  message,
  variant,
  className,
}) => {
  return (
    <div className={cx('alert', variant, className)}>
      {title && <Copy as="h3">{title}</Copy>}
      <Copy as="p">{message}</Copy>
    </div>
  );
};

Alert.displayName = 'Alert';
