import React, { FC, ReactElement, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './VerticalTabContainer.module.css';
import { SelectInput } from 'src/components/SelectInput';

const cx = classnames.bind(styles);

type VerticalTabContainerProps = {
  tabs: Array<string>;
  children: (activeTabIndex: number) => ReactElement;
  className?: string;
};

export const VerticalTabContainer: FC<VerticalTabContainerProps> = ({
  tabs,
  children,
  className,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={cx('verticalTabContainer', className)}>
      {!!tabs.length ? (
        <>
          <div className={cx('selector')}>
            <SelectInput
              items={tabs.map((label, index) => ({
                label,
                value: `${index}`,
              }))}
              value={`${activeTab}`}
              placeholder="&nbsp;"
              onChange={(value) => setActiveTab(+value)}
            />
          </div>
          <div className={cx('tabs')}>
            <div className={cx('shadow')}></div>
            {tabs.map((label, index) => (
              <div
                className={cx('tab', { active: activeTab === index })}
                onClick={() => setActiveTab(index)}
                key={label}
              >
                {label}
              </div>
            ))}
          </div>
        </>
      ) : null}
      <div className={cx('tabContent')}>{children(activeTab)}</div>
    </div>
  );
};

VerticalTabContainer.displayName = 'VerticalTabContainer';
