import { Accordion, ExpandedIndex, Text } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { getFacilitiesList } from 'src/clients/api/v5.facility';
import { getFolders } from 'src/clients/api/v5.folders';
import { BlockError } from 'src/components/BlockError';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { FolderItem } from './FolderItem';

type FolderListProps = {
  sort: 'asc' | 'desc';
  frozen: Set<number>;
};

export const FolderList: FC<FolderListProps> = ({ sort, frozen }) => {
  const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>(-1);
  const folderRes = useQuery(['folders'], () => getFolders());

  const facilitiesRes = useQuery(['facilities'], () => getFacilitiesList());

  const facilities =
    facilitiesRes.data?.status === 'success' && facilitiesRes.data?.value.data
      ? facilitiesRes.data?.value.data
      : [];

  const folders =
    folderRes.data?.status === 'success' && !!folderRes.data?.value.data
      ? folderRes.data?.value.data
      : [];

  const sortedFolders = useDeepCompareMemo(() => {
    const arr = [...folders].sort((a, b) => {
      const aProp = a.folderName;
      const bProp = b.folderName;
      if (aProp && bProp) {
        if (aProp < bProp) {
          return -1;
        }
        if (aProp > bProp) {
          return 1;
        }
      }

      return 0;
    });

    if (sort === 'desc') {
      return arr.reverse();
    }

    return arr;
  }, [folders, sort]);

  const { data, isLoading, isError, refetch, isFetching } = folderRes;

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (isError || (data && data.status === 'error')) {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading folders"
        message="We were unable to load your folders successfully"
      />
    );
  }

  return (
    <Accordion
      index={accordionIndex}
      onChange={(val) => setAccordionIndex(val)}
      allowToggle
    >
      {sortedFolders.length > 0 ? (
        sortedFolders.map((folder, idx) => (
          <FolderItem
            facilities={facilities}
            key={folder.folderId}
            folder={folder}
            isOpen={accordionIndex === idx}
            isFrozen={frozen.has(folder.folderId)}
            onToggle={() =>
              setAccordionIndex(accordionIndex === idx ? -1 : idx)
            }
          />
        ))
      ) : (
        <Text>No folders found</Text>
      )}
    </Accordion>
  );
};
