export const DEFAULT_CHART = {
  chart: {
    height: 300,
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: { enabled: false },
};

export const RISK_COLOR_MAP = {
  high: '#ec5c39',
  medium: '#f7bb16',
  low: '#93be4c',
  none: '#717171',
};

export const STRESS_COLOR_MAP = {
  'Extremely high': RISK_COLOR_MAP['high'],
  High: RISK_COLOR_MAP['high'],
  'Medium-high': RISK_COLOR_MAP['medium'],
  'Low-medium': RISK_COLOR_MAP['medium'],
  Low: RISK_COLOR_MAP['low'],
  none: RISK_COLOR_MAP['none'],
} as const;

export const COLOR_MAP = {
  R: { text: 'HIGH', color: 'danger' },
  Y: { text: 'MEDIUM', color: 'warning' },
  G: { text: 'LOW', color: 'success' },
  N: { text: 'NONE', color: 'none' },
};

export const WITHDRAWAL_RISK_SUGGESTION_MAP = {
  high:
    'greater than 50% decrease needed from current annual water user, invest in smart water strategies immediately',
  medium:
    'between 25% and 50% decrease needed from current annual water use, invest in smart water strategies',
  low:
    'between 0% and 25% decrease needed from current annual water use, monitor water issues and invest in smart water strategies',
  none:
    'less than 0% decrease required needed current annual water use, monitor water issues accordingly',
};

export function getRegressionLineV3(
  xData: Array<number>,
  yData: Array<number>,
  revenueSlope?: number
) {
  const regressionLine = [];
  let slope = revenueSlope ? revenueSlope : getSlopeV3(xData, yData);

  for (let i = 0; i < xData.length; i++) {
    regressionLine[i] = xData[i] / slope;
  }

  regressionLine.sort(function (a, b) {
    return a - b;
  });

  return {
    xData,
    yData,
    values: regressionLine,
  };
}

export function getColorByFacilityRank(rank: string) {
  switch (rank) {
    case 'HIGH-HIGH': {
      return '#e64848';
    }
    case 'HIGH-LOW': {
      return '#dc8f18';
    }
    case 'LOW-HIGH': {
      return '#6ba737';
    }
    default: {
      return '#717171';
    }
  }
}

export function getSlopeV3(xData: Array<number>, yData: Array<number>) {
  return arrayAverage(waterIntensityAverage(xData, yData));
}

function waterIntensityAverage(xData: Array<number>, yData: Array<number>) {
  var i,
    waterIntensity = [];

  for (i = 0; i < xData.length; i++) {
    waterIntensity[i] = xData[i] / yData[i];
  }

  return waterIntensity;
}

function arrayAverage(array: Array<number>) {
  return array.reduce((acc, c) => acc + c, 0) / array.length;
}

export function getScatterPlotOptions() {
  return {
    dataLabels: {
      enabled: true,
      shadow: false,
      formatter: function (): string {
        // @ts-expect-error
        return `${this.point.idx}`;
      },
      allowOverlap: true,
      align: 'center',
      verticalAlign: 'middle',
      inside: true,
      useHTML: true,
      format: `<div class="scatter-label" style="display:flex; position: relative; align-items: center; justify-content:center; border: 2px solid {point.color}; color: black; background-color: white; border-radius: 50px; height: 20px; width:20px; z-index: {point.idx}">{point.idx}</div>`,
    },
    marker: {
      enabled: false,
      radius: 1,
      symbol: 'dot',
      lineColor: null, // inherit from series
    },
    zIndex: 6,
  };
}
