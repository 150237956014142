import {
  ListItem,
  OrderedList,
  Table,
  Text,
  Tbody,
  Td,
  Thead,
  Tr,
  UnorderedList,
  Box,
  Stack,
} from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getAggregates } from 'src/clients/api/v5.aggregates';
import { getFolderAggregates, getFolders } from 'src/clients/api/v5.folders';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import tabStyles from 'src/components/FacilityForm/Helpers/FacilityTabNavigator/FacilityTabNavigator.module.css';
import { FacilityRiskFilter } from 'src/components/FacilityRiskFilter';
import { FilterAndSort } from 'src/components/FilterAndSort';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { RiskAnalysisChart } from 'src/components/RiskAnalysisChart';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { FacilityRiskFilterProvider } from 'src/hooks/useFacilityRiskFilter';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { filter } from 'src/hooks/useFilterAndSort/utils';
import { AggregateResults } from 'src/types/aggregates';
import { Folders } from 'src/types/folder';
import styles from './RiskAnalysis.module.css';

const cx = classnames.bind(styles);

type RiskAnalysisProps = {
  aggregates: AggregateResults;
  folders: Folders;
};

const RiskAnalysisWrapper: FC<RiskAnalysisProps> = ({
  children,
  aggregates,
  folders,
}) => (
  <>
    <header>
      <nav className={cx('tabs', tabStyles.tabs)}>
        <div className={cx('shadow', tabStyles.shadow)}></div>
        <div
          className={cx(
            'tab',
            'active',
            `${tabStyles.tab} ${tabStyles.active}`
          )}
        >
          Quantify Risk
        </div>
        <Link
          className={cx('tab', tabStyles.tab)}
          to={'/app/enterprise/benchmarking'}
        >
          Track Performance
        </Link>
      </nav>
    </header>
    <div className={cx('riskAnalysis')}>
      <div className={cx('header')}>
        <Copy as="h2">Quantify Risk</Copy>
        <Copy as="p">
          This Water Risk Analysis charts a company's enterprise risk profile by
          assessing each facility's risk based on three year projected output
          growth and location-specific water stress and assigns action plans for
          facilities based on risk likelihood. This analysis allows business
          leaders to prioritize and determine appropriate risk mitigation
          strategies for each location to reduce a company's overall risk
          profile.
        </Copy>
        <br />
        <Copy as="p">
          The following steps can be used to develop a successful corporate
          water strategy:
        </Copy>
        <OrderedList pl="10px">
          <ListItem>
            <Copy as="p">Establish a water management plan</Copy>
          </ListItem>
          <ListItem>
            <Copy as="p">
              Assess and monetize business risks to prioritize actions at
              specific sites
            </Copy>
          </ListItem>
          <ListItem>
            <Copy as="p">
              Identify opportunities to minimize water risk, maximize
              performance results and optimize costs
            </Copy>
          </ListItem>
          <ListItem>
            <Copy as="p">
              Execute water management plan using a "plan-do-check-act" cycle
            </Copy>
          </ListItem>
        </OrderedList>
      </div>
      <div className={cx('filterAndSort')}>
        <FilterAndSort hideSort aggregates={aggregates} folders={folders} />
      </div>
      {children}
    </div>
  </>
);

export const RiskAnalysis: FC<RiskAnalysisProps> = () => {
  const filterState = useFilterAndSort();

  const { data: folderData, isLoading: isLoadingFolders } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const folderId = filterState.folderId;
  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['aggregates', ...(folderId ? ['folder', folderId] : [])],
    () => (folderId ? getFolderAggregates(folderId) : getAggregates()),
    {
      cacheTime: 0,
      enabled: !isLoadingFolders,
    }
  );

  const aggregates =
    data?.status === 'success' && data?.value.data ? data?.value.data : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.data
      ? folderData?.value.data
      : [];

  const filteredAggregates = useDeepCompareMemo(() => {
    return filter(aggregates, filterState);
  }, [aggregates, filterState]);

  if (isLoading || isLoadingFolders) {
    return (
      <RiskAnalysisWrapper aggregates={filteredAggregates} folders={folders}>
        <LoadingSpinner />
      </RiskAnalysisWrapper>
    );
  }

  if (error || data?.status === 'error') {
    return (
      <RiskAnalysisWrapper aggregates={filteredAggregates} folders={folders}>
        <BlockError
          retry={refetch}
          isLoading={isFetching}
          title="Error loading facilities"
          message="We were unable to load your facilities successfully"
        />
      </RiskAnalysisWrapper>
    );
  }

  return (
    <RiskAnalysisWrapper aggregates={aggregates} folders={folders}>
      <div className={cx('content')}>
        <FacilityRiskFilterProvider aggregates={filteredAggregates}>
          <div className={cx('charts')}>
            <Stack direction="column">
              <Box className="responsive" flex="1 1 100%">
                <RiskAnalysisChart />
              </Box>
              <Box mt="16">
                <Text as="h3" fontWeight="bold" mb="8px">
                  Key:
                </Text>
                <Table
                  border="1px solid"
                  borderColor="gray.300"
                  sx={{
                    '& tbody > tr': {
                      borderBottom: '1px solid',
                      borderColor: 'gray.400',
                    },
                    '& tbody > tr:nth-child(even)': {
                      bgColor: 'gray.200',
                    },
                    '& tbody td': {
                      verticalAlign: 'top',
                      borderRight: '1px solid',
                      borderColor: 'gray.400',
                    },
                  }}
                >
                  <Thead>
                    <Tr bgColor="#00547a" color="white">
                      <Td w="20%">
                        <strong>Facility Risk Level</strong>
                      </Td>
                      <Td w="40%">
                        <strong>Context</strong>
                      </Td>
                      <Td w="40%">
                        <strong>Actions</strong>
                      </Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Text fontSize="sm" fontWeight="600">
                          Significant potential for business risk
                        </Text>
                        <Text fontSize="sm">
                          (High Growth & High Water Scarcity)
                        </Text>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            Today, more than ever before, companies recognize
                            that water poses a significant risk to business. For
                            some facilities located in high water stress basins,
                            urgent action is required to prevent threats to
                            business vitality ranging from disruptions in
                            operations and rising costs to reputational damage
                            and loss of license to operate. Facilities in
                            locations where high growth rates could be hindered
                            by high water scarcity should take the following
                            steps:
                          </Text>
                          <UnorderedList spacing="3" pl="20px">
                            <ListItem>
                              <Text fontSize="sm">
                                Prioritize locations for targeted action plans
                                based on site-specific risk (locations where
                                water risk is most material)
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Identify opportunities to minimize water risk,
                                maximize performance results and optimize costs
                                (reduce, reuse and recycle)
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Execute water management plan using a
                                “plan-do-check-act” cycle
                              </Text>
                            </ListItem>
                          </UnorderedList>
                        </Stack>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            <strong>INVEST:</strong>
                          </Text>
                          <UnorderedList spacing="3" pl="20px">
                            <ListItem>
                              <Text fontSize="sm">
                                Explore the “Take Action” tab on your dashboard
                                to review your tailored guide to implement
                                improvements in water management, use,
                                target-setting and stewardship practices
                              </Text>
                            </ListItem>
                            <ListItem ml="20px">
                              <Text fontSize="sm">
                                Use the Risk Analysis Investment Calculator to
                                model project investment outcomes
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Establish a process to monitor local water
                                availability (for example droughts or pollution
                                incidents) so that acute events can be managed
                                in a way that reduces business disruption or
                                cost
                              </Text>
                            </ListItem>
                          </UnorderedList>
                        </Stack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm" fontWeight="600">
                          Moderate potential for business risk based on low
                          growth potential
                        </Text>
                        <Text fontSize="sm">
                          (Low Growth & High Water Scarcity)
                        </Text>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            Today, more than ever before, companies recognize
                            the growing challenges posed by resource scarcity,
                            energy demands, climate change and increasing
                            competition for freshwater resources. Even at
                            current use levels, companies operating in water
                            scarce regions face increasingly tangible business
                            risks. Water is a shared resource and therefore
                            requires a stewardship approach to ensure
                            sustainable outcomes for all.
                          </Text>
                          <Text fontSize="sm">
                            Good water stewards understand their own water use,
                            catchment context and shared concerns related to
                            water governance, water balance and water quality.
                            With this awareness, good water stewards engage in
                            meaningful individual and collective actions that
                            benefit people and the environment.
                          </Text>
                        </Stack>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            <strong>ENGAGE:</strong>
                          </Text>
                          <UnorderedList spacing="3" pl="20px">
                            <ListItem>
                              <Text fontSize="sm">
                                Explore the “Take Action” tab on your dashboard
                                to review your tailored guide to implement
                                improvements in water management, use,
                                target-setting and stewardship practices
                              </Text>
                            </ListItem>
                            <ListItem ml="20px">
                              <Text fontSize="sm">
                                Use the Risk Analysis Investment Calculator to
                                model project investment outcomes
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Meet with local water service providers to
                                understand the current state of the watershed,
                                plans to manage stakeholder needs, how water
                                will be allocated in the future, how future
                                water rates may be linked to availability and
                                quality and current and future treatment
                                capacity. Model scenarios of productivity, local
                                community growth, and water provider plans to
                                understand how these variables will impact
                                future water demand and risk.
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Collaborate with other local users on a water
                                stewardship plan and explore where there may be
                                opportunities to share data that would minimize
                                risk.
                              </Text>
                            </ListItem>
                          </UnorderedList>
                        </Stack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm" fontWeight="600">
                          Low potential for business risk based on low water
                          scarcity
                        </Text>
                        <Text fontSize="sm">
                          (High Growth & Low Water Scarcity)
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          Water dependency is known but business risk is low
                          because sufficient water is available given current
                          conditions. To manage acute risks and prevent future
                          risk, develop a plan to monitor changes in water
                          quality and quantity. Update your facility data in the
                          Smart Water Navigator annually using updated growth
                          projections.
                        </Text>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            <strong>MONITOR:</strong>
                          </Text>
                          <UnorderedList spacing="3" pl="20px">
                            <ListItem>
                              <Text fontSize="sm">
                                Explore the “Take Action” tab on your dashboard
                                to review your tailored guide to implement
                                improvements in water management, use,
                                target-setting and stewardship practices
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Monitor water availability and growth in the
                                basin and enact a process to respond to changes
                                that could place the facility at a higher risk.
                                Monitoring growth and local water availability
                                can include a combination of private and public
                                efforts. Examples include trends in water demand
                                by category (agricultural, domestic and
                                industrial), real time satellite data on water
                                availability, water use intensity within the
                                region, changes in economic development or
                                basin-wide water conservation initiatives.
                              </Text>
                            </ListItem>
                          </UnorderedList>
                        </Stack>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm" fontWeight="600">
                          Low potential for business risk scarcity
                        </Text>
                        <Text fontSize="sm">
                          (Low Growth & Low Water Scarcity)
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          While water is necessary for operations, facilities
                          with low growth potential in areas of low water stress
                          face minimal business risk.
                        </Text>
                      </Td>
                      <Td>
                        <Stack spacing="3">
                          <Text fontSize="sm">
                            <strong>CONTINUE ONGOING CONSERVATION:</strong>
                          </Text>
                          <UnorderedList spacing="3" pl="20px">
                            <ListItem>
                              <Text fontSize="sm">
                                Continue ongoing water efficiency efforts to
                                reduce reliance on fresh water
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Explore the “Take Action” tab on your dashboard
                                to review your tailored guide to implement
                                improvements in water management, use,
                                target-setting and stewardship practices
                              </Text>
                            </ListItem>
                            <ListItem>
                              <Text fontSize="sm">
                                Periodically assess water basin conditions and
                                local growth to understand changes that might
                                increase risk
                              </Text>
                            </ListItem>
                          </UnorderedList>
                        </Stack>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Stack>
          </div>

          <div className={cx('sidebar')}>
            <FacilityRiskFilter />
          </div>
        </FacilityRiskFilterProvider>
      </div>
    </RiskAnalysisWrapper>
  );
};

RiskAnalysis.displayName = 'RiskAnalysis';
