export type ExpenseOrSavings = {
  amount: number;
  year: number;
};

export class OptionalExpensesSavingsYear {
  expenseAmount: number;
  savingsAmount: number;
  expenses: Array<ExpenseOrSavings>;
  savings: Array<ExpenseOrSavings>;

  constructor() {
    this.expenseAmount = 0;
    this.savingsAmount = 0;
    this.expenses = [];
    this.savings = [];
  }

  getOtherExpensesSavings() {
    return this.savingsAmount - this.expenseAmount;
  }
}
