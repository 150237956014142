import { Flex, Text } from '@chakra-ui/react';
import { mdiSwapHorizontal } from '@mdi/js';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { getTransfers } from 'src/clients/api/v5.transfers';
import { Icon } from 'src/components/Icon';
import { useAuth } from 'src/hooks/useAuth';
import { Transfer } from 'src/types/transfer';
import { TransferList } from './TransferList';

type TransfersGroupProps = {
  showPast?: boolean;
};

export const TransfersGroup: FC<TransfersGroupProps> = ({
  showPast = false,
}) => {
  const { userId } = useAuth();
  const { data, isLoading } = useQuery(['transfers'], () => getTransfers());

  const transfers =
    data?.status === 'success' && !!data?.value.data
      ? data?.value.data
      : ([] as Array<Transfer>);

  if (isLoading || transfers.length === 0) {
    return <></>;
  }

  if (showPast) {
    const other = transfers.filter((transfer) => transfer.status !== 'queued');

    if (other.length === 0) {
      return (
        <Flex
          border="1px solid"
          borderColor="border"
          bg="bg-subtle"
          p="2"
          alignItems="center"
        >
          <Icon path={mdiSwapHorizontal} color="subtle" mr="2" />
          <Text color="subtle">There are no past transfers</Text>
        </Flex>
      );
    }

    return <TransferList transfers={other} />;
  }

  const incoming = transfers.filter(
    (transfer) =>
      transfer.status === 'queued' &&
      transfer.transferToUserId === Number(userId)
  );
  const outgoing = transfers.filter(
    (transfer) =>
      transfer.status === 'queued' &&
      transfer.transferFromUserId === Number(userId)
  );

  if (transfers.length === 0) {
    return (
      <Flex
        border="1px solid"
        borderColor="border"
        bg="bg-subtle"
        p="2"
        alignItems="center"
      >
        <Icon path={mdiSwapHorizontal} color="subtle" mr="2" />
        <Text color="subtle">There are no active transfers</Text>
      </Flex>
    );
  }

  return (
    <>
      {incoming.length ? (
        <TransferList direction="in" transfers={incoming} />
      ) : null}
      {outgoing.length ? (
        <TransferList direction="out" transfers={outgoing} />
      ) : null}
    </>
  );
};

TransfersGroup.displayName = 'TransfersGroup';
