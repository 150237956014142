export enum EventType {
  SessionInvalid = 'session::invalid',
  Notification = 'notification',
}

export type Event =
  | {
      type: EventType.Notification;
      detail: {
        message: string;
      };
    }
  | {
      type: EventType.SessionInvalid;
      detail?: never;
    };

export const SESSION_INVALID = EventType.SessionInvalid;
