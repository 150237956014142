import React, { FC, useRef } from 'react';
import classnames from 'classnames/bind';

import styles from './Dialog.module.css';
import { Dialog as HeadlessDialog } from '@headlessui/react';
import { Copy } from '../Copy';

const cx = classnames.bind(styles);

export type DialogProps = {
  variant?: 'danger' | 'default';
  isOpen: boolean;
  title: string;
  description: string;
  onClose?: () => void;
  className?: string;
};
export const Dialog: FC<DialogProps> = ({
  variant = 'default',
  isOpen,
  title,
  description,
  children,
  onClose,
  className,
}) => {
  const initialFocus = useRef<HTMLDivElement>(null);
  return (
    <HeadlessDialog
      open={isOpen}
      onClose={!!onClose ? onClose : () => {}}
      className={cx('dialog', variant)}
      initialFocus={initialFocus}
    >
      <HeadlessDialog.Overlay className={cx('overlay')} />

      <span className={cx('centerer')} aria-hidden="true">
        &#8203;
      </span>

      <div className={cx('content', className)} ref={initialFocus}>
        <HeadlessDialog.Title className={cx('title')}>
          {title}
        </HeadlessDialog.Title>
        <HeadlessDialog.Description as="div" className={cx('description')}>
          <Copy as="p">{description}</Copy>
        </HeadlessDialog.Description>

        <div className={cx('customContent')}>{children}</div>
      </div>
    </HeadlessDialog>
  );
};

Dialog.displayName = 'Dialog';
