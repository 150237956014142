import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { FC, PropsWithChildren } from 'react';

type RaindropProps = BoxProps;

export const Raindrop: FC<PropsWithChildren<RaindropProps>> = ({
  boxSize = '60px',
  ...props
}) => {
  return (
    <Box
      boxSize={boxSize}
      transform="rotate(45deg)"
      borderRightRadius="100px"
      borderBottomLeftRadius="100px"
      bgColor="#007AC9"
      zIndex="2"
      {...props}
    ></Box>
  );
};

Raindrop.displayName = 'Raindrop';
