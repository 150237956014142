import * as yup from 'yup';

const contactFormSchemaProperties = {
  firstName: yup.string(),
  lastName: yup.string(),
  company: yup.string(),
  city: yup.string(),
  country: yup.string(),
  email: yup.string().email().required('Required'),
  comments: yup.string().required('Required'),
  requiredMiddleName: yup.string(),
};
export const contactFormSchema = yup.object(contactFormSchemaProperties);
export type ContactFormSchemaPayload = yup.InferType<typeof contactFormSchema>;
