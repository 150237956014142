export const PARTNERS = [
  {
    image: 'world-resources-institute.jpeg',
    href: 'https://www.wri.org/',
  },
  {
    image: 'ceo-water-mandate.png',
    href: 'https://ceowatermandate.org/',
  },
  {
    image: 'pacific-institute.png',
    href: 'https://thepacificinstitute.com/',
  },
  {
    image: 'alliance-for-water-stewardship.png',
    href: 'https://a4ws.org/',
  },
  {
    image: 'global-sustainable1.png',
    href: 'https://www.spglobal.com/esg/',
  },
];
