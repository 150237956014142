import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  FlexProps,
  Hide,
  Stack,
  Text,
} from '@chakra-ui/react';
import { mdiFolderOpenOutline, mdiFolderOutline } from '@mdi/js';
import React, { FC } from 'react';
import { Icon } from 'src/components/Icon';
import { Folder } from 'src/types/folder';
import { Facility } from 'src/types/v5.facility';
import { FolderItemContents } from './FolderItemContents';

type FolderListItemProps = {
  facilities?: Array<Facility>;
  folder: Folder;
  isOpen: boolean;
  isFrozen: boolean;
  onToggle: () => void;
} & FlexProps;

export const FolderItem: FC<FolderListItemProps> = ({
  facilities,
  folder,
  isOpen,
  isFrozen = false,
  onToggle,
}) => {
  return (
    <AccordionItem
      bg="white"
      border={
        isOpen
          ? '1px solid  var(--chakra-colors-gray-300)'
          : '1px solid  var(--chakra-colors-gray-200)'
      }
      borderBottom={
        isOpen ? '1px solid  var(--chakra-colors-gray-300)' : 'none'
      }
      _last={{
        borderBottom: isOpen
          ? '1px solid  var(--chakra-colors-gray-300)'
          : '1px solid  var(--chakra-colors-gray-200)',
      }}
      sx={{
        "&[data-open='true'] + [data-open='false']": {
          borderTop: 'none !important',
        },
      }}
      data-open={isOpen}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _hover={{
              bg: 'gray.50',
            }}
            _expanded={{
              bg: 'gray.100',
              color: 'gray.500',
              borderY: '1px solid var(--chakra-colors-gray-300)',
              borderTop: 'none',
            }}
            p="2"
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
              flex="1"
            >
              <Stack
                flex="1 1 100%"
                direction="row"
                maxWidth="calc(100% - 1px)"
                width="100%"
                spacing="1"
              >
                <Hide below="md">
                  <Icon
                    path={isExpanded ? mdiFolderOpenOutline : mdiFolderOutline}
                    size="24px"
                    color="bg.muted"
                    mr={{ base: 0, md: '2' }}
                  />
                </Hide>
                <Flex width="100%" maxWidth="100%" alignItems="center">
                  <Text
                    isTruncated
                    lineHeight="1"
                    align="left"
                    fontWeight={isExpanded ? 'semibold' : 'normal'}
                  >
                    {folder.folderName}
                  </Text>
                </Flex>
              </Stack>
            </Stack>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel p="2" pt="0">
            {isExpanded ? (
              <FolderItemContents
                facilities={facilities}
                folder={folder}
                isOpen={isExpanded}
                isFrozen={isFrozen}
                onToggle={onToggle}
              />
            ) : null}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
