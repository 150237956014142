import classnames from 'classnames/bind';
import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SiteHeader } from 'src/components/SiteHeader';
import { SESSION_INVALID } from 'src/constants/events';
import { useAuth } from 'src/hooks/useAuth';
import { useMaintenance } from 'src/hooks/useMaintenance';
import { eventBus } from 'src/utils/eventBus';
import { App as WebApp } from './app';
import styles from './index.module.css';
import { App as Marketing } from './marketing';

const cx = classnames.bind(styles);

interface AppProps {}

export const App: FC<AppProps> = () => {
  const { logout } = useAuth();

  const {
    hasActiveMaintenance,
    hasFutureMaintenance,
    maintenanceMessage,
  } = useMaintenance();

  useEffect(() => {
    const terminate = () => logout && logout('/login?r=sess_exp');
    eventBus.on(SESSION_INVALID, terminate);
    return () => eventBus.remove(SESSION_INVALID, terminate);
  }, [logout]);

  return (
    <BrowserRouter>
      <div className={cx('app')}>
        <SiteHeader
          isCurrentlyUnderMaintenance={hasActiveMaintenance}
          hasUpcomingMaintenance={hasFutureMaintenance}
          maintenanceMessage={maintenanceMessage}
        />

        <Switch>
          <Route
            path="/app"
            component={() => (
              <WebApp
                isCurrentlyUnderMaintenance={hasActiveMaintenance}
                maintenanceMessage={maintenanceMessage}
              />
            )}
          />

          <Marketing />
        </Switch>
      </div>
    </BrowserRouter>
  );
};
