import { Flex, Stack, StackDivider, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Icon } from 'src/components/Icon';
import { ActiveUploadRow } from 'src/types/upload';
import { RowResultsListItem } from './RowResultListItem';

type RowResultsListProps = {
  variant: 'error' | 'success' | 'processing';
  rows: Array<ActiveUploadRow>;
  headerIcon: string;
  headerText: string;
  uploadId: string;
};

export enum UPLOAD_STATUS_COLOR {
  success = 'green',
  error = 'red',
  processing = 'gray',
}

export const RowResultsList: FC<RowResultsListProps> = ({
  variant = 'error',
  rows = [],
  headerIcon,
  headerText,
  uploadId,
}) => {
  if (rows.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={0}
      divider={
        <StackDivider borderColor={`${UPLOAD_STATUS_COLOR[variant]}.400`} />
      }
      border="1px solid"
      borderColor={`${UPLOAD_STATUS_COLOR[variant]}.400`}
    >
      <Flex
        bgColor={`${UPLOAD_STATUS_COLOR[variant]}.400`}
        p="2"
        alignItems="center"
      >
        <Icon path={headerIcon} size="24px" color="white" mr="2" />
        <Text fontWeight="semibold" color="white">
          {headerText}
        </Text>
      </Flex>

      {rows.map((row) => (
        <RowResultsListItem
          key={row.id}
          variant={variant}
          row={row}
          uploadId={uploadId}
        />
      ))}
    </Stack>
  );
};
