import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { getUserAccounts } from 'src/clients/api/user';
import { useAuth } from 'src/hooks/useAuth';
import { SelectInput } from '../SelectInput';
import { Box, BoxProps, Text } from '@chakra-ui/react';

type AccountSelectorProps = {
  hideWhileSingle?: boolean;
} & BoxProps;

export const AccountSelector: FC<AccountSelectorProps> = ({
  hideWhileSingle,
  ...props
}) => {
  const { switchAccounts, accountId, userId } = useAuth();

  const { data: accounts } = useQuery(
    ['user', userId!, 'accounts'],
    () => getUserAccounts(userId!),
    {
      enabled: !!userId,
    }
  );

  const handleSwitch = (value: string) => {
    switchAccounts!(value);
  };

  if (accounts?.status === 'success') {
    if (hideWhileSingle && accounts.value.data.length === 1) {
      return null;
    }

    return (
      <Box {...props}>
        <Text fontSize="xs">Switch accounts:</Text>
        <SelectInput
          items={accounts.value.data.map((a) => ({
            label: a.companyName,
            value: a.accountId,
          }))}
          value={accountId ?? ''}
          onChange={handleSwitch}
        />
      </Box>
    );
  }

  return null;
};

AccountSelector.displayName = 'AccountSelector';
