import { User } from 'src/types/user';
import { Env } from '../../constants/env';
import { WrappedResponse } from '../types';
import request from '../request';
import { clearStorage } from '../helpers';
import {
  ForgotPasswordSchemaPayload,
  LoginFormSchemaPayload,
  RegisterFormSchemaPayload,
  ResetPasswordSchemaPayload,
  VerifyAccountSchemaPayload,
} from './schema';

export type LoginResponseType = {
  user_id: number;
  id_token: string;
};
function login({ email, password }: LoginFormSchemaPayload) {
  return request.post<
    LoginResponseType,
    { username: string; password: string }
  >(`${Env.authUrl}/v5/auth/token`, {
    username: email, // TODO: change when endpoint takes `email` as a prop instead of `username`
    password,
  });
}

function logout() {
  clearStorage();
  return Promise.resolve();
}

export type RegisterResponseType = {};
function register(form: RegisterFormSchemaPayload) {
  return request.post<RegisterResponseType, RegisterFormSchemaPayload>(
    `${Env.authUrl}/v5/users/registration`,
    form
  );
}

export type ForgotPasswordResponseType = {};
function forgotPassword(form: ForgotPasswordSchemaPayload) {
  return request.post<ForgotPasswordResponseType, ForgotPasswordSchemaPayload>(
    `${Env.authUrl}/v5/users/forgot-password`,
    form
  );
}

export type ResetPasswordResponseType = {};
function resetPassword({
  resetPasswordCode,
  ...form
}: ResetPasswordSchemaPayload) {
  return request.post<
    ResetPasswordResponseType,
    Omit<ResetPasswordSchemaPayload, 'resetPasswordCode'>
  >(`${Env.authUrl}/v5/users/change-password/${resetPasswordCode}`, form);
}

export type VerifyAccountResponseType = {};
function verifyAccount({ code }: VerifyAccountSchemaPayload) {
  return request.post<VerifyAccountResponseType, {}>(
    `${Env.authUrl}/v5/users/verify/${code}`,
    {}
  );
}

export type FetchUserResponseType = WrappedResponse<User>;
function fetchUser() {
  return request.get<FetchUserResponseType>(`${Env.apiUrl}/v5/users/user`);
}

export default {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  verifyAccount,
  fetchUser,
};
