import React, { FC } from 'react';
import { Helmet as Helm, HelmetProps } from 'react-helmet';

const HELMET_DEFAULTS = {
  defaultTitle:
    'Smart Water Navigator - Guide Your Water Strategy with Smart Water Tools',
  titleTemplate: '%s - Smart Water Navigator',
};

export const Helmet: FC<HelmetProps> = ({ children, ...props }) => {
  return (
    <Helm {...HELMET_DEFAULTS} {...props}>
      {children}
    </Helm>
  );
};

Helmet.displayName = 'Helmet';
