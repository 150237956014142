import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  ListItem,
  Stack,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  UnorderedList,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { useScrollToAnchor } from 'src/hooks/useScrollToAnchor';

export interface TakeActionProps extends React.HTMLAttributes<HTMLDivElement> {}

const TIMELINE_COLORS = [
  '#e6eef2',
  '#ccdde4',
  '#99bbca',
  '#6698af',
  '#337695',
  '#00547a',
];

const INPUT_ACTION = [
  {
    title: 'Incoming water',
    content: 'Incoming water quantity, price and source',
  },
  {
    title: 'Water replenishment',
    content:
      'Replenishing any of this site’s incoming water through a nature-based solution within the water basin. The name of the water fund this replenishment supports, the duration of the volumetric benefit, the name of the third party verifier (if applicable) and the annualized volumetric benefit returned to the basin.',
  },
  {
    title: 'Outgoing water',
    content:
      'Outgoing water quantity, price and treatment location (if applicable)',
  },
  {
    title: 'Facility details',
    content:
      'Type and quantity of facility outputs, as well as projected facility output over 3 years',
  },
  {
    title: 'Business information',
    content:
      'If the basin your facility is located in is currently experiencing a drought. Projected incoming and outgoing water prices and quantities over three years. Current facility revenue, projected increase over three years and any recent revenue loss due to water scarcity.',
  },
  {
    title: 'Regulation & reputation',
    content:
      'Water regulations in the area the facility is located in for your particular industry. Knowledgeability about other water users in the basin and any reputational challenges.',
  },
  {
    title: 'Water quality & pollutants',
    content:
      'Water quality impairments and any limitations related to water treatment capacity and availability. Information on particular pollutant concentrations (if applicable).',
  },
  {
    title: 'Water management practices',
    content:
      'To what extent water factors into expansion plans, investment decisions and interactions with the surrounding community and employees. Any existing measurement or target setting related to water at the facility or for the enterprise.',
  },
  {
    title: 'Investment inputs',
    content:
      'Capital investment, expected water savings, discount rate and projected increase in water costs. Any additional expenses and savings (if applicable).',
  },
];

const OUTPUT_ACTION = [
  {
    title: 'Total risk premium',
    content:
      'Monetary estimate of the potential increased operating costs resulting from incoming and outgoing water quality and quantity risk. Risk premiums place a monetary value on the local environmental, human-health and domestic supply impacts of water depletion and pollution and the future costs of water treatment. Local water availability, local water quality and local population density are all variables that impact the size of the risk premium. Locations that have high levels of water stress (due to quantity- or quality-related impacts) and high population densities will usually have a higher risk premium; however, the type of water pollutant and its treatment method will also influence the value for outgoing water.',
    image: '/charts/total-risk-premium.jpg',
  },
  {
    title: 'Revenue at risk',
    content:
      "Revenue at risk compares the estimated amount of water a business requires to generate revenue to the business' share of water available in the water basin if water were allocated among water users based on economic activity. If more water is required than the basin share of water allocated, then a proportion of the business' revenue is potentially at risk.",
    image: '/charts/revenue-risk.jpg',
  },
  {
    title: 'Water withdrawal risk',
    content:
      'The facility may use more water than what would be a sustainable amount of water from its surrounding water sources. If the facility’s current water use, subtracting any replenishment volumes, is higher than the current and future sustainable water use amounts, the facility needs to decrease its water use to avoid impacts to its operations from water scarcity. Water-intensive industries located in areas of high levels of water stress will usually have a higher amount of water use risk. Sustainable annual water withdrawal is calculated to achieve a &lt; 40% risk threshold of baseline water stress to a basin.',
  },
  {
    title: 'Checklist',
    content:
      'Based on the industry a facility is in and its current water maturity, the Smart Water Navigator provides a personalized checklist for users to identify areas of improvement within their operations. The checklist includes an assessment of current water management practices, as well as existing target setting and water stewardship efforts.',
    image: '/charts/checklist.png',
  },
  {
    title: 'Tools and resources',
    content:
      'This feature provides 5 steps that a given facility can take to increase its water maturity, with particular examples and links to resources for each.',
    image: '/charts/tools-and-resources.png',
  },
  {
    title: 'Business case',
    content:
      'Based on a facility’s position on the water maturity curve and the industry it is in, the Smart Water Navigator provides targeted information on the business benefit of investment in water stewardship and efficiency projects. Users also receive personalized information about the potential tangible and intangible benefits of deciding to make these investments in water stewardship for the individual facility and the broader company as a whole.',
    image: '/charts/benefits-chart.jpg',
  },
  {
    title: 'Investment calculator',
    content:
      'Users can input information about the cost and projected savings of particular investments in water to understand the payback period for the investment, both according to a conventional and risk-adjusted methodology.',
  },
  {
    title: 'Case studies',
    content:
      'Based on a water maturity, the Smart Water Navigator provides best-in-class examples from other companies in similar industries who have invested in smart water management. Users can learn more about the particular projects implemented by the company and the benefit recorded to their business.',
    image: '/charts/case-studies.jpg',
  },
  {
    title: 'Absolute water volume over time',
    content:
      'Comparison of the incoming, outgoing and calculated consumptive water use over multiple years.',
    image: '/charts/absolute-water-volume.jpg',
  },
  {
    title: 'Water intensity over time',
    content:
      'Comparison of the incoming, outgoing and calculated consumptive water use per production unit and per revenue over multiple years.',
  },
  {
    title: 'Chart your company’s enterprise risk profile',
    content:
      "Assess a facility's risk based on three year projected output growth and location-specific water stress. The Smart Water Navigator also assigns action plans for facilities based on risk likelihood. This analysis provides a way for business leaders to prioritize and determine appropriate risk mitigation strategies for each location in order to reduce a company's overall risk profile.",
    image: '/charts/quantify-risk-matrix.jpg',
  },
  {
    title: 'Benchmark performance across sites in your portfolio',
    content:
      'Compare the performance of different sites within a facility portfolio. This analysis provides a way for business leaders to prioritize and determine appropriate water use efficiency strategies for each location in order to bring sites closer to the industry, or organizational, average or median water-intensity.',
  },
  {
    title: 'Total and risk-adjusted water bill',
    content:
      'Based on the facility water data input into the tool, the Smart Water Navigator provides the total water bill for all facilities across an enterprise, as well as the total price when factoring in the risk water scarcity creates for a business. This allows users to compare what they are paying for water with the true cost of water to their operations.',
  },
  {
    title:
      'Range of total, incoming & outgoing water prices, as compared to risk-adjusted prices',
    content:
      'Based on the facility water data input into the tool, the Smart Water Navigator shows the range of total, incoming and outgoing water prices to show the maximum and minimum amounts paid for sourcing and treating water across the enterprise. This is compared to risk-adjusted ranges to show the true cost of water in an enterprise’s operations when accounting for water quality and quantity risks.',
  },
  {
    title: 'Risk analysis quadrant summary',
    content:
      'See what percentages of facilities, outputs and water across an enterprise fall into which of the risk quadrants (low water scarcity/low growth, low water scarcity/high growth, high water scarcity/low growth, high water scarcity/high growth). Understand the water risk premiums and percentage of revenue at risk for facilities in each of these quadrants.',
  },
];

const ActionItem: FC<PropsWithChildren<{
  title: string;
  content: string;
  image?: string;
}>> = ({ title, content, image }) => {
  const hasImage = !!image;

  return (
    <AccordionItem border="none" mb="15px">
      <AccordionButton border="1px solid #eee">
        <AccordionIcon />
        <Box flex="1" textAlign="left" p="10px">
          <Heading as="h3" size="md">
            {title}
          </Heading>
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="5">
          <Box flex={`1 1 ${hasImage ? '50%' : '100%'}`}>
            <Text fontSize="lg">{content}</Text>
          </Box>

          {hasImage && (
            <Box flex="1 1 50%">
              <Image src={`/images${image}`} />
            </Box>
          )}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export const TakeAction: FC<TakeActionProps> = () => {
  useScrollToAnchor();

  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/dam-by-mountain.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Take action
          </Heading>
        )}
        subtitle={() => (
          <Text fontSize="lg" mt="5">
            Add facility data to get started on your smart water journey
          </Text>
        )}
        actions={() => (
          <ButtonGroup size="sm" mt="10" colorScheme="whiteAlpha">
            <Button variant="solid" as={Link} to="/app/facilities/new">
              Add One Facility
            </Button>
            <Button variant="solid" as={Link} to="/app/uploads">
              Add Multiple Facilities
            </Button>
          </ButtonGroup>
        )}
      />

      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(10vh)' }}
        p="20px"
        pb="40px"
        maxW={1920 / 1.5}
      >
        <Stack spacing="24px">
          <Heading as="h2" size="2xl" textAlign="center">
            From data to personalized insights
          </Heading>
          <Text>
            Input facility data on water sources, uses and quality, as well as
            information on a site’s water management practices, to receive
            targeted information to help you identify risks, set targets, take
            action and monitor progress over time for both individual facilities
            and your enterprise as a whole. Review the chart below to learn more
            about the inputs required to assess your facilities and the outputs
            produced by the Smart Water Navigator.
          </Text>
        </Stack>

        <Box>
          <Table
            border="1px solid"
            borderColor="gray.600"
            sx={{
              '& thead th': {
                borderBottom: '1px solid',
                borderColor: 'gray.600',
                color: 'white',
              },
              '& tbody > tr': {
                borderBottom: '1px solid',
                borderColor: 'gray.600',
              },
              '& tbody > tr:nth-child(even)': {
                bgColor: TIMELINE_COLORS[1],
              },
              '& tbody td': {
                verticalAlign: 'top',
                borderRight: '1px solid',
                borderColor: 'gray.600',
              },
            }}
          >
            <Thead
              bgColor={TIMELINE_COLORS[5]}
              borderBottom="1px solid"
              borderColor="gray.600"
              color="white"
            >
              <Tr>
                <Td></Td>
                <Td w="50%">Facility</Td>
                <Td w="50%">Enterprise</Td>
              </Tr>
            </Thead>
            <Tbody
              bgColor={TIMELINE_COLORS[0]}
              borderTop="1px solid"
              borderColor="gray.600"
            >
              <Tr>
                <Td
                  verticalAlign="top"
                  bgColor={TIMELINE_COLORS[4]}
                  color="white"
                >
                  <a href="#inputs">Inputs</a>
                </Td>
                <Td verticalAlign="top">
                  <UnorderedList>
                    <ListItem>Incoming water</ListItem>
                    <ListItem>Water replenishment</ListItem>
                    <ListItem>Outgoing water</ListItem>
                    <ListItem>Facility details</ListItem>
                    <ListItem>Business information</ListItem>
                    <ListItem>Regulation & reputation</ListItem>
                    <ListItem>Water quality & pollutants</ListItem>
                    <ListItem>Water management practices</ListItem>
                    <ListItem>Investment inputs</ListItem>
                  </UnorderedList>
                </Td>
                <Td verticalAlign="top">
                  <UnorderedList>
                    <ListItem>
                      Automatic compilation of all facility-level inputs
                    </ListItem>
                  </UnorderedList>
                </Td>
              </Tr>
              <Tr>
                <Td
                  verticalAlign="top"
                  bgColor={TIMELINE_COLORS[4]}
                  color="white"
                >
                  <a href="#outputs">Outputs</a>
                </Td>
                <Td verticalAlign="top">
                  <UnorderedList>
                    <ListItem>Total risk premium</ListItem>
                    <ListItem>Revenue risk</ListItem>
                    <ListItem>Water withdrawal risk</ListItem>
                    <ListItem>Checklist</ListItem>
                    <ListItem>Tools and resources</ListItem>
                    <ListItem>Business case</ListItem>
                    <ListItem>Investment calculator</ListItem>
                    <ListItem>Case studies</ListItem>
                    <ListItem>Absolute water volume</ListItem>
                    <ListItem>Water intensity over time</ListItem>
                  </UnorderedList>
                </Td>
                <Td verticalAlign="top">
                  <UnorderedList>
                    <ListItem>
                      Chart your company’s enterprise risk profile
                    </ListItem>
                    <ListItem>
                      Benchmark performance across sites in your portfolio
                    </ListItem>
                    <ListItem>Total and risk-adjusted water bill</ListItem>
                    <ListItem>
                      Range of total, incoming & outgoing water prices, as
                      compared to risk-adjusted prices
                    </ListItem>
                    <ListItem>Risk analysis quadrant summary</ListItem>
                  </UnorderedList>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Stack spacing="24px">
          <Heading as="h2" size="2xl" textAlign="center">
            <a id="inputs" href="#inputs">
              Inputs
            </a>
          </Heading>

          <Accordion defaultIndex={[0]} allowToggle>
            {INPUT_ACTION.map((action, index) => (
              <ActionItem key={index} {...action} />
            ))}
          </Accordion>
        </Stack>

        <Stack spacing="24px">
          <Heading as="h2" size="2xl" textAlign="center">
            <a id="outputs" href="#outputs">
              Outputs
            </a>
          </Heading>

          <Accordion defaultIndex={[0]} allowToggle>
            {OUTPUT_ACTION.map((action, index) => (
              <ActionItem key={index} {...action} />
            ))}
          </Accordion>
        </Stack>
      </Stack>

      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
