import { ProcessedFacility } from 'src/types/processedFacility';
import { DEFAULT_CHART } from './utils';

export function outgoingRiskChartDataGenerator({
  attributes,
}: ProcessedFacility['charts']['outgoingWaterRiskChart']): Highcharts.Options {
  return {
    ...DEFAULT_CHART,
    chart: {
      ...DEFAULT_CHART.chart,
      type: 'column',
    },
    xAxis: {
      categories: ['1 Year', '3 Year', '5 Year', '10 Year'],
    },
    yAxis: {
      title: {
        text: 'Value (USD per year)',
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormatter: function (this: {
        series: { name: string };
        y: number;
        stackTotal: number;
      }) {
        return `${this.series.name}: ${this.y} <br/> Combined: ${this.stackTotal}`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Quality Risk',
        data: [
          attributes['year1-quality-risk'],
          attributes['year3-quality-risk'],
          attributes['year5-quality-risk'],
          attributes['year10-quality-risk'],
        ],
        color: '#d7eab8',
      },
      {
        name: 'Water Bill',
        data: [
          attributes['year1-waterbill'],
          attributes['year3-waterbill'],
          attributes['year5-waterbill'],
          attributes['year10-waterbill'],
        ],
        color: '#a7d162',
      },
    ],
  };
}
