import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import {
  resetPasswordFormSchema,
  ResetPasswordSchemaPayload,
} from 'src/clients/auth';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { Maintenance } from 'src/components/Maintenance';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { TextInput } from 'src/components/TextInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useAuth } from 'src/hooks/useAuth';
import { useMaintenance } from 'src/hooks/useMaintenance';
import { useQueryParams } from 'src/hooks/useQueryParams';
import styles from './ResetPassword.module.css';

const cx = classnames.bind(styles);

export interface ResetPasswordProps
  extends React.HTMLAttributes<HTMLDivElement> {}

const RESOLVER = yupResolver<ResetPasswordSchemaPayload>(
  resetPasswordFormSchema
);

export const ResetPassword: FC<ResetPasswordProps> = () => {
  const { hasActiveMaintenance } = useMaintenance();
  const { resetPassword } = useAuth();
  const params = useQueryParams();

  const { handleSubmit, errors, register } = useForm<
    ResetPasswordSchemaPayload
  >({
    resolver: RESOLVER,
    mode: 'onChange',
  });

  const [state, setState] = useAsyncState();

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  const onSubmit = async (form: ResetPasswordSchemaPayload) => {
    if (resetPassword) {
      try {
        setState({
          status: 'loading',
        });

        const result = await resetPassword(form);

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: 'Password reset email sent successfully.',
        });
      } catch (e) {
        setState({
          status: 'error',
          message: e.message ?? 'There was an error with your request.',
        });
      }
    }
  };

  if (hasActiveMaintenance) {
    return <Maintenance />;
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article className={cx('resetPassword')} data-testid="resetPassword">
          <section>
            <div className={cx('resetPasswordBox')}>
              <h2>Reset Password</h2>
              {state.status === 'success' ? (
                <animated.div style={animate}>
                  <Alert
                    variant="success"
                    message="Your password has been updated. Please login."
                    className={cx('success')}
                  />

                  <Link to={'/login'}>
                    <Button>Login</Button>
                  </Link>
                </animated.div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div className={cx('error')}>
                      <animated.div style={animate}>
                        <Alert
                          variant="danger"
                          message={
                            state.message ??
                            'There was an error resetting your password. Please try again.'
                          }
                        />
                      </animated.div>
                    </div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={cx('resetPasswordForm')}
                  >
                    <input
                      type="hidden"
                      name="resetPasswordCode"
                      ref={register}
                      value={`${params.get('code')}`}
                    />

                    <TextInput
                      type="password"
                      name="password"
                      ref={register}
                      placeholder="Password"
                      autoComplete="new-password"
                      error={errors.password}
                      className={cx('input')}
                    />

                    <TextInput
                      type="password"
                      name="confirmPassword"
                      ref={register}
                      placeholder="Confirm password"
                      autoComplete="new-password"
                      error={errors.confirmPassword}
                      className={cx('input')}
                    />

                    <Button
                      type="submit"
                      className={cx('buttonSubmit')}
                      isLoading={state.status === 'loading'}
                    >
                      Reset Password
                    </Button>
                  </form>
                </>
              )}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

ResetPassword.displayName = 'ResetPassword';
