import * as React from 'react';

const SvgBrandContact = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 80 59" data-testid="svg-icon" {...props}>
      <g clipPath="url(#brand-contact_svg__clip0)">
        <path
          fill="#007AC9"
          d="M79.588 8.549L57.22 29.664l22.367 21.117c.062-.418.105-.844.105-1.28V9.827a8.7 8.7 0 00-.104-1.277z"
        />
        <path
          fill="#6AC5C4"
          d="M44.898 35.231L77.7 4.265a8.81 8.81 0 00-6.828-3.255H9.128A8.816 8.816 0 002.3 4.264l32.803 30.968c2.655 2.507 7.142 2.507 9.796 0z"
        />
        <path
          fill="#007AC9"
          d="M.412 8.547c-.061.418-.104.844-.104 1.28v39.675c0 .435.043.861.104 1.279l22.367-21.119L.412 8.547zM47.926 38.437C45.74 40.502 42.87 41.533 40 41.533c-2.871 0-5.741-1.031-7.926-3.096l-6.083-5.743-23.69 22.37a8.813 8.813 0 006.827 3.255h61.744a8.813 8.813 0 006.828-3.255L54.009 32.695l-6.083 5.742z"
        />
      </g>
      <defs>
        <clipPath id="brand-contact_svg__clip0">
          <path fill="#fff" d="M0 0h80v58.71H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoSvgBrandContact = React.memo(SvgBrandContact);
export default MemoSvgBrandContact;
