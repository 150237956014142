import { Box, Tooltip } from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import styles from './FacilityTabNavigator.module.css';
import { UseTabNavigator } from './useTabNavigator';

const cx = classnames.bind(styles);

export type FacilityTabNavigatorProps = {
  tabNavigator: UseTabNavigator;
  clickToNavigate?: boolean;
  tabsInError?: Set<number>;
};

export const FacilityTabNavigator: FC<FacilityTabNavigatorProps> = ({
  tabNavigator,
  tabsInError,
  clickToNavigate = true,
}) => {
  const { activeTabIndex, setActiveTabIndex, tabs } = tabNavigator;

  return (
    <nav className={cx('tabs')}>
      <div className={cx('shadow')}></div>
      {tabs.map((tab, index) => {
        const hasError = tabsInError?.has(tab.index);

        return (
          <Box
            key={tab.label}
            className={cx('tab', {
              active: activeTabIndex === index,
              clickable: clickToNavigate,
            })}
            onClick={() => clickToNavigate && setActiveTabIndex(index)}
            position="relative"
          >
            {hasError ? (
              <Tooltip
                label="This tab has errors that need to be resolved"
                aria-label="A tooltip"
              >
                <Box
                  position="absolute"
                  top="2"
                  right="2"
                  boxSize="8px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="4px"
                  backgroundColor="red.500"
                />
              </Tooltip>
            ) : null}
            {typeof tab === 'string' ? tab : tab.component}
          </Box>
        );
      })}
    </nav>
  );
};

FacilityTabNavigator.displayName = 'FacilityTabNavigator';
