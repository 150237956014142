export type EnvType = {
  baseUrl: string;
  apiUrl: string;
  authUrl: string;
  waaApiUrl: string;
  tokenCallbackUrl: string;
  appVersion: number;
  styleNonce: string;
  maxReportingYear: number;
  underMaintenance?: boolean;
  underMaintenanceMessage?: string;
  futureMaintenance?: boolean;
  futureMaintenanceMessage?: string;
};

export function getEnv(): string {
  return process.env.NODE_ENV;
}

function getBaseUrl(append = '') {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return `${
    baseUrl ?? 'https://mkt-swn-webapp-endpoint-001-d.azureedge.net/'
  }/${append}`;
}

const environments: Record<string, EnvType> = {
  development: {
    baseUrl: 'https://mkt-swn-webapp-endpoint-001-d.azureedge.net/',
    apiUrl: getBaseUrl('api'),
    authUrl: getBaseUrl('api'),
    waaApiUrl: getBaseUrl('api'),
    tokenCallbackUrl: getBaseUrl('api'),
    appVersion: 5.0,
    styleNonce:
      'jylglkaiwvwcbvqlsrxwdicdznqsjwhmallrsaisisjsafzfzlogtjfjtoquzussogipopejpizkxzwopvrmisiaepmavxexezeevnbzrpzviomxzibusfjwngxpuvze',
    maxReportingYear: 2023,
  },
  production: {
    baseUrl: 'https://www.smartwaternavigator.com/',
    apiUrl: 'https://api.waterriskmonetizer.com/api',
    authUrl: 'https://api.waterriskmonetizer.com/api',
    waaApiUrl: 'https://api.waterriskmonetizer.com/api',
    tokenCallbackUrl: 'https://eclmktgwrmweb-dev.azurewebsites.net',
    appVersion: 5.0,
    styleNonce:
      'nomdnergtrjqyxgfnzrnsprasrnkvjteiydtqzhinrsmdlbrdpvbpzerjytcmwybxweaktmncnepvzpxfsvtaqyporsnklkxyqaxfxlttgcdepxqqtektdxpicwidnae',
    maxReportingYear: 2023,
  },
};

const isDev = getEnv() === 'development';

export const Env: EnvType = Object.assign(
  {},
  environments[isDev ? 'development' : 'production'],
  {
    ...(process.env.REACT_APP_BASE_URL && {
      baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    ...(process.env.REACT_APP_API_URL && {
      apiUrl: process.env.REACT_APP_API_URL,
    }),
    ...(process.env.REACT_APP_AUTH_API_URL && {
      authUrl: process.env.REACT_APP_AUTH_API_URL,
    }),
    ...(process.env.REACT_APP_WAA_API_URL && {
      waaApiUrl: process.env.REACT_APP_WAA_API_URL,
    }),
    ...(process.env.REACT_APP_TOKEN_CALLBACK_URL && {
      tokenCallbackUrl: process.env.REACT_APP_TOKEN_CALLBACK_URL,
    }),
    ...(process.env.REACT_APP_APP_VERSION && {
      appVerison: process.env.REACT_APP_APP_VERSION,
    }),
    ...(process.env.REACT_APP_STYLE_NONCE && {
      styleNonce: process.env.REACT_APP_STYLE_NONCE,
    }),
    ...(process.env.REACT_APP_MAX_REPORTING_YEAR && {
      maxReportingYear: process.env.REACT_APP_MAX_REPORTING_YEAR,
    }),

    // Dev Overrides
    ...(process.env.REACT_APP_UNDER_MAINTENANCE_MESSAGE &&
      process.env.REACT_APP_UNDER_MAINTENANCE_MESSAGE !== '' && {
        underMaintenance: true,
        underMaintenanceMessage:
          process.env.REACT_APP_UNDER_MAINTENANCE_MESSAGE,
      }),
    ...(process.env.REACT_APP_FUTURE_MAINTENANCE_MESSAGE &&
      process.env.REACT_APP_FUTURE_MAINTENANCE_MESSAGE !== '' && {
        futureMaintenance: true,
        futureMaintenanceMessage:
          process.env.REACT_APP_FUTURE_MAINTENANCE_MESSAGE,
      }),
  }
);
