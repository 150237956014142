import { ColumnChartSeriesOptions } from 'highcharts';
import { ProcessedFacility } from 'src/types/processedFacility';
import { asCommaSeparatedString } from '../number';
import {
  DEFAULT_CHART,
  RISK_COLOR_MAP,
  WITHDRAWAL_RISK_SUGGESTION_MAP,
} from './utils';

export function waterWithdrawalChartDataGenerator(
  data: ProcessedFacility['charts']['sustainableWaterAllocationChart']
): Highcharts.Options {
  const riskLevel = {
    level: data.attributes['swu-riskRank'],
    color: RISK_COLOR_MAP[data.attributes['swu-riskRank']],
    suggestion: WITHDRAWAL_RISK_SUGGESTION_MAP[data.attributes['swu-riskRank']],
    style: { 'background-color': data.attributes['swu-riskRank'] },
  };

  return {
    ...DEFAULT_CHART,
    xAxis: {
      categories: ['Current', 'Year 1', 'Year 3', 'Year 5', 'Year 10'],
    },
    yAxis: {
      title: {
        text: 'Cubic meters (m3)',
      },
      stackLabels: {
        enabled: false,
        formatter: function (this: { total: number }): string {
          return asCommaSeparatedString(this.total);
        },
      },
    },
    tooltip: {
      formatter: function (this: { key: string; y: number }): string {
        return `${this.key}: <b>${asCommaSeparatedString(this.y)}</b>`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      line: {
        dashStyle: 'Dot',
        color: RISK_COLOR_MAP.none,
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Annual water use',
        type: 'column',
        stack: 1,
        data: [
          {
            name: 'Current annual water use',
            color: riskLevel.color,
            y: data.attributes['current-swuAmount'],
          },
          {
            name: 'Year 1 annual water use',
            color: RISK_COLOR_MAP.none,
            y: data.attributes['year1-swuAmountMed'],
          },
          {
            name: 'Year 3 annual water use',
            color: RISK_COLOR_MAP.none,
            y: data.attributes['year3-swuAmountMed'],
          },
          {
            name: 'Year 5 annual water use',
            color: RISK_COLOR_MAP.none,
            y: data.attributes['year5-swuAmountMed'],
          },
          {
            name: 'Year 10 annual water use',
            color: RISK_COLOR_MAP.none,
            y: data.attributes['year10-swuAmountMed'],
          },
        ],
      } as ColumnChartSeriesOptions,
      {
        name: 'Replenishment',
        type: 'column',
        stack: 2,
        data: [
          {
            name: 'Current replenishment amount',
            color: '#93c9da',
            y: data.attributes['total-replenishmentAmount'],
          },
        ],
      } as ColumnChartSeriesOptions,
      {
        name: 'Replenishment Offset',
        type: 'column',
        stack: 2,
        tooltip: false,
        enableMouseTracking: false,
        data: [
          {
            color: '#ffffff',
            // @ts-ignore
            className: 'transparentBar',
            y: data.attributes['current-waterUsageLessReplenishment'],
          },
        ],
      } as ColumnChartSeriesOptions,
      {
        name: 'Withdrawal Level',
        type: 'line',
        // @ts-ignore
        data: data.attributes['withdrawalLine'] ?? undefined,
      },
    ],
  };
}
