import * as React from 'react';

const SvgFacilityDashboard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="FacilityDashboard_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 36 36"
      xmlSpace="preserve"
      data-testid="svg-icon"
      {...props}
    >
      <path
        d="M34.5 2.2v32.2h-2.7V9.1h-7.5v25.5h-1.8V15.2H15v19.2h-1.8v-13H5.8v13H0V36h36V2.2h-1.5zM11.7 34.5H7.3V23h4.5v11.5zm9.3 0h-4.5V16.8H21v17.7zm9.3 0h-4.5V10.6h4.5v23.9zM2.3 18.5c.2 0 .3-.1.5-.1L20.6 6.3l-1.1 2.9c-.1.2-.1.4 0 .7.1.2.3.4.5.5.4.2.9-.1 1.1-.5l2.1-5.5c.1-.3.1-.6-.1-.8-.2-.2-.4-.4-.7-.3l-6.4.3c-.5 0-.8.4-.8.9s.4.8.9.8l3.3-.2L1.8 17c-.2.1-.3.3-.4.5 0 .2 0 .4.1.6.3.3.5.4.8.4z"
        className="FacilityDashboard_svg__st0"
      />
    </svg>
  );
};

const MemoSvgFacilityDashboard = React.memo(SvgFacilityDashboard);
export default MemoSvgFacilityDashboard;
