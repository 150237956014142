import {
  Box,
  ChakraProps,
  Flex,
  Hide,
  Link,
  LinkProps,
  Text,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { NavLink as RRNavLink, NavLinkProps } from 'react-router-dom';
import { Icon } from '../Icon';

type NavLinkSpecific = {
  icon: string;
  text: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
};

type NavLinkPropsType = LinkProps & NavLinkProps & NavLinkSpecific;

export const NavLink: FC<PropsWithChildren<NavLinkPropsType>> = ({
  to,
  icon,
  text,
  variant = 'primary',
  ...props
}) => {
  const isExternal = to && to.includes('http');

  const variantCssVars = useMemo<ChakraProps['sx']>(() => {
    switch (variant) {
      case 'secondary':
        return {
          '--fontWeight': 'var(--chakra-fontWeights-semibold)',
          '--borderColor': 'transparent',
          '--iconBgColor': 'var(--chakra-colors-whiteAlpha-500)',
          '--iconColor': 'var(--chakra-colors-brand-primary)',
          '--textColor': 'var(--chakra-colors-white)',
        };
      case 'tertiary':
        return {
          '--fontWeight': 'var(--chakra-fontWeights-normal)',
          '--iconBgColor': 'transparent',
          '--borderColor': 'transparent',
          '--iconColor': 'var(--chakra-colors-brand-primary)',
          '--textColor': 'var(--chakra-colors-brand-primary)',
        };
      default:
        return {
          '--fontWeight': 'var(--chakra-fontWeights-normal)',
          '--iconBgColor': 'transparent',
          '--iconColor': 'var(--chakra-colors-brand-primary)',
          '--textColor': 'var(--chakra-colors-white)',
          '--borderColor': 'var(--chakra-colors-whiteAlpha-400)',
        };
    }
  }, [variant]);

  return (
    <Box sx={variantCssVars}>
      <Link
        as={isExternal ? 'a' : RRNavLink}
        display="flex"
        alignItems="center"
        p="2"
        border="1px solid transparent"
        borderLeft="none"
        borderBottomColor="var(--borderColor)"
        fontFamily="'Interstate', sans-serif"
        fontSize="sm"
        textTransform="uppercase"
        color="var(--textColor)"
        zIndex={1}
        {...(!isExternal
          ? {
              to,
              activeStyle:
                variant === 'primary'
                  ? {
                      backgroundColor: 'var(--borderColor)',
                      boxShadow: 'var(--chakra-shadows-base)',
                      borderBottomColor: 'transparent',
                      zIndex: 2,
                    }
                  : {},
            }
          : {})}
        {...props}
      >
        {icon ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            p="1"
            mr="1"
            borderRadius="full"
            bgColor="var(--iconBgColor)"
            color="var(--iconColor)"
          >
            <Icon path={icon} size="24px" color="currentColor" />
          </Flex>
        ) : null}

        <Hide below="md">
          <Text color="var(--textColor)" fontWeight={'var(--fontWeight)'}>
            {text}
          </Text>
        </Hide>
      </Link>
    </Box>
  );
};
