import { WrappedResponse } from '../types';
import * as request from '../request';
import { Classifications as ClassificationsType } from '../../types/classification';
import { Env } from '../../constants/env';

export type ClassificationsResponseType = WrappedResponse<ClassificationsType>;

export function getClassifications(level: string = '1', parent: string = '0') {
  return request.get<ClassificationsResponseType>(
    `${Env.apiUrl}/v5/reference-data/sectors/levels/${level}/parents/${parent}`
  );
}
