import * as React from 'react';

const SvgBrandFaqs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 80 72" data-testid="svg-icon" {...props}>
      <path
        fill="#007AC9"
        d="M80 25.097C80 11.233 67.294 0 51.613 0 35.932 0 23.226 11.233 23.226 25.097c0 13.865 12.706 25.098 28.387 25.098 2.217 0 5.545-.219 7.616-.644l11.988 4.522c.42.158.82-.28.672-.733l-2.967-9.044C75.567 39.701 80 33.134 80 25.097zM53.833 37.962c-.418.462-1.008.693-1.772.693-.76 0-1.348-.23-1.759-.693-.41-.462-.617-1.037-.617-1.724 0-.737.214-1.334.642-1.79.429-.455 1.006-.683 1.734-.683.741 0 1.326.233 1.754.702.426.469.64 1.059.64 1.77 0 .688-.206 1.263-.622 1.725zm3.4-13.967l-2.135 2.278c-.764.941-1.157 2.316-1.18 4.124H49.98c0-1.67.191-3.001.574-3.992.38-.992 1.134-2.08 2.263-3.267 1.127-1.187 1.843-2.03 2.142-2.533.462-.764.694-1.59.694-2.479 0-1.177-.268-2.075-.807-2.695-.537-.62-1.325-.93-2.366-.93-.996 0-1.797.302-2.405.91-.607.604-.91 1.427-.91 2.464H44.95c.023-2.222.721-3.979 2.092-5.273 1.369-1.292 3.183-1.939 5.439-1.939 2.323 0 4.135.64 5.438 1.921 1.3 1.28 1.95 3.07 1.95 5.366 0 2.047-.879 4.061-2.635 6.045z"
      />
      <path
        fill="#6AC5C4"
        d="M35.034 49.876c-.065.015-.126.042-.195.042H10.323c-.535 0-.968-.47-.968-1.049 0-.58.433-1.05.968-1.05h21.243a31.52 31.52 0 01-5.67-4.916c-.031.003-.058.02-.09.02H10.323c-.535 0-.968-.47-.968-1.05 0-.58.433-1.05.968-1.05h13.85c-2.879-3.838-4.755-8.316-5.294-13.145C8.129 29.478 0 37.81 0 47.84c0 6.575 3.627 11.948 9.064 15.708l-2.427 7.4c-.122.37.206.728.549.6l9.809-3.7c1.694.347 4.417.526 6.23.526 10.663 0 19.622-6.355 22.353-15.01a35.466 35.466 0 01-10.544-3.488zm-10.518 7.038H10.323c-.535 0-.968-.47-.968-1.05 0-.578.433-1.048.968-1.048h14.193c.535 0 .968.47.968 1.049 0 .58-.434 1.05-.968 1.05z"
      />
    </svg>
  );
};

const MemoSvgBrandFaqs = React.memo(SvgBrandFaqs);
export default MemoSvgBrandFaqs;
