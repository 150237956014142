import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Image,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Flex,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'src/components/Button';
import { Link } from 'src/components/Link';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

type MethodologyProps = Record<string, unknown>;

const TIMELINE_COLORS = [
  '#e6eef2',
  '#ccdde4',
  '#99bbca',
  '#6698af',
  '#337695',
  '#00547a',
];

const TARGET_STRATEGIES = [
  {
    decrease: 'Greater than 50%',
    riskLevel: 'High',
    strategy: 'Invest in smart water strategies immediately',
  },
  {
    decrease: 'Greater than 25% and less than 50%',
    riskLevel: 'Medium',
    strategy: 'Invest in smart water strategies',
  },
  {
    decrease: 'Less than 25%',
    riskLevel: 'Low',
    strategy: 'Monitor water issues and invest in smart water strategies',
  },
  {
    decrease: 'Less than 0%',
    riskLevel: 'Low',
    strategy: 'Monitor water issues',
  },
];

const TAKE_ACTION_STAGES = [
  {
    stage: 'Water-smart',
    description:
      "Smart, circular water management is fully embedded in your site's decision-making and operations. You are actively working on water issues with the surrounding community.",
  },
  {
    stage: 'Exploratory',
    description:
      'You have mastered water conservation. Your site is deploying pilots for circular water management. You are reaching out to other water users in the surrounding community.',
  },
  {
    stage: 'Linear',
    description:
      'Your site is primarily focused on water conservation, with successful water reduction pilots in place.',
  },
  {
    stage: 'Untapped',
    description:
      'Your site has not yet adopoted smart water management practices.',
  },
];

export const Methodology: FC<PropsWithChildren<MethodologyProps>> = () => {
  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>

      <PageHeader
        image="/images/pool-water.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Methodology
          </Heading>
        )}
      />

      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(5vh)' }}
        p="20px"
        pb="40px"
        sx={{
          '& > div:nth-child(even)': {
            bgColor: TIMELINE_COLORS[0],
            p: '20px',
          },
        }}
      >
        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Methodology Overview
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack spacing="15">
                <Text fontSize="lg">
                  Water scarcity has the potential to increase the cost of
                  water, which makes operations more expensive and reduces
                  profit margins. Water scarcity also limits availability,
                  making it more difficult to access the water a business needs
                  to operate, which can decrease production and result in loss
                  of revenue. Water is undervalued, particularly in areas where
                  water is most scarce.
                </Text>
                <Text fontSize="lg">
                  To date, water has been easy to access and available. But the
                  reality is changing. Decreasing water quantity and quality
                  poses significant risks to businesses. Businesses need data
                  and insights on water-related risks to better understand and
                  internalize the full value of water at the site and enterprise
                  levels. Once companies understand these risks and the
                  potential costs to their business, they need tools and
                  expertise to take meaningful action and create tangible change
                  in water use practices at their facilities. The Smart Water
                  Navigator provides the data, insights, tools and expertise
                  that companies need to decrease risk and costs related to
                  water while increasing revenue.
                </Text>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="15"
                p="14"
              >
                <Stack spacing="12" flex="1 1 60%" direction="column">
                  <Heading as="h3" size="lg">
                    Smart Water Navigator Methodology
                  </Heading>
                  <Text fontSize="md">
                    This methodology describes how the Smart Water Navigator
                    functions, from key data sources and assumptions to inputs
                    and outputs of the tool.
                  </Text>
                  <Link
                    to="/documents/2024_SmartWaterNavigatorMethodology.pdf"
                    target="_blank"
                  >
                    <Button>Download</Button>
                  </Link>
                </Stack>

                <Stack
                  w="auto"
                  spacing="6"
                  flex="0 0 40%"
                  direction="column"
                  alignItems="flex-end"
                >
                  <Box>
                    <Image
                      src="/images/resources/swn-methodology.jpg"
                      h="400px"
                      w="auto"
                    />
                  </Box>
                </Stack>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Identify Risk
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="15">
                <Stack direction="column" flex="1 1 60%" spacing="15">
                  <Text fontSize="lg">
                    The Smart Water Navigator uses best-in-class local water
                    basin datasets, economic techniques and scientific
                    methodologies developed by S&P Global Sustainable1 to
                    monetize water-related business risks. The tool provides a
                    comprehensive series of risk metrics to help businesses
                    understand incoming (quantity and quality) risks and
                    outgoing (quality) risks.
                  </Text>
                  <Text fontSize="lg">
                    The tool takes user-provided business information on water
                    use, water prices and production data and calculates
                    incoming and outgoing water risk that could result in
                    increased operating costs. The monetary value assigned to
                    this risk takes into account water availability, water
                    quality and competing uses of water within local water
                    basins across a three-, five- and 10-year time horizon. The
                    Smart Water Navigator also calculates the potential loss in
                    revenue from incoming water quantity risk across the same
                    time horizons.
                  </Text>
                </Stack>
                <Box flex="0 0 40%">
                  <Image src="/images/methodology-flowchart.jpg" />
                </Box>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Set Targets
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack direction={{ base: 'column' }} spacing="15">
                <Text fontSize="lg">
                  The Smart Water Navigator uses baseline water stress data to
                  help users identify facilities in their enterprise that are at
                  risk of withdrawing unsustainable amounts of water from
                  surrounding sources to avoid operational impacts from water
                  scarcity. Once these sites are identified, the tool helps
                  users set specific, time-bound targets for sustainable water
                  allocation based on location-based contextual factors informed
                  by the best available science and analysis.
                </Text>
                <Text fontSize="lg">
                  These targets maintain a risk threshold of &lt;40% to minimize
                  a facility’s impact on the local ecosystem, human health and
                  economic needs.
                </Text>
                <Table
                  size="sm"
                  border="1px solid"
                  borderColor="gray.600"
                  sx={{
                    '& thead td': {
                      borderBottom: '1px solid',
                      borderColor: 'gray.600',
                    },
                    '& tbody > tr': {
                      borderBottom: '1px solid',
                      borderColor: 'gray.600',
                    },
                    '& tbody td': {
                      verticalAlign: 'top',
                      borderRight: '1px solid',
                      borderColor: 'gray.600',
                    },
                  }}
                >
                  <Thead
                    bgColor={TIMELINE_COLORS[5]}
                    color="white"
                    borderBottom="1px solid"
                    borderColor="gray.600"
                  >
                    <Tr>
                      <Td>Needed Decrease From Current Water Use</Td>
                      <Td>Risk Level</Td>
                      <Td>Recommended Strategy</Td>
                    </Tr>
                  </Thead>
                  <Tbody
                    bgColor={TIMELINE_COLORS[0]}
                    borderTop="1px solid"
                    borderColor="gray.600"
                  >
                    {TARGET_STRATEGIES.map(
                      ({ decrease, riskLevel, strategy }) => (
                        <Tr key={decrease}>
                          <Td
                            verticalAlign="top"
                            bgColor={TIMELINE_COLORS[4]}
                            color="white"
                          >
                            {decrease}
                          </Td>
                          <Td verticalAlign="top">{riskLevel}</Td>
                          <Td verticalAlign="top">{strategy}</Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Take Action
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack direction={{ base: 'column' }} spacing="15">
                <Text fontSize="lg">
                  Using a 13-question Water Action Assessment informed by
                  industry frameworks and stakeholder input, the tool offers a
                  comprehensive guiding philosophy and a practical roadmap to
                  build circular water management at the site level. The
                  assessment places sites on a “water maturity curve” from
                  “untapped” to “water-smart” based on current water management
                  practices. The site’s water maturity and industry inform a
                  customized guide with case studies and resources.
                </Text>
                <Table
                  size="sm"
                  border="1px solid"
                  borderColor="gray.600"
                  sx={{
                    '& thead td': {
                      borderBottom: '1px solid',
                      borderColor: 'gray.600',
                    },
                    '& tbody > tr': {
                      borderBottom: '1px solid',
                      borderColor: 'gray.600',
                    },
                    '& tbody td': {
                      verticalAlign: 'top',
                      borderRight: '1px solid',
                      borderColor: 'gray.600',
                    },
                  }}
                >
                  <Thead
                    bgColor={TIMELINE_COLORS[5]}
                    color="white"
                    borderBottom="1px solid"
                    borderColor="gray.600"
                  >
                    <Tr>
                      <Td w="25%">Stage</Td>
                      <Td>Description</Td>
                    </Tr>
                  </Thead>
                  <Tbody
                    bgColor={TIMELINE_COLORS[0]}
                    borderTop="1px solid"
                    borderColor="gray.600"
                  >
                    {TAKE_ACTION_STAGES.map(({ stage, description }) => (
                      <Tr key={stage}>
                        <Td
                          verticalAlign="top"
                          bgColor={TIMELINE_COLORS[4]}
                          color="white"
                        >
                          {stage}
                        </Td>
                        <Td verticalAlign="top">{description}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Track Progress
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack spacing="calc(5vh)">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Stack direction="column" flex="1 1 100%" spacing="15">
                    <Text fontSize="lg">
                      The Smart Water Navigator allows businesses to assess
                      water performance at their facilities over time. By
                      inputting revenue and incoming and outgoing water data
                      over multiple years, users can not only visualize the
                      interdependencies amongst production volume, revenue and
                      water use, they can also see how they are performing
                      against their water targets and identify new risks and
                      opportunities for growth.
                    </Text>
                  </Stack>
                </Stack>
                <Flex justifyContent="center">
                  <Link
                    to="/documents/2024_SmartWaterNavigatorMethodology.pdf"
                    target="_blank"
                  >
                    <Button>
                      Download the full Smart Water Navigator methodology
                    </Button>
                  </Link>
                </Flex>
              </Stack>
            </Container>
          </Box>
        </Box>
      </Stack>

      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};

Methodology.displayName = 'Methodology';
