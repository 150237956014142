import { Button, ButtonGroup, IconButton, Text } from '@chakra-ui/react';
import {
  mdiAlertCircle,
  mdiPencilOutline,
  mdiPlus,
  mdiSync,
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  deleteFacilityReportingYear,
  getFacilityReportingYears,
} from 'src/clients/api/v5.facility';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useDeepCompareEffect } from 'src/hooks/useDeepCompare';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { Alert } from '../Alert';
import { BlockError } from '../BlockError';
import { LoadingSpinner } from '../LoadingSpinner';
import styles from './ReportingYearsList.module.css';

const cx = classnames.bind(styles);

type ReportingYearsListProps = {
  facilityId: string;
};

export const ReportingYearsList: FC<ReportingYearsListProps> = ({
  facilityId,
}) => {
  const [asyncState, setAsyncState] = useAsyncState();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ['facilities', facilityId, 'reportingYears'],
    () => getFacilityReportingYears(facilityId),
    { cacheTime: 0 }
  );

  useDeepCompareEffect(() => {
    if (!isFetching && data?.status === 'success') {
      const hasProcessing = data.value.data.some(
        (reportingYear) => !reportingYear.processed
      );

      if (hasProcessing) {
        window.setTimeout(refetch, 10_000);
      }
    }
  }, [data, refetch, isFetching]);

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (error || (data && data.status === 'error' && data.code !== 404)) {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading reporting years"
        message="We were unable to load your reporting years successfully"
      />
    );
  }

  const reportingYears =
    data && data.status === 'success'
      ? data.value.data.sort((a, b) => b.reportingYear - a.reportingYear)
      : ([] as Array<ReportingYear>);

  const handleDelete = (reportingYearId: string | number) => {
    setAsyncState({
      status: 'loading',
    });

    deleteFacilityReportingYear(facilityId, reportingYearId)
      .then((result) => {
        if (result.status === 'error') {
          throw new Error();
        }

        queryClient.invalidateQueries(['aggregates']);
        queryClient.invalidateQueries(['facilities']);
        history.push(`/app/facilities/${facilityId}`);
      })
      .catch(() => {
        setAsyncState({
          status: 'error',
          message: 'There was an error deleting your facility.',
        });
      });
  };

  return (
    <>
      {asyncState.status === 'error' ? (
        <div style={{ padding: '15px 0' }}>
          <Alert variant="danger" message={asyncState.message} />
        </div>
      ) : null}

      <section className={cx('reportingYearsList')}>
        <table>
          <thead>
            <tr>
              <td>Reporting Years</td>
              <td style={{ textAlign: 'right' }}>
                <ButtonGroup justifyContent="flex-end">
                  <Button
                    onClick={() =>
                      history.push(
                        `/app/facilities/${facilityId}/reporting-years/new`
                      )
                    }
                    leftIcon={<Icon path={mdiPlus} size="24px" />}
                    aria-label="Edit Reporing Year"
                    colorScheme="blue"
                    size={'sm'}
                  >
                    <Text as="span">Add Reporting Year</Text>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          </thead>
          <tbody>
            {!reportingYears.length ? (
              <tr>
                <td colSpan={2}>No reporting years available</td>
              </tr>
            ) : (
              reportingYears.map((year) => (
                <tr key={year.reportingYear}>
                  <td>{year.reportingYear}</td>
                  <td>
                    <div className={cx('buttonGroup')}>
                      {year.error ? (
                        <>
                          <Icon
                            path={mdiAlertCircle}
                            size="18px"
                            vertical
                            style={{
                              marginRight: '5px',
                            }}
                            color="red"
                          />{' '}
                          <span style={{ marginRight: '15px' }}>Error</span>
                        </>
                      ) : null}

                      {!year.processed ? (
                        <>
                          <Icon
                            path={mdiSync}
                            size="18px"
                            spin
                            vertical
                            style={{
                              marginRight: '5px',
                            }}
                            rotate={180}
                          />{' '}
                          <span style={{ marginRight: '15px' }}>
                            Processing
                          </span>
                        </>
                      ) : null}

                      <ButtonGroup>
                        {year.processed ? (
                          <IconButton
                            onClick={() =>
                              history.push(
                                `/app/facilities/${year.facilityId}/reporting-years/${year.facilityReportingYearId}`
                              )
                            }
                            aria-label="Edit Reporing Year"
                            icon={
                              <Icon
                                path={mdiPencilOutline}
                                size="24px"
                                color="currentColor"
                              />
                            }
                          />
                        ) : null}

                        <IconButton
                          onClick={() =>
                            handleDelete(year.facilityReportingYearId)
                          }
                          aria-label="Delete Reporing Year"
                          icon={<Icon path={mdiTrashCanOutline} size="24px" />}
                          colorScheme="red"
                        />
                      </ButtonGroup>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </section>
    </>
  );
};

ReportingYearsList.displayName = 'ReportingYearsList';
