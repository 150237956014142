import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  Show,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { mdiAlertOutline, mdiMenu } from '@mdi/js';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Link, NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { useAuth } from 'src/hooks/useAuth';
import { DEFAULT_MAINTENANCE_MESSAGES } from 'src/hooks/useMaintenance';
import { AccountAvatar } from '../AccountAvatar';
import { Icon } from '../Icon';
import { SiteSubHeader } from '../SiteSubHeader';
import { UploadStatus } from '../UploadStatus';
import styles from './SiteHeader.module.css';

const cx = classnames.bind(styles);

export interface SiteHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  hasUpcomingMaintenance?: boolean;
  maintenanceMessage?: string;
  isCurrentlyUnderMaintenance?: boolean;
}

const LINKS: Record<string, Record<string, string> | string> = {
  About: {
    'Smart Water Navigator': '/about',
    'Development Partners': '/development-partners',
    FAQ: '/faq',
    Resources: '/resources',
  },
  Explore: {
    'How it Works': '/how-it-works',
    Methodology: '/methodology',
    'Take Action': '/take-action',
  },
  'My Dashboard': '/app/dashboard',
};

const ACCOUNT_LINKS: Record<string, Record<string, string> | string> = {
  Profile: {
    User: '/user',
    Account: '/account',
  },
};

const Navigation: FC<{
  component: FC<NavLinkProps>;
  className?: string;
}> = ({ component: CustomLink, className }) => {
  return (
    <ul className={cx('links', className)}>
      {Object.entries(LINKS).map(([label, value]) => {
        switch (typeof value) {
          case 'string': {
            return (
              <li className={cx('link')} key={label}>
                <CustomLink to={value}>{label}</CustomLink>
              </li>
            );
          }

          case 'object': {
            return (
              <li className={cx('link', 'withSubNav')} key={label}>
                <span>{label}</span>
                <ul className={cx('links')}>
                  {Object.entries(value).map(([subLabel, subValue]) => {
                    return (
                      <li className={cx('link')} key={subLabel}>
                        <CustomLink to={subValue}>{subLabel}</CustomLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }

          default: {
            return '';
          }
        }
      })}
    </ul>
  );
};

export const SiteHeader: FC<SiteHeaderProps> = ({
  hasUpcomingMaintenance,
  maintenanceMessage,
  isCurrentlyUnderMaintenance,
}) => {
  const { isAuthenticated, logout } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const location = useLocation();
  const shouldShowSubheader =
    isCurrentlyUnderMaintenance ||
    hasUpcomingMaintenance ||
    location.pathname.includes('/app');

  const HeaderLink: FC<NavLinkProps> = (props) => {
    const isExternal = props.to.includes('http');

    if (isExternal) {
      return <a href={props.to}>{props.children}</a>;
    }

    return (
      <NavLink
        {...props}
        onClick={() => {
          setIsOpen(false);
        }}
      />
    );
  };

  return (
    <>
      <Stack
        as="header"
        display="flex"
        direction="row"
        justifyContent={{
          base: 'space-around',
          lg: 'space-between',
        }}
        height={{ base: '60px', lg: '95px' }}
        minHeight={{ base: '60px', lg: '95px' }}
        px={{ base: '2', lg: '8' }}
        py={{ base: '2', lg: '4' }}
        position="relative"
        zIndex={4}
        boxShadow="md"
      >
        <Show below="lg">
          <Flex
            position="absolute"
            top="0"
            left="0"
            height={{ base: '60px', lg: '95px' }}
            alignItems="center"
            p="2"
          >
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              aria-label={'Navigation'}
              icon={<Icon path={mdiMenu} size="24px" />}
            />
          </Flex>
        </Show>
        <Flex height={{ base: '40px', lg: '60px' }} flex="0 0 auto">
          <ChakraLink as={Link} to="/" height={{ base: '40px', lg: '60px' }}>
            <Image
              src="/images/smart-water-navigator.png"
              alt="Smart Water Navigator logo"
              height={{ base: '40px', lg: '60px' }}
            />
          </ChakraLink>
        </Flex>

        <Show above="lg">
          <Navigation component={HeaderLink} />

          <HStack>
            {isCurrentlyUnderMaintenance ? (
              <Flex className={cx('action')} alignItems="center">
                <Tooltip
                  hasArrow
                  placement="left"
                  label={DEFAULT_MAINTENANCE_MESSAGES.active}
                  aria-label="Ongoing Maintenance Alert"
                >
                  <Icon
                    path={mdiAlertOutline}
                    size="24px"
                    color="var(--chakra-colors-orange-300)"
                  />
                </Tooltip>
              </Flex>
            ) : null}

            {isAuthenticated ? (
              <>
                <div className={cx('action')}>
                  <UploadStatus />
                </div>
                <div className={cx('action')}>
                  <AccountAvatar />
                </div>
              </>
            ) : (
              <HeaderLink to="/login">
                <Button disabled={isCurrentlyUnderMaintenance}>Login</Button>
              </HeaderLink>
            )}
          </HStack>
        </Show>
      </Stack>

      {shouldShowSubheader ? (
        <Box>
          <SiteSubHeader
            hasUpcomingMaintenance={hasUpcomingMaintenance}
            isCurrentlyUnderMaintenance={isCurrentlyUnderMaintenance}
            maintenanceMessage={maintenanceMessage}
          />
        </Box>
      ) : null}

      <Show below="lg">
        {isOpen && (
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={() => setIsOpen(false)}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />

              <DrawerBody as={Flex} direction="column">
                <Flex width="full">
                  <Navigation component={HeaderLink} />
                </Flex>
                <Flex flex="1 1 100%" />

                <ul className={cx('links')}>
                  {Object.entries(ACCOUNT_LINKS).map(([label, value]) => {
                    return (
                      <li className={cx('link', 'withSubNav')} key={label}>
                        <span>{label}</span>
                        <ul className={cx('links')}>
                          {Object.entries(value).map(([subLabel, subValue]) => {
                            return (
                              <li className={cx('link')} key={subLabel}>
                                <HeaderLink to={subValue}>
                                  {subLabel}
                                </HeaderLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </DrawerBody>

              <DrawerFooter as={Stack} flexDirection="column">
                {isAuthenticated ? (
                  <>
                    <Button
                      className={cx('authButton')}
                      onClick={() => logout && logout()}
                      style={{ width: '100%' }}
                    >
                      Logout
                    </Button>
                  </>
                ) : (
                  <>
                    <HeaderLink to="/login" style={{ width: '100%' }}>
                      <Button
                        className={cx('authButton')}
                        style={{ width: '100%' }}
                      >
                        Login
                      </Button>
                    </HeaderLink>
                    <HeaderLink to="/register" style={{ width: '100%' }}>
                      <Button
                        className={cx('authButton')}
                        variant="secondary"
                        style={{ width: '100%' }}
                      >
                        Signup now
                      </Button>
                    </HeaderLink>
                  </>
                )}
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )}
      </Show>
    </>
  );
};

SiteHeader.displayName = 'SiteHeader';
