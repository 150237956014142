import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getAggregates } from 'src/clients/api/v5.aggregates';
import { getFolderAggregates, getFolders } from 'src/clients/api/v5.folders';
import { BenchmarkingChart } from 'src/components/BenchmarkingChart';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import tabStyles from 'src/components/FacilityForm/Helpers/FacilityTabNavigator/FacilityTabNavigator.module.css';
import { FacilityRiskFilter } from 'src/components/FacilityRiskFilter';
import { FilterAndSort } from 'src/components/FilterAndSort';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { FacilityRiskFilterProvider } from 'src/hooks/useFacilityRiskFilter';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { filter } from 'src/hooks/useFilterAndSort/utils';
import { AggregateResults } from 'src/types/aggregates';
import { Folders } from 'src/types/folder';
import styles from './Benchmarking.module.css';

const cx = classnames.bind(styles);

type BenchmarkingProps = {};

const BenchmarkingWrapper: FC<{
  aggregates: AggregateResults;
  folders: Folders;
}> = ({ aggregates, folders, children }) => (
  <>
    <header>
      <nav className={cx('tabs', tabStyles.tabs)}>
        <div className={cx('shadow', tabStyles.shadow)}></div>
        <Link
          className={cx('tab', tabStyles.tab)}
          to={'/app/enterprise/risk-analysis'}
        >
          Quantify Risk
        </Link>
        <div
          className={cx(
            'tab',
            'active',
            `${tabStyles.tab} ${tabStyles.active}`
          )}
        >
          Track Performance
        </div>
      </nav>
    </header>
    <div className={cx('benchmarking')}>
      <div className={cx('header')}>
        <Copy as="h2">Track Performance</Copy>
        <Copy as="p">
          This Benchmarking chart allows a company to compare the performance of
          different sites within a facility portfolio. This analysis provides a
          way for business leaders to prioritize and determine appropriate water
          use efficiency strategies for each location in order to bring sites
          closer to the industry, or organizational, average or median
          water-intensity.
        </Copy>
      </div>
      <div className={cx('filterAndSort')}>
        <FilterAndSort hideSort aggregates={aggregates} folders={folders} />
      </div>
      {children}
    </div>
  </>
);

export const Benchmarking: FC<BenchmarkingProps> = () => {
  const filterState = useFilterAndSort();

  const { data: folderData, isLoading: isLoadingFolders } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    [
      'aggregates',
      ...(filterState.folderId ? ['folder', filterState.folderId] : []),
    ],
    () =>
      filterState.folderId
        ? getFolderAggregates(filterState.folderId)
        : getAggregates(),
    {
      cacheTime: 0,
      enabled: !isLoadingFolders,
    }
  );

  const aggregates =
    data?.status === 'success' && data?.value.data ? data?.value.data : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.data
      ? folderData?.value.data
      : [];

  const filteredAggregates = useDeepCompareMemo(
    () => filter(aggregates, filterState),
    [aggregates, filterState]
  );

  if (isLoading || isLoadingFolders) {
    return (
      <BenchmarkingWrapper aggregates={filteredAggregates} folders={folders}>
        <LoadingSpinner />
      </BenchmarkingWrapper>
    );
  }

  if (error || data?.status === 'error') {
    return (
      <BenchmarkingWrapper aggregates={filteredAggregates} folders={folders}>
        <BlockError
          retry={refetch}
          isLoading={isFetching}
          title="Error loading facilities"
          message="We were unable to load your facilities successfully"
        />
      </BenchmarkingWrapper>
    );
  }

  return (
    <BenchmarkingWrapper aggregates={aggregates} folders={folders}>
      <div className={cx('content')}>
        <FacilityRiskFilterProvider aggregates={filteredAggregates}>
          <div className={cx('charts')}>
            <BenchmarkingChart />
          </div>
          <div className={cx('sidebar')}>
            <FacilityRiskFilter />
          </div>
        </FacilityRiskFilterProvider>
      </div>
    </BenchmarkingWrapper>
  );
};

Benchmarking.displayName = 'Benchmarking';
