import classnames from 'classnames/bind';
import React, { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getFacilityGuide, parseGuide } from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { AggregateResult } from 'src/types/aggregates';
import { NoAssessment } from '../../NoAssessment';
import { VerticalTabContainer } from '../../VerticalTabContainer';
import { BusinessCase } from './Tabs/BusinessCase';
import { CaseStudies } from './Tabs/CaseStudies';
import { Checklist } from './Tabs/Checklist';
import { InvestmentCalculatorTab } from './Tabs/InvestmentCalculator';
import { Scenario } from './Tabs/Scenario';
import { ToolsAndResources } from './Tabs/ToolsAndResources';
import styles from './TakeActionTabGroup.module.css';

export type TakeActionTabGroupProps = {
  aggregate: AggregateResult;
  className?: string;
};

const TABS = [
  'Scenario',
  'Checklist',
  'Tools and Resources',
  'Business Case',
  'Investment Calculator',
  'Case Studies',
];

const cx = classnames.bind(styles);

export const TakeActionTabGroup: FC<TakeActionTabGroupProps> = ({
  aggregate,
  className,
}) => {
  const maturity = aggregate.waaWaterMaturity;
  const normalizedMaturity = maturity?.toLowerCase() ?? '';

  const request = useQuery(
    [
      'facilities',
      aggregate.facility.facilityId,
      'reportingYears',
      aggregate.facilityReportingYearId,
      'guide',
    ],
    () =>
      getFacilityGuide(
        aggregate.facility.facilityId,
        aggregate.facilityReportingYearId
      ),
    {
      enabled: !!aggregate.facility.facilityId,
      cacheTime: 0,
    }
  );

  const graphUrl = useMemo(() => {
    switch (normalizedMaturity) {
      case 'exploratory':
        return '/images/waa/wmc_exploratory.png';
      case 'linear':
        return '/images/waa/wmc_linear.png';
      case 'untapped':
        return '/images/waa/wmc_untapped.png';
      default:
        return '/images/waa/wmc_watersmart.png';
    }
  }, [normalizedMaturity]);

  if (request.isLoading) {
    return <LoadingSpinner />;
  }

  if (
    request.isError ||
    (request.isSuccess &&
      request.data.status === 'error' &&
      request.data.code !== 404) ||
    !request.data?.value
  ) {
    return (
      <BlockError
        isLoading={request.isFetching}
        title={'Could not load facility guide.'}
        message="We were unable to load the guide for this facility and reporting year."
      />
    );
  }

  const guide =
    request.data?.status === 'success'
      ? parseGuide(request.data?.value.data[0])
      : undefined;

  try {
    return (
      <VerticalTabContainer
        tabs={true || !!aggregate.waaScore ? TABS : []}
        className={cx('animate', 'fadeInDown', className)}
      >
        {(idx) => {
          if (!aggregate.waaScore || !aggregate.waaWaterMaturity || !guide) {
            return (
              <div
                style={{
                  width: '65%',
                  padding: '25px 0',
                  display: 'flex',
                  justifyContent: 'center',
                  justifySelf: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
              >
                <NoAssessment aggregate={aggregate} />
              </div>
            );
          }

          return (
            <>
              {idx === 0 ? (
                <Scenario
                  guide={guide.Scenario}
                  maturity={maturity}
                  graphUrl={graphUrl}
                />
              ) : null}
              {idx === 1 ? <Checklist guide={guide.checklist} /> : null}
              {idx === 2 ? (
                <ToolsAndResources guide={guide.ToolsAndResources} />
              ) : null}
              {idx === 3 ? <BusinessCase guide={guide.BusinessCase} /> : null}
              {idx === 4 ? (
                <InvestmentCalculatorTab aggregate={aggregate} />
              ) : null}
              {idx === 5 ? <CaseStudies guide={guide.CaseStudies} /> : null}
            </>
          );
        }}
      </VerticalTabContainer>
    );
  } catch (error) {
    return <BlockError />;
  }
};

TakeActionTabGroup.displayName = 'TakeActionTabGroup';
