import { Env } from 'src/constants/env';
import { ContactFormSchemaPayload } from 'src/routes/marketing/Contact/validation';
import request from '../request';
import { WrappedResponse } from '../types';

export type ContactRequestType = {};
export type ContactResponseType = WrappedResponse<unknown>;
export function sendContactMessage(payload: ContactFormSchemaPayload) {
  return request.post<ContactResponseType, ContactRequestType>(
    `${Env.apiUrl}/v5/contact`,
    payload
  );
}
