import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Copy } from 'src/components/Copy';
import { RadioList } from 'src/components/RadioList';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { Choice, Question } from 'src/types/assessment';
import { compareByProp } from 'src/utils/array';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import styles from '../Tabs.module.css';

const cx = classnames.bind(styles);

type CarouselProps = {
  assessmentId: string;
  reportingYearId?: string;
  questions: Record<string, Question>;
  className?: string;
  onSave: (values: any) => void;
  onCancel: () => void;
};

export const Carousel: FC<CarouselProps> = ({
  reportingYearId,
  assessmentId,
  className,
  questions,
  onSave,
  onCancel,
}) => {
  const { control, errors, watch } = useFormContext();
  const answers = watch('assessment.assessmentAnswers');

  const sortedQuestions = Object.values(questions).sort((...props) =>
    compareByProp('displayOrder', ...props)
  );

  const slideshow = useDeepCompareMemo(() => {
    return sortedQuestions.reduce<Array<Question>>((acc, question) => {
      if (!question.conditional) {
        return [...acc, question];
      }

      if (
        answers[question.parentConditionChoiceId] ===
        question.parentConditionChoiceValue
      ) {
        return [...acc, question];
      }

      return acc;
    }, []);
  }, [answers]);

  const questionsCount = slideshow.length;
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);

  const handleNext = () => {
    if (!!answers[slideshow[activeQuestionIndex].questionId]) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  return (
    <>
      <div className={cx('tabContent', 'carousel', className)}>
        <div className={cx('cardStack')}>
          <small className={cx('cardCount')}>
            {activeQuestionIndex + 1} / {questionsCount}
          </small>
          <Controller
            control={control}
            name={`assessment.questionnaireId`}
            defaultValue={assessmentId}
            render={(props) => <input type="hidden" {...props} />}
          />
          <Controller
            control={control}
            name={`assessment.reportingyearId`}
            defaultValue={reportingYearId}
            render={(props) => <input type="hidden" {...props} />}
          />
          {slideshow.map((question, index) => (
            <div
              className={cx('card', {
                active: activeQuestionIndex === index,
              })}
              key={question.questionId}
            >
              <Copy>{question.displayText}</Copy>
              <br />
              <Copy>{question.displaySubText}</Copy>
              <div className={cx('options')}>
                <Controller
                  control={control}
                  name={
                    `assessment.assessmentAnswers.${question.questionId}` as const
                  }
                  rules={{ required: 'Required' }}
                  defaultValue={''}
                  render={(props) => (
                    <RadioList
                      {...props}
                      items={question.questionChoices.map((c: Choice) => ({
                        label: c.displayText,
                        value: c.questionChoiceId,
                      }))}
                      error={
                        errors?.assessment?.assessmentAnswers
                          ? errors.assessment.assessmentAnswers[
                              question.questionId
                            ]
                          : undefined
                      }
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
        {activeQuestionIndex < questionsCount - 1 ? (
          <FacilityTabFooter
            canNext={
              !!answers[slideshow[activeQuestionIndex].questionId] ||
              answers.length === 0
            }
            onNext={handleNext}
            nextButtonText="Next question"
            backButtonText="Previous question"
            canBack={activeQuestionIndex !== 0}
            onBack={() => setActiveQuestionIndex(activeQuestionIndex - 1)}
            onCancel={onCancel}
          />
        ) : (
          <FacilityTabFooter
            canBack={activeQuestionIndex !== 0}
            backButtonText="Previous question"
            onBack={() => setActiveQuestionIndex(activeQuestionIndex - 1)}
            onNext={onSave}
            onCancel={onCancel}
          />
        )}
      </div>
    </>
  );
};

Carousel.displayName = 'Carousel';
