import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { getClassifications } from 'src/clients/api/classification';
import { getCities, getCountries, getRegions } from 'src/clients/api/country';
import { createWrmFacility } from 'src/clients/api/v5.facility';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { Copy } from 'src/components/Copy';
import { FacilityTabFooter } from 'src/components/FacilityForm/Helpers/FacilityTabFooter';
import {
  facilityInformationSchema,
  FacilityInformationSchemaForm,
} from 'src/components/FacilityForm/validation';
import { FormHelpButton, FormHelpText } from 'src/components/FormHelp';
import { SelectInput } from 'src/components/SelectInput';
import { TextInput } from 'src/components/TextInput';
import { UploadFlowError } from 'src/constants/errors';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useFormHelp } from 'src/hooks/useFormHelp';
import {
  ParsedUploadRowErrors,
  ParsedUploadRowResults,
} from 'src/types/upload';
import styles from './FacilityCreate.module.css';
import { mapDefaultFacilityFormValues } from './helpers';

const cx = classnames.bind(styles);

type FacilityCreateProps = {};

export const FacilityCreate: FC<
  FacilityCreateProps & RouteComponentProps<{ id: string }, {}>
> = ({ history }) => {
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState();
  const { state } = useLocation<
    | {
        uploadResults: {
          rowResults: ParsedUploadRowResults;
          rowErrors: ParsedUploadRowErrors;
          rowId: string;
          errorType: UploadFlowError;
        };
      }
    | undefined
  >();
  const uploadResults = state?.uploadResults;

  const isUploadResolutionFlow = useMemo(() => !!uploadResults, [
    uploadResults,
  ]);

  const formHelp = useFormHelp();

  const {
    control,
    formState: { errors, isValid },
    watch,
    register,
    handleSubmit,
    trigger,
  } = useForm<FacilityInformationSchemaForm>({
    defaultValues: mapDefaultFacilityFormValues(uploadResults?.rowResults),
    resolver: yupResolver(facilityInformationSchema),
    mode: 'onChange',
  });

  const {
    data: countriesData,
    isLoading: isLoadingCountries,
    status: countriesDataStatus,
  } = useQuery(['countries'], () => getCountries());

  const countries =
    (countriesData?.status === 'success' && countriesData.value.data) || [];

  const countryId = watch('countryId');
  const {
    data: regionData,
    isLoading: isLoadingRegions,
    status: regionsDataStatus,
  } = useQuery(['regions', countryId], () => getRegions(countryId), {
    enabled: !!countryId,
  });

  const regions =
    (regionData?.status === 'success' && regionData.value.data) || [];

  const regionId = watch('regionId');
  const {
    data: cityData,
    isLoading: isLoadingCities,
    status: citiesDataStatus,
  } = useQuery(['citys', regionId], () => getCities(countryId, regionId), {
    enabled: !!(countryId && regionId),
  });

  const citys = (cityData?.status === 'success' && cityData.value.data) || [];

  const {
    data: sectorsLevelOneData,
    isLoading: isLoadingClassificationsLevelOne,
    status: sectorsLevelOneDataStatus,
  } = useQuery(['sectors', 'levelOne'], () => getClassifications());

  const sectorsLevelOne =
    (sectorsLevelOneData?.status === 'success' &&
      sectorsLevelOneData.value.data) ||
    [];

  const sector1Id = watch('sector1Id');
  const {
    data: sectorsLevelTwoData,
    isLoading: isLoadingClassificationsLevelTwo,
    status: sectorsLevelTwoDataStatus,
  } = useQuery(
    ['sectors', 'levelTwo', sector1Id],
    () => getClassifications('2', `${sector1Id}`),
    {
      enabled: !!sector1Id,
    }
  );

  const sectorsLevelTwo =
    (sectorsLevelTwoData?.status === 'success' &&
      sectorsLevelTwoData.value.data) ||
    [];

  const sector2Id = watch('sector2Id');
  const {
    data: sectorsLevelThreeData,
    isLoading: isLoadingClassificationsLevelThree,
    status: sectorsLevelThreeDataStatus,
  } = useQuery(
    ['sectors', 'levelThree', sector2Id],
    () => getClassifications('3', `${sector2Id}`),
    {
      enabled: !!sector2Id,
    }
  );

  const sectorsLevelThree =
    (sectorsLevelThreeData?.status === 'success' &&
      sectorsLevelThreeData.value.data) ||
    [];

  useEffect(() => {
    if (!isUploadResolutionFlow) {
      return;
    }

    trigger();
  }, [
    isUploadResolutionFlow,
    citiesDataStatus,
    countriesDataStatus,
    regionsDataStatus,
    sectorsLevelOneDataStatus,
    sectorsLevelTwoDataStatus,
    sectorsLevelThreeDataStatus,
    trigger,
  ]);

  const onSave = handleSubmit(async (values) => {
    try {
      setAsyncState({ status: 'loading' });

      const payload = {
        ...values,
      };

      const result = await createWrmFacility({
        accountId: localStorage.getItem('__ECOLAB_WAP_ACCOUNT__') ?? '',
        ...payload,
      });

      if (result.status === 'error') {
        throw new Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: 'Facility created successfully!',
      });

      const id = result.value.data.facilityId;

      queryClient.invalidateQueries(['facilities', id]);
      queryClient.invalidateQueries(['aggregates']);

      // Clear the history state so that the user can't go back to the upload resolution
      window.history.replaceState({}, document.title);

      if (isUploadResolutionFlow) {
        queryClient.invalidateQueries(['activeUploads']);
        history.push(
          `/app/facilities/${id}/reporting-years/new${
            uploadResults?.errorType === UploadFlowError.Assessment
              ? '?tab=water-action-assessment'
              : ''
          }`,
          {
            uploadResults,
          }
        );
      } else {
        history.push(`/app/facilities/${id}`);
      }
    } catch (e) {
      console.error(e);
      setAsyncState({
        status: 'error',
        message:
          e.message ?? 'There was an error submitting your facility data.',
      });
    }
  });

  const hasFormError =
    asyncState.status === 'error' || (isUploadResolutionFlow && !isValid);

  return (
    <>
      <header className={cx('header')}>
        <Copy as="h2">Create Facility</Copy>

        <Copy as="p">
          Your privacy is important to us. Precautions are in place to protect
          your information against any misuse. Please refer to the{' '}
          <a href="https://www.ecolab.com/privacy-policy">PRIVACY NOTICE</a> for
          additional details.
        </Copy>
      </header>
      <div className={cx('formContainer')}>
        <form>
          {hasFormError ? (
            <Stack direction="column" padding="4" gap="4">
              {asyncState.status === 'error' ? (
                <Alert status="error" alignItems="flex-start">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Form could not be submitted</AlertTitle>
                    <AlertDescription>{asyncState.message}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
              {isUploadResolutionFlow && !isValid ? (
                <Alert status="error" alignItems="flex-start">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>You have upload conflicts</AlertTitle>
                    <AlertDescription>
                      Your upload data was not able to be mapped successfully.
                      Please resolve the conflicts below before continuing.
                    </AlertDescription>
                  </Box>
                </Alert>
              ) : null}
            </Stack>
          ) : null}

          <div className={cx('formGroup')}>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Facility Name</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <TextInput
                    className={cx('inputField')}
                    name="facilityName"
                    ref={register({ required: 'Required' })}
                    error={errors.facilityName}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Country</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="countryId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        className={cx('inputField')}
                        items={countries.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        placeholder="&nbsp;"
                        error={errors.countryId}
                        disabled={countriesData?.status !== 'success'}
                        loading={isLoadingCountries}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">State/Province</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="regionId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.regionId}
                        items={regions.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        placeholder="&nbsp;"
                        className={cx('inputField')}
                        disabled={regionData?.status !== 'success'}
                        loading={isLoadingRegions}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">City</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="cityId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.cityId}
                        placeholder="&nbsp;"
                        items={citys.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        className={cx('inputField')}
                        disabled={cityData?.status !== 'success'}
                        loading={isLoadingCities}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Industry Classification</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector1Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector1Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelOne.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={sectorsLevelOneData?.status !== 'success'}
                        loading={isLoadingClassificationsLevelOne}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}></div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector2Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector2Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelTwo.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={sectorsLevelTwoData?.status !== 'success'}
                        loading={isLoadingClassificationsLevelTwo}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}></div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector3Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector3Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelThree.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={sectorsLevelThreeData?.status !== 'success'}
                        loading={isLoadingClassificationsLevelThree}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Active</Copy>
                <FormHelpButton formHelp={formHelp} name="active" />
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="active"
                    control={control}
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.active}
                      />
                    )}
                  ></Controller>
                </div>
                <FormHelpText formHelp={formHelp} name="active">
                  Indicates whether a facility is active or inactive. This field
                  is helpful if facilities are closed at certain times or
                  permanently.
                </FormHelpText>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Modeling Only</Copy>
                <FormHelpButton formHelp={formHelp} name="modelingOnly" />
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="modelingOnly"
                    control={control}
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.modelingOnly}
                      />
                    )}
                  ></Controller>
                </div>
                <FormHelpText formHelp={formHelp} name="modelingOnly">
                  Indicates if a facility is to be used for modeling purposes
                  only i.e., if theoretical data will be input to model
                  different scenarios or plan for new facilities.
                </FormHelpText>
              </div>
            </div>
          </div>
        </form>
      </div>
      <FacilityTabFooter
        onCancel={() => history.go(-1)}
        onNext={onSave}
        canNext={true}
        nextButtonText="Save"
        isSaving={asyncState?.status === 'loading'}
      />
    </>
  );
};

FacilityCreate.displayName = 'FacilityCreate';
