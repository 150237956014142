import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './JourneyStepBubble.module.css';

const cx = classnames.bind(styles);

export interface JourneyStepBubbleProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const JourneyStepBubble: FC<JourneyStepBubbleProps> = ({
  children,
  className,
}) => {
  return <div className={cx('journeyStepBubble', className)}>{children}</div>;
};

JourneyStepBubble.displayName = 'JourneyStepBubble';
