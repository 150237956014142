import {
  Box,
  Button,
  Flex,
  SlideFade,
  Spacer,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { mdiFolderEdit, mdiTextBoxSearchOutline, mdiTransferUp } from '@mdi/js';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AddFolderFacilityModal } from 'src/components/AddFolderFacilityModal';
import { Icon } from 'src/components/Icon';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folder } from 'src/types/folder';
import { Facility } from 'src/types/v5.facility';
import { FacilityItem } from './FacilityItem';
import { FolderDeletePopover } from './FolderDeletePopover';
import { FolderMutateModal } from './FolderMutateModal';
import { TransferModal } from './TransferModal';

type FolderItemContentsProps = {
  facilities?: Array<Facility>;
  folder: Folder;
  isOpen: boolean;
  isFrozen: boolean;
  onToggle: () => void;
};

export const FolderItemContents: FC<FolderItemContentsProps> = ({
  facilities,
  folder,
  isOpen,
  isFrozen,
  onToggle,
}) => {
  const history = useHistory();
  const [isMutating, setIsMutating] = useBoolean();
  const [isTransferring, setIsTransferring] = useBoolean();
  const { setFilterState } = useFilterAndSort();
  const [isAddingToFolder, setIsAddingToFolder] = useBoolean();

  const hasChildren = folder.facilitiesInFolder.length > 0;

  return (
    <Stack spacing="6">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        width="full"
        spacing={0}
        flex="1 1 auto"
        pt="2"
      >
        <SlideFade in={isOpen} offsetX="-20px">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 1, md: 1 }}
          >
            <Button
              variant="brand"
              size="sm"
              leftIcon={<Icon path={mdiFolderEdit} size="24px" />}
              borderRadius={{ base: 'none', md: 'md' }}
              onClick={setIsMutating.on}
              isDisabled={isFrozen}
            >
              Edit Folder
            </Button>
            <FolderMutateModal
              folder={folder}
              isOpen={isMutating}
              onClose={setIsMutating.off}
            />

            <Button
              variant="brand"
              size="sm"
              leftIcon={<Icon path={mdiFolderEdit} size="24px" />}
              borderRadius={{ base: 'none', md: 'md' }}
              onClick={setIsAddingToFolder.on}
              isDisabled={!facilities?.length || isFrozen}
            >
              Add Facility
            </Button>
            {facilities?.length ? (
              <AddFolderFacilityModal
                facilities={facilities}
                folder={folder}
                isOpen={isAddingToFolder}
                onClose={setIsAddingToFolder.off}
              />
            ) : null}

            <Button
              variant="brand"
              size="sm"
              leftIcon={<Icon path={mdiTransferUp} size="24px" />}
              borderRadius={{ base: 'none', md: 'md' }}
              onClick={setIsTransferring.on}
              isDisabled={!hasChildren || isFrozen}
            >
              Transfer Folder
            </Button>
            <TransferModal
              folderId={folder.folderId}
              isOpen={isTransferring}
              onClose={setIsTransferring.off}
            />

            <Button
              variant="brand"
              size="sm"
              leftIcon={<Icon path={mdiTextBoxSearchOutline} size="24px" />}
              borderRadius={{ base: 'none', md: 'md' }}
              onClick={() => {
                setFilterState!('folderId', `${folder.folderId}`);
                history.push('/app/dashboard');
              }}
              isDisabled={!hasChildren}
            >
              View on Dashboard
            </Button>
          </Stack>
        </SlideFade>

        <Spacer />

        <SlideFade in={isOpen} offsetX="20px">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 1, md: 1 }}
          >
            <Box />
            <FolderDeletePopover
              folderId={folder.folderId}
              isDisabled={hasChildren || isFrozen}
              onDelete={onToggle}
            />
          </Stack>
        </SlideFade>
      </Stack>

      <SlideFade in={isOpen} offsetY="20px">
        <Stack
          direction="column"
          width="full"
          spacing={0}
          flex="1 1 auto"
          shouldWrapChildren
          border="1px solid"
          borderColor="gray.300"
          borderBottom="none"
        >
          <Flex
            bgColor={'gray.100'}
            p="2"
            alignItems="center"
            borderBottom="1px solid var(--chakra-colors-gray-300)"
          >
            <Text fontWeight="semibold" color="subtle">
              Folder Facilities
            </Text>
          </Flex>

          {folder.facilitiesInFolder.length ? (
            folder.facilitiesInFolder.map((facility) => (
              <FacilityItem
                key={facility.facilityId}
                folderId={folder.folderId}
                facility={facility}
              />
            ))
          ) : (
            <Flex
              p="2"
              borderBottom="1px solid"
              borderColor="gray.300"
              _hover={{
                bg: 'bg-subtle',
              }}
            >
              <Text color="muted">Folder has no facilities</Text>
            </Flex>
          )}
        </Stack>
      </SlideFade>
    </Stack>
  );
};

FolderItemContents.displayName = 'FolderItemContents';
