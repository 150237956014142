import { useLocation } from 'react-router-dom';
import { Path } from 'history';

export function useQueryParams() {
  // return new URLSearchParams(useLocation().search);
  return getQueryParam(useLocation().search);
}

export function getQueryParam(search: Path['search']) {
  return new URLSearchParams(search);
}
