import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { mdiAccountArrowRightOutline, mdiCancel, mdiCheck } from '@mdi/js';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { updateTransfer } from 'src/clients/api/v5.transfers';
import { Icon } from 'src/components/Icon';
import { TransferStatusTag } from 'src/components/TransferStatusTag';
import { Transfer } from 'src/types/transfer';

type TransferListItemProps = {
  transfer: Transfer;
  direction?: 'in' | 'out';
  color: 'gray' | 'red';
};

export const TransferListItem: FC<TransferListItemProps> = ({
  transfer,
  direction,
  color,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useBoolean();

  const handleReaction = async (reaction: Transfer['status']) => {
    try {
      setIsLoading.on();

      const result = await updateTransfer(transfer.transferId, {
        ...transfer,
        status: reaction,
      });

      if (result.status !== 'success') {
        throw Error(result.value?.errors?.[0].message);
      }

      toast({
        title: `Transfer ${
          reaction === 'accepted' ? 'accept' : 'decline'
        } successfully!`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      queryClient.refetchQueries('transfers');
      queryClient.refetchQueries('folders');
      queryClient.invalidateQueries('activeUploads');
      queryClient.invalidateQueries('facilities');
    } catch (e) {
      toast({
        title: e?.message?.length
          ? e.message
          : `Could not ${
              reaction === 'accepted' ? 'accept' : 'decline'
            } the folder transfer.`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading.off();
    }
  };

  return (
    <Stack
      key={transfer.transferId}
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      p="2"
      _hover={{ bg: `${color}.50` }}
      borderBottom="1px solid"
      borderColor={`${color}.300`}
    >
      <Flex
        direction="column"
        flex={{ base: '1 1 100%', md: '1 1 auto' }}
        justifyContent="center"
      >
        <Text isTruncated fontSize="sm" fontWeight="semibold" lineHeight="1">
          {transfer.assetName} ({transfer.facilityCount} facilities)
        </Text>
        <Flex flex="0">
          <Icon path={mdiAccountArrowRightOutline} mr="1" />
          <Text isTruncated fontSize="xs">
            {direction === 'in'
              ? `${transfer.transferFromName} (${transfer.transferFromUsername})`
              : transfer.transferToUsername}
          </Text>
        </Flex>
      </Flex>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
        justifyContent="end"
        flex={{ base: '1 1 100%', md: '0 0 auto' }}
        w={{ base: 'full', md: 'auto' }}
      >
        {direction === 'in' ? (
          <Button
            size="sm"
            variant="brand"
            leftIcon={<Icon path={mdiCheck} size="18px" />}
            onClick={() => {
              handleReaction('accepted');
            }}
            w={{ base: 'full', md: 'auto' }}
            flex={{ base: '1 1 100%', md: '1 1 auto' }}
            px="2"
            py="1"
            height="32px"
            isLoading={isLoading}
          >
            Accept
          </Button>
        ) : (
          <TransferStatusTag status={transfer.status} />
        )}

        {direction === 'in' ? (
          <Popover placement="left" closeOnBlur={false} closeOnEsc={false}>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    size="sm"
                    leftIcon={<Icon path={mdiCancel} size="18px" />}
                    colorScheme="red"
                    isLoading={isLoading}
                    w={{ base: 'full', md: 'auto' }}
                    flex={{ base: '1 1 100%', md: '1 1 auto' }}
                    px="2"
                    py="1"
                  >
                    Reject
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent outline="none">
                    <PopoverArrow />
                    <PopoverHeader bg="red.400" alignItems="center">
                      Reject Transfer
                    </PopoverHeader>
                    <PopoverCloseButton />

                    <PopoverBody>
                      <Text mb="2" textAlign="center" width="full">
                        Are you sure you want to reject this transfer?
                      </Text>
                      <Button
                        colorScheme="red"
                        width="full"
                        onClick={() => {
                          handleReaction('declined');
                          onClose();
                        }}
                      >
                        Yes, reject this transfer
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        ) : null}
      </Stack>
    </Stack>
  );
};
