import { AggregateResults } from 'src/types/aggregates';
import {
  DEFAULT_CHART,
  getColorByFacilityRank,
  getRegressionLineV3,
  getScatterPlotOptions,
} from './utils';

export type BenchmarkingDisplayType = 'Incoming' | 'Outgoing' | 'Consumptive';
export const BENCHMARKING_TYPE_OPTIONS = [
  'Incoming',
  'Outgoing',
  'Consumptive',
];
export type BenchmarkingDisplayIntensity = 'Production' | 'Revenue';
export const BENCHMARKING_INTENSITY_OPTIONS = ['Production', 'Revenue'];

export function benchmarkingChartDataGenerator(
  aggregates: AggregateResults = [],
  hiddenAggregates?: Set<number>,
  displayState: {
    type: BenchmarkingDisplayType;
    intensity: BenchmarkingDisplayIntensity;
    showOrgAvg: boolean;
    showSectorAvg: boolean;
  } = {
    type: 'Incoming',
    intensity: 'Production',
    showOrgAvg: true,
    showSectorAvg: true,
  }
) {
  const filteredAggregates = (aggregates ?? []).filter(
    (f) => !hiddenAggregates?.has(f.facilityId)
  );

  const getDots = function (facilities: AggregateResults) {
    const dots = facilities.map((a) => ({
      name: a.facility.facilityName,
      color: getColorByFacilityRank(a.twoByTwoQuadrant ?? ''),
      // @ts-ignore
      x: a[`benchmark${displayState.type}XAxis`] ?? undefined,
      // @ts-ignore
      y: a[`benchmark${displayState.intensity}YAxis`] ?? undefined,
      idx: a.facilityRank,
    }));

    const data = dots.reduce<{ x: Array<number>; y: Array<number> }>(
      (acc, dot) => {
        acc.x.push(Number(dot.x));
        acc.y.push(Number(dot.y));

        return acc;
      },
      {
        x: [],
        y: [],
      }
    );

    return { dots, data };
  };

  const organizationalAverageData = getDots(aggregates);
  const organizationalAverage = getRegressionLineV3(
    organizationalAverageData.data.x,
    organizationalAverageData.data.y
  );

  organizationalAverage.xData.sort(sortFunc);
  organizationalAverage.yData.sort(sortFunc);

  const sectorId =
    filteredAggregates &&
    filteredAggregates[0] &&
    filteredAggregates[0].facility.sector3Id;
  const isSector =
    filteredAggregates &&
    filteredAggregates.every((a) => a.facility.sector3Id === sectorId);

  const filteredAggregatesData = getDots(filteredAggregates);
  const sectorAverage = getRegressionLineV3(
    organizationalAverageData.data.x,
    organizationalAverageData.data.y,
    isSector &&
      filteredAggregates &&
      filteredAggregates[0] &&
      filteredAggregates[0].sectorWaterUsePerRevenue
      ? filteredAggregates[0].sectorWaterUsePerRevenue
      : undefined
  );

  sectorAverage.xData.sort(sortFunc);
  sectorAverage.yData.sort(sortFunc);

  return {
    ...DEFAULT_CHART,
    chart: {
      type: 'scatter',
      animation: false,
      spacing: [20, 10, 10, 10],
    },
    xAxis: {
      title: {
        text: `${displayState.intensity} (${
          displayState.intensity === 'Production' ? 'unit' : '$M'
        })`,
      },
    },
    plotOptions: {
      scatter: getScatterPlotOptions(),
      line: {
        enableMouseTracking: true,
        marker: {
          enabled: true,
        },
        label: {
          enabled: true,
        },
      },
      series: {
        animation: false,
      },
    },
    yAxis: {
      title: {
        text: 'Water Volume (m3)',
      },
    },
    legend: {
      enabled: false,
      squareSymbol: false,
      symbolHeight: 12,
      symbolWidth: 24,
      symbolPadding: 10,
      symbolRadius: 0,
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px; color:{series.color}">{point.key}</span><table>',
      // prettier-ignore
      pointFormat: `
      <table>
        <tr>
          <td style=";padding:0">
            ${displayState.intensity} (${
              displayState.intensity === 'Production' ? 'unit' : '$M'
            }):
          </td>
          <td style="padding:0">
            <b>{point.x:.5f}</b>
          </td>
        </tr>
        <tr>
          <td style=";padding:0">Water Volume:</td>
          <td style="padding:0"><b>{point.y:.5f}</b></td>
        </tr>
      </table>`,
      shared: false,
      useHTML: true,
    },
    series: [
      {
        type: 'scatter',
        name: displayState.type,
        data: filteredAggregatesData.dots.map((d) =>
          Object.assign({}, d, {
            x: d.y,
            y: d.x,
          })
        ),
      },
      {
        type: 'line',
        color: '#096490',
        id: 'organizationalAverage',
        name: 'Organizational Average',
        visible: displayState.showOrgAvg,
        data: [
          {
            x: organizationalAverage.values[0],
            y: organizationalAverage.xData[0],
          },
          {
            x:
              organizationalAverage.values[
                organizationalAverage.values.length - 1
              ],
            y:
              organizationalAverage.xData[
                organizationalAverage.xData.length - 1
              ],
          },
        ],
      },
      {
        type: 'line',
        color: '#dc8f18',
        id: 'sectorAverage',
        name: 'Sector Average',
        visible:
          displayState.showSectorAvg &&
          isSector &&
          displayState.intensity === 'Revenue',
        data: [
          {
            x: sectorAverage.values[0],
            y: sectorAverage.xData[0],
          },
          {
            x: sectorAverage.values[sectorAverage.values.length - 1],
            y: sectorAverage.xData[sectorAverage.xData.length - 1],
          },
        ],
      },
    ],
  };
}

function sortFunc(a: number, b: number) {
  return a - b;
}
