import request from '../request';
import { WrappedResponse } from '../types';
import { urlWithAccountId } from '../helpers';
import { Env } from 'src/constants/env';

export type InviteUserRequestType = {
  accountId: string;
  email: string;
  externalInvite: boolean;
  consolidateAccount: boolean;
  internalInvite: boolean;
};
export type InviteUserResponseType = WrappedResponse<unknown>;
export function inviteUser(data: InviteUserRequestType) {
  return request.post<InviteUserResponseType, InviteUserRequestType>(
    urlWithAccountId(`invitations`),
    data
  );
}

export type GetInviteResponseType = WrappedResponse<{
  userName: string;
  accountName: string;
}>;
export function getInvite(accountId: string, invitationId: string) {
  return request.get<GetInviteResponseType>(
    `${Env.apiUrl}/v5/users/invitations/accounts/${accountId}/invitations/${invitationId}`
  );
}

export type AcceptInviteRequestType = {
  accepted: boolean;
};
export type AcceptInviteResponseType = WrappedResponse<unknown>;
export function acceptInvite(
  accountId: string,
  invitationId: string,
  data: AcceptInviteRequestType
) {
  return request.post<AcceptInviteResponseType, AcceptInviteRequestType>(
    `${Env.apiUrl}/v5/users/invitations/accounts/${accountId}/invitations/${invitationId}`,
    data
  );
}

export type DeleteUserRequestType = WrappedResponse<unknown>;
export function deleteUserFromAccount(userId: string) {
  return request.del<DeleteUserRequestType>(
    urlWithAccountId(`users/${userId}`)
  );
}

export type GenerateAccountCsvReportResponseType = WrappedResponse<string>;
export function generateAccountCsvReport() {
  return request.post<never, GenerateAccountCsvReportResponseType>(
    urlWithAccountId(`reports/csv`)
  );
}
