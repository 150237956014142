import { Box, Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import {
  mdiChevronDoubleLeft,
  mdiLabelOutline,
  mdiMapMarkerOutline,
  mdiPencilOutline,
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, RouteComponentProps } from 'react-router-dom';
import { deleteFacility, getFacility } from 'src/clients/api/v5.facility';
import { Alert } from 'src/components/Alert';
import { BlockError } from 'src/components/BlockError';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { ReportingYearsList } from 'src/components/ReportingYearsList';
import { useAsyncState } from 'src/hooks/useAsyncState';
import styles from './Facility.module.css';

const cx = classnames.bind(styles);

type FacilityProps = {};

export const Facility: FC<
  FacilityProps &
    RouteComponentProps<
      { id: string },
      {},
      {
        referrer?: {
          pathname: string;
          title: string;
        };
      }
    >
> = ({ match, history, location }) => {
  const [asyncState, setAsyncState] = useAsyncState();
  const queryClient = useQueryClient();
  const { id } = match.params;

  const { data, isLoading, refetch, isFetching } = useQuery(
    ['facilities', id],
    () => getFacility(id)
  );

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (data && data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading facilities"
        message="We were unable to load your facilities successfully"
      />
    );
  }

  if (data && data.status === 'success') {
    const facility = data.value.data;

    const handleDelete = () => {
      setAsyncState({
        status: 'loading',
      });

      deleteFacility(id)
        .then((result) => {
          if (result.status === 'error') {
            throw new Error();
          }

          queryClient.refetchQueries(['aggregates']);
          queryClient.refetchQueries(['facilities']);
          history.push(`/app/facilities`);
        })
        .catch(() => {
          setAsyncState({
            status: 'error',
            message: 'There was an error deleting your facility.',
          });
        });
    };

    return (
      <div className={cx('facility')}>
        <div className={cx('header')}>
          <div className={cx('left')}>
            <Button
              variant="link"
              as={Link}
              to={
                location.state?.referrer
                  ? location.state.referrer.pathname
                  : '/app/dashboard'
              }
              mb="10px"
            >
              <Icon
                path={mdiChevronDoubleLeft}
                size="14px"
                style={{ display: 'inline-block' }}
              />{' '}
              {`Back to ${
                location.state?.referrer
                  ? location.state.referrer.title
                  : 'Dashboard'
              }`}
            </Button>
            <Box>
              <h1 className={cx('name')}>{facility.facilityName}</h1>
              <div className={cx('location')}>
                <Icon path={mdiMapMarkerOutline} size="24px" />
                <span>
                  {facility.cityName}, {facility.regionName},{' '}
                  {facility.countryName}
                </span>
              </div>
              {facility.facilityLabel ? (
                <div className={cx('folder')}>
                  <Icon path={mdiLabelOutline} size="24px" />
                  <span>{facility.facilityLabel}</span>
                </div>
              ) : null}
            </Box>
          </div>
          <div className={cx('right')}>
            <div className={cx('button-group')}>
              <ButtonGroup>
                <IconButton
                  onClick={() => history.push(`/app/facilities/${id}/edit`)}
                  icon={<Icon path={mdiPencilOutline} size="24px" />}
                  aria-label="Edit Facility"
                />

                <IconButton
                  onClick={handleDelete}
                  icon={<Icon path={mdiTrashCanOutline} size="24px" />}
                  aria-label="Delete Facility"
                  colorScheme={'red'}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        {asyncState.status === 'error' ? (
          <div style={{ padding: '15px' }}>
            <Alert variant="danger" message={asyncState.message} />
          </div>
        ) : null}

        <nav className={cx('tabs')}>
          <div className={cx('shadow')}></div>
          <div className={cx('tab', 'active')}>Reporting Years</div>
        </nav>
        <div className={cx('tabContent')}>
          <ReportingYearsList facilityId={id} />
        </div>
      </div>
    );
  }

  return (
    <BlockError
      title="Error loading facilities"
      message="We were unable to load your facilities successfully"
    />
  );
};

Facility.displayName = 'Facility';
