import { ReactNode, useState } from 'react';

export type TabNavigatorTab = {
  label: string;
  index: number;
  urlQueryParam: string;
  component: ReactNode;
};

export type UseTabNavigator = {
  tabs: Array<TabNavigatorTab>;
  canNext: boolean;
  onNextTab: () => void;
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function useTabNavigator(
  tabs: UseTabNavigator['tabs'],
  initializedIndex: number = 0
): UseTabNavigator {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    initializedIndex
  );

  const canNext = activeTabIndex < tabs.length - 1;

  const onNextTab = () => {
    if (canNext) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  };

  return {
    tabs,
    onNextTab,
    activeTabIndex,
    setActiveTabIndex,
    canNext,
  };
}
