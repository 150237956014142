import React, { createContext, useContext, useState } from 'react';
import { AsyncStateType } from './useAsyncState';

export type AsyncFormContextMethods = {
  setState: (key: 'saveState' | 'deleteState', value: AsyncStateType) => void;
  resetState: () => void;
};

export type AsyncFormContextPropsType = {
  deleteState: AsyncStateType;
  saveState: AsyncStateType;
};

const DEFAULT_STATE = {
  deleteState: { status: 'idle' } as const,
  saveState: { status: 'idle' } as const,
};

type FilterAndSortContextProps = AsyncFormContextMethods &
  AsyncFormContextPropsType;

const AsyncFormContext = createContext<Partial<FilterAndSortContextProps>>({});

function AsyncFormStateProvider(props: any) {
  const [internalFilterState, setInternalFilterState] = useState<
    AsyncFormContextPropsType
  >(DEFAULT_STATE);

  const setState = (key: keyof AsyncFormContextPropsType, value: string) =>
    setInternalFilterState({
      ...internalFilterState,
      [key]: value,
    });

  const resetState = () => setInternalFilterState(DEFAULT_STATE);

  return (
    <AsyncFormContext.Provider
      value={{
        ...internalFilterState,
        setState,
        resetState,
      }}
      {...props}
    />
  );
}

const useAsyncFormState = () => useContext(AsyncFormContext);

export { AsyncFormStateProvider, useAsyncFormState };
