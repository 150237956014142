import { Box } from '@chakra-ui/react';
import classnames from 'classnames/bind';
import zip from 'lodash-es/zip';
import React, { FC } from 'react';
import { ParsedGuide } from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type CaseStudiesProps = {
  guide: ParsedGuide['CaseStudies'];
};

const caseStudyImages = [
  '/images/waa/case_study_1.jpg',
  '/images/waa/case_study_2.jpg',
];

export const CaseStudies: FC<CaseStudiesProps> = ({ guide }) => {
  const caseStudies = zip(
    guide.Header.filter(Boolean),
    guide.Content.filter(Boolean)
  );

  try {
    return (
      <div className={cx('facilityTargetTab')}>
        <Copy as="h2">Case Studies</Copy>
        <div className={cx('twoUp')}>
          {caseStudies
            .map(([header, content], i) => {
              if (header && content) {
                return (
                  <Box
                    key={`${header.guideNodeId}`}
                    className={cx('caseStudy')}
                  >
                    <img
                      className={cx('img')}
                      src={caseStudyImages[i % caseStudyImages.length]}
                      alt=""
                    />
                    <Box
                      my="4"
                      className={cx('subHeader')}
                      dangerouslySetInnerHTML={{
                        __html: header.nodeValue,
                      }}
                    />
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: content.nodeValue,
                      }}
                    />
                  </Box>
                );
              }

              return null;
            })
            .filter(Boolean)}
        </div>
      </div>
    );
  } catch (error) {
    return <BlockError />;
  }
};

CaseStudies.displayName = 'CaseStudies';
