import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getClassifications } from 'src/clients/api/classification';
import { getCities, getCountries, getRegions } from 'src/clients/api/country';
import {
  deleteFacility,
  getFacility,
  updateWrmFacility,
} from 'src/clients/api/v5.facility';
import { Alert } from 'src/components/Alert';
import { BlockError } from 'src/components/BlockError';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { Copy } from 'src/components/Copy';
import { FacilityTabFooter } from 'src/components/FacilityForm/Helpers/FacilityTabFooter';
import {
  facilityInformationSchema,
  FacilityInformationSchemaForm,
} from 'src/components/FacilityForm/validation';
import { FormHelpButton, FormHelpText } from 'src/components/FormHelp';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { SelectInput } from 'src/components/SelectInput';
import { TextInput } from 'src/components/TextInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useFormHelp } from 'src/hooks/useFormHelp';
import styles from './FacilityEdit.module.css';

const cx = classnames.bind(styles);

type FacilityEditProps = {};

export const FacilityEdit: FC<
  FacilityEditProps & RouteComponentProps<{ id: string }>
> = ({ match, history }) => {
  const { id } = match.params;
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState();

  const {
    data: facilityData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(['facilities', id], () => getFacility(id));

  const formHelp = useFormHelp();

  const {
    control,
    formState: { errors },
    watch,
    register,
    reset,
    handleSubmit,
  } = useForm<FacilityInformationSchemaForm>({
    defaultValues: {},
    resolver: yupResolver(facilityInformationSchema),
  });

  const { data: countriesData, isLoading: isLoadingCountries } = useQuery(
    ['countries'],
    () => getCountries()
  );

  const countries =
    (countriesData?.status === 'success' && countriesData.value.data) || [];

  const countryId = watch('countryId');
  const { data: regionData, isLoading: isLoadingRegions } = useQuery(
    ['regions', countryId],
    () => getRegions(countryId),
    {
      enabled: !!countryId,
    }
  );

  const regions =
    (regionData?.status === 'success' && regionData.value.data) || [];

  const regionId = watch('regionId');
  const { data: cityData, isLoading: isLoadingCities } = useQuery(
    ['citys', regionId],
    () => getCities(countryId, regionId),
    {
      enabled: !!(countryId && regionId),
    }
  );

  const citys = (cityData?.status === 'success' && cityData.value.data) || [];

  const {
    data: sectorsLevelOneData,
    isLoading: isLoadingClassificationsLevelOne,
  } = useQuery(['sectors', 'levelOne'], () => getClassifications());

  const sectorsLevelOne =
    (sectorsLevelOneData?.status === 'success' &&
      sectorsLevelOneData.value.data) ||
    [];

  const sector1Id = watch('sector1Id');
  const {
    data: sectorsLevelTwoData,
    isLoading: isLoadingClassificationsLevelTwo,
  } = useQuery(
    ['sectors', 'levelTwo', sector1Id],
    () => getClassifications('2', `${sector1Id}`),
    {
      enabled: !!sector1Id,
    }
  );

  const sectorsLevelTwo =
    (sectorsLevelTwoData?.status === 'success' &&
      sectorsLevelTwoData.value.data) ||
    [];

  const sector2Id = watch('sector2Id');
  const {
    data: sectorsLevelThreeData,
    isLoading: isLoadingClassificationsLevelThree,
  } = useQuery(
    ['sectors', 'levelThree', sector2Id],
    () => getClassifications('3', `${sector2Id}`),
    {
      enabled: !!sector2Id,
    }
  );

  const sectorsLevelThree =
    (sectorsLevelThreeData?.status === 'success' &&
      sectorsLevelThreeData.value.data) ||
    [];

  useEffect(() => {
    if (facilityData?.status === 'success') {
      reset(facilityData.value.data);
    }
  }, [facilityData, reset]);

  if (isLoading) {
    return <LoadingSpinner block />;
  }

  if (error || !facilityData || facilityData.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading facilities"
        message="We were unable to load your facilities successfully"
      />
    );
  }

  const facility = facilityData.value.data;

  const onSave = handleSubmit(async (values: FacilityInformationSchemaForm) => {
    try {
      setAsyncState({ status: 'loading' });

      const payload = {
        ...facility,
        ...values,
      };

      const result = await updateWrmFacility(id, payload);

      if (result.status === 'error') {
        throw new Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: 'Facility created successfully!',
      });

      queryClient.invalidateQueries(['facilities']);
      queryClient.invalidateQueries(['aggregates']);

      history.push(`/app/facilities/${id}`);
    } catch (e) {
      setAsyncState({
        status: 'error',
        message:
          e.message ?? 'There was an error submitting your facility data.',
      });
    }
  });

  const onDelete = async () => {
    try {
      setAsyncState({ status: 'loading' });
      await deleteFacility(id);

      setAsyncState({
        status: 'success',
        message: 'Facility deleted successfully.',
      });

      queryClient.refetchQueries(['facilities']);
      history.push('/app/dashboard');
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: 'There was an error processing your request.',
      });
    }
  };

  return (
    <>
      <header className={cx('header')}>
        <Copy as="h2">Edit Facility</Copy>

        <Copy as="p">
          Your privacy is important to us. Precautions are in place to protect
          your information against any misuse. Please refer to the{' '}
          <a href="https://www.ecolab.com/privacy-policy">PRIVACY NOTICE</a> for
          additional details.
        </Copy>
      </header>
      <div className={cx('formContainer')}>
        <form>
          {asyncState.status === 'error' ? (
            <Alert variant="danger" message={asyncState.message} />
          ) : null}
          <div className={cx('formGroup')}>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Facility Name</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <TextInput
                    className={cx('inputField')}
                    name="facilityName"
                    ref={register({ required: 'Required' })}
                    error={errors.facilityName}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Country</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="countryId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        className={cx('inputField')}
                        items={countries.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        placeholder="&nbsp;"
                        error={errors.countryId}
                        disabled={countriesData?.status !== 'success'}
                        loading={isLoadingCountries}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">State/Province</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="regionId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.regionId}
                        items={regions.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        placeholder="&nbsp;"
                        className={cx('inputField')}
                        disabled={regionData?.status !== 'success'}
                        loading={isLoadingRegions}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">City</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="cityId"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.cityId}
                        placeholder="&nbsp;"
                        items={citys.map(({ id, localName }) => ({
                          label: localName,
                          value: `${id}`,
                        }))}
                        className={cx('inputField')}
                        disabled={cityData?.status !== 'success'}
                        loading={isLoadingCities}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Industry Classification</Copy>
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector1Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector1Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelOne.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={true}
                        loading={isLoadingClassificationsLevelOne}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}></div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector2Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector2Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelTwo.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={true}
                        loading={isLoadingClassificationsLevelTwo}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}></div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="sector3Id"
                    control={control}
                    rules={{ required: 'Required' }}
                    render={(props) => (
                      <SelectInput
                        {...props}
                        error={errors.sector3Id}
                        placeholder="&nbsp;"
                        items={sectorsLevelThree.map(
                          ({ fkSector, sectorName: label }) => ({
                            label,
                            value: `${fkSector}`,
                          })
                        )}
                        className={cx('inputField')}
                        disabled={true}
                        loading={isLoadingClassificationsLevelThree}
                        onChange={(val) => props.onChange(val)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Facility Label</Copy>
                <FormHelpButton formHelp={formHelp} name="label" />
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <TextInput
                    className={cx('inputField')}
                    name="facilityLabel"
                    ref={register}
                  />
                </div>
                <FormHelpText formHelp={formHelp} name="label">
                  Enter a label that classifies this facility and can be used to
                  filter your facilities.
                </FormHelpText>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Active</Copy>
                <FormHelpButton formHelp={formHelp} name="active" />
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="active"
                    control={control}
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.active}
                      />
                    )}
                  ></Controller>
                </div>
                <FormHelpText formHelp={formHelp} name="active">
                  Indicates whether a facility is active or inactive. This field
                  is helpful if facilities are closed at certain times or
                  permanently.
                </FormHelpText>
              </div>
            </div>
            <div className={cx('row')}>
              <div className={cx('label')}>
                <Copy as="h4">Modeling Only</Copy>
                <FormHelpButton formHelp={formHelp} name="modelingOnly" />
              </div>
              <div className={cx('inputGroup')}>
                <div className={cx('input')}>
                  <Controller
                    name="modelingOnly"
                    control={control}
                    defaultValue={facility?.modelingOnly ?? false}
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.modelingOnly}
                      />
                    )}
                  ></Controller>
                </div>
                <FormHelpText formHelp={formHelp} name="modelingOnly">
                  Indicates if a facility is to be used for modeling purposes
                  only i.e., if theoretical data will be input to model
                  different scenarios or plan for new facilities.
                </FormHelpText>
              </div>
            </div>
          </div>
        </form>
      </div>
      <FacilityTabFooter
        onDelete={onDelete}
        onCancel={() => history.push(`/app/facilities/${id}`)}
        onNext={onSave}
        canNext={true}
        nextButtonText="Save"
      />
    </>
  );
};

FacilityEdit.displayName = 'FacilityEdit';
