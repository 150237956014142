import classnames from 'classnames/bind';
import React, { FC } from 'react';

import { mdiFileUploadOutline, mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { Copy } from 'src/components/Copy';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import styles from './NoFacilities.module.css';
import { Flex } from '@chakra-ui/react';

const cx = classnames.bind(styles);

type NoFacilitiesProps = {};

export const NoFacilities: FC<NoFacilitiesProps> = () => {
  const { folderId } = useFilterAndSort();

  return (
    <>
      <Flex
        className={cx('noFacilities')}
        direction="column"
        justifyContent="flex-start"
      >
        {folderId ? (
          <Copy as="h3" color="blueLight" className={cx('header')}>
            There are no facilities assigned to this folder
          </Copy>
        ) : (
          <Flex
            maxW="calc(100% - 100px)"
            direction="column"
            justifyContent="center"
            alignItems="center"
            pt="12"
          >
            <Copy
              as="h2"
              color="blueLight"
              className={cx('header')}
              align="center"
            >
              Welcome to the Smart Water Navigator!
            </Copy>
            <Copy align="center" className={cx('subheader')}>
              There aren't any facility records to display at the moment. Let's
              change that.
              <br />
              Click the Add Facility button or upload multiple facilities and
              begin entering information for a specific location.
            </Copy>
            <div className={cx('actions')}>
              <Link to={`/app/facilities/new`} className={cx('action')}>
                <Icon
                  path={mdiPlusCircleOutline}
                  size="24px"
                  style={{ marginRight: '5px' }}
                />
                <span>ADD NEW FACILITY</span>
              </Link>
              <Link to={`/app/uploads`} className={cx('action')}>
                <Icon
                  path={mdiFileUploadOutline}
                  size="24px"
                  style={{ marginRight: '5px' }}
                />
                <span>Upload Multiple Facilities</span>
              </Link>
            </div>

            <Copy className="help" align="center">
              Visit the{' '}
              <a href="/take-action" className={cx('link')}>
                Take Action
              </a>{' '}
              page to learn more about the facility information required by the
              tool and the outputs the Smart Water Navigator can produce.
            </Copy>
          </Flex>
        )}
      </Flex>
    </>
  );
};

NoFacilities.displayName = 'NoFacilities';
