import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { Controller, useFormContext } from 'react-hook-form';
import { MappedReferenceData } from 'src/types/referenceData';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { useFormHelp } from 'src/hooks/useFormHelp';
import { FormHelpButton, FormHelpText } from '../../../FormHelp';

const cx = classnames.bind(styles);

type RegulationReputationProps = {
  referenceData: MappedReferenceData;
  reportingYear?: ReportingYear;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
};

export const RegulationReputation: FC<RegulationReputationProps> = ({
  referenceData,
  onSave,
  onCancel,
  className,
}) => {
  const formHelp = useFormHelp();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Regulation &amp; Reputation</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                How would you rate the regulation of water in your local
                community compared to the country as a whole?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="regulationOfWaterInLocalCommunityQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="regulationOfWaterInLocalCommunityQuestion"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options
                          .regulationOfWaterInLocalCommunityQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.regulationOfWaterInLocalCommunityQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="regulationOfWaterInLocalCommunityQuestion"
              >
                Select an answer to customize the tool's regulatory risk score
                to the specific local area of the facility. Please consider the
                quality, strength and coverage of the water legal framework in
                your local area. If it is worse than the country as a whole then
                the regulatory risk score will be lower. The regulatory risk
                score impacts the incoming, outgoing and revenue at risk
                likelihood scores. If you are unsure, please select 'Do not know
                or N/A' and the country level score will be used as a default.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Have there been environmental issues at your facility that have
                affected your company's reputation?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="facilityEnvironmentalReputationQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="facilityEnvironmentalReputationQuestion"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options
                          .facilityEnvironmentalReputationQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.facilityEnvironmentalReputationQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="facilityEnvironmentalReputationQuestion"
              >
                Select an answer to customize the tool's reputational risk score
                for your facility to be specific to your local area. If there
                have been environmental issues at your facility that have
                affected your company's reputation, the reputational risk score
                will be higher. The reputational risk score impacts the incoming
                and outgoing risk likelihood scores. If you are unsure, please
                select 'Do not know or N/A' and the country level score will be
                used as a default.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Is your facility aware of the stakeholders depending on the same
                water resources?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="dependingSameWaterSourceQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="dependingSameWaterSourceQuestion"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options
                          .dependingSameWaterSourceQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.dependingSameWaterSourceQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="dependingSameWaterSourceQuestion"
              >
                Select an answer to customize the reputational risk score for
                your facility to the specific local area of the facility. If you
                do not know what other businesses or organizations depend on the
                same water resources as your facility, the reputational risk
                score will be higher. The reputational risk score impacts the
                incoming and outgoing risk likelihood scores. If you are unsure,
                please select 'Do not know or N/A' and the country level score
                will be used as a default.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Is your facility's industry exempt from potential water
                restrictions imposed by local regulators?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="exemptWaterRestrictionQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="exemptWaterRestrictionQuestion"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options.waterRestrictionQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.exemptWaterRestrictionQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="exemptWaterRestrictionQuestion"
              >
                The tool provides a revenue at risk likelihood score which
                examines water scarcity, your industry's importance to the local
                economy, your industry's water requirement, the quality of local
                regulations and your facility's historical trend of revenue
                loss. To customize the tool's revenue at risk likelihood score
                to be specific to local conditions, please indicate if your
                industry is exempt from potential water restrictions imposed by
                local regulators. If your facility's industry is exempt from
                potential water restrictions, the revenue at risk likelihood
                score will be lower. If you are unsure, please select 'Do not
                know or N/A'.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Has there been any local pressure from NGOs or the public
                relating to water quality issues that have led to a change in
                water management practices at your facility?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="ngoLocalPressureQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="ngoLocalPressureQuestion"
                  control={control}
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options.waterRestrictionQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.ngoLocalPressureQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText formHelp={formHelp} name="ngoLocalPressureQuestion">
                The tool provides an outgoing risk likelihood score which
                examines water stress, regulatory and reputational risk and
                population density. To customize the tool's outgoing risk
                likelihood score to be more specific to local conditions, please
                indicate whether there has been any local pressure from NGOs or
                the public relating to water quality issues that have led to a
                change in water management practices at your facility. If there
                has been a change in water management practices because of local
                stakeholder pressure, the outgoing risk likelihood score will be
                higher. If you are unsure, please select 'Do not know or N/A'.
              </FormHelpText>
            </div>
          </div>
        </div>
      </form>
      <FacilityTabFooter onCancel={onCancel} onNext={onSave} />
    </div>
  );
};
