import * as React from 'react';

const SvgBrandResources = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 80 82" data-testid="svg-icon" {...props}>
      <g clipPath="url(#brand-resources_svg__clip0)">
        <path
          fill="#00547A"
          d="M38.146 21.915c-.692 0-1.285.446-1.434 1.142-.79 3.773-3.016 7.398-5.142 10.923-2.522 4.171-5.192 8.442-5.587 13.258v.794c0 6.505 4.895 12.215 11.372 12.612 7.021.447 12.905-5.164 12.905-12.165v-1.191c-.396-4.817-3.016-9.137-5.587-13.308-2.126-3.475-4.351-7.15-5.142-10.923-.1-.696-.693-1.192-1.385-1.142zm2.868 32.374c2.126-1.093 3.51-3.327 3.51-5.71 0-.795.643-1.44 1.434-1.44.791 0 1.434.645 1.434 1.44V49.77c0 .199-.05.397-.099.596-.05.298-.099.546-.198.844a.94.94 0 01-.099.397c0 .15-.098.298-.148.497-.148.348-.297.695-.494 1.043l-.445.744a7.645 7.645 0 01-1.137 1.44c-.445.447-.89.844-1.434 1.142a4.272 4.272 0 01-.791.497l-.297.149c-.148.05-.346.1-.495.1a1.425 1.425 0 01-1.285-.795c-.396-.695-.198-1.54.494-1.986 0-.1.05-.15.05-.15z"
        />
        <path
          fill="#007AC9"
          d="M44.796 11.62c.05.049.099.098.148.098 14.438 3.178 25.266 16.088 25.266 31.53 0 3.427-.544 6.704-1.533 9.782v.199l2.868 5.015c.099.149.346.149.396 0a36.788 36.788 0 003.164-14.946c0-17.08-11.47-31.48-27.095-35.85-2.472-.745-4.5 1.937-3.214 4.171z"
        />
        <path
          fill="#007AC9"
          d="M71.792 59.336c-.445 0-.84-.249-1.038-.596l-2.868-5.015a1.37 1.37 0 01-.099-.993 30.903 30.903 0 001.484-9.484c0-14.598-10.285-27.458-24.525-30.587-.346-.05-.642-.298-.79-.596-.792-1.39-.693-3.028.296-4.32.989-1.29 2.522-1.787 4.054-1.34 16.366 4.618 27.837 19.712 27.837 36.843 0 5.313-1.088 10.477-3.214 15.393-.197.446-.593.695-1.038.744-.05-.05-.099-.05-.099-.05zm-2.076-6.306l1.928 3.376a36.337 36.337 0 002.472-13.158c0-16.187-10.828-30.537-26.403-34.907-.742-.198-1.483.05-1.978.646a1.931 1.931 0 00-.296 1.837c14.932 3.426 25.71 17.031 25.71 32.424a31.256 31.256 0 01-1.433 9.782z"
        />
        <path
          fill="#AADBDA"
          d="M62.15 65.741c-.642 0-1.285.249-1.73.745-5.785 5.61-13.646 9.087-22.348 9.087-8.702 0-16.564-3.476-22.349-9.087-.445-.447-1.088-.745-1.73-.745-2.225 0-3.313 2.731-1.73 4.27 6.674 6.455 15.772 10.477 25.809 10.477s19.085-4.022 25.76-10.526c1.582-1.54.494-4.22-1.681-4.22z"
        />
        <path
          fill="#AADBDA"
          d="M38.072 81.481c-9.939 0-19.333-3.823-26.453-10.774a3.38 3.38 0 01-.79-3.774c.543-1.34 1.78-2.185 3.213-2.185.89 0 1.78.348 2.423.993 5.785 5.66 13.498 8.789 21.607 8.789 8.108 0 15.772-3.128 21.656-8.789a3.436 3.436 0 012.423-.993c1.434 0 2.67.844 3.214 2.185.544 1.34.247 2.78-.791 3.773-7.17 6.952-16.564 10.775-26.502 10.775zm-24.08-14.747c-.642 0-1.137.348-1.384.944-.247.596-.099 1.191.346 1.638 6.774 6.554 15.723 10.18 25.118 10.18 9.394 0 18.294-3.626 25.117-10.23.445-.446.593-1.042.346-1.638-.247-.596-.741-.944-1.384-.944a1.42 1.42 0 00-1.039.447c-6.23 6.058-14.437 9.385-23.04 9.385-8.604 0-16.811-3.327-23.041-9.385a1.592 1.592 0 00-1.038-.397z"
        />
        <path
          fill="#6AC5C4"
          d="M6.922 54.53c.346-.546.396-1.241.247-1.837a31.494 31.494 0 01-1.285-8.938c.05-15.194 10.432-27.905 24.474-31.38.643-.15 1.138-.547 1.484-1.143l.05-.1c1.087-1.836-.594-4.12-2.67-3.574C13.003 11.58.988 26.228.988 43.706c0 3.624.494 7.1 1.483 10.377.593 2.036 3.362 2.334 4.4.497l.05-.05z"
        />
        <path
          fill="#6AC5C4"
          d="M4.796 56.814c-.148 0-.297 0-.445-.05-1.384-.149-2.472-1.092-2.868-2.433A38.297 38.297 0 010 43.705c0-17.627 11.916-32.92 28.974-37.14 1.335-.348 2.72.148 3.56 1.29.84 1.142.94 2.582.247 3.774l-.05.1c-.444.794-1.235 1.39-2.076 1.588C16.663 16.793 6.922 29.305 6.922 43.705c0 2.93.396 5.86 1.236 8.69.247.844.149 1.837-.346 2.631l-.05.1c-.642 1.092-1.73 1.688-2.966 1.688zM29.815 8.5c-.1 0-.248 0-.347.05-16.217 3.972-27.49 18.42-27.49 35.154 0 3.426.494 6.852 1.434 10.13.148.546.593.943 1.186.992.594.05 1.137-.198 1.434-.695l.05-.099c.197-.348.247-.745.148-1.092a34.134 34.134 0 01-1.286-9.236c0-15.343 10.334-28.6 25.167-32.324.346-.1.692-.348.84-.646l.05-.1c.297-.496.247-1.092-.099-1.588a1.311 1.311 0 00-1.087-.546z"
        />
        <path
          fill="#6AC5C4"
          d="M31.743 18.521l-3.412-1.986c-.247-.15-.296-.447-.198-.695l4.055-7.1a.475.475 0 00-.247-.696l-7.862-3.426c-.247-.1-.346-.397-.247-.645L25.414.348C25.513.1 25.81 0 26.057.1l12.707 5.56a.76.76 0 01.346 1.043l-6.675 11.62c-.148.248-.445.297-.692.198z"
        />
        <path
          fill="#007FB7"
          d="M61.755 49.455l3.659-1.49c.247-.1.544 0 .643.298l3.065 7.597a.471.471 0 00.692.248l7.565-4.121c.248-.149.544-.05.693.198l1.878 3.476c.149.248.05.547-.197.695L67.59 62.96c-.396.199-.89.05-1.039-.397L61.508 50.1c-.148-.248 0-.546.247-.645z"
        />
        <path
          fill="#AADBDA"
          d="M22.349 63.308l-.791 3.873a.513.513 0 01-.594.397l-8.01-1.639c-.296-.05-.544.15-.593.447l-.791 8.59c-.05.249-.247.497-.544.447l-3.955-.397c-.248-.05-.495-.248-.446-.546L7.96 60.626a.724.724 0 01.89-.645l13.103 2.731c.297.05.495.348.396.596z"
        />
      </g>
      <defs>
        <clipPath id="brand-resources_svg__clip0">
          <path fill="#fff" d="M0 0h80v81.481H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MemoSvgBrandResources = React.memo(SvgBrandResources);
export default MemoSvgBrandResources;
