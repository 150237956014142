import equal from 'fast-deep-equal/es6/react';
import {
  DependencyList,
  EffectCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: DependencyList
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export function useDeepCompareMemo<T>(
  callback: () => T,
  dependencies: DependencyList
) {
  return useMemo<T>(callback, dependencies.map(useDeepCompareMemoize));
}
