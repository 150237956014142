import React, { PropsWithChildren } from 'react';
import classnames from 'classnames/bind';

import styles from './CheckboxInput.module.css';
import { FieldError } from 'react-hook-form';
import { forwardRef } from 'react';

const cx = classnames.bind(styles);

export type CheckboxInputProps = PropsWithChildren<{
  name: string;
  onChange: (val: boolean) => void;
  value: boolean;
  error?: FieldError;
  className?: string;
}>;

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ name, className, children, value, onChange, error, ...props }, ref) => {
    const isChecked = !!value;

    return (
      <>
        <div
          className={cx('checkboxContainer', className)}
          onClick={() => onChange(!value)}
          ref={ref}
        >
          <input
            {...props}
            readOnly
            className={cx('checkboxInput')}
            type="checkbox"
            checked={isChecked}
          />
          <span className={cx('svgContainer')}>
            <svg width="12px" height="10px" viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
          </span>
          <span className={cx('label')}>{children}</span>
        </div>
        {error && <span className={cx('error')}>{error.message}</span>}
      </>
    );
  }
);

CheckboxInput.displayName = 'CheckboxInput';
