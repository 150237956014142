import { Table, Thead, Tr, Tbody, Td, Text, Link } from '@chakra-ui/react';
import React, { FC } from 'react';

import {
  ParsedGuide,
  ParsedGuideBulletList,
} from 'src/clients/api/v5.facility';
import { TIMELINE_COLORS } from 'src/constants/journey';
import { GuideNode } from 'src/types/guide';

type StepsTableProps = {
  steps: ParsedGuide['ToolsAndResources']['Step'];
};

export const StepsTable: FC<StepsTableProps> = ({ steps }) => {
  return (
    <Table
      border="1px solid"
      borderColor="gray.600"
      sx={{
        '& thead td': {
          borderBottom: '1px solid',
          borderColor: 'gray.600',
        },
        '& tbody > tr': {
          borderBottom: '1px solid',
          borderColor: 'gray.600',
        },
        '& tbody > tr:nth-child(even)': {
          bgColor: TIMELINE_COLORS[1],
        },
        '& tbody td': {
          verticalAlign: 'top',
          borderRight: '1px solid',
          borderColor: 'gray.600',
        },
      }}
    >
      <Thead
        bgColor={TIMELINE_COLORS[5]}
        color="white"
        borderBottom="1px solid"
        borderColor="gray.600"
      >
        <Tr>
          <Td></Td>
          <Td>Description</Td>
          <Td>Examples</Td>
          <Td>Tools</Td>
        </Tr>
      </Thead>
      <Tbody
        bgColor={TIMELINE_COLORS[0]}
        borderTop="1px solid"
        borderColor="gray.600"
      >
        {steps
          .map((step, index) => {
            const stepNumber = index + 1;
            if (step) {
              return (
                <Tr key={`step_${stepNumber}`}>
                  <Td
                    verticalAlign="top"
                    bgColor={TIMELINE_COLORS[4]}
                    color="white"
                    textAlign="center"
                  >
                    <Text>Step</Text>
                    <Text>{stepNumber}</Text>
                  </Td>
                  <Td verticalAlign="top">{step.Description.nodeValue}</Td>
                  <Td verticalAlign="top">
                    <ul>
                      {resolveBullets(step.Examples).map((node) => (
                        <li
                          key={`example_${node.guideNodeId}`}
                          dangerouslySetInnerHTML={{
                            __html: node.nodeValue,
                          }}
                        />
                      ))}
                    </ul>
                  </Td>
                  <Td verticalAlign="top">
                    <ul>
                      {resolveBullets(step.Tools).map((node) => (
                        <li key={`tools_${node.guideNodeId}`}>
                          {node.nodeValueLink ? (
                            <Link
                              target="_blank"
                              textDecor="underline"
                              rel="noopener noreferrer"
                              href={node.nodeValueLink}
                              dangerouslySetInnerHTML={{
                                __html: node.nodeValue,
                              }}
                              isExternal
                            />
                          ) : (
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: node.nodeValue,
                              }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </Td>
                </Tr>
              );
            }

            return null;
          })
          .filter(Boolean)}
      </Tbody>
    </Table>
  );
};

function resolveBullets(node: GuideNode | ParsedGuideBulletList): GuideNode[] {
  if (node.hasOwnProperty('Bullet')) {
    return (node as ParsedGuideBulletList).Bullet.filter(
      Boolean
    ) as GuideNode[];
  }

  return [node as GuideNode];
}

StepsTable.displayName = 'StepsTable';
