import { Box, Flex, SlideFade, Text } from '@chakra-ui/react';
import { mdiAlert } from '@mdi/js';
import React, { FC } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { Icon } from '../Icon';

export interface SiteSubHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  hasUpcomingMaintenance?: boolean;
  isCurrentlyUnderMaintenance?: boolean;
  maintenanceMessage?: string;
}

export const SiteSubHeader: FC<SiteSubHeaderProps> = ({
  hasUpcomingMaintenance,
  isCurrentlyUnderMaintenance,
  maintenanceMessage,
}) => {
  const { isAuthenticated } = useAuth();
  const hasMaintenance = isCurrentlyUnderMaintenance || hasUpcomingMaintenance;

  if (!hasMaintenance || isAuthenticated) {
    return null;
  }

  return (
    <Box flex="1 1 45px">
      <SlideFade in={true} offsetY="-20px">
        <Flex
          w="full"
          minH="45px"
          justifyContent="center"
          alignItems="center"
          bgColor="var(--chakra-colors-orange-50)"
          border="2px solid"
          borderColor="var(--chakra-colors-yellow-500)"
          color="var(--chakra-colors-yellow-500)"
          fontWeight="bold"
          p="2"
        >
          <Flex maxW="1920px">
            <Icon path={mdiAlert} size="20px" mr="2" />
            <Text
              fontSize="sm"
              dangerouslySetInnerHTML={{
                __html: maintenanceMessage || 'Maintenance in progress',
              }}
              sx={{ '& > a': { textDecor: 'underline' } }}
            />
          </Flex>
        </Flex>
      </SlideFade>
    </Box>
  );
};

SiteSubHeader.displayName = 'SiteSubHeader';
