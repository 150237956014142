import React from 'react';
import {
  forwardRef,
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import MdiIcon from '@mdi/react';
import type { IconProps } from '@mdi/react/dist/IconProps';

export type IconPropsType = IconProps &
  ChakraIconProps & {
    size?: string | number;
  };

export const Icon = forwardRef<IconPropsType, typeof MdiIcon>(
  ({ size = '16px', ...props }, ref) => {
    return (
      <ChakraIcon
        as={MdiIcon}
        h={size}
        w={size}
        {...props}
        ref={ref}
        size={size}
      />
    );
  }
);
