import { Box, Show, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC } from 'react';
import {
  ParsedGuide,
  ParsedGuideBulletList,
} from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import { Copy } from 'src/components/Copy';
import { TIMELINE_COLORS } from 'src/constants/journey';
import { GuideNode } from 'src/types/guide';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type BusinessCaseProps = {
  guide: ParsedGuide['BusinessCase'];
};

export const BusinessCase: FC<BusinessCaseProps> = ({ guide }) => {
  try {
    return (
      <div className={cx('facilityTargetTab')}>
        <Copy as="h2">Business Case</Copy>

        <Box
          dangerouslySetInnerHTML={{
            __html: guide.Content.nodeValue,
          }}
          sx={{
            '& p': {
              marginBottom: '1rem',
            },
            '& a': {
              color: 'blue.500',
              textDecoration: 'underline',
              '&:hover': {
                color: 'blue.600',
              },
            },
          }}
        />

        <Box my="1">
          <Copy as="h2" className="print-benefits">
            Benefits
          </Copy>
        </Box>

        <Table
          className={cx('benefitMatrix')}
          border="1px solid"
          borderColor="gray.600"
          sx={{
            '& thead th': {
              borderBottom: '1px solid',
              borderColor: 'gray.600',
              color: 'white',
            },
            '& tbody > tr': {
              borderBottom: '1px solid',
              borderColor: 'gray.600',
            },
            '& tbody > tr:nth-child(even)': {
              bgColor: TIMELINE_COLORS[1],
            },
            '& tbody td': {
              verticalAlign: 'top',
              borderRight: '1px solid',
              borderColor: 'gray.600',
            },
            '& a': {
              color: 'blue.500',
              textDecoration: 'underline',
              '&:hover': {
                color: 'blue.600',
              },
            },
          }}
        >
          <Thead
            bgColor={TIMELINE_COLORS[5]}
            borderBottom="1px solid"
            borderColor="gray.600"
            color="white"
          >
            <Tr>
              <Th width="4"></Th>
              <Th>
                <Show below="xl">
                  <span className={cx('center')}>Enterprise Level</span>
                </Show>
                <Show above="xl">
                  <span className={cx('center')}>
                    Potential benefits at the enterprise level
                  </span>
                </Show>
              </Th>
              <Th>
                <Show below="xl">
                  <span className={cx('center')}>Facility Level</span>
                </Show>
                <Show above="xl">
                  <span className={cx('center')}>
                    Potential benefits at the individual facility level
                  </span>
                </Show>
              </Th>
            </Tr>
          </Thead>
          <Tbody
            bgColor={TIMELINE_COLORS[0]}
            borderTop="1px solid"
            borderColor="gray.600"
          >
            <Tr>
              <Td
                className={cx('gutter')}
                width="4"
                verticalAlign="middle!important"
                bgColor={TIMELINE_COLORS[4]}
                color="white"
              >
                <span className={cx('tilt')}>Tangible</span>
              </Td>
              <Td>
                <ul>
                  {resolveBullets(guide.Enterprise.Tangible).map((node) => (
                    <li
                      key={`tangible_enterprise_${node.guideNodeId}`}
                      dangerouslySetInnerHTML={{
                        __html: node.nodeValue,
                      }}
                    />
                  ))}
                </ul>
              </Td>
              <Td>
                <ul>
                  {resolveBullets(guide.Individual.Tangible).map((node) => (
                    <li
                      key={`tangible_individual_${node.guideNodeId}`}
                      dangerouslySetInnerHTML={{
                        __html: node.nodeValue,
                      }}
                    />
                  ))}
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Td
                className={cx('gutter')}
                width="4"
                verticalAlign="middle!important"
                bgColor={TIMELINE_COLORS[4]}
                color="white"
              >
                <span className={cx('tilt')}>Intangible</span>
              </Td>
              <Td>
                <ul>
                  {resolveBullets(guide.Enterprise.Intangible).map((node) => (
                    <li
                      key={`intangible_enterprise_${node.guideNodeId}`}
                      dangerouslySetInnerHTML={{
                        __html: node.nodeValue,
                      }}
                    />
                  ))}
                </ul>
              </Td>
              <Td>
                <ul>
                  {resolveBullets(guide.Individual.Intangible).map((node) => (
                    <li
                      key={`intangible_individual_${node.guideNodeId}`}
                      dangerouslySetInnerHTML={{
                        __html: node.nodeValue,
                      }}
                    />
                  ))}
                </ul>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    );
  } catch (error) {
    return <BlockError />;
  }
};

function resolveBullets(node: GuideNode | ParsedGuideBulletList): GuideNode[] {
  if (node.hasOwnProperty('Bullet')) {
    return (node as ParsedGuideBulletList).Bullet.filter(
      Boolean
    ) as GuideNode[];
  }

  return [node as GuideNode];
}

BusinessCase.displayName = 'BusinessCase';
