import { SESSION_INVALID } from 'src/constants/events';
import { eventBus } from 'src/utils/eventBus';
import { getTokenInStorage } from './helpers';
import { Api$ApplicationError, Api$Task, TaskResult } from './types';

export function getDefaultHeaders() {
  return {
    'Content-Type': 'application/json',
  };
}

export function getAuthHeaders() {
  const token = getTokenInStorage();

  return {
    ...getDefaultHeaders(),

    // Attach token to headers if token exists
    ...(token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {}),
  };
}

function rawRequest(
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> {
  const controller = new AbortController();
  const requestTimer = setTimeout(() => controller.abort(), 120000);

  return fetch(input, {
    ...init,
    signal: controller.signal,
  }).finally(() => {
    clearTimeout(requestTimer);
  });
}

function httpRequest<ResponseData, U>(
  options:
    | {
        method: 'GET' | 'DELETE';
        url: string;
        headers?: Record<string, unknown>;
      }
    | {
        method: 'POST' | 'PUT';
        url: string;
        payload: U;
        headers?: Record<string, unknown>;
      }
): Api$Task<ResponseData> {
  const headers = new Headers({
    ...getDefaultHeaders(),
    ...getAuthHeaders(),
  });

  const body =
    options.method === 'POST' || options.method === 'PUT'
      ? {
          body: JSON.stringify({ data: options.payload }),
        }
      : {};

  return rawRequest(options.url, {
    method: options.method,
    headers,
    ...body,
  })
    .then((response: Response) => requestSuccess<ResponseData>(response))
    .catch((error: Error) => requestError<ResponseData>(error));
}

export function requestSuccess<ResponseData>(response: Response) {
  return response.json().then((json) => {
    if (!response.ok && response.status === 401) {
      eventBus.dispatch({ type: SESSION_INVALID });
    }

    return response.ok
      ? ({
          status: 'success',
          code: response.status,
          value: json,
        } as TaskResult<Api$ApplicationError, ResponseData>)
      : ({
          status: 'error',
          code: response.status,
          value: {
            type: 'Exception',
            errors: json.errors,
            metadata: json.metaData,
          },
        } as TaskResult<Api$ApplicationError, ResponseData>);
  });
}

export function requestError<ResponseData>(error: Error) {
  return {
    status: 'error',
    code: 500,
    value: {
      type: 'Exception',
      errors: [
        {
          field: '',
          message: error.message ?? 'Something went wrong.',
        },
      ],
      metadata: {},
    },
  } as TaskResult<Api$ApplicationError, ResponseData>;
}

export function get<Response>(url: string): Api$Task<Response> {
  return httpRequest({
    method: 'GET',
    url,
  });
}

export function post<Response, Payload>(
  url: string,
  payload?: Payload
): Api$Task<Response> {
  return httpRequest({ method: 'POST', url, payload: payload ?? {} });
}

export function put<Response, Payload>(
  url: string,
  payload: Payload
): Api$Task<Response> {
  return httpRequest({ method: 'PUT', url, payload: payload ?? {} });
}

export function del<Response>(url: string): Api$Task<Response> {
  return httpRequest({
    method: 'DELETE',
    url,
  });
}

export default {
  get,
  post,
  put,
  del,
  raw: rawRequest,
};
