import { ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import { Copy } from 'src/components/Copy';

function Danger() {
  return (
    <>
      <Copy>
        Model investment outcomes for water quality improvement or conservation
        to make the business case to take action.
      </Copy>
      <Copy>
        <strong>INVEST:</strong>
      </Copy>
      <UnorderedList>
        <ListItem>
          <Copy>
            Take the Water Action Assessment to receive a tailored guide to
            implement improvements in water management, use, target-setting and
            stewardship practices.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Use the Risk Analysis Investment calculator to model project
            investment outcomes.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Establish a process to monitor local water availability (for example
            droughts or pollution incidents) so that acute events can be managed
            in a way that reduces business disruption or cost.
          </Copy>
        </ListItem>
      </UnorderedList>
    </>
  );
}

function Warning() {
  return (
    <>
      <Copy>
        <strong>ENGAGE:</strong>
      </Copy>
      <UnorderedList>
        <ListItem>
          <Copy>
            Take the Water Action Assessment to receive a tailored guide to
            implement improvements in water management, use, target-setting and
            stewardship practices.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Use the Risk Analysis Investment Calculator to model investment
            project outcomes.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Meet with local water service providers to understand the current
            state of the watershed and plans to manage stakeholder needs.
            Understand how local authorities plan to allocate water today and in
            the future, how future water rates may be linked to availability and
            quality, and current and future treatment capacity.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Collaborate with other local users on a water stewardship plan and
            explore where there may be opportunities to share data that would
            minimize risk.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Leverage the Alliance for Water Stewardship's six step framework to
            implement a water stewardship plan:{' '}
            <a
              href="http://www.allianceforwaterstewardship.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Alliance for Water Stewardship
            </a>
          </Copy>
        </ListItem>
      </UnorderedList>
    </>
  );
}

function Success() {
  return (
    <>
      <Copy>
        <strong>ENGAGE:</strong>
      </Copy>
      <UnorderedList>
        <ListItem>
          <Copy>
            Take the Water Action Assessment to receive a tailored guide to
            implement improvements in water management, use, target-setting and
            stewardship practices.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Monitor water availability and growth in the basin and put in place
            a process to react to changes that would place the facility at a
            higher risk.
          </Copy>
          <Copy>
            Monitoring growth and local water availability can include a
            combination of private and public efforts. Examples could include
            trends in water demand by category (agricultural, domestic and
            industrial), real time satellite data on water availability, water
            use intensity within the region, changes in economic development or
            basin-wide water conservation initiatives.
          </Copy>
        </ListItem>
      </UnorderedList>
    </>
  );
}

function Primary() {
  return (
    <>
      <Copy>
        <strong>CONTINUE ONGOING CONSERVATION:</strong>
      </Copy>
      <UnorderedList>
        <ListItem>
          <Copy>
            Continue ongoing water efficiency efforts and monitor performance
            over time to reduce reliance on fresh water.
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Take the Water Action Assessment to receive a tailored guide to
            implement improvements in water management, use, target-setting and
            stewardship practices
          </Copy>
        </ListItem>
        <ListItem>
          <Copy>
            Periodically assess water basin conditions and local growth to
            understand changes that might increase risk
          </Copy>
        </ListItem>
      </UnorderedList>
    </>
  );
}

export default {
  Danger,
  Warning,
  Success,
  Primary,
};
