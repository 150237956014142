export enum JourneyStep {
  Identify = 'identify', //risk
  Target = 'target', //targets
  Implement = 'implement', //take action
  Track = 'track', // track progress
}

export const JourneyTitles = {
  [JourneyStep.Identify]: 'Identify Risk',
  [JourneyStep.Target]: 'Set Targets',
  [JourneyStep.Implement]: 'Take Action',
  [JourneyStep.Track]: 'Track Progress',
};

export const TIMELINE_COLORS = [
  '#e6eef2',
  '#ccdde4',
  '#99bbca',
  '#6698af',
  '#337695',
  '#00547a',
];
