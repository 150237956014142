import { Box, List, ListItem, Stack } from '@chakra-ui/react';
import {
  mdiChartLine,
  mdiDomain,
  mdiFileOutline,
  mdiFileUploadOutline,
  mdiFinance,
  mdiFolderMultipleOutline,
  mdiHelp,
  mdiPlusCircleOutline,
} from '@mdi/js';
import React, { FC, useState } from 'react';
import { AccountSelector } from 'src/components/AccountSelector';
import { GenerateReportDialog } from '../GenerateReportDialog';
import { NavLink } from './NavLink';

type SidebarNavProps = {};

export const SidebarNav: FC<SidebarNavProps> = () => {
  const [isGenerateReportOpen, setIsGenerateReportOpen] = useState(false);

  return (
    <Stack
      flex={{ base: '0 0 50px', md: '0 0 250px' }}
      overflow="hidden"
      bgColor="#5EB6E4"
      spacing="8"
    >
      <Box p="2" display={{ base: 'none', md: 'block' }}>
        <AccountSelector display={{ base: 'none', md: 'block' }} />
      </Box>

      <List pl={{ base: '0', md: '2' }}>
        <ListItem>
          <NavLink
            to="/app/dashboard"
            text="Facility Dashboard"
            icon={mdiFinance}
            borderTop="1px solid var(--borderColor)"
            borderLeftColor="transparent"
          />
        </ListItem>
        <ListItem>
          <NavLink to="/app/facilities" icon={mdiDomain} text="Facilities" />
        </ListItem>
        <ListItem>
          <NavLink
            to="/app/folders"
            icon={mdiFolderMultipleOutline}
            text="Facility Folders"
          />
        </ListItem>
        <ListItem>
          <NavLink
            to="/app/enterprise"
            icon={mdiChartLine}
            text="Enterprise Dashboard"
          />
        </ListItem>
        <ListItem>
          <NavLink
            to="/app/uploads"
            icon={mdiFileUploadOutline}
            text="Upload Manager"
          />
        </ListItem>
      </List>

      <List gap="4">
        <ListItem>
          <NavLink
            exact
            to=""
            icon={mdiFileOutline}
            text="Generate Report"
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsGenerateReportOpen(!isGenerateReportOpen);
            }}
          />
        </ListItem>
        <ListItem>
          <NavLink
            to={`/app/facilities/new`}
            icon={mdiPlusCircleOutline}
            text="Add New Facility"
            variant="secondary"
          />
        </ListItem>
      </List>

      {isGenerateReportOpen ? (
        <GenerateReportDialog
          isOpen={isGenerateReportOpen}
          onClose={() => setIsGenerateReportOpen(false)}
        />
      ) : null}

      <List>
        <ListItem>
          <NavLink
            to="/contact"
            icon={mdiHelp}
            text="Help"
            variant="tertiary"
          />
        </ListItem>
      </List>
    </Stack>
  );
};

SidebarNav.displayName = 'SidebarNav';
