import classnames from 'classnames/bind';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC } from 'react';
import { Copy } from 'src/components/Copy';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { AggregateResult } from 'src/types/aggregates';
import {
  riskFactorChartDataGenerator,
  riskSourceChartDataGenerator,
} from '../../../../utils/chart';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type TotalRiskProps = {
  aggregate: AggregateResult;
};

export const TotalRisk: FC<TotalRiskProps> = ({ aggregate }) => {
  const riskSource = useDeepCompareMemo<Highcharts.Options>(
    () =>
      riskSourceChartDataGenerator(
        aggregate.charts.waterRiskPremiumIncomingOutgoingChart
      ),
    [aggregate.charts]
  );

  const riskFactor = useDeepCompareMemo<Highcharts.Options>(
    () =>
      riskFactorChartDataGenerator(
        aggregate.charts.waterRiskPremiumScarcityQualityChart
      ),
    [aggregate.charts]
  );

  return (
    <div className={cx('facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Total Risk Premium</Copy>
        <Copy as="p">
          The Total Risk Premium is a monetary estimate of the full value of
          water to the facility. It is the sum of the incoming risk premium
          (based on quantity and quality) and the outgoing risk premium (based
          on quality risk).
        </Copy>
      </div>
      <div className={cx('chartContainer')}>
        <div className={cx('chartContainerPane')}>
          <HighchartsReact highcharts={Highcharts} options={riskSource} />
          <div className={cx('legend', 'totalRiskLegend')}>
            <div className={cx('legendHeader', 'inOut')}>
              <p>Risk source: Incoming vs. Outgoing water</p>
              <ul>
                <li>Incoming</li>
                <li>Outgoing</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={cx('chartContainerPane')}>
          <HighchartsReact highcharts={Highcharts} options={riskFactor} />
          <div className={cx('legend', 'totalRiskLegend')}>
            <div className={cx('legendHeader', 'sourceOfRisk')}>
              <p>Risk Factor: Quantity vs. Quality</p>
              <ul>
                <li>Quantity</li>
                <li>Quality</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TotalRisk.displayName = 'TotalRisk';
