import * as React from 'react';

const SvgRiskAnalysis = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="RiskAnalysis_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 36 30.4"
      xmlSpace="preserve"
      data-testid="svg-icon"
      {...props}
    >
      <path
        d="M36 0H0v30.4h36V0zm-1.6 28.9H1.6V1.5h32.9v27.4z"
        className="RiskAnalysis_svg__st0"
      />
      <path
        d="M5.9 24.6c1.3 0 2.4-1.1 2.4-2.4 0-.4-.1-.8-.3-1.2l4.6-4.6c.8.5 1.8.5 2.5-.1l3.8 3.5c-.2.3-.3.7-.3 1.1 0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4 0-.6-.2-1.1-.6-1.6l6.3-8.9c1.3.5 2.7-.1 3.2-1.4.5-1.1-.1-2.5-1.3-3-1.3-.5-2.7.1-3.2 1.4-.3.8-.2 1.8.4 2.5l-6.3 8.9c-.7-.3-1.5-.2-2.2.2l-3.8-3.5c.2-.3.3-.7.3-1.1 0-1.3-1-2.4-2.4-2.4-1.3 0-2.4 1-2.4 2.4 0 .4.1.9.3 1.3l-4.6 4.6c-1.1-.7-2.6-.3-3.3.8-.7 1.1-.3 2.6.8 3.3.4 0 .9.2 1.3.2z"
        className="RiskAnalysis_svg__st0"
      />
    </svg>
  );
};

const MemoSvgRiskAnalysis = React.memo(SvgRiskAnalysis);
export default MemoSvgRiskAnalysis;
