import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

type FaqProps = Record<string, unknown>;

const FaqItem: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <AccordionItem border="none" mb="15px">
      <AccordionButton border="1px solid #eee">
        <AccordionIcon />
        <Box flex="1" textAlign="left" p="10px">
          <Heading as="h3" size="md">
            {title}
          </Heading>
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Stack direction="column" spacing="15px" my="15px">
          {children}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export const Faq: FC<PropsWithChildren<FaqProps>> = () => {
  return (
    <RouteContentScrollable>
      <PageHeader
        image="/images/clarifiers.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Frequently <br />
            asked <br />
            questions
          </Heading>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(10vh)' }}
        p="20px"
        pb="40px"
        maxW={1920 / 1.5}
      >
        <Stack spacing="24px">
          <Heading as="h2" size="2xl" textAlign="center">
            Smart Water Navigator FAQ
          </Heading>
          <Text>
            Are you a user of the tool looking for more details about its
            underlying calculations, potential uses and outputs, or other
            information related to the Smart Water Navigator? Review the FAQ to
            find answers to commonly asked questions about the Smart Water
            Navigator.
          </Text>
          <Text>
            <strong>
              For additional questions, please reach out to our{' '}
              <Link
                color="brand.primary"
                href="mailto:SmartWaterNavigator@ecolab.com"
              >
                support team
              </Link>
              .
            </strong>
          </Text>
        </Stack>

        <Accordion defaultIndex={[0]} allowToggle>
          <FaqItem title="What is the Ecolab Smart Water Navigator?">
            <Text>
              Water is essential to the health of our environment, communities
              and our economy. By 2030, businesses water use is expected to
              surpass supply by 56% (World Resources Institute) a disparity that
              will continue to increase if nothing changes. Businesses rely on
              water to operate and grow and water scarcity presents a growing
              risk to organizations. While most corporations have established
              goals to reduce water use, it can be difficult to make progress
              due to lack of tools and expertise at the facility level.
            </Text>
            <Text>
              The Ecolab Smart Water Navigator is a publicly available, online
              tool that can help you protect your business and ensure continued
              growth. It offers a proven methodology to help organizations
              bridge the gap between corporate goals and local action. Ecolab’s
              Smart Water Navigator can help you by:
            </Text>
            <UnorderedList pl="20px">
              <ListItem>
                Enhancing your understanding of local water risks and the full
                value of water to your operations in financial terms
              </ListItem>
              <ListItem>
                Providing water targets that are proportional to the local water
                stress
              </ListItem>
              <ListItem>
                Delivering an industry-specific smart water roadmap to guide
                improved performance over time
              </ListItem>
            </UnorderedList>
          </FaqItem>

          <FaqItem title="Did the Smart Water Navigator replace the Water Risk Monetizer?">
            <Text>
              As global water scarcity grows increasingly severe, posing an
              increased risk to businesses worldwide, it’s more important than
              ever for companies to prioritize sustainable water management. In
              order to align with these global industry trends and create a
              seamless user experience along all points of creating and
              executing a smart water management plan, the Smart Water Navigator
              and the Water Risk Monetizer were combined into one tool. Users
              still have access to all of the same features to identify risks,
              set targets, implement action plans and track progress over time.
              The integrated Smart Water Navigator now features one unified
              dashboard and consolidated downloadable PDF reports with water
              risk data, checklists, resources and case studies to support you
              along all stages of your water management journey.
            </Text>
          </FaqItem>

          <FaqItem title="How does the Smart Water Navigator work?">
            <Text>
              The tool uses a proven, four-step process, to enhance business
              resilience in your organization through smart water management.
            </Text>
            <Text>
              <strong>Step 1: Identify</strong> | To get started on your smart
              water journey, the tool will help you identify the role of water
              in your operations, assess your current water use and quantify
              water-related risks in financial terms
            </Text>
            <Text>
              <strong>Step 2: Target</strong> | Then, you’ll set sustainable
              water withdrawal targets for your facility to manage water-use
              risk
            </Text>
            <Text>
              <strong>Step 3: Implement</strong> | Next, the Ecolab Smart Water
              Navigator will provide you with a practical, industry-specific
              guide to implement solutions that will improve water management
              practices at an individual facility level
            </Text>
            <Text>
              <strong>Step 4: Track</strong> | In the final step, you can track
              your progress over time and revisit your smart water action plan
              to make adjustments based on changing circumstances.
            </Text>
            <Text>
              Using the Smart Water Navigator, you’ll have the ability to:
            </Text>

            <UnorderedList pl="20px">
              <ListItem>
                Set clear targets and drive internal accountability to help
                reach those targets
              </ListItem>
              <ListItem>
                Optimize water-use performance to help ensure more resilient
                operations
              </ListItem>
              <ListItem>
                Build trust and credibility with stakeholders to drive
                collective action
              </ListItem>
            </UnorderedList>

            <Text>
              And, by addressing shared water challenges, you will contribute to
              a world with enough water for all.
            </Text>
          </FaqItem>

          <FaqItem title="How are the incoming and outgoing water risk premiums calculated?">
            <Text>
              The Smart Water Navigator uses scientific models developed by S&P
              Global Sustainable1 to estimate incoming and outgoing water risk
              in monetary terms. The incoming water risk premium is made up of
              the incoming quantity and incoming quality risk premiums. It looks
              at the amount and quality of water available at a specific
              location, the amount of water used by a facility, additional
              demands on the supply of water and the impact of a facility's
              water use on the local water basin. The outgoing water risk
              premium is made up of the outgoing quality risk premium. It looks
              at the amount and quality of water discharged by a facility, local
              water quality thresholds and the impact of water pollution on the
              local water basin.
            </Text>
            <Text>
              The incoming and outgoing water risk premiums are a monetary
              estimate of the increased price of water, which may be realized by
              a business as an increase in its operating costs. The water risk
              premium is calculated based on the full value of water to a
              facility, as estimated based on local water stress and local water
              quality.
            </Text>
          </FaqItem>

          <FaqItem title="How is potential revenue at risk calculated?">
            <Text>
              Potential revenue at risk is the estimated monetary value of the
              revenue that could potentially be lost at a facility as a result
              of stress on water availability. Because water is a finite
              resource that is shared by many users in a water basin, the amount
              of water that should be available to a facility may be less than
              what a facility needs. The amount available could also change over
              time, as water stress increases or as a local economy grows. The
              model estimates the amount of water a facility requires to
              generate revenue, and compares it to the facility's share of water
              in the basin if water were allocated among water users based on
              economic activity (contribution to basin-level GDP). If more water
              is required than the basin share of water allocated (as determined
              by the model), then the facility's revenue is potentially at risk.
              The Smart Water Navigator has a built-in water risk threshold that
              prevents water withdrawals breaching 20% water stress within any
              water basin.
            </Text>
          </FaqItem>

          <FaqItem title="Does the Smart Water Navigator predict or forecast future water prices?">
            <Text>
              The Smart Water Navigator does not predict whether water risks
              will be realized in a water bill. Water providers set their local
              prices based on local water stress and water quality, as well as a
              variety of factors. It's not possible in a global tool like this
              to address all those local factors. The Smart Water Navigator
              provides risk-adjusted water prices that businesses should use for
              budgeting, planning, strategy development, and when considering
              the return on CapEx or OpEx investments for water projects. The
              water risk premiums should be used as one data point in a
              comprehensive risk assessment process and is intended to be
              refined based on local conditions and business particulars.
            </Text>
          </FaqItem>

          <FaqItem title="Does the user need to enter facility-specific revenue to calculate potential revenue at risk?">
            <Text>
              The user has the option to enter facility-specific revenue to
              calculate potential revenue at risk due to stress on water
              availability. This input is optional. If the user does not enter
              facility revenue, the Smart Water Navigator will estimate revenue
              based on the facility's industry classification and industry
              average economic data based on S&P Global Sustainable1’s
              environmentally extended input-output (EEI-O) model. S&P Global
              Sustainable1’s EEI-O model is informed by the US Bureau of
              Economic Analysis (BEA) data.
            </Text>
          </FaqItem>

          <FaqItem title="Does the Smart Water Navigator calculate the full value of water and the full business value at risk?">
            <Text>
              The Smart Water Navigator addresses business risk related to the
              price paid for water and revenue dependent on water availability.
              There are other water-related business risks and costs that the
              tool currently does not address quantitatively, for example, the
              water risk in supply chains, brand reputational loss, aging
              infrastructure etc. If you would like to directly measure and
              monetize supply chain risks or other business risks, understand
              the full value of water at your location, or use a more
              site-specific information to better understand and manage water
              risks, please contact{' '}
              <Link color="brand.primary" href="mailto:S1SAS@spglobal.com">
                S&P Global Sustainable1
              </Link>
            </Text>
          </FaqItem>

          <FaqItem title="What is the likelihood that my business will have to pay a water risk premium or experience revenue loss?">
            <Text>
              The likelihood that businesses will have to pay a water risk
              premium or lose revenue will depend on a number of local factors,
              including local water stress and quality, reputational issues
              related to water, the amount of water required for facility
              operations and the regulatory framework governing water allocation
              and pricing. The Smart Water Navigator calculates likelihood
              scores as high, medium or low. Facilities with likelihood scores
              that are high or medium are more likely to pay a water risk
              premium or experience revenue loss and should take immediate steps
              to better understand their local situation, including factors like
              local water subsidies, infrastructure leakage rates and other
              demands for water in their basin. These businesses should also
              take immediate steps to understand how investing in water
              efficiency programs can reduce those risks.
            </Text>
          </FaqItem>

          <FaqItem title="How do risk levels correlate with likelihood of impact?">
            <Text>
              If a facility has a high or medium risk, the likelihood of
              increased water costs or revenue loss in the context of
              site-specific issues should be evaluated. In particular, a
              business should identify local development or growth pressures
              that may affect the availability of water, the extent to which
              other water users have plans for growth, whether water subsidies
              are expected to continue, any plans that the local water agency
              may have to reassess or review water risks and costs, and other
              locally specific issues that will factor into the price paid for
              water and the ability to generate expected revenue.
            </Text>
          </FaqItem>

          <FaqItem title="How does the Smart Water Navigator address locations currently in drought?">
            <Text>
              Global water databases reflect long-term water conditions. Some
              regions and cities may be experiencing droughts that are not
              captured in global databases and the tools that use those
              databases, including the Smart Water Navigator. As a result, water
              quantity and water quality risks may not reflect that there is
              currently a drought. To provide additional insights for users, the
              Smart Water Navigator has a Drought Scenario feature. This feature
              provides the user with risk-based water price and revenue at risk
              results that reflect a drought condition, regardless of long-term
              water conditions in the underlying water database. If you select
              the Drought Scenario when entering a facility location, we
              recommend that you also evaluate your facility under the baseline
              conditions so that you have a more complete understanding of water
              stress issues. For more information, please refer to the{' '}
              <Link as={RRLink} color="brand.primary" to="/methodology">
                "Methodology" page
              </Link>
              .
            </Text>
          </FaqItem>

          <FaqItem title="How is the Smart Water Navigator different from other publicly available water tools?">
            <Text>
              Several water frameworks and tools provide a good starting point
              for quantitative and systematic assessments of water risk. These
              include the World Resource Institute’s Aqueduct Tool and the World
              Wildlife Fund’s Water Risk Filter. The Smart Water Navigator is
              the first tool to place a monetary value on water quantity and
              water quality risks and provide users with an action plan to help
              mitigate and adapt to those risks.
            </Text>
            <Text>
              The Smart Water Navigator is a publicly available global water
              risk tool that uses best-in-class local water basin datasets and
              scientific methodologies to monetize business water risks. It
              defines water quantity and water quality risks in financial terms
              to estimate potential risk-related increases in operating costs
              and possible loss of revenue their businesses. The Smart Water
              Navigator uses a water action assessment to provide users with a
              high-level overview of where they land on the water maturity curve
              and detailed action steps they can take to improve their water
              management practices. One way to improve water management
              practices is to make the business case for projects or
              technologies that help them better manage water. In other words,
              the Smart Water Navigator can both help users identify how they
              are doing in terms of smart water management and make the business
              case for change.
            </Text>
          </FaqItem>

          <FaqItem title="Why is the Smart Water Navigator vital to addressing global water challenges?">
            <Text>
              Global water scarcity, a function of quantity (availability) and
              quantity of clean, freshwater threatens business vitality and
              demands action. The current cost of water does not account for
              real and future risks, making it hard to justify business
              decisions to reduce use. In addition, decreasing water
              availability and declining water quality factors make it harder
              for businesses to access the water they need to operate, which
              puts revenue at risk. The Smart Water Navigator provides valuable,
              actionable information including risk-adjusted water prices for
              incoming and outgoing water and calculation of potential revenue
              at risk. This helps businesses better understand risks and
              potential impacts so they can make more informed decisions around
              strategies and investments to mitigate water risks and enable
              growth.
            </Text>
            <Text>
              The Smart Water Navigator can also help guide corporate and
              facility managers to reduce water use and utilize this valuable
              resource for long-term revenue growth. Managing a site’s water
              resources sustainably can result in reduced operating costs,
              reduced utility bills, reduced liability risk and occasionally
              increased reputational benefits. As competition for clean water
              increases, so will water-related expenditures. The Smart Water
              Navigator is designed to support assessment of a site’s current
              state of water performance, benchmark its performance over time,
              and help improve water performance by providing customized
              resources.
            </Text>
          </FaqItem>

          <FaqItem title="How do I share outputs from the Smart Water Navigator?">
            <Text>
              There are options to download outputs as reports in .csv (comma
              separated values) or PDF formats. To share this information with
              someone else, just download the .csv or PDF report.
            </Text>
            <Text>
              There is also the option to invite others to use the Ecolab Smart
              Water Navigator. This will not enable them to view your facility
              Dashboard. Other users in the same company with the same company
              email address will be able to view benchmarking data for the same
              company, but will not be able to view data for individual
              facilities.
            </Text>
          </FaqItem>

          <FaqItem title="How do I know the data I input into the Smart Water Navigator is kept confidential?">
            <Text>
              All accounts are private and other users do not have access to
              information in individual accounts. Please view Ecolab’s{' '}
              <Link
                color="brand.primary"
                href="https://www.ecolab.com/privacy-policy"
                isExternal
              >
                Privacy notice
              </Link>{' '}
              for more information.
            </Text>
          </FaqItem>

          <FaqItem title="Who can I reach out to if I have other questions or need help?">
            <Text>
              For more information, you can download the complete{' '}
              <Link
                color="brand.primary"
                as={RRLink}
                to="/documents/2024_SmartWaterNavigatorMethodology.pdf"
                target="_blank"
              >
                methodology
              </Link>{' '}
              for the Smart Water Navigator, which includes information on the
              tool’s data sources and key assumptions. And, Ecolab’s
              sustainability team is here to help support you: please reach out
              to our{' '}
              <Link
                color="brand.primary"
                href="mailto:SmartWaterNavigator@ecolab.com"
              >
                support team
              </Link>
              .
            </Text>
          </FaqItem>
        </Accordion>
      </Stack>

      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};

Faq.displayName = 'Faq';
