import {
  Button,
  Flex,
  Heading,
  HStack,
  List,
  ListItem,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { mdiAlertOctagon, mdiDownloadBoxOutline } from '@mdi/js';
import classnames from 'classnames/bind';
import natsort from 'natsort';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  getFacilitiesList,
  getFacilitiesListExport,
} from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import { Icon } from 'src/components/Icon';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { NoFacilities } from 'src/components/NoFacilities';
import { SelectInput } from 'src/components/SelectInput';
import { useAuth } from 'src/hooks/useAuth';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { Facility } from 'src/types/v5.facility';
import styles from './Facilities.module.css';

const cx = classnames.bind(styles);

const FILTER_OPTIONS = {
  facilityName: 'Name',
  cityName: 'City Name',
  regionName: 'State Name',
  countryName: 'Country Name',
  sectorName: 'Sector Name',
  active: 'Status',
  modelingOnly: 'Modeling Only',
} as const;

type FacilitiesListItemProps = {
  facility: Facility;
};

const FacilitiesListItem: FC<FacilitiesListItemProps> = ({ facility }) => {
  return (
    <ListItem
      as={Link}
      className={cx('listItem')}
      p="2"
      bg="blackAlpha.200"
      to={{
        pathname: `/app/facilities/${facility.facilityId}`,
        state: {
          referrer: {
            pathname: `/app/facilities`,
            title: 'Facilities',
          },
        },
      }}
      borderRight="4px solid"
      borderColor={facility.active ? 'green.500' : 'gray.500'}
      _hover={{
        boxShadow: 'base',
      }}
    >
      <HStack width="full" flex="1 1 100%">
        <Flex flex="0 0 auto">
          <Text noOfLines={1}>
            {facility.facilityName} | {facility.cityName}, {facility.regionName}
          </Text>
        </Flex>
        {!facility.hasWrmData ? (
          <Flex alignItems="center" justifyContent="center" direction="column">
            <Tooltip label="No reporting year data" placement="right">
              <Icon path={mdiAlertOctagon} size="18px" color="red.500" />
            </Tooltip>
          </Flex>
        ) : null}
        <Spacer />
        <Flex flex="0 0 auto">
          <Text
            display={{ base: 'none', md: 'inline' }}
            color={facility.active ? 'green.500' : 'gray.500'}
          >
            {facility.active ? 'ACTIVE' : 'INACTIVE'}
          </Text>
        </Flex>
      </HStack>
    </ListItem>
  );
};

type FacilitiesProps = {};
export const Facilities: FC<FacilitiesProps> = () => {
  const { accountId } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortState, setSortState] = useState<{
    key: keyof typeof FILTER_OPTIONS;
    dir: 'asc' | 'desc';
  }>({
    key: 'facilityName',
    dir: 'asc',
  });

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['facilities'],
    () => getFacilitiesList()
  );

  const facilities =
    data?.status === 'success' && data?.value.data ? data?.value.data : [];

  const sortedFacilities = useDeepCompareMemo(() => {
    const sorter = natsort({ desc: sortState.dir === 'desc' });
    return facilities.sort((a, b) => {
      return sorter(
        `${(a[sortState.key] ?? '').toString()}`,
        `${(b[sortState.key] ?? '').toString()}`
      );
    });
  }, [facilities, sortState]);

  if (isLoading || isFetching) {
    return <LoadingSpinner block />;
  }

  if (error || !data || data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading facilities"
        message="We were unable to load your facilities successfully"
      />
    );
  }

  if (!facilities.length) {
    return <NoFacilities />;
  }

  const handleExport = async () => {
    setIsSubmitting(true);
    try {
      if (accountId) {
        const result = await getFacilitiesListExport();

        if (result.status === 'success') {
          window.open(result.value.data, '_self');
        }

        setIsSubmitting(false);
      } else {
        throw new Error();
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };
  const handleSortChange = (value: string) => {
    if (sortState.key === value) {
      setSortState({
        ...sortState,
        dir: sortState.dir === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortState({
        ...sortState,
        key: value as keyof typeof FILTER_OPTIONS,
      });
    }
  };

  return (
    <Stack direction="column" spacing="4" padding={6}>
      <Heading fontSize="32px" flex="1">
        Facilities
      </Heading>
      <Stack direction="row" alignItems="flex-end">
        <Stack
          direction="column"
          flex="0 0"
          flexBasis={{ base: 'full', md: 'xs' }}
        >
          <Heading className={cx('sortAndFilterTitle')} size="sm">
            Sort by:
          </Heading>
          <SelectInput
            items={Object.entries(FILTER_OPTIONS).map(([value, label]) => ({
              value,
              label,
            }))}
            value={sortState.key}
            placeholder="&nbsp;"
            onChange={handleSortChange}
          />
        </Stack>
        <Spacer />
        <Flex flex="0 0 auto">
          <Button
            variant="outline"
            borderColor="brand.primary"
            color="brand.primary"
            alignItems="center"
            leftIcon={<Icon path={mdiDownloadBoxOutline} size="24px" />}
            onClick={handleExport}
            isLoading={isSubmitting}
          >
            Export Facilities
          </Button>
        </Flex>
      </Stack>

      <List spacing="2">
        {sortedFacilities.map((f) => (
          <FacilitiesListItem facility={f} key={f.facilityId} />
        ))}
      </List>
    </Stack>
  );
};

Facilities.displayName = 'Facilities';
