import React, { FC } from 'react';
import { BoxProps, Flex, Spinner } from '@chakra-ui/react';

export type LoadingSpinnerProps = BoxProps & {
  block?: boolean;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = () => {
  return (
    <Flex
      height="full"
      width="full"
      flex="1 1 100%"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="md" />
    </Flex>
  );
};

LoadingSpinner.displayName = 'LoadingSpinner';
