import classnames from 'classnames/bind';
import React, { forwardRef } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelect, UseSelectStateChange } from 'downshift';
import { FieldError } from 'react-hook-form';
import styles from './SelectInput.module.css';

const cx = classnames.bind(styles);

export type ListItemType = {
  label: string;
  value: string;
};

export type SelectInputProps = {
  items: Array<ListItemType>;
  value: ListItemType['value'];
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  error?: FieldError;
  disabled?: boolean;
  loading?: boolean;
};

export const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  (
    {
      items,
      placeholder,
      value,
      onChange,
      className,
      error,
      disabled = false,
      loading = false,
    },
    ref
  ) => {
    const selectedItemObject = items.find(({ value: v }) => v === `${value}`);

    const handleSelectedItemChange = (
      changes: UseSelectStateChange<ListItemType>
    ) => {
      onChange(changes.selectedItem?.value ?? '');
    };

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
    } = useSelect({
      items,
      onSelectedItemChange: handleSelectedItemChange,
      initialSelectedItem: selectedItemObject,
      itemToString: (item) => item?.value ?? '',
    });

    return (
      <div className={cx('selectInput', className)} ref={ref}>
        <button
          type="button"
          {...getToggleButtonProps({ disabled })}
          className={cx('input', {
            empty: !selectedItemObject?.label,
            disabled,
            loading,
            inputError: !!error,
          })}
        >
          <span className={cx('text')}>
            {selectedItemObject?.label ?? placeholder}
          </span>
          {loading ? (
            <Spinner size="md" color="brand.primary" />
          ) : (
            <Icon
              path={mdiChevronDown}
              className={cx('icon', { open: isOpen })}
            />
          )}
        </button>
        {error && (
          <Flex justifyContent="flex-end">
            <Text as="span" size="sm" color="red.500">
              {error.message}
            </Text>
          </Flex>
        )}
        <div {...getMenuProps()} style={{ position: 'relative' }}>
          {isOpen && (
            <ul className={cx('menu')}>
              {items.map((item, index) => (
                <li
                  className={cx('menuItem', {
                    selected: item.value === selectedItemObject?.value,
                  })}
                  key={item.value}
                  {...getItemProps({ item, index })}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
);

SelectInput.displayName = 'SelectInput';
