import { useState } from 'react';

export type UseFormHelpProps = {
  helpKey: string;
  setHelpKey: (newKey: string) => void;
};

export function useFormHelp(): UseFormHelpProps {
  const [key, setKey] = useState<string>('');

  const setActiveKey = (newKey: string) => setKey(key !== newKey ? newKey : '');

  return { helpKey: key, setHelpKey: setActiveKey };
}
