import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  createElement,
  FC,
} from 'react';
import classnames from 'classnames/bind';

import styles from './Button.module.css';
import { LoaderIcon } from '../Icons';

const cx = classnames.bind(styles);

export type ButtonProps = {
  as?: 'button' | 'a';
  light?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'danger';
};

export const Button: FC<
  ButtonProps &
    (
      | ButtonHTMLAttributes<HTMLButtonElement>
      | AnchorHTMLAttributes<HTMLAnchorElement>
    )
> = ({
  as = 'button',
  light = false,
  variant = 'primary',
  children,
  className,
  isLoading = false,
  disabled = false,
  ...buttonProps
}) => {
  const isBaseButton = variant === 'primary' && !light;

  const props = {
    ...buttonProps,
    className: cx('button', variant, className, {
      light,
      isLoading,
    }),
    disabled: disabled || isLoading,
  };

  return createElement(
    as,
    { ...props },
    <>
      {children}
      {isBaseButton && isLoading && (
        <div className={cx('submittingIcon')}>
          <LoaderIcon height={16} width={16} />
        </div>
      )}
    </>
  );
};

export default Button;
