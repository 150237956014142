import {
  ChakraProvider as BaseChakraProvider,
  ChakraProviderProps,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { theme } from 'src/styles/theme';

export const ChakraProvider: FC<ChakraProviderProps> = (props) => {
  return <BaseChakraProvider theme={theme} {...props} />;
};
