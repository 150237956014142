import {
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import { mdiFolderRemoveOutline } from '@mdi/js';
import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { removeFacilityFolderAssociation } from 'src/clients/api/v5.facility';
import { Icon } from 'src/components/Icon';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { TruncatedFacility } from 'src/types/facility';

type FacilityItemProps = {
  folderId: number;
  facility: TruncatedFacility;
};

export const FacilityItem: FC<FacilityItemProps> = ({ folderId, facility }) => {
  const queryClient = useQueryClient();
  const [asyncState, setAsyncState] = useAsyncState();

  const handleRemoveFacility = async () => {
    try {
      setAsyncState({ status: 'loading' });
      const result = await removeFacilityFolderAssociation(
        facility.facilityId,
        folderId
      );

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      setAsyncState({
        status: 'success',
        message: 'Facility removed successfully!',
      });

      queryClient.refetchQueries(['folders']);
      queryClient.refetchQueries(['facilities']);
    } catch (e) {
      setAsyncState({
        status: 'error',
        message: e.message ?? 'There was an error removing your folder.',
      });
    }
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      p="2"
      borderBottom="1px solid"
      borderColor="gray.300"
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Flex flex={{ base: '1 1 100%', md: '1 1 auto' }} alignItems="center">
        <Text isTruncated>{facility.facilityName}</Text>
      </Flex>

      <Popover isLazy placement="left" closeOnBlur={false} closeOnEsc={false}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <IconButton
                variant="outline"
                size="sm"
                icon={<Icon path={mdiFolderRemoveOutline} size="24px" />}
                colorScheme="red"
                aria-label="Remove facility from folder"
                isLoading={asyncState.status === 'loading'}
                ml="2"
              />
            </PopoverTrigger>
            <PopoverContent outline="none">
              <PopoverArrow />
              <PopoverHeader bg="red.400" alignItems="center">
                Confirm Remove
              </PopoverHeader>
              <PopoverCloseButton />

              <PopoverBody>
                <Text mb="2" textAlign="center" width="full">
                  Are you sure you want to remove this facility from this
                  folder?
                </Text>
                <Button
                  colorScheme="red"
                  width="full"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveFacility();
                    onClose();
                  }}
                >
                  Yes, remove this facility
                </Button>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Stack>
  );
};
