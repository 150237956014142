import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './VerificationRequired.module.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { animated, useSpring } from 'react-spring';
import { FADE_IN_DOWN } from 'src/constants/animations';
import { Alert } from 'src/components/Alert';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { resendVerificationEmail } from 'src/clients/api/user';
import { useAuth } from 'src/hooks/useAuth';
import { useMaintenance } from 'src/hooks/useMaintenance';
import { Maintenance } from 'src/components/Maintenance';

const cx = classnames.bind(styles);

type VerificationRequiredProps = {};

export const VerificationRequired: FC<VerificationRequiredProps> = () => {
  const { hasActiveMaintenance } = useMaintenance();
  const { userId } = useAuth();
  const [state, setState] = useAsyncState();

  const animate = useSpring(FADE_IN_DOWN);

  const handleResend = async () => {
    try {
      setState({
        status: 'loading',
      });

      const result = await resendVerificationEmail(userId!);

      if (result.status === 'error') {
        throw new Error(result.value.errors[0].message);
      }

      setState({
        status: 'success',
        message: `Verification mail sent successfully. Please check your email.`,
      });
    } catch (e) {
      setState({
        status: 'error',
        message:
          e.message ?? `There was an error sending your verification email.`,
      });
    }
  };

  if (hasActiveMaintenance) {
    return <Maintenance />;
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <article
          className={cx('verificationRequired')}
          data-testid="verificationRequired"
        >
          <section>
            <div className={cx('verificationRequiredBox')}>
              <h2>Email Validation Required</h2>
              {state.status === 'success' ? (
                <animated.div style={animate}>
                  <Alert
                    variant="success"
                    message={state.message}
                    className={cx('success')}
                  />

                  <Link to={'/login'}>
                    <Button>Login</Button>
                  </Link>
                </animated.div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div className={cx('error')}>
                      <animated.div style={animate}>
                        <Alert
                          variant="danger"
                          message={
                            state.message ??
                            'There was an error resetting your password. Please try again.'
                          }
                        />
                      </animated.div>
                    </div>
                  )}

                  <div>
                    <Copy>
                      Please click the “Validate Email” link in the registration
                      confirmation email.
                    </Copy>
                    <div className={cx('actions')}>
                      <Button
                        onClick={handleResend}
                        isLoading={state.status === 'loading'}
                      >
                        Resend Validation Email
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </article>
      </div>
    </RouteContentScrollable>
  );
};

VerificationRequired.displayName = 'VerificationRequired';
