import { Flex, Heading, ScaleFade, Spinner, Stack } from '@chakra-ui/react';
import { mdiTableAlert, mdiTableCheck, mdiTableSync } from '@mdi/js';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getActiveBatchUpload } from 'src/clients/api/upload';
import { ActiveUploadCard } from 'src/components/ActiveUploadCard';
import { BlockError } from 'src/components/BlockError';
import { ActiveUploadRow } from 'src/types/upload';
import { RowResultsList } from './RowResultsList';

type UploadProps = {};

const SORT_WEIGHT = {
  processed: 0,
  validated: 1,
  validatedWithErrors: 2,
  processedWithErrors: 3,
};

type RowStatusMap = Record<keyof typeof SORT_WEIGHT, Array<ActiveUploadRow>>;

type DisplayStatus = {
  status: 'idle' | 'active' | 'error';
};

export const Upload: FC<UploadProps & RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const timeout = useRef<number | null>(null);
  const { id } = match.params;

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ['activeUploads', id],
    () => getActiveBatchUpload(id),
    { enabled: !!id }
  );

  const check = useCallback(() => window.setTimeout(refetch, 20000), [refetch]);

  const checking = isLoading || isFetching;
  const isSuccess = data?.status === 'success';

  useEffect(() => {
    if (!checking) {
      if (isSuccess) {
        const upload = data.value.data;
        const status = upload.status;

        if (status === 'unprocessed') {
          // Turn on polling because there are still unprocessed uploads
          timeout.current = check();
        }
      }
    }

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [data, isSuccess, check, checking]);

  if (isLoading) {
    return (
      <Flex flex="1 1 auto" alignItems="center" justifyContent="center">
        <Spinner size="lg" />
      </Flex>
    );
  }

  if (error || !data || data.status === 'error') {
    return (
      <BlockError
        retry={refetch}
        isLoading={isFetching}
        title="Error loading active upload"
        message="We were unable to load your active upload successfully"
      />
    );
  }

  const upload = data?.value.data;
  const filteredRows = upload?.rows.reduce<RowStatusMap>((acc, row) => {
    if (!acc[row.status]) {
      acc[row.status] = [];
    }

    acc[row.status].push(row);

    return acc;
  }, {} as RowStatusMap);

  return (
    <Flex direction="column" padding={6}>
      <Heading mb="2" fontSize="32px">
        Upload Status
      </Heading>
      <ScaleFade initialScale={0.95} in={true}>
        <ActiveUploadCard upload={upload}>
          <Stack gap="4">
            <RowResultsList
              variant="error"
              rows={[
                ...(filteredRows.processedWithErrors ?? []),
                ...(filteredRows.validatedWithErrors ?? []),
              ]}
              headerText="Conflicts"
              headerIcon={mdiTableAlert}
              uploadId={id}
            />
            <RowResultsList
              variant="processing"
              rows={[...(filteredRows.validated ?? [])]}
              headerText="Pending"
              headerIcon={mdiTableSync}
              uploadId={id}
            />
            <RowResultsList
              variant="success"
              rows={filteredRows.processed}
              headerText="Completed"
              headerIcon={mdiTableCheck}
              uploadId={id}
            />
          </Stack>
        </ActiveUploadCard>
      </ScaleFade>
    </Flex>
  );
};

Upload.displayName = 'Upload';
