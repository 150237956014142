import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './BlockError.module.css';
import { Button } from '../Button';
import { Copy } from '../Copy';

const cx = classnames.bind(styles);

export interface BlockErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  message?: string;
  retry?: () => void;
  isLoading?: boolean;
}

const DEFAULT_TITLE = 'There was an error.';
const DEFAULT_MESSAGE = `Your request could not be processed or resulted in an error. Please try again.`;

export const BlockError: FC<BlockErrorProps> = ({
  title = DEFAULT_TITLE,
  message = DEFAULT_MESSAGE,
  isLoading = false,
  retry,
}) => {
  return (
    <div className={cx('blockError')}>
      <Copy as="h2">{title}</Copy>
      <br />
      <Copy as="p">{message}</Copy>
      <br />
      {!!retry ? (
        <Button onClick={retry} isLoading={isLoading}>
          Refetch
        </Button>
      ) : null}
    </div>
  );
};

BlockError.displayName = 'BlockError';
