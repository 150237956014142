import React, { FC } from 'react';
import { VerticalTabContainer } from '../../VerticalTabContainer';
import classnames from 'classnames/bind';
import styles from './TrackProgressTabGroup.module.css';
import { AbsoluteVolume } from './Tabs/AbsoluteVolume';
import { ProductionBasis } from './Tabs/ProductionBasis';
import { RevenueBasis } from './Tabs/RevenueBasis';
import { AggregateResult } from 'src/types/aggregates';

export type TrackProgressTabGroupProps = {
  aggregate: AggregateResult;
  className?: string;
};

const TABS = [
  'Absolute Volume',
  'Intensity - Production Basis',
  'Intensity - Revenue Basis',
];

const cx = classnames.bind(styles);

export const TrackProgressTabGroup: FC<TrackProgressTabGroupProps> = ({
  aggregate,
  className,
}) => {
  return (
    <VerticalTabContainer
      tabs={TABS}
      className={cx('animate', 'fadeInDown', className)}
    >
      {(idx) => (
        <>
          {idx === 0 ? <AbsoluteVolume aggregate={aggregate} /> : null}
          {idx === 1 ? <ProductionBasis aggregate={aggregate} /> : null}
          {idx === 2 ? <RevenueBasis aggregate={aggregate} /> : null}
        </>
      )}
    </VerticalTabContainer>
  );
};

TrackProgressTabGroup.displayName = 'TrackProgressTabGroup';
