import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { createFolder, updateFolder } from 'src/clients/api/v5.folders';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folder } from 'src/types/folder';
import * as yup from 'yup';

type FolderMutateModalProps = {
  folder?: Folder;
  isOpen: boolean;
  onClose: () => void;
};

const createFolderFormSchema = yup.object({
  folderName: yup.string().defined('Required'),
});

const RESOLVER = yupResolver(createFolderFormSchema);

export const FolderMutateModal: FC<FolderMutateModalProps> = ({
  folder,
  isOpen = false,
  onClose,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { setFilterState } = useFilterAndSort();
  const [isLoading, setIsLoading] = useBoolean();

  const {
    register,
    formState: { isValid, isDirty, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: RESOLVER,
    defaultValues: {
      folderName: folder?.folderName ?? '',
    },
  });

  const handleCreateFolder = handleSubmit(async (values) => {
    try {
      setIsLoading.on();
      const result = folder?.folderId
        ? await updateFolder(folder.folderId, values.folderName)
        : await createFolder(values.folderName);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      toast({
        title: `Folder ${
          folder?.folderId ? 'edited' : 'created'
        } successfully!`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      setFilterState!('folderId', null);
      queryClient.refetchQueries(['folders']);
      queryClient.invalidateQueries(['aggregates']);

      onClose();
    } catch (e) {
      toast({
        title: e.message ?? 'There was an error deleting your folder.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading.off();
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="muted">
          {folder?.folderId ? 'Update' : 'Create'} Folder
        </ModalHeader>
        <ModalCloseButton tabIndex={4} />
        <ModalBody>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Folder Name</FormLabel>
            <Input
              placeholder=" "
              name="folderName"
              ref={register}
              tabIndex={1}
            />
            <FormHelperText fontSize="xs">
              Enter the name of your new folder.
            </FormHelperText>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent={'space-between'}>
          <Button
            color="brand.primary"
            variant="ghost"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="brand"
            isDisabled={!isDirty || (isDirty && !isValid)}
            isLoading={isLoading}
            onClick={handleCreateFolder}
          >
            {folder?.folderId ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

FolderMutateModal.displayName = 'FolderMutateModal';
