import { RouteProps } from 'src/components/Route';
import { Account } from './Account';
import { Contact } from './Contact';
import { Dashboard } from './Dashboard';
import { Faq } from './Faq';
import { ForgotPassword } from './ForgotPassword';
import { Invitation } from './Invitation';
import { HowItWorks } from './HowItWorks';
import { Login } from './Login';
import { Logout } from './Logout';
import { Registration } from './Registration';
import { ResetPassword } from './ResetPassword';
import { User } from './User';
import { VerificationRequired } from './VerificationRequired';
import { VerifyAccount } from './VerifyAccount';
import { Resources } from './Resources';
import { DevelopmentPartners } from './DevelopmentPartners';
import { About } from './About';
import { TakeAction } from './TakeAction';
import { Methodology } from './Methodology';
import { TermsOfService } from './TermsOfService';

const routes: Array<RouteProps> = [
  {
    path: '/dashboard',
    component: Dashboard,
    variant: 'protected',
  },
  {
    path: '/login',
    component: Login,
    variant: 'public',
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/register',
    component: Registration,
    variant: 'public',
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    variant: 'public',
  },
  {
    path: '/(reset-password|change-password)',
    component: ResetPassword,
    variant: 'public',
  },
  {
    path: '/verify-account',
    exact: true,
    component: VerificationRequired,
  },
  {
    path: '/verify-account/:code',
    exact: true,
    component: VerifyAccount,
  },
  {
    path: '/user',
    component: User,
    variant: 'protected',
  },
  {
    path: '/account',
    component: Account,
    variant: 'protected',
  },
  {
    path: '/invitation',
    component: Invitation,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/resources',
    component: Resources,
  },
  {
    path: '/development-partners',
    component: DevelopmentPartners,
  },
  {
    path: '/Methodology',
    component: Methodology,
  },
  {
    path: '/take-action',
    component: TakeAction,
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
  },
];

export default routes;
