import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import classnames from 'classnames/bind';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { ParsedGuide } from 'src/clients/api/v5.facility';
import { BlockError } from 'src/components/BlockError';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type ChecklistProps = {
  guide: ParsedGuide['checklist'];
};

const ChecklistItem: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children,
}) => {
  return (
    <AccordionItem border="none" mb="15px">
      <AccordionButton border="1px solid #eee">
        <AccordionIcon />
        <Box flex="1" textAlign="left" p="10px">
          <Heading as="h3" size="md">
            {title}
          </Heading>
        </Box>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Stack direction="column" spacing="15px" my="15px">
          {children}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
};

export const Checklist: FC<ChecklistProps> = ({ guide }) => {
  const sections = useMemo(() => {
    return [
      {
        title: 'Site Management',
        items: guide.SiteManagement?.CheckBox.filter(Boolean),
      },
      {
        title: 'Water Management Practices',
        items: guide.WaterManagementPractices?.CheckBox.filter(Boolean),
      },
      {
        title: 'Target Setting',
        items: guide.TargetSetting?.CheckBox.filter(Boolean),
      },
      {
        title: 'Water Stewardship',
        items: guide.WaterStewardship?.CheckBox.filter(Boolean),
      },
    ];
  }, [guide]);

  try {
    return (
      <div className={cx('facilityTargetTab')}>
        <Text
          as="h3"
          fontSize="32px"
          fontWeight="bold"
          lineHeight="48px"
          mb="26px"
        >
          Checklist
        </Text>
        <Accordion allowToggle>
          {sections.map((section) =>
            section.items?.length ? (
              <ChecklistItem key={section.title} title={section.title}>
                <List spacing={3}>
                  {section.items.map((node, i) =>
                    node ? (
                      <ListItem key={node.guideNodeId} fontSize="lg">
                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          lineHeight="1"
                          spacing="2"
                        >
                          <ListIcon
                            w="24px"
                            minW="24px"
                            boxSize="24px"
                            flex="0 0 24px"
                            as={() => (
                              <Box
                                w="24px"
                                minW="24px"
                                boxSize="24px"
                                border="2px solid"
                                borderColor="brand.blue"
                              />
                            )}
                            color="green.500"
                            mr="2"
                          />
                          <Box
                            flex="1 0 100%"
                            lineHeight={1.5}
                            dangerouslySetInnerHTML={{
                              __html: node.nodeValue,
                            }}
                          />
                        </Stack>
                      </ListItem>
                    ) : null
                  )}
                </List>
              </ChecklistItem>
            ) : null
          )}
        </Accordion>
      </div>
    );
  } catch (error) {
    return <BlockError />;
  }
};

Checklist.displayName = 'Checklist';
