import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  Hide,
  ListItem,
  Stack,
  Image,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { Raindrop } from 'src/components/Raindrop/Raindrop';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { useScrollToAnchor } from 'src/hooks/useScrollToAnchor';

export interface AboutProps extends React.HTMLAttributes<HTMLDivElement> {}

const KEY_MILESTONES = {
  2014: 'The Water Risk Monetizer was introduced to help businesses quantify the risk of increased operating costs from insufficient quantity of incoming water.',
  2015: 'New functionality added to monetize revenue at risk. Underlying water risk data sets were updated.',
  2017: 'New functionality added to monetize quality-related water risks. New user interface was launched.',
  2019: 'Launched the Smart Water Navigator to help users along all steps of setting and executing a water management strategy. Updated underlying global water risk datasets.',
  2021: 'Started integration of the Water Risk Monetizer with the Smart Water Navigator to create a seamless user experience. New functionality added to allow benchmarking and tracking performance over time.',
  2024: 'Completed unification of the Water Risk Monetizer and Smart Water Navigator into one tool: the Smart Water Navigator. Updated underlying water risk datasets.',
};

const TIMELINE_COLORS = [
  '#e6eef2',
  '#ccdde4',
  '#99bbca',
  '#6698af',
  '#337695',
  '#00547a',
];

export const About: FC<AboutProps> = () => {
  useScrollToAnchor();

  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/bottle_manufacturing.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            About the Smart Water Navigator
          </Heading>
        )}
        actions={() => (
          <Hide below="md">
            <ButtonGroup size="sm" mt="10" colorScheme="whiteAlpha">
              <Button variant="solid" as={Link} to="#overview">
                Overview
              </Button>
              <Button variant="solid" as={Link} to="#who">
                Audience
              </Button>
              <Button variant="solid" as={Link} to="#why">
                Approach
              </Button>
              <Button variant="solid" as={Link} to="#history">
                History
              </Button>
            </ButtonGroup>
          </Hide>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(10vh)' }}
        p="20px"
        pb="40px"
        sx={{
          '& > div:nth-child(even)': {
            bgColor: TIMELINE_COLORS[0],
            p: '20px',
          },
        }}
      >
        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            <a id="overview" href="#overview">
              Overview
            </a>
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Text fontSize="lg">
                The Smart Water Navigator is a publicly-available, online tool
                that helps organizations assess water risk, set informed,
                context-based water goals and develop a plan to achieve them.
                The tool helps increase water resilience and support responsible
                business growth by turning corporate water targets into real,
                on-the-ground results. By assessing the impact of declining
                water quantity and quality on operations, the Smart Water
                Navigator provides actionable information to help companies turn
                water risks into business strategies that enable growth. This is
                accomplished through a holistic approach to corporate water
                management, supported by a practical guide to smart, sustainable
                water practices at the facility level.
              </Text>
            </Container>
          </Box>
        </Box>

        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            <a id="who" href="#who">
              Who should use the Smart Water Navigator?
            </a>
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Stack direction="column" spacing="10">
                <Text fontSize="lg">
                  The tool is designed for use by a range of business
                  decision-makers, including:
                </Text>
                <UnorderedList pl="5">
                  <ListItem>
                    Individuals and teams who set enterprise business strategy
                    for corporates (supply chain, operations, quality,
                    engineering, sustainability)
                  </ListItem>
                  <ListItem>
                    Facility and operations managers (site level) – individuals
                    and teams responsible for improving performance at the local
                    level
                  </ListItem>
                  <ListItem>
                    Business consultants – third-party experts who help shape
                    corporate strategy
                  </ListItem>
                  <ListItem>
                    Nongovernmental organizations (NGOs) – organizations that
                    influence global water stewardship practices
                  </ListItem>
                </UnorderedList>
                <Text fontSize="lg">
                  The Smart Water Navigator uses targeted industry data for
                  almost 100 industries, from mining and construction to finance
                  and food service. Visit your dashboard and add a facility to
                  explore all the industries served by the tool.
                </Text>
                <Flex justifyContent="center">
                  <Button
                    as={Link}
                    variant="primary"
                    to="/app/facilities/new"
                    w={{ base: 'full', md: 'lg' }}
                  >
                    Add Facility
                  </Button>
                </Flex>
              </Stack>
            </Container>
          </Box>
        </Box>

        <Box>
          <Stack spacing="30px" maxW={1920 / 1.5}>
            <Heading as="h3" size="2xl" textAlign="center" flex="1" mb="20px">
              <a id="why" href="#why">
                Why the Smart Water Navigator?
              </a>
            </Heading>

            <Stack direction={{ base: 'column', md: 'row' }}>
              <Box flex="1 1 60%" pr="30px">
                <Text fontSize="lg" mb="20px">
                  Several water frameworks and tools provide a good starting
                  point for quantitative and systematic assessments of water
                  risk. The Smart Water Navigator incorporates data from these
                  tools to create an integrated view of targeted water risk data
                  and action plans to mitigate and adapt to those risks. This
                  allows users to understand how they are doing in terms of
                  smart water management, what impact that could have on their
                  business and how to improve their water management strategy,
                  all in one tool.
                </Text>
                <Text fontSize="lg">
                  The relatively low price of water often means the risks
                  associated with its use are overlooked. The Smart Water
                  Navigator helps businesses assess water-related risks in order
                  to understand the gap between what their business pays for
                  water and the potential costs of water risks to the business.
                  The tool also provides credible, actionable information that
                  businesses can use to make more informed decisions to protect
                  against water quantity and quality constraints to growth. The
                  Water Risk Monetizer has helped businesses quantify the risk
                  of increased operating costs due to insufficient water
                  quantity and quality. The Smart Water Navigator has allowed
                  users to set action plans to improve water management
                  practices in their enterprise. The Smart Water Navigator and
                  Water Risk Monetizer were combined into one tool in order to
                  create a seamless user experience along all points of creating
                  and executing a smart water management plan. The integration
                  of the Water Risk Monetizer and the Smart Water Navigator has
                  continued to enhance benefits for businesses in monetizing
                  risk and ensuring alignment with industry trends.
                </Text>
              </Box>
              <Box flex="0 0 40%">
                <Image src="/images/tablet-ui.jpeg" />
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box bgColor="white!important">
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            <a id="history" href="#history">
              History
            </a>
          </Heading>
          <Box maxW={1920 / 1.5} position="relative">
            <Box
              w="1px"
              bgColor="black"
              height="full"
              position="absolute"
              bottom="0"
              left={{
                base: '63px',
                lg: '50%',
              }}
              zIndex="1"
            />
            <Box
              position="absolute"
              width="12px"
              height="12px"
              backgroundImage="linear-gradient(to bottom right, transparent 50%, black 0),
                              linear-gradient(to top right, black 50%, transparent 0)"
              backgroundSize="50% 100%"
              backgroundRepeat="no-repeat"
              backgroundPosition="left, right"
              transform="rotate(180deg)"
              bottom="-5px"
              left={{
                base: '',
                lg: 'calc(50% - (12px / 2) + 1px)',
              }}
            />
            <Stack direction="column" spacing="10">
              {Object.entries(KEY_MILESTONES).map(([year, text], idx) => {
                const isEven = idx % 2;
                return (
                  <Flex
                    w="100%"
                    direction={{
                      base: 'row',
                      lg: isEven ? 'row-reverse' : 'row',
                    }}
                    key={year}
                  >
                    <Flex
                      flex={{ base: '0 0 40px', lg: '0 0 45%' }}
                      justifyContent={{
                        base: 'flex-start',
                        lg: isEven ? 'flex-start' : 'flex-end',
                      }}
                      alignItems="center"
                    >
                      <Text>{year}</Text>
                    </Flex>
                    <Flex
                      flex={{ base: '0 0 auto', lg: '0 0 10%' }}
                      alignItems="center"
                      justifyContent="center"
                      position="relative"
                    >
                      <Raindrop
                        boxSize="12"
                        border="8px solid white"
                        flex="0 0 48px"
                      />

                      <Box
                        h="1px"
                        bgColor="black"
                        position="absolute"
                        left={isEven ? '10px' : '50%'}
                        right={!isEven ? '10px' : '50%'}
                        zIndex="1"
                      />
                    </Flex>
                    <Flex
                      flex={{ base: '1 1 auto', lg: '0 0 45%' }}
                      alignItems="center"
                    >
                      <Text
                        w="full"
                        borderRadius="5px"
                        p="5"
                        bgColor={TIMELINE_COLORS[idx]}
                        color={idx > 2 ? 'white' : 'initial'}
                        boxShadow="base"
                        transition="all .2s ease-in-out"
                        _hover={{
                          transform: {
                            base: `translate(10px, 0)`,
                            lg: `translate(${isEven ? '-10px' : '10px'}, 0)`,
                          },
                          boxShadow: 'lg',
                        }}
                      >
                        {text}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Stack>
      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
