import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from '../Tabs.module.css';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { MappedReferenceData } from 'src/types/referenceData';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { FacilityTabFooter } from '../../Helpers/FacilityTabFooter';
import { FormHelpButton, FormHelpText } from '../../../FormHelp';
import { useFormHelp } from 'src/hooks/useFormHelp';

const cx = classnames.bind(styles);

type WaterQualityProps = {
  referenceData: MappedReferenceData;
  reportingYear?: ReportingYear;
  onSave: () => void;
  onCancel: () => void;
  className?: string;
};

export const WaterQuality: FC<WaterQualityProps> = ({
  referenceData,
  reportingYear,
  onSave,
  onCancel,
  className,
}) => {
  const formHelp = useFormHelp();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={cx('tabContent', className)}>
      <form>
        <div className={cx('formGroup')}>
          <h1 className={cx('formGroupTitle')}>Water Quality</h1>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                How would you rate the quality of incoming water for this year
                compared to previous years?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="incomingWaterQualityComparison"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="incomingWaterQualityComparison"
                  control={control}
                  defaultValue={
                    reportingYear?.input?.incomingWaterQualityComparison ?? ''
                  }
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options
                          .incomingWaterQualityComparisonOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.incomingWaterQualityComparison}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="incomingWaterQualityComparison"
              >
                Please indicate how you would rate the quality of incoming
                water. If incoming water quality is getting worse, you can
                specify the type of impairment below. If the quality is the same
                or getting better this will remove the incoming quality risk
                premium. If you are unsure, please select "Do not know".
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                What type of local water impairment, if any, is there for
                incoming water? (Check all that apply):
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="incomingWaterImpairmentSalinity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <div>
                  <Controller
                    name="incomingWaterImpairmentSalinity"
                    control={control}
                    defaultValue={
                      reportingYear?.input?.incomingWaterImpairmentSalinity ??
                      false
                    }
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.incomingWaterImpairmentSalinity}
                      >
                        A. Salinity
                      </CheckboxInput>
                    )}
                  ></Controller>
                  <Controller
                    name="incomingWaterImpairmentOrganics"
                    control={control}
                    defaultValue={
                      reportingYear?.input?.incomingWaterImpairmentOrganics ??
                      false
                    }
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.incomingWaterImpairmentOrganics}
                      >
                        B. Organics
                      </CheckboxInput>
                    )}
                  ></Controller>
                  <Controller
                    name="incomingWaterImpairmentSuspendedSolids"
                    control={control}
                    defaultValue={
                      reportingYear?.input
                        ?.incomingWaterImpairmentSuspendedSolids ?? false
                    }
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.incomingWaterImpairmentSuspendedSolids}
                      >
                        C. Suspended Solids
                      </CheckboxInput>
                    )}
                  ></Controller>
                  <Controller
                    name="incomingWaterImpairmentTaste"
                    control={control}
                    defaultValue={
                      reportingYear?.input?.incomingWaterImpairmentTaste ??
                      false
                    }
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        error={errors.incomingWaterImpairmentTaste}
                        className={cx('inputField', 'checkbox')}
                      >
                        D. Taste
                      </CheckboxInput>
                    )}
                  ></Controller>
                  <Controller
                    name="incomingWaterImpairmentOdor"
                    control={control}
                    defaultValue={
                      reportingYear?.input?.incomingWaterImpairmentTaste ??
                      false
                    }
                    render={(props) => (
                      <CheckboxInput
                        {...props}
                        className={cx('inputField', 'checkbox')}
                        error={errors.incomingWaterImpairmentOdor}
                      >
                        E. Odor
                      </CheckboxInput>
                    )}
                  ></Controller>
                </div>
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="incomingWaterImpairmentSalinity"
              >
                If local water quality is getting worse, please specify the type
                of local water impairment by checking all boxes that apply. If
                you do not know the type of impairment, leave all the boxes
                unchecked. This will influence the incoming quality risk
                calculation.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                Are there alternative sources of water available for this
                facility?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="alternativeWaterSourceQuestion"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="alternativeWaterSourceQuestion"
                  control={control}
                  defaultValue={
                    reportingYear?.input?.alternativeWaterSourceQuestion ?? ''
                  }
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={
                        referenceData.options
                          .alternativeWaterSourceQuestionOptions
                      }
                      placeholder="&nbsp;"
                      error={errors.alternativeWaterSourceQuestion}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="alternativeWaterSourceQuestion"
              >
                Please indicate whether there are alternative sources of water
                available for your facility. This will influence the incoming
                quality risk premium calculation.
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                If treating outgoing water on-site, is your water treatment
                facility close to capacity?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="outgoingWaterOnsiteTreatmentCapacity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="outgoingWaterOnsiteTreatmentCapacity"
                  control={control}
                  defaultValue={
                    reportingYear?.input
                      ?.outgoingWaterOnsiteTreatmentCapacity ?? ''
                  }
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={referenceData.options.thirdPartyCapacityOptions}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterOnsiteTreatmentCapacity}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="outgoingWaterOnsiteTreatmentCapacity"
              >
                Please indicate whether your on-site water treatment facility is
                close to capacity. On-site treatment facilities that are close
                to capacity will have an outgoing risk premium even if local
                water quality thresholds are not currently being breached. Your
                facility will also be more likely to realize increased operating
                costs in the future. If you are unsure, please select "Do Not
                Know".
              </FormHelpText>
            </div>
          </div>
          <div className={cx('row')}>
            <div className={cx('label')}>
              <Copy as="p" align="right">
                If sending outgoing water to a third party for treatment, is the
                third party close to capacity?
              </Copy>
              <FormHelpButton
                formHelp={formHelp}
                name="outgoingWaterOffsiteTreatmentCapacity"
              />
            </div>
            <div className={cx('inputGroup')}>
              <div className={cx('input')}>
                <Controller
                  name="outgoingWaterOffsiteTreatmentCapacity"
                  control={control}
                  defaultValue={
                    reportingYear?.input
                      ?.outgoingWaterOffsiteTreatmentCapacity ?? ''
                  }
                  render={(props) => (
                    <SelectInput
                      {...props}
                      className={cx('inputField')}
                      items={referenceData.options.thirdPartyCapacityOptions}
                      placeholder="&nbsp;"
                      error={errors.outgoingWaterOffsiteTreatmentCapacity}
                      onChange={(val) => props.onChange(val)}
                    />
                  )}
                />
              </div>
              <FormHelpText
                formHelp={formHelp}
                name="outgoingWaterOnsiteTreatmentCapacity"
              >
                Please indicate whether your third party water treatment
                facility is close to capacity. Third party treatment facilities
                that are close to capacity will have an outgoing risk premium
                even if local water quality thresholds are not currently being
                breached. Your facility will also be more likely to realize
                increased operating costs in the future. If you are unsure,
                please select "Do Not Know".
              </FormHelpText>
            </div>
          </div>
        </div>
      </form>
      <FacilityTabFooter onCancel={onCancel} onNext={onSave} />
    </div>
  );
};
