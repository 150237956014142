import { useState } from 'react';

export type AsyncStateType =
  | {
      status: 'idle';
    }
  | {
      status: 'loading';
    }
  | {
      status: 'success' | 'error';
      message: string;
    };

export function useAsyncState(state?: AsyncStateType) {
  return useState<AsyncStateType>(
    state ?? {
      status: 'idle',
    }
  );
}
