import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './BenchmarkingChart.module.css';
import { SelectInput } from 'src/components/SelectInput';
import { useFacilityRiskFilter } from 'src/hooks/useFacilityRiskFilter';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import {
  benchmarkingChartDataGenerator,
  BenchmarkingDisplayIntensity,
  BenchmarkingDisplayType,
  BENCHMARKING_INTENSITY_OPTIONS,
  BENCHMARKING_TYPE_OPTIONS,
} from 'src/utils/chart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const cx = classnames.bind(styles);

type BenchmarkingChartProps = {};

export const BenchmarkingChart: FC<BenchmarkingChartProps> = () => {
  const { filteredAggregates, hiddenAggregates } = useFacilityRiskFilter();
  const [displayState, setDisplayState] = useState<{
    type: BenchmarkingDisplayType;
    intensity: BenchmarkingDisplayIntensity;
    showOrgAvg: boolean;
    showSectorAvg: boolean;
  }>({
    type: 'Incoming',
    intensity: 'Production',
    showOrgAvg: true,
    showSectorAvg: true,
  });

  const chart = useDeepCompareMemo(
    () =>
      benchmarkingChartDataGenerator(
        filteredAggregates,
        hiddenAggregates,
        displayState
      ),
    [filteredAggregates, hiddenAggregates, displayState]
  );

  return (
    <div className={cx('benchmarkingChart')}>
      <header className={cx('header')}>
        <div className={cx('switch')}>
          <SelectInput
            items={BENCHMARKING_TYPE_OPTIONS.map((s) => ({
              label: s,
              value: s,
            }))}
            value={displayState.type}
            onChange={(value) =>
              setDisplayState({
                ...displayState,
                type: value as BenchmarkingDisplayType,
              })
            }
          />
        </div>
        <div className={cx('switch')}>
          <SelectInput
            items={BENCHMARKING_INTENSITY_OPTIONS.map((s) => ({
              label: s,
              value: s,
            }))}
            value={displayState.intensity}
            onChange={(v) =>
              setDisplayState({
                ...displayState,
                intensity: v as BenchmarkingDisplayIntensity,
              })
            }
          />
        </div>
      </header>
      <HighchartsReact highcharts={Highcharts} options={chart} />
      <div className={cx('legend')}>
        <div className={cx('legendItem')}>
          <div
            className={cx('toggle', { hidden: !displayState.showOrgAvg })}
            onClick={() =>
              setDisplayState({
                ...displayState,
                showOrgAvg: !displayState.showOrgAvg,
              })
            }
          >
            <span className={cx('swatch', 'org')}></span>
            &nbsp; Organization Average
          </div>
          {displayState.intensity === 'Revenue' ? (
            <div
              className={cx('toggle', { hidden: !displayState.showSectorAvg })}
              onClick={() =>
                setDisplayState({
                  ...displayState,
                  showSectorAvg: !displayState.showSectorAvg,
                })
              }
            >
              <span className={cx('swatch', 'sector')}></span>
              &nbsp; Sector Average
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
