import React, { FC } from 'react';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Stack,
  Box,
} from '@chakra-ui/react';
import { mdiAlertOutline } from '@mdi/js';
import { Icon } from '../Icon';
import { DEFAULT_MAINTENANCE_MESSAGES } from 'src/hooks/useMaintenance';

type MaintenanceProps = {
  message?: string;
};

export const Maintenance: FC<MaintenanceProps> = ({ message }) => {
  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="full"
    >
      <Stack direction="column" spacing="calc(5vh)" alignItems="center">
        <Box>
          <Icon
            path={mdiAlertOutline}
            size="120px"
            color="var(--chakra-colors-orange-300)"
          />
          <AlertTitle
            mt={4}
            mb={1}
            fontSize="4xl"
            color="var(--chakra-colors-orange-300)"
          >
            Undergoing Maintenance
          </AlertTitle>
        </Box>
        <AlertDescription
          maxWidth="md"
          border="1px solid"
          borderColor="var(--chakra-colors-orange-200)"
          bgColor="gray.50"
          borderRadius="md"
          p={8}
          boxShadow="base"
          sx={{ '& > a': { textDecor: 'underline' } }}
          dangerouslySetInnerHTML={{
            __html: message ?? DEFAULT_MAINTENANCE_MESSAGES.active,
          }}
        />
      </Stack>
    </Alert>
  );
};

Maintenance.displayName = 'Maintenance';
