import { WrappedResponse } from '../types';
import request from '../request';
import { Env } from '../../constants/env';
import { ReferenceData } from '../../types/referenceData';

export type ReferenceDataResponseType = WrappedResponse<ReferenceData>;
export function getReferenceData() {
  return request.get<ReferenceDataResponseType>(
    `${Env.apiUrl}/v5/reference-data/options`
  );
}

export type GetWrmBatchUploadTemplateResponseType = WrappedResponse<string>;
export function getWrmBatchUploadTemplate() {
  return request.get<GetWrmBatchUploadTemplateResponseType>(
    `${Env.apiUrl}/v5/reference-data/batch-upload-template`
  );
}
