import currency from 'currency.js';

export function asCommaSeparatedString(value: number, decimals: number = 0) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(value);
}

export function formatAsCurrency(
  value: currency.Any | number,
  opts?: currency.Options | undefined
) {
  return currency(value, opts).format();
}
