import React, { FC } from 'react';
import classnames from 'classnames/bind';
import {
  mdiAccountCircleOutline,
  mdiLogout,
  mdiAccountCogOutline,
  mdiAccountBoxOutline,
} from '@mdi/js';
import styles from './AccountAvatar.module.css';
import { useAuth } from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Icon } from '../Icon';

const cx = classnames.bind(styles);

type AccountAvatarProps = {};

export const AccountAvatar: FC<AccountAvatarProps> = () => {
  const { isAuthenticated, logout, fetchUser } = useAuth();

  const { data } = useQuery(['user', 'current'], fetchUser!, {
    enabled: !!isAuthenticated,
  });

  return (
    <div className={cx('accountAvatar')}>
      <button type="button" className={cx('trigger')}>
        <Icon path={mdiAccountCircleOutline} size="32px" />
      </button>
      <div className={cx('menu')}>
        <Flex
          justify-content="flex-start"
          whiteSpace="nowrap"
          flex="0 0 auto"
          padding="15px"
          minW="0"
        >
          {data?.status === 'success' ? (
            <Box flex="1 1 auto" minW="0">
              <Text
                fontWeight="semibold"
                fontSize="md"
                isTruncated
              >{`${data.value.data.firstName} ${data.value.data.lastName}`}</Text>
              <Text fontSize="xs" isTruncated>
                {data.value.data.companyName}
              </Text>
            </Box>
          ) : null}

          <Flex flex="0 0 auto">
            <Icon path={mdiAccountCircleOutline} size="32px" mt="2px" />
          </Flex>
        </Flex>
        <ul className={cx('links')}>
          <li className={cx('link')}>
            <Link type="button" className={cx('linkButton')} to="/user">
              <Icon
                path={mdiAccountBoxOutline}
                className={cx('linkButtonIcon')}
                size="18px"
              />
              Profile
            </Link>
          </li>
          <li className={cx('link')}>
            <Link type="button" className={cx('linkButton')} to="/account">
              <Icon
                path={mdiAccountCogOutline}
                className={cx('linkButtonIcon')}
                size="18px"
              />
              Account
            </Link>
          </li>
          <li className={cx('divider')}></li>
          <li className={cx('link')}>
            <button
              type="button"
              className={cx('linkButton')}
              onClick={() => logout && logout()}
            >
              <Icon
                path={mdiLogout}
                className={cx('linkButtonIcon')}
                size="18px"
              />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

AccountAvatar.displayName = 'AccountAvatar';
