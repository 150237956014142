import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import styles from './Account.module.css';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { Copy } from 'src/components/Copy';
import { useQuery, useQueryClient } from 'react-query';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { BlockError } from 'src/components/BlockError';
import Icon from '@mdi/react';
import { mdiDomain, mdiPlusCircleOutline } from '@mdi/js';
import { getUserAccounts } from 'src/clients/api/user';
import { Alert } from 'src/components/Alert';
import { UserInviteDialog } from 'src/components/UserInviteDialog';
import { useAuth } from 'src/hooks/useAuth';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { deleteUserFromAccount } from 'src/clients/api/account';
import { Button } from 'src/components/Button';
import { animated } from 'react-spring';
import { FADE_IN_DOWN } from 'src/constants/animations';
import { useMaintenance } from 'src/hooks/useMaintenance';
import { Maintenance } from 'src/components/Maintenance';

const cx = classnames.bind(styles);

type AccountProps = {};

const SHOW_INVITE = true;

export const Account: FC<AccountProps> = () => {
  const { hasActiveMaintenance } = useMaintenance();
  const { accountId, userId } = useAuth();
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [state, setState] = useAsyncState();
  const queryClient = useQueryClient();

  const { isLoading, error, data: accounts } = useQuery(
    ['user', userId!, 'accounts'],
    () => getUserAccounts(userId!),
    {
      enabled: !!userId,
    }
  );

  if (hasActiveMaintenance) {
    return <Maintenance />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const currentAccount = (accounts?.status === 'success'
    ? accounts.value.data
    : []
  ).find((a) => {
    return a.accountId === accountId;
  });

  if (error || accounts?.status !== 'success' || !currentAccount) {
    return <BlockError />;
  }

  const currentAccountUsers = currentAccount.users ?? [];
  currentAccountUsers.sort((a, b) =>
    `${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}` ? 1 : -1
  );

  const currentAccountUser = (currentAccount?.users
    ? currentAccount?.users
    : []
  ).find((u) => `${u.userId}` === userId);

  const removeUserFromAccount = async (uid: string) => {
    try {
      setState({ status: 'loading' });
      await deleteUserFromAccount(uid);
      setState({
        status: 'success',
        message: 'User removed from account successfully.',
      });
      queryClient.refetchQueries(['user', userId!, 'accounts']);
    } catch (e) {
      setState({
        status: 'error',
        message: 'Error removing user from account.',
      });
    }
  };

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <div className={cx('account')} data-testid="account">
          <aside>
            <div className={cx('sidearm')}>
              <Icon path={mdiDomain} size="128px" className={cx('icon')} />
              <div>
                <span className={cx('companyName')}>
                  {currentAccount.companyName}
                </span>
                <span className={cx('accountNumber')}>
                  ({currentAccount.accountNumber})
                </span>
              </div>
            </div>
          </aside>
          <section>
            <div className={cx('accountBox')}>
              <Copy as="h2">Account Management</Copy>
              {!currentAccountUser?.primary ? (
                <div className={cx('alert')}>
                  <Alert
                    variant="info"
                    message="You must be the admin user on the account."
                  />
                </div>
              ) : (
                <>
                  {state.status === 'error' && (
                    <div className={cx('alert', 'error')}>
                      <animated.div style={FADE_IN_DOWN}>
                        <Alert variant="danger" message={state.message} />
                      </animated.div>
                    </div>
                  )}
                  <div className={cx('actions')}>
                    {SHOW_INVITE ? (
                      <button
                        className={cx('basicButton', 'addFolder')}
                        onClick={() => setIsInvitingUser(true)}
                      >
                        <Icon
                          path={mdiPlusCircleOutline}
                          size="24px"
                          style={{ marginRight: '5px' }}
                        />
                        <span>INVITE USER</span>
                      </button>
                    ) : null}
                  </div>
                  {isInvitingUser ? (
                    <UserInviteDialog
                      isOpen={isInvitingUser}
                      onSave={() => setIsInvitingUser(false)}
                      onClose={() => setIsInvitingUser(false)}
                      accountId={currentAccount.accountId}
                    />
                  ) : null}
                  <div className={cx('accountUsers')}>
                    {currentAccount.users.map((u) => (
                      <div className={cx('accountUser')} key={u.userId}>
                        <div className={cx('info')}>
                          <span className={cx('name')}>
                            {u.lastName}, {u.firstName}
                          </span>
                          <span className={cx('email')}>{u.email}</span>
                        </div>
                        <div className={cx('actions')}>
                          {u.userId !== currentAccountUser.userId ? (
                            <>
                              {/* {!u.primary ? (
                                <button>Make Admin</button>
                              ) : (
                                <button>Remove Admin</button>
                              )} */}
                              <Button
                                onClick={() =>
                                  removeUserFromAccount(`${u.userId}`)
                                }
                                isLoading={state.status === 'loading'}
                              >
                                Remove User
                              </Button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </RouteContentScrollable>
  );
};

Account.displayName = 'Account';
