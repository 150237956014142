import set from 'lodash-es/set';
import { CalculatorPdfRequestDataType } from 'src/components/InvestmentCalculator/utils';
import { AggregateResult } from 'src/types/aggregates';
import { Guide, GuideNode } from 'src/types/guide';
import { Facility, TruncatedFacilities } from 'src/types/v5.facility';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { getAccountIdInStorage, urlWithAccountId } from '../helpers';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetFacilitiesListResponseType = WrappedResponse<
  TruncatedFacilities
>;
export function getFacilitiesList() {
  return request.get<GetFacilitiesListResponseType>(
    urlWithAccountId(`facilities`)
  );
}

export type GetFacilitiesListExportResponseType = WrappedResponse<string>;
export function getFacilitiesListExport() {
  return request.get<GetFacilitiesListExportResponseType>(
    urlWithAccountId(`facilities/export`)
  );
}

export type CreateWrmFacilityRequestPayloadType = {
  accountId: string;
  active: boolean;
  cityId: number;
  countryId: number;
  facilityName: string;
  modelingOnly: boolean;
  regionId: number;
  sector3Id: number;
  facilityLabel?: string;
};
export type CreateWrmFacilityResponsePayloadType = WrappedResponse<Facility>;
export function createWrmFacility(data: CreateWrmFacilityRequestPayloadType) {
  return request.post<
    CreateWrmFacilityResponsePayloadType,
    CreateWrmFacilityRequestPayloadType
  >(urlWithAccountId(`facilities`), data);
}

export type UpdateWrmFacilityRequestPayloadType = {
  accountId: string;
  active: boolean;
  cityId: number;
  countryId: number;
  facilityId: number;
  facilityName: string;
  modelingOnly: boolean;
  regionId: number;
  sector3Id: number;
  userId: number;
  facilityLabel?: string;
};
export function updateWrmFacility(
  facilityId: string | number,
  data: UpdateWrmFacilityRequestPayloadType
) {
  return request.put<unknown, UpdateWrmFacilityRequestPayloadType>(
    urlWithAccountId(`facilities/${facilityId}`),
    data
  );
}

export type GetFacilityResponseType = WrappedResponse<Facility>;
export function getFacility(facilityId: string) {
  return request.get<GetFacilityResponseType>(
    urlWithAccountId(`facilities/${facilityId}`)
  );
}

export function deleteFacility(facilityId: string | number) {
  return request.del<unknown>(urlWithAccountId(`facilities/${facilityId}`));
}

export type GetFacilityReportingYearsResponseType = WrappedResponse<
  Array<ReportingYear>
>;
export function getFacilityReportingYears(facilityId: string) {
  return request.get<GetFacilityReportingYearsResponseType>(
    urlWithAccountId(`facilities/${facilityId}/reporting-years`)
  );
}

export type GetFacilityReportingYearResponseType = WrappedResponse<
  ReportingYear
>;
export function getFacilityReportingYear(
  facilityId: string,
  reportingYearId: string
) {
  return request.get<GetFacilityReportingYearResponseType>(
    urlWithAccountId(
      `facilities/${facilityId}/reporting-years/${reportingYearId}`
    )
  );
}

export type CreateFacilityReportingYearRequestPayloadType = ReportingYear['input'];
export function createFacilityReportingYear(
  facilityId: string,
  data: CreateFacilityReportingYearRequestPayloadType,
  options: Partial<{
    query: Record<string, string>;
  }> = {}
) {
  return request.post<unknown, CreateFacilityReportingYearRequestPayloadType>(
    urlWithAccountId(
      `facilities/${facilityId}/reporting-years${
        options?.query
          ? `?${new URLSearchParams(options.query).toString()}`
          : ''
      }`
    ),
    {
      ...data,
      accountId: `${getAccountIdInStorage()}`,
    }
  );
}

export type UpdateFacilityReportingYearRequestPayloadType = ReportingYear['input'];
export function updateFacilityReportingYear(
  facilityId: string,
  reportingYearId: string,
  data: UpdateFacilityReportingYearRequestPayloadType
) {
  return request.put<unknown, UpdateFacilityReportingYearRequestPayloadType>(
    urlWithAccountId(
      `facilities/${facilityId}/reporting-years/${reportingYearId}`
    ),
    {
      ...data,
      accountId: `${getAccountIdInStorage()}`,
    }
  );
}

export function deleteFacilityReportingYear(
  facilityId: string | number,
  reportingYearId: string | number
) {
  return request.del<unknown>(
    urlWithAccountId(
      `facilities/${facilityId}/reporting-years/${reportingYearId}`
    )
  );
}

export type AddFacilityFolderAssociationResponseType = WrappedResponse<{}>;
export function addFacilityFolderAssociation(
  facilityId: number,
  folderId: number
) {
  return request.post<RemoveFacilityFolderAssociationResponseType, {}>(
    urlWithAccountId(`folders/${folderId}/facilities`),
    { facilityId }
  );
}

export type RemoveFacilityFolderAssociationResponseType = WrappedResponse<{}>;
export function removeFacilityFolderAssociation(
  facilityId: number,
  folderId: number
) {
  return request.del<RemoveFacilityFolderAssociationResponseType>(
    urlWithAccountId(`folders/${folderId}/facilities/${facilityId}`)
  );
}

export type GenerateFacilityPDFReportResponseType = WrappedResponse<string>;
export type GenerateFacilityPDFReportRequestType = {
  facilityRank: number;
  incomingSvgChart: string;
  outgoingSvgChart: string;
  revenueSvgChart: string;
  riskFactorSvgChart: string;
  riskSourceSvgChart: string;
  waterWithdrawalSvgChart: string;
  aggregateResult: AggregateResult;
};
export function generateFacilityPDFReport(
  facilityId: number,
  aggregateResult: AggregateResult,
  data: Omit<
    GenerateFacilityPDFReportRequestType,
    'reportingYear' | 'aggregateResult'
  >
) {
  return request.post<
    GenerateFacilityPDFReportResponseType,
    GenerateFacilityPDFReportRequestType
  >(urlWithAccountId(`facilities/${facilityId}/reports/pdf`), {
    ...data,
    aggregateResult,
  });
}

export type GenerateFacilityInvestmentPDFReportResponseType = WrappedResponse<
  string
>;
export type GenerateFacilityInvestmentPDFReportRequestType = CalculatorPdfRequestDataType;
export function generateFacilityInvestmentPDFReport(
  facilityId: number,
  data: GenerateFacilityInvestmentPDFReportRequestType
) {
  return request.post<
    GenerateFacilityInvestmentPDFReportResponseType,
    GenerateFacilityInvestmentPDFReportRequestType
  >(
    urlWithAccountId(`facilities/${facilityId}/investment-calculator/pdf`),
    data
  );
}

export type GetFacilityGuideResponseType = WrappedResponse<Array<Guide>>;
export function getFacilityGuide(facilityId: number, reportingYearId: string) {
  return request.get<GetFacilityGuideResponseType>(
    urlWithAccountId(
      `facilities/${facilityId}/reporting-years/${reportingYearId}/guides`
    )
  );
}

export type ParsedGuide = {
  BusinessCase: {
    Content: GuideNode;
    Enterprise: ParsedGuideBusinessCaseSubSection;
    Individual: ParsedGuideBusinessCaseSubSection;
  };
  CaseStudies: {
    Header: ParsedGuideNodeList<GuideNode>;
    Content: ParsedGuideNodeList<GuideNode>;
  };
  Scenario: {
    Header: GuideNode;
    Content: GuideNode;
  };
  ToolsAndResources: {
    Step: ParsedGuideNodeList<{
      Description: GuideNode;
      Examples: ParsedGuideBulletList;
      Tools: ParsedGuideBulletList | GuideNode;
    }>;
  };
  checklist: {
    SiteManagement?: ParsedGuideCheckList;
    WaterManagementPractices?: ParsedGuideCheckList;
    TargetSetting?: ParsedGuideCheckList;
    WaterStewardship?: ParsedGuideCheckList;
  };
};

export type ParsedGuideBusinessCaseSubSection = {
  SubHeaderBelowContent: GuideNode;
  Tangible: ParsedGuideBulletList;
  Intangible: ParsedGuideBulletList;
};

export type ParsedGuideBulletList = {
  Bullet: ParsedGuideNodeList<GuideNode>;
};

export type ParsedGuideCheckList = {
  CheckBox: ParsedGuideNodeList<GuideNode>;
};

export type ParsedGuideNodeList<T> = Array<T | null>;

export function parseGuide(guide: Guide): ParsedGuide {
  const tree = guide.guideNodes.reduce<ParsedGuide>((acc, node) => {
    return set(acc, node.nodeName, node);
  }, {} as ParsedGuide);

  return tree;
}
