import { Transfer } from 'src/types/transfer';
import { urlWithAccountId } from '../helpers';
import request from '../request';
import { WrappedResponse } from '../types';

export type GetTransfersResponseType = WrappedResponse<Array<Transfer>>;
export function getTransfers() {
  return request.get<GetTransfersResponseType>(urlWithAccountId(`transfers`));
}

export type CreateTransferRequestType = {};
export type CreateTransferResponseType = WrappedResponse<{}>;
export function createTransfer(folderId: number, email: string) {
  return request.post<CreateTransferResponseType, CreateTransferRequestType>(
    urlWithAccountId(`transfers`),
    {
      assetId: folderId,
      transferToUsername: email,
      type: 'folder',
    }
  );
}

export type UpdateTransferRequestType = Transfer;
export type UpdateTransferResponseType = WrappedResponse<{}>;
export function updateTransfer(transferId: string, data: Transfer) {
  return request.put<UpdateTransferResponseType, UpdateTransferRequestType>(
    urlWithAccountId(`transfers/${transferId}`),
    data
  );
}

export type DeleteTransferResponseType = WrappedResponse<{}>;
export function deleteTransfer(transferId: string) {
  return request.del<DeleteTransferResponseType>(
    urlWithAccountId(`transfers/${transferId}`)
  );
}
