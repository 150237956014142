import classnames from 'classnames/bind';
import React, { FC, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import {
  createFacilityReportingYear,
  getFacilityReportingYears,
} from 'src/clients/api/v5.facility';
import { getUserIdInStorage } from 'src/clients/helpers';
import { Copy } from 'src/components/Copy';
import { FacilityFormSchema } from 'src/components/FacilityForm/validation';
import { WrmForm } from 'src/components/FacilityForm/WrmForm';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { UploadFlowError } from 'src/constants/errors';
import { REPORTING_YEARS } from 'src/constants/years';
import { useAsyncFormState } from 'src/hooks/useAsyncFormState';
import {
  ParsedUploadRowErrors,
  ParsedUploadRowResults,
} from 'src/types/upload';
import styles from './ReportingYearCreate.module.css';

const cx = classnames.bind(styles);

type ReportingYearCreateProps = {};

export const ReportingYearCreate: FC<
  ReportingYearCreateProps & RouteComponentProps<{ id: string }>
> = ({ match, history }) => {
  const { id } = match.params;
  const { state } = useLocation<
    | {
        uploadResults?: {
          rowResults: ParsedUploadRowResults;
          rowErrors: ParsedUploadRowErrors;
          rowId: string;
          errorType: UploadFlowError;
        };
      }
    | undefined
  >();

  const uploadResults = state?.uploadResults;

  const { setState, resetState } = useAsyncFormState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (resetState) {
      resetState();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isLoading } = useQuery(
    ['facilities', id, 'reportingYears'],
    () => getFacilityReportingYears(id)
  );

  if (isLoading) {
    return (
      <div className={cx('createFacility', 'loading')}>
        <LoadingSpinner block />
      </div>
    );
  }

  const onSave = async (values: FacilityFormSchema) => {
    if (setState) {
      try {
        setState('saveState', { status: 'loading' });

        const assessmentEntries = Object.entries(
          values.assessment.assessmentAnswers
        );

        const cleanedEntries = assessmentEntries.filter(
          ([_key, value]) => !!value
        );

        const assessment =
          cleanedEntries.length > 0 && cleanedEntries.length < 13
            ? null
            : {
                ...values.assessment,
                assessmentAnswers: assessmentEntries.map(
                  ([questionId, questionChoiceId]) => ({
                    questionId,
                    questionChoiceId,
                  })
                ),
              };

        const result = await createFacilityReportingYear(
          id,
          {
            ...values,
            userId: `${getUserIdInStorage()}`,
            projectedFacilityOutputIncrease: Number(
              values.projectedFacilityOutputIncrease
            ),
            assessment,
          },
          {
            ...(uploadResults?.rowId
              ? {
                  query: {
                    batchUploadRowId: uploadResults.rowId,
                  },
                }
              : {}),
          }
        );

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState('saveState', {
          status: 'success',
          message: 'Facility created successfully!',
        });

        if (uploadResults) {
          queryClient.refetchQueries(['activeUploads']);
        }

        queryClient.invalidateQueries(['facilities', id, 'reportingYears']);
        queryClient.invalidateQueries(['aggregates']);

        history.push(`/app/facilities/${id}`);
      } catch (e) {
        setState('saveState', {
          status: 'error',
          message: 'There was an error saving your facility.',
        });
      }
    }
  };

  const takenYears =
    (data &&
      data.status === 'success' &&
      data.value.data.map((ry) => ry.reportingYear)) ||
    [];

  return (
    <>
      <header className={cx('header')}>
        <Copy as="h2">Add Reporting Year</Copy>

        <Copy as="p">
          Your privacy is important to us. Precautions are in place to protect
          your information against any misuse. Please refer to the{' '}
          <a href="https://www.ecolab.com/privacy-policy">PRIVACY NOTICE</a> for
          additional details.
        </Copy>
      </header>
      <WrmForm
        hydrateWith={uploadResults?.rowResults}
        availableYears={REPORTING_YEARS.filter((x) => !takenYears.includes(x))}
        onSave={onSave}
        uploadErrorType={uploadResults?.errorType}
      />
    </>
  );
};

ReportingYearCreate.displayName = 'ReportingYearCreate';
