import { Flex, Stack, Text } from '@chakra-ui/react';
import { mdiHistory, mdiTransferDown, mdiTransferUp } from '@mdi/js';
import React, { FC, useMemo } from 'react';
import { Icon } from 'src/components/Icon';
import { Transfer } from 'src/types/transfer';
import { TransferListItem } from './TransferListItem';

type TransferListProps = {
  direction?: 'in' | 'out';
  transfers: Array<Transfer>;
};

export const TransferList: FC<TransferListProps> = ({
  direction,
  transfers = [],
}) => {
  const display = useMemo(() => {
    switch (direction) {
      case 'in':
        return {
          title: 'Incoming',
          icon: mdiTransferDown,
          color: 'gray' as const,
        };
      case 'out':
        return {
          title: 'Outgoing',
          icon: mdiTransferUp,
          color: 'gray' as const,
        };
      default:
        return {
          title: 'Past',
          icon: mdiHistory,
          color: 'gray' as const,
        };
    }
  }, [direction]);

  return (
    <Stack
      direction="column"
      spacing={0}
      border="1px solid"
      borderColor={`${display.color}.300`}
      borderBottom="none"
    >
      <Flex
        bgColor={`${display.color}.100`}
        p="2"
        alignItems="center"
        borderBottom="1px solid"
        borderColor={`${display.color}.300`}
      >
        <Icon
          path={display.icon}
          size="24px"
          color={`${display.color}.500`}
          mr="2"
        />
        <Text fontWeight="semibold" color={`${display.color}.500`}>
          {display.title} Transfers
        </Text>
      </Flex>

      {transfers.map((transfer) => (
        <TransferListItem
          key={transfer.transferId}
          transfer={transfer}
          direction={direction}
          color={display.color}
        />
      ))}
    </Stack>
  );
};
