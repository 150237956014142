import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import { loginFormSchema, LoginFormSchemaPayload } from 'src/clients/auth';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { Maintenance } from 'src/components/Maintenance';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { TextInput } from 'src/components/TextInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useAuth } from 'src/hooks/useAuth';
import { useMaintenance } from 'src/hooks/useMaintenance';
import { useQueryParams } from 'src/hooks/useQueryParams';
import styles from './Login.module.css';

const cx = classnames.bind(styles);

const RESOLVER = yupResolver<LoginFormSchemaPayload>(loginFormSchema);

type LoginPropsType = RouteComponentProps<
  {},
  {},
  {
    referrer: string;
  }
>;

export const Login: FC<LoginPropsType> = ({ location }) => {
  const { hasActiveMaintenance } = useMaintenance();
  const params = useQueryParams();
  const reason = params.get('r');
  const history = useHistory();
  const [shouldShowExpiredBanner, setShouldShowExpiredBanner] = useState(
    reason === 'sess_exp'
  );
  const { login } = useAuth();

  const { handleSubmit, errors, register } = useForm<LoginFormSchemaPayload>({
    resolver: RESOLVER,
  });

  const [state, setState] = useAsyncState();

  const animate = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -10px)' },
    config: config.molasses,
  });

  const onSubmit = async ({ email, password }: LoginFormSchemaPayload) => {
    setState({
      status: 'loading',
    });

    if (login) {
      try {
        setShouldShowExpiredBanner(false);
        setState({
          status: 'loading',
        });

        const result = await login({ email, password });

        if (result.status === 'error') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: 'Login successful.',
        });

        history.push(location?.state?.referrer ?? '/');
      } catch (e) {
        setState({
          status: 'error',
          message: e.message ?? 'There was an error logging you in.',
        });
      }
    }
  };

  if (hasActiveMaintenance) {
    return <Maintenance />;
  }

  return (
    <RouteContentScrollable className={cx('scrollable')}>
      <div>
        <div className={cx('login')} data-testid="login">
          <aside>
            <div className={cx('sidearm')}>
              <Copy color="white" as="h2">
                Welcome to the Smart Water Navigator
              </Copy>
              <p>New User?</p>
              <Button
                light
                variant="secondary"
                onClick={() => history.push('/register')}
              >
                Register
              </Button>
            </div>
          </aside>
          <section>
            <div className={cx('loginBox')}>
              <Copy as="h2">Login</Copy>
              {shouldShowExpiredBanner && (
                <div className={cx('error')}>
                  <animated.div style={animate}>
                    <Alert
                      variant="danger"
                      message="Your session has expired. Please log in again."
                    />
                  </animated.div>
                </div>
              )}
              {state.status === 'error' && (
                <div className={cx('error')}>
                  <animated.div style={animate}>
                    <Alert
                      variant="danger"
                      message="There was an error signing you in. Please try again."
                    />
                  </animated.div>
                </div>
              )}

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={cx('loginForm')}
              >
                <TextInput
                  type="text"
                  name="email"
                  ref={register}
                  placeholder="Email address"
                  autoComplete="off"
                  error={errors.email}
                  className={cx('input')}
                />

                <TextInput
                  type="password"
                  name="password"
                  ref={register}
                  placeholder="Password"
                  autoComplete="off"
                  error={errors.password}
                  className={cx('input')}
                />

                <div className={cx('forgotPasswordBox')}>
                  <Link
                    to={'/forgot-password'}
                    className={cx('forgotPassword')}
                  >
                    Forgot Password?
                  </Link>
                </div>

                <Button
                  type="submit"
                  className={cx('buttonSubmit')}
                  isLoading={state.status === 'loading'}
                >
                  Login
                </Button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </RouteContentScrollable>
  );
};
