import React, { createContext, useContext, useState } from 'react';

export type FilterAndSortContextMethods = {
  setFilterState: (
    key: keyof FilterAndSortContextPropsType,
    value: string | null
  ) => void;
};

export type FilterAndSortContextPropsType = Partial<{
  folderId: string | null;
  countryId: string;
  label: string;
  sectorId: string;
  sortId: string;
}>;

type FilterAndSortContextProps = FilterAndSortContextMethods &
  FilterAndSortContextPropsType;

const FilterAndSortContext = createContext<Partial<FilterAndSortContextProps>>(
  {}
);

function FilterAndSortProvider(props: any) {
  const [internalFilterState, setInternalFilterState] = useState<
    FilterAndSortContextPropsType
  >();

  const setFilterState = (
    key: keyof FilterAndSortContextPropsType,
    value: string | null
  ) => {
    if (key === 'folderId') {
      return setInternalFilterState({
        [key]: value,
      });
    }

    return setInternalFilterState({
      ...internalFilterState,
      [key]: value,
    });
  };

  return (
    <FilterAndSortContext.Provider
      value={{
        ...internalFilterState,
        setFilterState,
      }}
      {...props}
    />
  );
}

const useFilterAndSort = () => useContext(FilterAndSortContext);

export { FilterAndSortProvider, useFilterAndSort };
