import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';

const Button: ComponentSingleStyleConfig = {
  baseStyle: {},
  variants: {
    brand: {
      bg: 'brand.primary',
      color: 'white',
      border: '1px solid',
      borderColor: 'brand.primary',

      _hover: {
        bg: 'brand.secondary',
        color: 'white',
      },
    },
    primary: {
      position: 'relative',
      border: '2px solid',
      transition: 'all 200ms',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: '1rem',
      padding: '0.75rem 40px',
      cursor: 'pointer',
      textDecoration: 'none',
      boxShadow: 'base',
      bg: 'brand.darkBlue',
      borderColor: 'brand.darkBlue',
      color: 'white',

      _hover: {
        boxShadow: 'md',
      },

      _active: {
        boxShadow: 'inner',
      },

      _focus: {
        outline: '-webkit-focus-ring-color auto 0',
      },

      _disabled: {
        cursor: 'not-allowed',
        boxShadow: 'none',
        bgColor: 'gray.300',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};

export default Button;
