import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './FilterAndSort.module.css';
import { SelectInput } from 'src/components/SelectInput';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folders } from 'src/types/folder';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { SORTING_OPTIONS } from 'src/hooks/useFilterAndSort/utils';
import { AggregateResults } from 'src/types/aggregates';

const cx = classnames.bind(styles);

const SORT = (a: FilterOption, b: FilterOption) => (a.label > b.label ? 1 : -1);

type FilterOption = { label: string; value: string };
type FilterData = {
  countries: Array<FilterOption>;
  sectors: Array<FilterOption>;
};

type FilterAndSortProps = {
  aggregates: AggregateResults;
  folders: Folders;
  hideSort?: boolean;
};

export const FilterAndSort: FC<FilterAndSortProps> = ({
  aggregates,
  folders,
  hideSort = false,
}) => {
  const { setFilterState, ...filterState } = useFilterAndSort();

  const filterData = useDeepCompareMemo(() => {
    const countriesDedupe = new Set();
    const sectorsDedupe = new Set();
    const result = aggregates.reduce<FilterData>(
      (acc, aggregate) => {
        if (
          aggregate.facility.countryId &&
          aggregate.facility.countryName &&
          !countriesDedupe.has(
            `${aggregate.facility.countryId}-${aggregate.facility.countryName}`
          )
        ) {
          countriesDedupe.add(
            `${aggregate.facility.countryId}-${aggregate.facility.countryName}`
          );
          acc.countries.push({
            value: `${aggregate.facility.countryId}`,
            label: aggregate.facility.countryName,
          });
        }

        if (
          aggregate.facility.sector3Id &&
          aggregate.facility.sectorName &&
          !sectorsDedupe.has(
            `${aggregate.facility.sector3Id}-${aggregate.facility.sectorName}`
          )
        ) {
          sectorsDedupe.add(
            `${aggregate.facility.sector3Id}-${aggregate.facility.sectorName}`
          );
          acc.sectors.push({
            value: `${aggregate.facility.sector3Id}`,
            label: aggregate.facility.sectorName,
          });
        }

        return acc;
      },
      {
        countries: [],
        sectors: [],
      }
    );

    result.countries.sort(SORT);
    result.sectors.sort(SORT);

    return result;
  }, [aggregates]);

  return (
    <div className={cx('filterAndSort')}>
      <div className={cx('filtering')}>
        <header className={cx('filterAndSortTitle')}>Filter by:</header>
        <div className={cx('options')}>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Folders', value: '' },
                ...folders.map((f) => ({
                  label: f.folderName,
                  value: `${f.folderId}`,
                })),
              ]}
              value={filterState.folderId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('folderId', value);
              }}
            />
          </div>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Countries', value: '' },
                ...filterData.countries,
              ]}
              value={filterState.countryId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('countryId', value);
              }}
            />
          </div>
          <div className={cx('filterOption')}>
            <SelectInput
              items={[
                { label: 'All Sectors', value: '' },
                ...filterData.sectors,
              ]}
              value={filterState.sectorId ?? ''}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('sectorId', value);
              }}
            />
          </div>
        </div>
      </div>
      {!hideSort ? (
        <div className={cx('sorting')}>
          <header className={cx('filterAndSortTitle')}>Sort by:</header>
          <div className={cx('sortOption')}>
            <SelectInput
              items={[...SORTING_OPTIONS]}
              value={filterState.sortId ?? '2'}
              placeholder="&nbsp;"
              onChange={(value) => {
                setFilterState && setFilterState('sortId', value);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

FilterAndSort.displayName = 'FilterAndSort';
