import { useQuery } from 'react-query';
import { checkVersion } from 'src/clients/api/version';
import { Env } from 'src/constants/env';

export const DEFAULT_MAINTENANCE_MESSAGES = {
  active: 'Smart Water Navigator is currently under maintenance.',
  future: 'Smart Water Navigator will be under maintenance soon.',
};

export function useMaintenance() {
  const { data } = useQuery(['app', 'version'], checkVersion!, {
    enabled: !Env.underMaintenance && !Env.futureMaintenance && !!checkVersion,
  });

  const hasActiveMaintenance =
    Env.underMaintenance ||
    (data?.status === 'success' && data.value.data.maintenance === true);

  const hasFutureMaintenance =
    Env.futureMaintenance ||
    (data?.status === 'success' &&
      data.value.data.upcomingMaintenance === true);

  let message = '';
  if (Env.underMaintenance) {
    message =
      Env.underMaintenanceMessage || DEFAULT_MAINTENANCE_MESSAGES.active;
  } else if (Env.futureMaintenance) {
    message =
      Env.futureMaintenanceMessage || DEFAULT_MAINTENANCE_MESSAGES.future;
  } else if (
    data?.status === 'success' &&
    data.value.data.maintenance === true
  ) {
    message =
      data.value.data.message !== ''
        ? data.value.data.message
        : DEFAULT_MAINTENANCE_MESSAGES.active;
  } else if (
    data?.status === 'success' &&
    data.value.data.upcomingMaintenance === true
  ) {
    message =
      data.value.data.message !== ''
        ? data.value.data.message
        : DEFAULT_MAINTENANCE_MESSAGES.future;
  }

  // const hasUpgrade =
  //   data?.status === 'success' &&
  //   data.value.data.upgrade === true;

  return {
    hasActiveMaintenance,
    hasFutureMaintenance,
    maintenanceMessage: message,
  };
}
