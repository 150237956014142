import React, { FC } from 'react';
import classnames from 'classnames/bind';
import { UseFormHelpProps } from 'src/hooks/useFormHelp';
import Icon from '@mdi/react';
import { mdiWindowClose } from '@mdi/js';

import styles from './FormHelp.module.css';

const cx = classnames.bind(styles);

export const FormHelpButton: FC<{
  name: string;
  formHelp: UseFormHelpProps;
}> = ({ name, formHelp }) => {
  return (
    <button
      type="button"
      className={cx('button')}
      onClick={() => formHelp.setHelpKey(name)}
    >
      ?
    </button>
  );
};

export const FormHelpText: FC<{
  name: string;
  formHelp: UseFormHelpProps;
}> = ({ name, formHelp, children }) => {
  return formHelp.helpKey === name ? (
    <div className={cx('text')}>
      <span className={cx('close')} onClick={() => formHelp.setHelpKey('')}>
        <Icon path={mdiWindowClose} className={cx('closeIcon')} />
      </span>
      {children}
    </div>
  ) : null;
};

export const FormHelpTooltip: FC<{ text: string }> = ({ text }) => {
  return (
    <div className={cx('tooltip')}>
      <div className={cx('button')}>?</div>
      <div className={cx('top')}>
        <span>{text}</span>
        <i></i>
      </div>
    </div>
  );
};
