import { ProcessedFacility } from 'src/types/processedFacility';
import { DEFAULT_CHART } from './utils';

export function incomingRiskChartDataGenerator({
  attributes,
}: ProcessedFacility['charts']['incomingWaterRiskChart']): Highcharts.Options {
  return {
    ...DEFAULT_CHART,
    chart: {
      ...DEFAULT_CHART.chart,
      type: 'column',
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: ['1 Year', '3 Year', '5 Year', '10 Year'],
    },
    yAxis: {
      title: {
        text: 'Value (USD per year)',
      },
      gridLineDashStyle: 'longdash',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormatter: function (this: {
        series: { name: string };
        y: number;
        stackTotal: number;
      }) {
        return `${this.series.name}: ${this.y} <br/> Combined: ${this.stackTotal}`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Quality Risk',
        data: [
          attributes['year1-quality'],
          attributes['year3-quality'],
          attributes['year5-quality'],
          attributes['year10-quality'],
        ],
        color: '#b7dbe6',
      },
      {
        name: 'Quantity Risk',
        data: [
          attributes['year1-scarcity'],
          attributes['year3-scarcity'],
          attributes['year5-scarcity'],
          attributes['year10-scarcity'],
        ],
        color: '#93c9da',
      },
      {
        name: 'Water Bill',
        data: [
          attributes['year1-waterbill'],
          attributes['year3-waterbill'],
          attributes['year5-waterbill'],
          attributes['year10-waterbill'],
        ],
        color: '#6fb7ce',
      },
    ],
    credits: {
      enabled: false,
    },
  };
}
