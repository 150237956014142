import {
  Box,
  Container,
  Heading,
  Link,
  Stack,
  Text,
  Image,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';
import { PARTNERS } from 'src/constants/partners';

export interface DevelopmentPartnersProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const DevelopmentPartners: FC<DevelopmentPartnersProps> = () => {
  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/casting_CTG.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Development partners
          </Heading>
        )}
        subtitle={() => (
          <Text fontSize="lg" mt="5">
            The Smart Water Navigator was developed by Ecolab in collaboration
            with S&P Global Sustainable1.
          </Text>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(10vh)' }}
        p="20px"
        pb="40px"
      >
        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            About our partners
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Text fontSize="lg">
                The Smart Water Navigator was developed by Ecolab, a global
                leader in water technologies and services and S&P Global
                Sustainable1, a global leader in environmental data and risk
                analysis. Through this collaboration, the platform leverages
                industry insights, advanced analytics and Azure Cloud technology
                from legacy partner Microsoft to help business leaders easily
                access information to better understand and address the impacts
                of water scarcity. Our data partners and advisors include the
                World Resources Institute, the UN CEO Water Mandate, the Pacific
                Institute and the Alliance for Water Stewardship.
              </Text>
            </Container>
          </Box>
        </Box>
        <SimpleGrid
          columns={{ base: 1, sm: 3, lg: 5 }}
          spacing={10}
          maxW="1920px"
          mt="10"
        >
          {PARTNERS.map((partner) => (
            <Flex
              alignItems="center"
              justifyContent="center"
              key={partner.href}
            >
              <Link isExternal href={partner.href}>
                <Image src={`/images/logos/${partner.image}`} maxH="100px" />
              </Link>
            </Flex>
          ))}
        </SimpleGrid>
        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Hear from our partners
          </Heading>
          <Container maxW={1920 / 1.5}>
            <Stack direction="column" spacing="10">
              <Box>
                <Text
                  borderLeft="2px solid"
                  borderColor="gray.300"
                  fontStyle="italic"
                  mb="20px"
                  pl="10"
                  py="5"
                >
                  "Extremely high water stress is already affecting a quarter of
                  the world's population, and the consequences for people, the
                  planet and economies will be dire if we don't start
                  prioritizing improved water management. All water users in a
                  basin, including corporations, must do their part to prevent
                  water stress from becoming a water crisis. Tools like the
                  Smart Water Navigator help businesses on this journey by
                  enabling them to set meaningful targets and move towards
                  sustainable water use to ensure a water-secure future for
                  all."
                </Text>
                <Text pl="20" textAlign="right">
                  <strong>
                    – Samantha Kuzma, Senior Manager Global Water Data Products,
                    World Resources Institute
                  </strong>
                </Text>
              </Box>
              <Box>
                <Text
                  borderLeft="2px solid"
                  borderColor="gray.300"
                  fontStyle="italic"
                  mb="20px"
                  pl="10"
                  py="5"
                >
                  "Water stress poses a significant threat to economies
                  worldwide as water risk is inherently financial risk. The
                  Smart Water Navigator gives companies who depend on water
                  across their value chains the tools to understand and quantify
                  this risk, ultimately making the business case for investing
                  in smart water management."
                </Text>
                <Text pl="20" textAlign="right">
                  <strong>
                    – Steven Bullock, Managing Director and Global Head of
                    Research and Methodology, S&P Global Sustainable1
                  </strong>
                </Text>
              </Box>
            </Stack>
          </Container>
        </Box>
      </Stack>
      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
