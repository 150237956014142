import React, { FC, useState } from 'react';
import classnames from 'classnames/bind';
import * as yup from 'yup';
import { Dialog } from 'src/components/Dialog';
import { TextInput } from 'src/components/TextInput';
import { Button } from 'src/components/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './UserInviteDialog.module.css';
import { RadioList } from '../RadioList';
import { inviteUser, InviteUserRequestType } from 'src/clients/api/account';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { animated } from 'react-spring';
import { Alert } from '../Alert';
import { FADE_IN_DOWN } from 'src/constants/animations';

const cx = classnames.bind(styles);

const RESOLVER = yupResolver(
  yup.object().shape({
    email: yup.string().email().required('Required'),
  })
);

const OPTIONS = [
  {
    value: 'internal',
    label:
      'Invite an internal user, such as an employee, or an external collaborator and give them access to your account. The user will have full access to your account.',
  },
  // {
  //   value: 'consolidate',
  //   label:
  //     'Invite an internal user, such as an employee, and merge the users account with yours. All facility and folder data will be merged. The user will have full access to your account.',
  // },
];

type UserInviteDialogProps = {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  accountId: string;
  isShare?: boolean;
};
export const UserInviteDialog: FC<UserInviteDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  accountId,
  isShare = false,
}) => {
  const [inviteType, setInviteType] = useState<
    'external' | 'internal' | 'consolidate'
  >('internal');
  const [state, setState] = useAsyncState();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: RESOLVER,
    defaultValues: {
      email: '',
    },
  });

  const handleInviteUser = handleSubmit(
    async (values: InviteUserRequestType) => {
      if (values) {
        try {
          setState({ status: 'loading' });

          const result = await inviteUser({
            accountId: accountId,
            email: values.email,
            externalInvite: isShare,
            consolidateAccount: !isShare && inviteType === 'consolidate',
            internalInvite: !isShare && inviteType !== 'consolidate',
          });

          if (result.status !== 'success') {
            throw new Error(result.value.errors[0].message);
          }

          setState({
            status: 'success',
            message: `User invited successfully!`,
          });

          onSave();
        } catch (e) {
          setState({
            status: 'error',
            message: e.message ?? 'There was an error sending your invite.',
          });
        }
      }
    }
  );

  return (
    <Dialog
      isOpen={isOpen}
      title="Invite user"
      description="Please provide the email address the account to invite"
      className={cx('userInviteDialog')}
    >
      {state.status === 'error' && (
        <div className={cx('error')}>
          <animated.div style={FADE_IN_DOWN}>
            <Alert
              variant="danger"
              message={
                state.message ??
                'There was an error resetting your password. Please try again.'
              }
            />
          </animated.div>
        </div>
      )}

      <TextInput name="email" ref={register} error={errors.email} />

      {!isShare ? (
        <RadioList
          items={OPTIONS}
          value={inviteType}
          onChange={(v) =>
            setInviteType(v as 'external' | 'internal' | 'consolidate')
          }
        />
      ) : null}

      <div className={cx('footer')}>
        <Button
          className={cx('secondaryAction')}
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          className={cx('primaryAction')}
          onClick={handleInviteUser}
          isLoading={state.status === 'loading'}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

UserInviteDialog.displayName = 'UserInviteDialog';
