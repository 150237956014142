import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './ConfirmDialog.module.css';
import { Dialog } from '../Dialog';
import { Button } from '../Button';
import { DialogProps } from '../Dialog';

const cx = classnames.bind(styles);

export type ConfirmDialogProps = {
  isLoading: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
} & DialogProps;

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  variant = 'default',
  isOpen,
  isLoading,
  title,
  description,
  children,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      variant={variant}
      isOpen={isOpen}
      title={title}
      description={description}
    >
      {children}

      <div className={cx('footer')}>
        <Button onClick={onClose} variant="secondary">
          {cancelButtonText}
        </Button>
        <Button isLoading={isLoading} onClick={onConfirm} variant="danger">
          {confirmButtonText}
        </Button>
      </div>
    </Dialog>
  );
};

ConfirmDialog.displayName = 'ConfirmDialog';
