import * as React from 'react';

const SvgHelpQuestionMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 22.5 36" data-testid="svg-icon" {...props}>
      <path
        fill="#0e6591"
        d="M7.9 15.8c5.5-.4 9.4-2.8 9.4-5.8 0-2.8-2.4-5.4-6.3-5.4-3.3 0-5.9 1.8-7.9 4.3L0 5.7C2.8 2.4 6.5 0 11.6 0c6.5 0 10.9 4.2 10.9 9.5 0 4.6-4.1 8.7-9.7 9.9V25H7.9v-9.2zm-1 16.8c0-1.7 1.5-3.3 3.4-3.3 1.8 0 3.3 1.6 3.3 3.3 0 1.9-1.5 3.4-3.3 3.4-1.9 0-3.4-1.6-3.4-3.4z"
      />
    </svg>
  );
};

const MemoSvgHelpQuestionMark = React.memo(SvgHelpQuestionMark);
export default MemoSvgHelpQuestionMark;
