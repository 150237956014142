import { FC, useEffect } from 'react';

type PreventIframeProps = Record<string, unknown>;

const PreventIframe: FC<PreventIframeProps> = () => {
  useEffect(() => {
    if (window.top && window.top !== window) {
      window.top.location.href = '/';
    }
  }, []);
  return null;
};

PreventIframe.displayName = 'PreventIframe';

export default PreventIframe;
