import {
  Box,
  Button,
  ButtonGroup,
  Link as ChakraLink,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Show,
  Stack,
  PopoverHeader,
} from '@chakra-ui/react';
import {
  mdiCardSearchOutline,
  mdiFileDownloadOutline,
  mdiFolderOutline,
  mdiDotsVertical,
  mdiHelpCircleOutline,
} from '@mdi/js';
import classnames from 'classnames/bind';
import React, { FC, HTMLAttributes, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Copy } from 'src/components/Copy';
import { Icon } from 'src/components/Icon';
import { AggregateResult } from 'src/types/aggregates';
import { asCommaSeparatedString } from 'src/utils/number';
import tableStyles from '../FacilityList/FacilityList.module.css';
import { IdentifyRiskTabGroup } from '../FacilityListItemTabs/IdentifyRiskTabGroup';
import { SetTargetsTabGroup } from '../FacilityListItemTabs/SetTargetsTabGroup';
import { TakeActionTabGroup } from '../FacilityListItemTabs/TakeActionTabGroup';
import { TrackProgressTabGroup } from '../FacilityListItemTabs/TrackProgressTabGroup';
import { FacilityReportDialog } from '../FacilityReportDialog';
import { FolderAssignDialog } from '../FolderAssignDialog';
import styles from './FacilityListItem.module.css';

const cx = classnames.bind(styles);

export interface FacilityListItemProps extends HTMLAttributes<HTMLDivElement> {
  aggregate: AggregateResult;
}

enum ViewTabs {
  IDENTIFY_RISK = 'identify',
  SET_TARGETS = 'set',
  TAKE_ACTION = 'take',
  TRACK_PROGRESS = 'track',
}

export const FacilityListItem: FC<FacilityListItemProps> = (props) => {
  const history = useHistory();
  const { aggregate, className } = props;
  const { reportingYear, facility, showAverageWaterStress } = aggregate;
  const { facilityName, regionName, cityName, countryName } = facility;
  const queryClient = useQueryClient();
  const [openModalView, setOpenModalView] = useState<string>();

  const [currentOpenView, setCurrentOpenView] = useState<ViewTabs>();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  return (
    <div className={cx('facilityListItem', className)}>
      <div
        className={cx('row')}
        onClick={(e) => {
          currentOpenView !== undefined
            ? setCurrentOpenView(undefined)
            : setCurrentOpenView(ViewTabs.IDENTIFY_RISK);
        }}
      >
        <div className={cx('cell', 'title', tableStyles.title)}>
          <div className={cx('cellContent')}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Stack direction="column">
                <Heading
                  size="md"
                  fontWeight="semibold"
                  noOfLines={1}
                  flex="1 1 auto"
                >
                  {facilityName}
                </Heading>

                <Stack direction="row" spacing="2" alignItems="center">
                  <Copy
                    as="p"
                    className={cx('smallText')}
                  >{`${cityName}, ${regionName}, ${countryName}`}</Copy>
                  {showAverageWaterStress ? (
                    <Popover trigger="hover" placement="right">
                      <PopoverTrigger>
                        <Box
                          as={Icon}
                          path={mdiHelpCircleOutline}
                          color="brand.primary"
                        />
                      </PopoverTrigger>
                      <PopoverContent
                        boxShadow="md"
                        borderRadius="md"
                        overflow="hidden"
                        border="1px solid #337695"
                      >
                        <PopoverArrow bgColor="#e6eef2" />
                        <PopoverHeader
                          as={Flex}
                          fontSize="sm"
                          bgColor="#337695"
                          p="2"
                          lineHeight={1}
                          color="white"
                          fontWeight="bold"
                          alignItems="center"
                        >
                          <Box
                            as={Icon}
                            path={mdiHelpCircleOutline}
                            color="white"
                            mr="2"
                          />
                          Notice
                        </PopoverHeader>
                        <PopoverBody fontSize="xs" bgColor="#e6eef2">
                          <span>
                            Due to gaps in local-level data, water risk metrics
                            for this facility are derived from global averages.
                            For more information, please refer to the{' '}
                            <ChakraLink
                              as={Link}
                              to="/methodology"
                              textDecoration="underline"
                            >
                              Smart Water Navigator methodology
                            </ChakraLink>
                            .
                          </span>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ) : null}
                </Stack>
                <Copy as="p" className={cx('smallText')}>
                  {reportingYear}
                </Copy>
              </Stack>

              <Show below="lg">
                <Menu isLazy closeOnSelect>
                  {({ onClose }) => (
                    <>
                      <Box position="relative">
                        <Box>
                          <MenuButton
                            as={IconButton}
                            variant="unstyled"
                            aria-label="Options"
                            rightIcon={<Icon path={mdiDotsVertical} />}
                            zIndex="5"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </Box>
                      </Box>
                      <MenuList color="brand.primary">
                        <MenuGroup title="Actions">
                          <MenuItem
                            icon={<Icon path={mdiCardSearchOutline} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(
                                `/app/facilities/${facility.facilityId}`
                              );
                            }}
                          >
                            Facility Details
                          </MenuItem>
                          <MenuItem
                            icon={<Icon path={mdiFolderOutline} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenModalView(
                                `assignFolder:${facility.facilityId}`
                              );
                            }}
                          >
                            Assign to Folder
                          </MenuItem>
                          <MenuItem
                            icon={<Icon path={mdiFileDownloadOutline} />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsGeneratingReport(true);
                            }}
                          >
                            Download Report
                          </MenuItem>
                        </MenuGroup>

                        <MenuDivider />

                        <MenuGroup title="Strategy">
                          <MenuItem
                            onClick={() => {
                              setCurrentOpenView(
                                currentOpenView !== ViewTabs.IDENTIFY_RISK
                                  ? ViewTabs.IDENTIFY_RISK
                                  : undefined
                              );
                              onClose();
                            }}
                          >
                            Identify Risk
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCurrentOpenView(
                                currentOpenView !== ViewTabs.SET_TARGETS
                                  ? ViewTabs.SET_TARGETS
                                  : undefined
                              );

                              onClose();
                            }}
                          >
                            Set Targets
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCurrentOpenView(
                                currentOpenView !== ViewTabs.TAKE_ACTION
                                  ? ViewTabs.TAKE_ACTION
                                  : undefined
                              );

                              onClose();
                            }}
                          >
                            Take Action
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setCurrentOpenView(
                                currentOpenView !== ViewTabs.TRACK_PROGRESS
                                  ? ViewTabs.TRACK_PROGRESS
                                  : undefined
                              );

                              onClose();
                            }}
                          >
                            Track Progress
                          </MenuItem>
                        </MenuGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Show>
            </Stack>
          </div>
        </div>
        <div className={cx('cell', 'incoming', tableStyles.incoming)}>
          <div className={cx('cellContent')}>
            <small>Incoming Water</small>
            <Copy as="p" className={cx('cellValue')}>
              {asCommaSeparatedString(
                aggregate?.incomingOutgoingWaterTotal ?? 0
              )}
            </Copy>
            <small>
              m<sup>3</sup> per year
            </small>
          </div>
        </div>
        <div className={cx('cell', 'stress', tableStyles.stress)}>
          <div className={cx('cellContent')}>
            <small>Overall Water Stress</small>
            <Copy as="p" className={cx('cellValue')}>
              {aggregate.overallWaterStressCategory
                ? `${asCommaSeparatedString(
                    aggregate.overallWaterStressCategory,
                    0
                  )}`
                : '0'}
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'premium', tableStyles.premium)}>
          <div className={cx('cellContent')}>
            <small>Water Risk Premium</small>
            <Copy as="p" className={cx('cellValue')}>
              {aggregate.waterRiskPremium
                ? `${asCommaSeparatedString(aggregate.waterRiskPremium, 1)}x`
                : 'N/A'}
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'risk', tableStyles.risk)}>
          <div className={cx('cellContent')}>
            <small>Revenue at Risk</small>
            <Copy as="p" className={cx('cellValue')}>
              {aggregate.revenueAtRiskPercentage}%
            </Copy>
          </div>
        </div>
        <div className={cx('cell', 'rank', tableStyles.rank)}>
          <div className={cx('cellContent')}>
            <small>Facility Rank</small>
            <Copy as="p" className={cx('cellValue')}>
              {aggregate.facilityRank}
            </Copy>
          </div>
        </div>
      </div>

      {isGeneratingReport ? (
        <FacilityReportDialog
          aggregate={aggregate}
          isOpen={isGeneratingReport}
          onClose={() => setIsGeneratingReport(false)}
        />
      ) : null}

      {openModalView === `assignFolder:${facility.facilityId}` ? (
        <FolderAssignDialog
          isOpen={openModalView === `assignFolder:${facility.facilityId}`}
          onSave={() => {
            setOpenModalView(undefined);
            queryClient.refetchQueries(['aggregates']);
          }}
          onClose={() => setOpenModalView(undefined)}
          facilityId={aggregate.facility.facilityId}
          reportingYear={aggregate.reportingYear}
          facilityFolderId={aggregate.folderId}
        />
      ) : null}

      <Show above="lg">
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Flex
            flex="1 1 auto"
            alignItems="center"
            justifyContent={{ base: 'center', lg: 'flex-start' }}
            p={{ base: '2', lg: '0' }}
          >
            <ButtonGroup color="brand.primary" px="2">
              <Button
                variant="link"
                fontWeight="300"
                fontSize="sm"
                size="sm"
                color="brand.primary"
                leftIcon={<Icon path={mdiCardSearchOutline} />}
                onClick={() =>
                  history.push(`/app/facilities/${facility.facilityId}`)
                }
                iconSpacing="2px"
              >
                Facility Details
              </Button>
              <Button
                variant="link"
                fontWeight="300"
                fontSize="sm"
                size="sm"
                color="brand.primary"
                leftIcon={<Icon path={mdiFolderOutline} />}
                onClick={() =>
                  setOpenModalView(`assignFolder:${facility.facilityId}`)
                }
                iconSpacing="2px"
              >
                Assign to Folder
              </Button>
              <Button
                variant="link"
                fontWeight="300"
                fontSize="sm"
                size="sm"
                color="brand.primary"
                onClick={() => setIsGeneratingReport(true)}
                leftIcon={<Icon path={mdiFileDownloadOutline} />}
                iconSpacing="2px"
              >
                Download Report
              </Button>
            </ButtonGroup>
          </Flex>

          <Flex
            flex="1 1 auto"
            alignItems="center"
            justifyContent={{ base: 'center', lg: 'flex-end' }}
          >
            <div className={cx('rightActions')}>
              <div className={cx('row', 'footer')}>
                <div className={cx('switchGroup')}>
                  <div
                    className={cx('footerLink', {
                      active: currentOpenView === ViewTabs.IDENTIFY_RISK,
                    })}
                    onClick={() =>
                      setCurrentOpenView(
                        currentOpenView !== ViewTabs.IDENTIFY_RISK
                          ? ViewTabs.IDENTIFY_RISK
                          : undefined
                      )
                    }
                  >
                    Identify Risk
                  </div>
                  <div
                    className={cx('footerLink', {
                      active: currentOpenView === ViewTabs.SET_TARGETS,
                    })}
                    onClick={() =>
                      setCurrentOpenView(
                        currentOpenView !== ViewTabs.SET_TARGETS
                          ? ViewTabs.SET_TARGETS
                          : undefined
                      )
                    }
                  >
                    Set Targets
                  </div>
                  <div
                    className={cx('footerLink', {
                      active: currentOpenView === ViewTabs.TAKE_ACTION,
                    })}
                    onClick={() =>
                      setCurrentOpenView(
                        currentOpenView !== ViewTabs.TAKE_ACTION
                          ? ViewTabs.TAKE_ACTION
                          : undefined
                      )
                    }
                  >
                    Take Action
                  </div>
                  <div
                    className={cx('footerLink', {
                      active: currentOpenView === ViewTabs.TRACK_PROGRESS,
                    })}
                    onClick={() =>
                      setCurrentOpenView(
                        currentOpenView !== ViewTabs.TRACK_PROGRESS
                          ? ViewTabs.TRACK_PROGRESS
                          : undefined
                      )
                    }
                  >
                    Track Progress
                  </div>
                </div>
              </div>
            </div>
          </Flex>
        </Stack>
      </Show>

      {currentOpenView === ViewTabs.IDENTIFY_RISK ? (
        <IdentifyRiskTabGroup
          aggregate={aggregate}
          className={cx('row', 'rowContent')}
        />
      ) : null}

      {currentOpenView === ViewTabs.SET_TARGETS ? (
        <SetTargetsTabGroup
          aggregate={aggregate}
          className={cx('row', 'rowContent')}
        />
      ) : null}

      {currentOpenView === ViewTabs.TAKE_ACTION ? (
        <TakeActionTabGroup
          aggregate={aggregate}
          className={cx('row', 'rowContent')}
        />
      ) : null}

      {currentOpenView === ViewTabs.TRACK_PROGRESS ? (
        <>
          <Copy as="p" className={cx('tabHeader')}>
            A facility's performance over time can be charted to show variation
            in incoming, outgoing and calculated consumptive water use over
            multiple years. This visualization provides a way for facility
            leaders to contextualize the interdependencies of production volume,
            revenue and water use to inform water efficiency.
          </Copy>
          <TrackProgressTabGroup
            aggregate={aggregate}
            className={cx('row', 'rowContent')}
          />
        </>
      ) : null}
    </div>
  );
};

FacilityListItem.displayName = 'FacilityListItem';
