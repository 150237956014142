import React, { FC } from 'react';
import { VerticalTabContainer } from '../../VerticalTabContainer';
import classnames from 'classnames/bind';
import styles from './IdentifyRiskTabGroup.module.css';
import { TotalRisk } from './Tabs/TotalRisk';
import { IncomingRisk } from './Tabs/IncomingRisk';
import { OutgoingRisk } from './Tabs/OutgoingRisk';
import { RevenueRisk } from './Tabs/RevenueRisk';
import { AggregateResult } from 'src/types/aggregates';

export type IdentifyRiskTabGroupProps = {
  aggregate: AggregateResult;
  className?: string;
};

const TABS = [
  'Total Risk Premium',
  'Incoming Water At Risk',
  'Outgoing Water At Risk',
  'Revenue Risk',
];

const cx = classnames.bind(styles);

export const IdentifyRiskTabGroup: FC<IdentifyRiskTabGroupProps> = ({
  aggregate,
  className,
}) => {
  return (
    <VerticalTabContainer
      tabs={TABS}
      className={cx('animate', 'fadeInDown', className)}
    >
      {(activeTabIndex) => (
        <>
          {activeTabIndex === 0 ? <TotalRisk aggregate={aggregate} /> : null}
          {activeTabIndex === 1 ? <IncomingRisk aggregate={aggregate} /> : null}
          {activeTabIndex === 2 ? <OutgoingRisk aggregate={aggregate} /> : null}
          {activeTabIndex === 3 ? <RevenueRisk aggregate={aggregate} /> : null}
        </>
      )}
    </VerticalTabContainer>
  );
};

IdentifyRiskTabGroup.displayName = 'IdentifyRiskTabGroup';
