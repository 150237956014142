import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

export const Logout: FC<{}> = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout!();
  }, [logout]);

  return <Redirect to="/" />;
};
