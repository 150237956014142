import React, { ReactNode, useState } from 'react';

const DEFAULT_PROPERTIES = {
  showSidebar: true,
  showSubheader: true,
};

type ThemeManagerContextMethods = {
  setThemeProperties: (values: Partial<ThemeManagerContextPropsType>) => void;
  resetThemeProperties: () => void;
};

type ThemeManagerContextPropsType = {
  showSidebar: boolean;
  showSubheader: boolean;
  subheaderVariant?: 'waa' | 'wrm';
};

type ThemeManagerContextProps = ThemeManagerContextMethods &
  ThemeManagerContextPropsType;

const ThemeManagerContext = React.createContext<
  Partial<ThemeManagerContextProps>
>({});

function ThemeManagerProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<ThemeManagerContextPropsType>({
    showSidebar: true,
    showSubheader: true,
  });

  const setThemeProperties = (
    values: Partial<ThemeManagerContextPropsType>
  ) => {
    setState({
      ...state,
      ...values,
    });
  };

  const resetThemeProperties = () => {
    setState(DEFAULT_PROPERTIES);
  };

  return (
    <ThemeManagerContext.Provider
      value={{
        ...state,
        setThemeProperties,
        resetThemeProperties,
      }}
    >
      {children}
    </ThemeManagerContext.Provider>
  );
}

const useThemeManager = () => React.useContext(ThemeManagerContext);

export { ThemeManagerProvider, useThemeManager };
