import classnames from 'classnames/bind';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC } from 'react';
import { Copy } from 'src/components/Copy';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { AggregateResult } from 'src/types/aggregates';
import { DEFAULT_CHART } from '../../../../utils/chart';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type ProductionBasisProps = {
  aggregate: AggregateResult;
};

export const ProductionBasis: FC<ProductionBasisProps> = ({ aggregate }) => {
  const attributes = aggregate.charts.potWaterIntensityProduction.attributes;
  const chart = useDeepCompareMemo<Highcharts.Options>(
    () => ({
      ...DEFAULT_CHART,
      chart: {
        ...DEFAULT_CHART.chart,
        type: 'column',
      },
      colors: ['#096490', '#93c9da', '#dc8f18'],
      title: {
        text: null,
      },
      xAxis: {
        categories: attributes.categories,
      },
      yAxis: {
        title: {
          text: 'Cubic meters water / unit of production (m3/unit)',
        },
      },
      legend: {
        squareSymbol: false,
        symbolHeight: 12,
        symbolWidth: 24,
        symbolPadding: 10,
        symbolRadius: 0,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.5f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      series: attributes.series,
    }),
    [aggregate]
  );

  return (
    <div className={cx('facilityPerformanceTab', 'facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Water Intensity - Production Basis Over Time</Copy>
        <Copy as="p">
          Water Intensity on a production basis over time compares the incoming,
          outgoing and calculated consumptive water use per production unit over
          multiple years.
        </Copy>
      </div>

      {/* @ts-ignore */}
      <HighchartsReact highcharts={Highcharts} options={chart} />
    </div>
  );
};

ProductionBasis.displayName = 'ProductionBasis';
