import React from 'react';
import ReactDOM from 'react-dom';
import { AppProviders } from './components/AppProviders';
import { PreventIframe } from './components/PreventIframe';
import './index.css';
import { App } from './routes';

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <PreventIframe />
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
);
