import { REPORTING_YEARS } from 'src/constants/years';
import * as yup from 'yup';

const transformEmptyString = (v: any) => (isNaN(v) ? undefined : v);
const optionalOneOf = (v: any, options: Array<any>) =>
  !v || v === '' ? true : options.includes(v);

export const facilityInformationSchemaProperties = {
  facilityName: yup.string().trim().required('Required'),
  countryId: yup.number().transform(transformEmptyString).required('Required'),
  regionId: yup.number().transform(transformEmptyString).required('Required'),
  cityId: yup.number().transform(transformEmptyString).required('Required'),
  sector1Id: yup.number().transform(transformEmptyString).required('Required'),
  sector2Id: yup.number().transform(transformEmptyString).required('Required'),
  sector3Id: yup.number().transform(transformEmptyString).required('Required'),
  facilityLabel: yup.string().trim(),
  active: yup.bool().required('Required'),
  modelingOnly: yup.bool().required('Required'),
};
export const facilityInformationSchema = yup.object(
  facilityInformationSchemaProperties
);
export type FacilityInformationSchemaForm = yup.InferType<
  typeof facilityInformationSchema
>;

const options = {
  timePeriodOptions: ['Weekly', 'Monthly', 'Quarterly', 'Annual'],
  locationOfWaterSourceOptions: ['1', '2', '3', '4', '5'],
  locationOfWaterTreatmentOptions: ['A', 'B', 'C'],
  revenueLostOptions: ['A', 'B', 'C'],
  regulationOfWaterInLocalCommunityQuestionOptions: [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
  ],
  facilityEnvironmentalReputationQuestionOptions: ['A', 'B', 'C', 'D'],
  dependingSameWaterSourceQuestionOptions: ['A', 'B', 'C', 'D'],
  waterRestrictionQuestionOptions: ['A', 'B', 'C'],
  incomingWaterQualityComparisonOptions: ['A', 'B', 'C'],
  alternativeWaterSourceQuestionOptions: ['A', 'B', 'C', 'D'],
  thirdPartyCapacityOptions: ['A', 'B', 'C'],
  waterUnits: [9, 16, 32],
  currencies: [
    'BRL',
    'CAD',
    'CHF',
    'CNY',
    'EUR',
    'GBP',
    'INR',
    'JPY',
    'RUB',
    'USD',
  ],
};

const facilityDetailsSchemaProperties = {
  reportingYear: yup
    .string()
    .trim()
    .oneOf(
      REPORTING_YEARS.map((y) => `${y}`),
      'Invalid year'
    )
    .required('Required'),
  incomingWaterTimePeriod: yup
    .string()
    .trim()
    .oneOf(options.timePeriodOptions, 'Invalid period')
    .required('Required'),
  incomingWaterQuantity: yup
    .number()
    .positive()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterQuantityUnit: yup
    .number()
    .oneOf(options.waterUnits, 'Invalid unit')
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterPrice: yup
    .number()
    .positive()
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterPriceCurrencyCode: yup
    .string()
    .oneOf(options.currencies, 'Invalid currency')
    .required('Required'),
  incomingWaterPriceUnit: yup
    .number()
    .oneOf(options.waterUnits, 'Invalid unit')
    .transform(transformEmptyString)
    .required('Required'),
  incomingWaterSource: yup
    .string()
    .trim()
    .oneOf(options.locationOfWaterSourceOptions, 'Invalid location')
    .required('Required'),
  waterWithdrawalReplenishment: yup.bool().required('Required'),
  waterWithdrawalAnnualizedVolumetricBenefit: yup
    .number()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().typeError('Invalid number').required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalAnnualizedVolumetricBenefitUOMId: yup
    .number()
    .oneOf(options.waterUnits, 'Invalid unit')
    .transform(transformEmptyString)
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().typeError('Invalid number').required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalSupportingReplenishmentWaterFund: yup
    .string()
    .trim()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.string().typeError('Invalid value').required('Required'),
      otherwise: yup.string(),
    }),
  waterWithdrawalReplenishmentDurationInYears: yup
    .number()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.number().typeError('Invalid number').required('Required'),
      otherwise: yup.number(),
    }),
  waterWithdrawalThirdPartyVerified: yup
    .bool()
    .when('waterWithdrawalReplenishment', {
      is: true,
      then: yup.bool().required('Required'),
      otherwise: yup.bool(),
    }),
  waterWithdrawalThirdPartyVerifier: yup
    .string()
    .trim()
    .when('waterWithdrawalThirdPartyVerified', {
      is: true,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  outgoingWaterTimePeriod: yup
    .string()
    .trim()
    .oneOf(options.timePeriodOptions, 'Invalid period')
    .required('Required'),
  outgoingWaterQuantity: yup
    .number()
    .positive()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterQuantityUnit: yup
    .number()
    .oneOf(options.waterUnits, 'Invalid unit')
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterPrice: yup
    .number()
    .positive()
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterPriceCurrencyCode: yup
    .string()
    .oneOf(options.currencies, 'Invalid currency')
    .required('Required'),
  outgoingWaterPriceUnit: yup
    .number()
    .oneOf(options.waterUnits, 'Invalid unit')
    .transform(transformEmptyString)
    .required('Required'),
  outgoingWaterTreatmentSource: yup
    .string()
    .oneOf(options.locationOfWaterTreatmentOptions, 'Invalid location')
    .required('Required'),
  facilityOutput: yup
    .number()
    .positive()
    .transform(transformEmptyString)
    .typeError('Required')
    .required('Required'),
  facilityOutputUnitsOfMeasure: yup.string().required('Required'),
  projectedFacilityOutputIncrease: yup
    .number()
    .moreThan(-101, 'Must be greater than -101')
    .transform(transformEmptyString)
    .required('Required'),
};
export const facilityDetailsSchema = yup.object(
  facilityDetailsSchemaProperties
);
export type FacilityDetailSchemaForm = yup.InferType<
  typeof facilityDetailsSchema
>;

const businessInformationSchemaProperties = {
  droughScenario: yup.bool(),
  projectedIncomingWaterPriceIncrease: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  projectedIncomingWaterQuantityIncrease: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  projectedOutgoingWaterPriceIncrease: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  projectedOutgoingWaterQuantityIncrease: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  revenue: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  revenueCurrencyCode: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.currencies)
    ),
  projectedRevenueIncrease: yup.lazy((value) => {
    if (value !== undefined) {
      return yup
        .number()
        .transform(transformEmptyString)
        .moreThan(-101, 'Must be greater than -101')
        .required();
    }
    return yup.mixed().notRequired();
  }),
  revenueLostDueToWaterScarcity: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.revenueLostOptions)
    ),
};
export const businessInformationSchema = yup.object(
  businessInformationSchemaProperties
);
export type BusinessInformationSchemaForm = yup.InferType<
  typeof businessInformationSchema
>;

const regulationReputationSchemaProperties = {
  regulationOfWaterInLocalCommunityQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.regulationOfWaterInLocalCommunityQuestionOptions)
    ),
  facilityEnvironmentalReputationQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.facilityEnvironmentalReputationQuestionOptions)
    ),
  dependingSameWaterSourceQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.dependingSameWaterSourceQuestionOptions)
    ),
  exemptWaterRestrictionQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.waterRestrictionQuestionOptions)
    ),
  ngoLocalPressureQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.waterRestrictionQuestionOptions)
    ),
};
export const regulationReputationSchema = yup.object(
  regulationReputationSchemaProperties
);
export type RegulationReputationSchemaForm = yup.InferType<
  typeof regulationReputationSchema
>;

const waterQualitySchemaProperties = {
  incomingWaterQualityComparison: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.incomingWaterQualityComparisonOptions)
    ),
  alternativeWaterSourceQuestion: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.alternativeWaterSourceQuestionOptions)
    ),
  outgoingWaterOnsiteTreatmentCapacity: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.thirdPartyCapacityOptions)
    ),
  outgoingWaterOffsiteTreatmentCapacity: yup
    .string()
    .trim()
    .test('optional-one-of', 'Invalid selection', (v) =>
      optionalOneOf(v, options.thirdPartyCapacityOptions)
    ),
  incomingWaterImpairmentSalinity: yup.bool(),
  incomingWaterImpairmentOrganics: yup.bool(),
  incomingWaterImpairmentSuspendedSolids: yup.bool(),
  incomingWaterImpairmentTaste: yup.bool(),
  incomingWaterImpairmentOdor: yup.bool(),
};
export const waterQualitySchema = yup.object(waterQualitySchemaProperties);
export type WaterQualitySchemaForm = yup.InferType<typeof waterQualitySchema>;

export const pollutantsSchemaProperties = {
  ownOutgoingWaterStandards: yup.bool(),
  pollutants: yup.array().of(
    yup.object().shape({
      threshold: yup.number().transform(transformEmptyString),
      concentration: yup.number().transform(transformEmptyString),
    })
  ),
  facilityDilutionFactor: yup
    .number()
    .min(0, 'Must be greater than or equal to 0')
    .max(1, 'Must be less than or equal to 1')
    .transform(transformEmptyString),
};
export const pollutantsSchema = yup.object(pollutantsSchemaProperties);
export type PollutantsSchemaForm = yup.InferType<typeof pollutantsSchema>;

const waterActionAssessmentAnswersSchemaProperties = {
  assessment: yup
    .object()
    .shape({
      questionnaireId: yup.string(),
      reportingyearId: yup.string().nullable(true),
      assessmentAnswers: yup.object(),
    })
    .nullable(true),
};
export const waterActionAssessmentAnswersSchema = yup.object(
  waterActionAssessmentAnswersSchemaProperties
);
export type WaterActionAssessmentAnswersSchemaForm = yup.InferType<
  typeof waterActionAssessmentAnswersSchema
>;

export const facilityFormSchema = yup.object({
  ...facilityDetailsSchemaProperties,
  ...businessInformationSchemaProperties,
  ...regulationReputationSchemaProperties,
  ...waterQualitySchemaProperties,
  ...pollutantsSchemaProperties,
  ...waterActionAssessmentAnswersSchemaProperties,
});

export type FacilityFormSchema = yup.InferType<typeof facilityFormSchema>;
