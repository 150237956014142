import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames/bind';
import currency from 'currency.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React, { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { generateFacilityInvestmentPDFReport } from 'src/clients/api/v5.facility';
import { Button } from 'src/components/Button';
import {
  CalculatorOptionalForm,
  StoredOptionalFormSchema,
} from 'src/components/CalculatorOptionalForm';
import { Copy } from 'src/components/Copy';
import { SelectInput } from 'src/components/SelectInput';
import { TextInput } from 'src/components/TextInput';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { AggregateResult } from 'src/types/aggregates';
import { ReferenceData, ReferenceDataWaterUnit } from 'src/types/referenceData';
import { paybackPeriodChartDataGenerator } from 'src/utils/chart/paybackPeriodChartDataGenerator';
import { asCommaSeparatedString, formatAsCurrency } from 'src/utils/number';
import { escapeQuotes } from 'src/utils/string';
import * as yup from 'yup';
import { CalculatorResults } from './CalculatorResults';
import { InvestmentCalculatorService } from './InvesetmentCalculatorService';
import styles from './InvestmentCalculator.module.css';
import { mapCalculatorPdfRequestData } from './utils';

const cx = classnames.bind(styles);

type InvestmentCalculatorProps = {
  aggregate: AggregateResult;
  referenceData: ReferenceData;
};

const investmentCalculatorSchemaProperties = {
  investmentName: yup.string().trim().required('Required'),
  initialYear: yup.string().trim().required('Required'),
  initialInvestment: yup.string().trim().required('Required'),
  waterSaved: yup.string().trim().required('Required'),
  discountRate: yup.string().trim().required('Required'),
  incomingIncrease: yup.string().trim().required('Required'),
  outgoingIncrease: yup.string().trim().required('Required'),
};
export const investmentCalculatorSchema = yup.object(
  investmentCalculatorSchemaProperties
);

const INVESTMENT_YEAR_START = 2010;
const FUTURE_INVESTMENT_YEARS = 10;
function getInvestmentYears() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (
    let i = INVESTMENT_YEAR_START;
    i <= currentYear - 1 + FUTURE_INVESTMENT_YEARS;
    i++
  ) {
    years.push(i);
  }
  return years;
}

export const InvestmentCalculator: FC<InvestmentCalculatorProps> = ({
  aggregate,
  referenceData,
}) => {
  const [state, setState] = useAsyncState();
  const paybackPeriodChart = useRef(null);
  const [selectors, setSelectors] = useState<{
    npvYear: string;
    irrYear: string;
  }>({
    npvYear: '2',
    irrYear: '2',
  });

  const [calculatorResults, setCalculatorResults] = useState<
    CalculatorResults | undefined
  >();
  const waterUnits = referenceData.waterUnits.reduce<
    Record<ReferenceDataWaterUnit['unitId'], ReferenceDataWaterUnit>
  >(
    (acc, unit) => ({
      ...acc,
      [unit.unitId]: unit,
    }),
    {}
  );

  const calculatorService = new InvestmentCalculatorService(waterUnits);

  const [savingsAndExpenses, setSavingsAndExpenses] = useState<{
    savings: Array<StoredOptionalFormSchema>;
    expenses: Array<StoredOptionalFormSchema>;
  }>({
    savings: [],
    expenses: [],
  });

  const projectYears = getInvestmentYears();

  const { register, control, watch, handleSubmit, errors } = useForm({
    resolver: yupResolver(investmentCalculatorSchema),
    defaultValues: {
      investmentName: aggregate.facility.facilityName,
      initialYear: projectYears[0],
      initialInvestment: '',
      waterSaved: '',
      discountRate: '',
      incomingIncrease: '',
      outgoingIncrease: '',
    },
  });

  const projectName = watch('investmentName');
  const projectYear = watch('initialYear');

  const onSubmit = handleSubmit(
    (values) => {
      setCalculatorResults(undefined);

      const expenses = savingsAndExpenses.expenses.map(
        ({ value, ...expense }) => ({
          ...expense,
          amount: Number(value),
        })
      );

      const savings = savingsAndExpenses.savings.map(
        ({ value, ...saving }) => ({
          ...saving,
          amount: Number(value),
        })
      );

      setCalculatorResults(
        calculatorService.calculateResults(
          expenses,
          savings,
          Number(values.initialInvestment),
          Number(values.initialYear),
          Number(values.waterSaved),
          Number(values.discountRate),
          aggregate,
          Number(values.incomingIncrease),
          Number(values.outgoingIncrease),
          values.investmentName
        )
      );
    },
    (errors) => {
      console.log(errors);
    }
  );

  const onDownload = async () => {
    if (calculatorResults) {
      try {
        setState({ status: 'loading' });

        const result = await generateFacilityInvestmentPDFReport(
          aggregate.facility.facilityId,
          mapCalculatorPdfRequestData(
            aggregate,
            calculatorResults,
            selectors.irrYear,
            selectors.npvYear,
            waterUnits[aggregate.incomingWaterQuantityUnitId].name,
            escapeQuotes(
              // @ts-ignore
              paybackPeriodChart.current!.chart.getSVG()
            )
          )
        );

        if (result.status !== 'success') {
          throw new Error(result.value.errors[0].message);
        }

        setState({
          status: 'success',
          message: 'Report generated successfully.',
        });

        window.open(result.value.data, '_self');
      } catch (e) {
        setState({
          status: 'error',
          message: e.message ?? 'Something went wrong.',
        });
      }
    }
  };

  const chart = useDeepCompareMemo<Highcharts.Options | undefined>(
    () =>
      calculatorResults
        ? paybackPeriodChartDataGenerator(
            calculatorResults.npvArrayConventional,
            calculatorResults.npvArrayRiskAdjusted,
            calculatorResults.initialYear
          )
        : undefined,

    [calculatorResults]
  );

  return (
    <div className={cx('investmentCalculator')}>
      <Copy as="h2" color="blueLight" className={cx('panelHeader')}>
        {aggregate.facility.facilityName}
      </Copy>
      <div className={cx('panels')}>
        <div className={cx('panel')}>
          <section className={cx('panelSection')}>
            <Copy as="h3" color="blueLight" className={cx('panelHeader')}>
              Required Investment Inputs
            </Copy>
            <div className={cx('row')}>
              <TextInput
                name="investmentName"
                placeholder="Investment Name"
                ref={register}
              />
            </div>
            <div className={cx('row')}>
              <TextInput
                type="number"
                name="initialInvestment"
                placeholder="Capital Investment"
                ref={register}
                error={errors.initialInvestment}
              />
              <Controller
                name="initialYear"
                control={control}
                defaultValue={`${projectYears[0]}`}
                render={(props) => (
                  <SelectInput
                    {...props}
                    items={projectYears.map((v) => ({
                      label: `${v}`,
                      value: `${v}`,
                    }))}
                    placeholder="&nbsp;"
                    error={errors.initialYear}
                  />
                )}
              />
            </div>
            <div className={cx('row')}>
              <TextInput
                type="number"
                name="waterSaved"
                placeholder="Water Saved Per Year"
                ref={register}
                error={errors.waterSaved}
              />
              <span>cubic meters</span>
            </div>
            <div className={cx('row')}>
              <TextInput
                type="number"
                name="discountRate"
                placeholder="Discount Rate %"
                ref={register}
                error={errors.discountRate}
              />
            </div>
            <div className={cx('row')}>Projected Water Cost Increase</div>
            <div className={cx('row')}>
              <TextInput
                type="number"
                name="incomingIncrease"
                placeholder="Incoming"
                ref={register}
                error={errors.incomingIncrease}
              />
              <TextInput
                type="number"
                name="outgoingIncrease"
                placeholder="Outgoing"
                ref={register}
                error={errors.outgoingIncrease}
              />
            </div>
          </section>
          <section className={cx('panelSection')}>
            <Copy as="h3" color="blueLight" className={cx('panelHeader')}>
              Optional Additional Expenses
            </Copy>
            <CalculatorOptionalForm
              minYear={projectYear}
              list={savingsAndExpenses.expenses}
              onChange={(expenses) =>
                setSavingsAndExpenses({ ...savingsAndExpenses, expenses })
              }
            />
          </section>
          <section className={cx('panelSection')}>
            <Copy as="h3" color="blueLight" className={cx('panelHeader')}>
              Optional Additional Savings
            </Copy>
            <CalculatorOptionalForm
              minYear={projectYear}
              list={savingsAndExpenses.savings}
              onChange={(savings) =>
                setSavingsAndExpenses({ ...savingsAndExpenses, savings })
              }
            />
          </section>

          <Button className={cx('button')} onClick={onSubmit}>
            Calculate
          </Button>
        </div>
        <div className={cx('panel')}>
          <section className={cx('panelSection')}>
            <div className={cx('innerSection')}>
              <strong>{aggregate.facility.facilityName}</strong>
              <br />
              <strong>
                {aggregate.facility.cityName}, {aggregate.facility.regionName},{' '}
                {aggregate.facility.countryName}
              </strong>
              <br />
              {projectName ? <span>Project Name: {projectName}</span> : null}
              <br />
              <span>Date: {DateTime.now().toLocaleString()}</span>
            </div>
            <div>
              <Copy as="h3" color="blueLight" className={cx('panelHeader')}>
                Financial Investment Summary
              </Copy>
              <div className={cx('row', 'tables')}>
                <table className={cx('table')}>
                  <tbody>
                    <tr>
                      <td>Capital Investment</td>
                      <td>
                        {formatAsCurrency(
                          calculatorResults?.initialInvestment ?? 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Discount Rate %</td>
                      <td>{calculatorResults?.discountRate ?? 0}%</td>
                    </tr>
                    <tr>
                      <td>Incoming Water Cost/Unit</td>
                      <td>
                        {formatAsCurrency(aggregate.incomingWaterUnitPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td>Outgoing Water Cost/Unit</td>
                      <td>
                        {formatAsCurrency(aggregate.outgoingWaterUnitPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td>Combined Water Cost/Unit</td>
                      <td>
                        {currency(aggregate.outgoingWaterUnitPrice)
                          .add(aggregate.incomingWaterUnitPrice)
                          .format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className={cx('table')}>
                  <tbody>
                    <tr>
                      <td>Total Operational Expenses</td>
                      <td>
                        {formatAsCurrency(
                          calculatorResults?.expensesTotal ?? 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Operational Savings</td>
                      <td>
                        {formatAsCurrency(calculatorResults?.savingsTotal ?? 0)}
                      </td>
                    </tr>
                    <tr>
                      <td>Incoming Quantity Risk Adjusted Price/Unit</td>
                      <td>
                        {currency(
                          aggregate.charts.incomingWaterRiskChart.attributes[
                            'year1-quantity-unit-price'
                          ]
                        )
                          .add(
                            aggregate.charts.incomingWaterRiskChart.attributes[
                              'year1-waterbill-unit-price'
                            ]
                          )
                          .format()}
                      </td>
                    </tr>
                    <tr>
                      <td>Outgoing Risk Adjusted Water Price/Unit</td>
                      <td>
                        {formatAsCurrency(
                          aggregate.charts.outgoingWaterRiskChart.attributes[
                            'year1-combined-unit-price'
                          ]
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Incoming Quantity &amp; Outgoing Total Risk Adjusted
                        Price
                      </td>
                      <td>
                        {currency(
                          aggregate.charts.incomingWaterRiskChart.attributes[
                            'year1-quantity-unit-price'
                          ]
                        )
                          .add(
                            aggregate.charts.incomingWaterRiskChart.attributes[
                              'year1-waterbill-unit-price'
                            ]
                          )
                          .add(
                            aggregate.charts.outgoingWaterRiskChart.attributes[
                              'year1-combined-unit-price'
                            ]
                          )
                          .format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={cx('row', 'tables', 'full')}>
                <table className={cx('table')}>
                  <tbody>
                    <tr>
                      <td width="50%">
                        <strong>
                          Estimated Annual Water Reduction (units)
                        </strong>
                      </td>
                      <td width="50%">
                        <span>
                          {calculatorResults?.waterSaved ?? 0}{' '}
                          {
                            waterUnits[aggregate.incomingWaterQuantityUnitId]
                              .name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Annual Water Reduction Impact % (savings/site Water
                          Use)
                        </strong>
                      </td>
                      <td>
                        {asCommaSeparatedString(
                          calculatorResults?.waterReductionImpact ?? 0,
                          2
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <Copy as="h3" color="blueLight" className={cx('panelHeader')}>
                Financial Investment Results
              </Copy>

              <div className={cx('row', 'tables', 'full')}>
                <table className={cx('resultTable')}>
                  <thead>
                    <tr>
                      <th></th>
                      <th colSpan={2}>
                        Conventional Decision Making Factors: Local Water Price
                      </th>
                      <th>
                        Risk Adjusted Decision Making Factors: WRM Outputs
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="25%">Net Present Value $</td>
                      <td width="15%">
                        <SelectInput
                          items={[
                            { label: '2 year', value: '2' },
                            { label: '5 year', value: '5' },
                            { label: '10 year', value: '10' },
                            { label: '20 year', value: '20' },
                          ]}
                          value={selectors.npvYear}
                          onChange={(value) =>
                            setSelectors({
                              ...selectors,
                              npvYear: value,
                            })
                          }
                        />
                      </td>
                      <td>
                        {formatAsCurrency(
                          calculatorResults?.npvArrayConventional[
                            Number(selectors.npvYear)
                          ] ?? 0
                        )}
                      </td>
                      <td>
                        {formatAsCurrency(
                          calculatorResults?.npvArrayRiskAdjusted[
                            Number(selectors.npvYear)
                          ] ?? 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td width="25%">IRR%</td>
                      <td width="15%">
                        <SelectInput
                          items={[
                            { label: '2 year', value: '2' },
                            { label: '5 year', value: '5' },
                            { label: '10 year', value: '10' },
                            { label: '20 year', value: '20' },
                          ]}
                          value={selectors.irrYear}
                          onChange={(value) =>
                            setSelectors({
                              ...selectors,
                              irrYear: value,
                            })
                          }
                        />
                      </td>
                      <td>
                        {selectors.irrYear === '2' ? (
                          <span>
                            {calculatorResults?.twoYearIRRConventional ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '5' ? (
                          <span>
                            {calculatorResults?.fiveYearIRRConventional ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '10' ? (
                          <span>
                            {calculatorResults?.tenYearIRRConventional ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '20' ? (
                          <span>
                            {calculatorResults?.twentyYearIRRConventional ?? 0}
                          </span>
                        ) : null}
                        %
                      </td>
                      <td>
                        {selectors.irrYear === '2' ? (
                          <span>
                            {calculatorResults?.twoYearIRRRiskAdjusted ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '5' ? (
                          <span>
                            {calculatorResults?.fiveYearIRRRiskAdjusted ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '10' ? (
                          <span>
                            {calculatorResults?.tenYearIRRRiskAdjusted ?? 0}
                          </span>
                        ) : null}
                        {selectors.irrYear === '20' ? (
                          <span>
                            {calculatorResults?.twentyYearIRRRiskAdjusted ?? 0}
                          </span>
                        ) : null}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td width="25%">Payback Period</td>
                      <td width="37.5%" colSpan={2}>
                        {calculatorResults?.paybackPeriodConventional ? (
                          <span>
                            {asCommaSeparatedString(
                              calculatorResults.paybackPeriodConventional ?? 0,
                              2
                            )}{' '}
                            years
                          </span>
                        ) : (
                          <span>&gt; 20 years</span>
                        )}
                      </td>
                      <td width="37.5%">
                        {calculatorResults?.paybackPeriodRiskAdjusted ? (
                          <span>
                            {asCommaSeparatedString(
                              calculatorResults.paybackPeriodRiskAdjusted ?? 0,
                              2
                            )}{' '}
                            years
                          </span>
                        ) : (
                          <span>&gt; 20 years</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          {chart ? (
            <section className={cx('panelSection')}>
              <HighchartsReact
                ref={paybackPeriodChart}
                highcharts={Highcharts}
                options={chart}
              />

              <div style={{ textAlign: 'center' }}>
                <Button
                  onClick={onDownload}
                  isLoading={state.status === 'loading'}
                >
                  Download PDF
                </Button>
              </div>
            </section>
          ) : null}
        </div>
      </div>
    </div>
  );
};

InvestmentCalculator.displayName = 'InvestmentCalculator';
