import classnames from 'classnames/bind';
import React, { FC } from 'react';

import { Heading, Stack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router-dom';
import { getAggregates } from 'src/clients/api/v5.aggregates';
import { getFolderAggregates, getFolders } from 'src/clients/api/v5.folders';
import { BlockError } from 'src/components/BlockError';
import { FacilityList } from 'src/components/FacilityList';
import { FilterAndSort } from 'src/components/FilterAndSort';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { AggregateResults } from 'src/types/aggregates';
import { Folders } from 'src/types/folder';
import styles from './Dashboard.module.css';

const cx = classnames.bind(styles);

type DashboardProps = {};

const DashboardWrapper: FC<{
  aggregates: AggregateResults;
  folders: Folders;
}> = ({ children, aggregates, folders }) => (
  <Stack direction="column" spacing="4" padding={6}>
    <Heading fontSize="32px" flex="1">
      Facility Dashboard
    </Heading>
    <div className={cx('content')}>
      <FilterAndSort aggregates={aggregates} folders={folders} />
      {children}
    </div>
  </Stack>
);

export const Dashboard: FC<
  DashboardProps & RouteComponentProps<{ year: string }>
> = () => {
  const { folderId } = useFilterAndSort();

  const { data: folderData, isLoading: isLoadingFolders } = useQuery(
    ['folders'],
    () => getFolders()
  );

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ['aggregates', ...(folderId ? ['folder', folderId] : [])],
    () => (folderId ? getFolderAggregates(folderId) : getAggregates()),
    {
      cacheTime: 0,
      enabled: !isLoadingFolders,
    }
  );

  const aggregates =
    data?.status === 'success' && data?.value.data ? data?.value.data : [];

  const folders =
    folderData?.status === 'success' && folderData?.value.data
      ? folderData?.value.data
      : [];

  if (isLoading || isFetching || isLoadingFolders) {
    return (
      <DashboardWrapper aggregates={aggregates} folders={folders}>
        <LoadingSpinner block />
      </DashboardWrapper>
    );
  }

  if (error || !data || data.status === 'error') {
    const noFacilities = data?.code === 404;

    return (
      <DashboardWrapper aggregates={aggregates} folders={folders}>
        <BlockError
          retry={noFacilities ? undefined : refetch}
          isLoading={isFetching}
          title={
            noFacilities ? 'No facilities found' : 'Error loading facilities'
          }
          message={
            noFacilities
              ? ''
              : 'We were unable to load your facilities successfully'
          }
        />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper aggregates={aggregates} folders={folders}>
      <FacilityList aggregates={aggregates} />
    </DashboardWrapper>
  );
};

Dashboard.displayName = 'Dashboard';
