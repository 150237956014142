import { Button as ChakraButton, Flex, Text } from '@chakra-ui/react';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames/bind';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { CheckboxInput } from 'src/components/CheckboxInput';
import { Copy } from 'src/components/Copy';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { useFacilityRiskFilter } from 'src/hooks/useFacilityRiskFilter';
import { AggregateResults } from 'src/types/aggregates';
import styles from './FacilityRiskFilter.module.css';
import SectionInfo from './SectionInfo';

const cx = classnames.bind(styles);
const SECTIONS = [
  {
    key: 'danger',
    label: 'HIGH WATER SCARCITY/HIGH GROWTH',
    filterKey: 'HIGH-HIGH',
    Info: SectionInfo.Danger,
  },
  {
    key: 'warning',
    label: 'HIGH WATER SCARCITY/LOW GROWTH',
    filterKey: 'HIGH-LOW',
    Info: SectionInfo.Warning,
  },
  {
    key: 'success',
    label: 'LOW WATER SCARCITY/HIGH GROWTH',
    filterKey: 'LOW-HIGH',
    Info: SectionInfo.Success,
  },
  {
    key: 'primary',
    label: 'LOW WATER SCARCITY/LOW GROWTH',
    filterKey: 'LOW-LOW',
    Info: SectionInfo.Primary,
  },
] as const;

type FacilityRiskFilterSectionProps = {
  isOpen: boolean;
  section: { key: string; label: string; Info: FC };
  onToggle: (id: string) => void;
  aggregates: AggregateResults;
};

const FacilityRiskFilterSection: FC<FacilityRiskFilterSectionProps> = ({
  isOpen,
  section,
  onToggle,
  aggregates,
}) => {
  const [openInfo, setOpenInfo] = useState<number | null>(null);
  const {
    allAggregates,
    hiddenAggregates,
    toggleFacilityVisibility,
  } = useFacilityRiskFilter();

  const { Info } = section;

  return (
    <section className={cx('section', section.key)}>
      <header
        className={cx('sectionHeader')}
        onClick={() => onToggle(section.key)}
      >
        <span>{section.label}</span>
        <Icon
          size="24"
          path={mdiChevronRight}
          className={cx('icon', { open: isOpen })}
        />
      </header>
      {isOpen ? (
        <section className={cx('sectionContent')}>
          <header>
            <small>
              {aggregates.length} of {allAggregates?.length}
            </small>
          </header>
          {aggregates.length ? (
            aggregates.map((aggregate) => (
              <div className={cx('sectionLineItem')} key={aggregate.facilityId}>
                <div className={cx('sectionLineItemTitleBar')}>
                  <CheckboxInput
                    name={`${aggregate.facilityId}`}
                    onChange={() => {
                      toggleFacilityVisibility &&
                        toggleFacilityVisibility(aggregate.facilityId);
                    }}
                    value={!hiddenAggregates?.has(aggregate.facilityId)}
                    className={cx('sectionLineItemInput')}
                  />
                  <div
                    className={cx('sectionLineItemLabel')}
                    onClick={() => {
                      openInfo === aggregate.facilityId
                        ? setOpenInfo(null)
                        : setOpenInfo(aggregate.facilityId);
                    }}
                  >
                    <Text>
                      [{aggregate.facilityRank}]{' '}
                      {aggregate.facility.facilityName}
                    </Text>
                  </div>
                </div>

                {openInfo === aggregate.facilityId ? (
                  <div className={cx('additionalInfo')}>
                    <Info />
                    {aggregate.waaFacility ? (
                      <Link to={`/app/facilities/${aggregate.facilityId}/waa`}>
                        <Button className={cx('infoAction')}>
                          Update this facility's Water Action Assessment
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          state: {
                            facilityId: aggregate.facilityId,
                          },
                          pathname: `/app/facilities/create/waa`,
                        }}
                      >
                        <Button className={cx('infoAction')}>
                          Take the Water Action Assessment
                        </Button>
                      </Link>
                    )}
                    <Link
                      to={{
                        state: {
                          facilityId: aggregate.facilityId,
                        },
                        pathname: `/app/facilities/${aggregate.facilityId}/calculator`,
                      }}
                    >
                      <Button className={cx('infoAction')}>
                        Prioritize Investments in Water Projects
                      </Button>
                    </Link>
                  </div>
                ) : null}
              </div>
            ))
          ) : (
            <div className={cx('empty')}>No facilities found.</div>
          )}
        </section>
      ) : null}
    </section>
  );
};

type ReducedAggregateResults = {
  danger: AggregateResults;
  warning: AggregateResults;
  success: AggregateResults;
  primary: AggregateResults;
};
type FacilityRiskFilterProps = {};

export const FacilityRiskFilter: FC<FacilityRiskFilterProps> = () => {
  const {
    allAggregates,
    hiddenAggregates,
    toggleVisibility,
  } = useFacilityRiskFilter();
  const [activeSection, setActiveSection] = useState<string | null>('danger');

  const handleToggle = (value: string) => {
    setActiveSection(value === activeSection ? null : value);
  };

  const reducedAggregates = useDeepCompareMemo(() => {
    return (allAggregates ?? []).reduce<ReducedAggregateResults>(
      (acc, aggregate) => {
        switch (aggregate.twoByTwoQuadrant) {
          case 'HIGH-HIGH': {
            acc.danger.push(aggregate);
            return acc;
          }

          case 'HIGH-LOW': {
            acc.warning.push(aggregate);
            return acc;
          }

          case 'LOW-HIGH': {
            acc.success.push(aggregate);
            return acc;
          }

          case 'LOW-LOW': {
            acc.primary.push(aggregate);
            return acc;
          }

          default: {
            return acc;
          }
        }
      },
      {
        danger: [],
        warning: [],
        success: [],
        primary: [],
      }
    );
  }, [allAggregates]);

  return (
    <div className={cx('facilityRiskFilter')}>
      <Copy as="h3" className={cx('sidebarHeader')}>
        Facility Rank &amp; Action Plan by Risk
      </Copy>
      <Flex alignItems="center" justifyContent="flex-end">
        <ChakraButton
          size="xs"
          variant="link"
          mb="2"
          onClick={() => toggleVisibility && toggleVisibility()}
        >
          {hiddenAggregates && hiddenAggregates.size > 0
            ? 'Select All'
            : 'Deselect All'}
        </ChakraButton>
      </Flex>
      {SECTIONS.map((section) => (
        <FacilityRiskFilterSection
          key={section.key}
          isOpen={activeSection === section.key}
          section={section}
          aggregates={reducedAggregates[section.key]}
          onToggle={handleToggle}
        />
      ))}
    </div>
  );
};

FacilityRiskFilter.displayName = 'FacilityRiskFilter';
