import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { mdiAlertCircle, mdiProgressUpload, mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getActiveBatchUploads } from 'src/clients/api/upload';
import { useAuth } from 'src/hooks/useAuth';

type UploadStatusProps = {};

type DisplayStatus = {
  status: 'idle' | 'active' | 'error';
};

export const UploadStatus: FC<UploadStatusProps> = () => {
  const { isAuthenticated, accountId } = useAuth();
  const timeout = useRef<number | null>(null);

  const [display, setDisplay] = useState<DisplayStatus>({
    status: 'idle',
  });

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['activeUploads'],
    () => getActiveBatchUploads(),
    {
      enabled: Boolean(isAuthenticated && accountId),
    }
  );

  const check = useCallback(() => window.setTimeout(refetch, 20000), [refetch]);

  const checking = isLoading || isFetching;
  const isSuccess = data?.status === 'success';

  useEffect(() => {
    if (!checking) {
      if (isSuccess) {
        const uploads = data.value.data;

        const statuses = new Set(uploads.map((upload) => upload.status));
        const hasErrors = uploads.find((u) => u.errorCount > 0);

        if (statuses.has('unprocessed')) {
          setDisplay({
            status: 'active',
          });

          // Turn on polling because there are still unprocessed uploads
          timeout.current = check();
        } else if (hasErrors) {
          setDisplay({
            status: 'error',
          });

          // Turn on polling because there are still errored uploads
          timeout.current = check();
        } else {
          setDisplay({
            status: 'idle',
          });
        }
      }
    }

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [data, isSuccess, check, checking]);

  if (!isAuthenticated || !isSuccess || display.status === 'idle') {
    return null;
  }

  return (
    <Tooltip
      hasArrow
      label={
        display.status === 'error'
          ? 'Error processing facilities'
          : 'Processing Facilities'
      }
      aria-label="Upload Status Description"
    >
      <Flex position="relative">
        <IconButton
          as={Link}
          to="/app/uploads"
          icon={
            <Icon
              path={mdiProgressUpload}
              size="32px"
              style={{ fill: 'currentColor' }}
            />
          }
          aria-label="Upload Status"
          variant="link"
          onClick={() => refetch()}
        />

        <Box
          position="absolute"
          bottom="-2px"
          right="-2px"
          borderRadius="50%"
          backgroundColor="white"
          padding="1px"
        >
          {display.status === 'error' ? (
            <Icon
              path={mdiAlertCircle}
              size="14px"
              color="var(--chakra-colors-red-500)"
            />
          ) : (
            <Icon
              path={mdiRefresh}
              size="14px"
              color="var(--chakra-colors-blue-500)"
              spin
            />
          )}
        </Box>
      </Flex>
    </Tooltip>
  );
};

UploadStatus.displayName = 'UploadStatus';
