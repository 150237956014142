import classnames from 'classnames/bind';
import React, { FC, useMemo } from 'react';
import { ParsedGuide } from 'src/clients/api/v5.facility';
import { StepsTable } from '../../../StepsTable';
import { Text } from '@chakra-ui/react';
import styles from '../../Tabs.module.css';
import { BlockError } from 'src/components/BlockError';

const cx = classnames.bind(styles);

type ToolsAndResourcesProps = {
  guide: ParsedGuide['ToolsAndResources'];
};

export const ToolsAndResources: FC<ToolsAndResourcesProps> = ({ guide }) => {
  try {
    const steps = useMemo(() => guide.Step.filter(Boolean), [guide]);

    return (
      <div className={cx('facilityTargetTab')}>
        <Text
          as="h3"
          fontSize="32px"
          fontWeight="bold"
          lineHeight="48px"
          mb="26px"
        >
          Tools and Resources
        </Text>
        <StepsTable steps={steps} />
      </div>
    );
  } catch (error) {
    return <BlockError />;
  }
};

ToolsAndResources.displayName = 'ToolsAndResources';
