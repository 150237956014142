import classnames from 'classnames/bind';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC } from 'react';
import { Copy } from 'src/components/Copy';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { AggregateResult } from 'src/types/aggregates';
import { asCommaSeparatedString } from 'src/utils/number';
import {
  COLOR_MAP,
  outgoingRiskChartDataGenerator,
} from '../../../../utils/chart';
import styles from '../../Tabs.module.css';

const cx = classnames.bind(styles);

type OutgoingRiskProps = {
  aggregate: AggregateResult;
};

const legend = {
  usdPerYear: [
    { label: 'Quality Risk', rowKey: 'quality-risk' },
    { label: 'Water Bill', rowKey: 'waterbill' },
    { label: 'Combined', rowKey: 'total' },
  ] as const,
  riskAdjustedPricing: [
    { label: 'Quality Risk Premium', rowKey: 'quality' },
    { label: 'Water Bill Unit Price', rowKey: 'waterbill' },
    { label: 'Combined Risk Adjusted Price', rowKey: 'combined' },
  ] as const,
};
const legendYears = [1, 3, 5, 10] as const;
const legendColors = ['#d7eab8', '#a7d162', '#cccccc'];

export const OutgoingRisk: FC<OutgoingRiskProps> = ({ aggregate }) => {
  const attributes = aggregate.charts.outgoingWaterRiskChart.attributes;
  const chart = useDeepCompareMemo<Highcharts.Options>(
    () =>
      outgoingRiskChartDataGenerator(aggregate.charts.outgoingWaterRiskChart),
    [aggregate.charts.outgoingWaterRiskChart]
  );

  const score =
    COLOR_MAP[aggregate.individualQualityRiskLevel] ?? COLOR_MAP['N'];

  return (
    <div className={cx('facilityTargetTab')}>
      <div className={cx('header')}>
        <Copy as="h2">Outgoing Risk</Copy>
        <Copy as="p">
          Outgoing water risk premium places a monetary value on the local
          environmental and human-health impacts of water pollution and the
          future costs of water treatment. Local water stress, local water
          quality and local population density and are all variables that impact
          the size of the risk premium. Locations that have high levels of water
          stress and high population densities will usually have a higher risk
          premium; however, the type of water pollutant and its treatment method
          will also influence the value.
        </Copy>
        <Copy as="p">
          Outgoing Risk Likelihood Score ={' '}
          <span className={cx('score', score.color)}>{score.text}</span>
        </Copy>
      </div>

      <HighchartsReact highcharts={Highcharts} options={chart} />

      <table className={cx('legendTable')}>
        <tbody>
          <tr className={cx('legendTableHeader')}>
            <td>USD per year</td>
            <td>1 year</td>
            <td>3 year</td>
            <td>5 year</td>
            <td>10 year</td>
          </tr>
          {legend['usdPerYear'].map(({ label, rowKey }, index, arr) => (
            <tr
              className={cx({
                strong: index === arr.length - 1,
              })}
            >
              <td className={cx('label')}>
                <span
                  className={cx('icon')}
                  style={{ backgroundColor: legendColors[index] }}
                ></span>
                <span className={cx('value')}>{label}</span>
              </td>
              {legendYears.map((number) => {
                const key = `year${number}-${rowKey}` as const;
                return (
                  <td>
                    <span
                      className={cx('icon')}
                      style={{ backgroundColor: legendColors[index] }}
                    ></span>
                    <span className={cx('value')}>
                      {asCommaSeparatedString(attributes[key])}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
          <tr className={cx('legendTableHeader')}>
            <td>
              Risk Adjusted Unit Pricing (USD per m<sup>3</sup>)
            </td>
            <td>1 year</td>
            <td>3 year</td>
            <td>5 year</td>
            <td>10 year</td>
          </tr>
          {legend['riskAdjustedPricing'].map(
            ({ label, rowKey }, index, arr) => (
              <tr
                className={cx({
                  strong: index === arr.length - 1,
                })}
              >
                <td className={cx('label')}>
                  <span
                    className={cx('icon')}
                    style={{ backgroundColor: legendColors[index] }}
                  ></span>
                  <span className={cx('value')}>{label}</span>
                </td>
                {legendYears.map((number) => {
                  const key = `year${number}-${rowKey}-unit-price` as const;
                  return (
                    <td>
                      <span
                        className={cx('icon')}
                        style={{ backgroundColor: legendColors[index] }}
                      ></span>
                      <span className={cx('value')}>
                        {asCommaSeparatedString(attributes[key], 2)}
                      </span>
                    </td>
                  );
                })}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

OutgoingRisk.displayName = 'OutgoingRisk';
