import { ParsedUploadRowResults } from 'src/types/upload';
import { Facility } from 'src/types/v5.facility';

export function mapDefaultFacilityFormValues(
  seed?: Partial<ParsedUploadRowResults>
): Partial<Facility> {
  const mapped = {
    active: true,
    cityId: undefined,
    countryId: undefined,
    facilityName: '',
    modelingOnly: false,
    regionId: undefined,
    sector3Id: undefined,
    facilityLabel: '',

    ...(seed
      ? {
          active: seed.active?.toLocaleLowerCase() === 'yes',
          cityId: seed.cityId ? Number(seed.cityId) : undefined,
          countryId: seed.countryId ? Number(seed.countryId) : undefined,
          facilityName: seed.facilityName ? seed.facilityName : undefined,
          modelingOnly: seed.modelingOnly?.toLocaleLowerCase() === 'yes',
          regionId: seed.regionId ? Number(seed.regionId) : undefined,
          sector1Id: seed.sector1Id ? Number(seed.sector1Id) : undefined,
          sector2Id: seed.sector2Id ? Number(seed.sector2Id) : undefined,
          sector3Id: seed.sector3Id ? Number(seed.sector3Id) : undefined,
          facilityLabel: seed.facilityLabel ? seed.facilityLabel : undefined,
        }
      : {}),
  };

  return mapped;
}
