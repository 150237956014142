import React, { FC, useEffect } from 'react';
import { Env } from '../../../constants/env';

export interface DashboardProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Dashboard: FC<DashboardProps> = () => {
  useEffect(() => {
    window.location.href = Env.apiUrl.replace('/api', '');
  }, []);

  return <div />;
};

Dashboard.displayName = 'Dashboard';
