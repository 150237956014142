import * as React from 'react';

const SvgFacilityFolders = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="FacilityFolders_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 36 28.9"
      xmlSpace="preserve"
      data-testid="svg-icon"
      {...props}
    >
      <path
        d="M32.8 3.6H17.7c-.4 0-1-.5-1.3-1.2C16 1 14.7 0 13.6 0H3.2C1.4 0 0 1.4 0 2.2v22.5c0 2.8 1.4 4.2 3.2 4.2h29.6c1.8 0 3.2-1.4 3.2-3.2v-18c0-3.3-1.1-4.1-3.2-4.1zM1.9 3.4c0-.8.7-1.5 1.5-1.5h10.2c.4 0 1 .5 1.3 1.2.4 1.3 1.7 2.3 2.9 2.3h14.9c1.3 0 1.5 1.1 1.5 2H18c-.9 0-2 .5-2.6 1.1l-.7.7c-.3.3-1 .6-1.4.6H1.9V3.4zm32.3 22.3c0 .8-.7 1.5-1.5 1.5H3.3c-.8 0-1.5-.7-1.5-1.5v-14h11.5c.9 0 2-.5 2.6-1.1l.7-.7c.3-.3 1-.6 1.4-.6h16.2v16.4z"
        className="FacilityFolders_svg__st0"
      />
      <path
        d="M31.3 19.7h-6.1v6.1h-3v-6.1h-6v-2.9h6v-6.1h3v6.1h6.1v2.9z"
        className="FacilityFolders_svg__st0"
      />
    </svg>
  );
};

const MemoSvgFacilityFolders = React.memo(SvgFacilityFolders);
export default MemoSvgFacilityFolders;
