import { ProcessedFacility } from 'src/types/processedFacility';
import { DEFAULT_CHART } from './utils';

export function riskFactorChartDataGenerator({
  attributes,
}: ProcessedFacility['charts']['waterRiskPremiumScarcityQualityChart']): Highcharts.Options {
  return {
    ...DEFAULT_CHART,
    chart: {
      type: 'pie',
      ...DEFAULT_CHART.chart,
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.name}: {point.y}%<br/>',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        showInLegend: true,
        dataLabels: {
          connectorPadding: -20,
          distance: 25,
        },
      },
    },
    series: [
      {
        data: [
          {
            name: 'Quantity',
            color: '#bde2d2',
            y: attributes.segment1,
          },
          {
            name: 'Quality',
            color: '#5e9c9a',
            y: attributes.segment2,
          },
        ],
        size: '80%',
        innerSize: '70%',
        //@ts-ignore
        dataLabels: {
          formatter: function (this: { percentage: number }): string {
            return `${Math.round(this.percentage)}%`;
          },
          style: {
            fontSize: '20px',
            color: '#2d2d2d',
            fontWeight: 'bold',
          },
        },
      },
    ],
  };
}
