import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  ModalFooter,
  useToast,
  useBoolean,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { mdiTransferUp } from '@mdi/js';
import React from 'react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { createTransfer } from 'src/clients/api/v5.transfers';
import { Icon } from 'src/components/Icon';
import { useFilterAndSort } from 'src/hooks/useFilterAndSort';
import { Folder } from 'src/types/folder';
import * as yup from 'yup';

type TransferModalProps = {
  folderId: Folder['folderId'];
  isOpen: boolean;
  onClose: () => void;
};

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const TransferModal: FC<TransferModalProps> = ({
  folderId,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { setFilterState } = useFilterAndSort();
  const [isLoading, setIsLoading] = useBoolean();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const handleTransferFolder = handleSubmit(async (values) => {
    try {
      setIsLoading.on();
      const result = await createTransfer(folderId, values.email);

      if (result.status !== 'success') {
        throw Error(result.value.errors[0].message);
      }

      toast({
        title: 'Transfer submitted successfully!',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      setFilterState!('folderId', null);
      queryClient.refetchQueries(['folders']);
      queryClient.invalidateQueries(['aggregates']);
      queryClient.invalidateQueries(['transfers']);

      onClose();
    } catch (e) {
      toast({
        title: e?.message?.length
          ? e.message
          : 'There was an error transfering the folder.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading.off();
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="muted">
          <Icon path={mdiTransferUp} /> Transfer Folder
        </ModalHeader>
        <ModalCloseButton tabIndex={4} />
        <ModalBody>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Recipient Email</FormLabel>
            <Input placeholder=" " name="email" ref={register} tabIndex={1} />
            <FormHelperText fontSize="xs">
              Enter the email of the account you want to send this folder to.
              Once a folder has been transferred, you will no longer have access
              to the facilities.
            </FormHelperText>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent={'space-between'}>
          <Button
            color="brand.primary"
            variant="ghost"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="brand"
            disabled={!isDirty || (isDirty && !isValid)}
            isLoading={isLoading}
            onClick={handleTransferFolder}
          >
            Transfer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

TransferModal.displayName = 'TransferModal';
