import { Env } from 'src/constants/env';
import request from '../request';
import { WrappedResponse } from '../types';

export type CheckVersionResponseType = WrappedResponse<{
  maintenance: boolean;
  upgrade: boolean;
  upcomingMaintenance: boolean;
  message: string;
  version: string;
}>;

export function checkVersion() {
  return request.get<CheckVersionResponseType>(
    `${Env.apiUrl}/status/${Env.appVersion.toFixed(1)}`
  );
}
