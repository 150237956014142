import {
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  OrderedList,
  Stack,
} from '@chakra-ui/react';
import React, { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { postWrmBatchUpload } from 'src/clients/api/upload';
import { Button as LegacyButton } from 'src/components/Button';
import { Copy } from 'src/components/Copy';
import { TextInput } from 'src/components/TextInput';
import { AsyncStateType } from 'src/hooks/useAsyncState';

type UploaderProps = {
  asyncUploadState: AsyncStateType;
  setAsyncUploadState: Dispatch<SetStateAction<AsyncStateType>>;
};

export const Uploader: FC<UploaderProps> = ({
  asyncUploadState,
  setAsyncUploadState,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleUpload = async () => {
    const value = fileRef.current;
    if (value && value.files) {
      try {
        setAsyncUploadState({ status: 'loading' });

        const result = await postWrmBatchUpload(value.files[0]);

        if (result.status === 'error') {
          throw new Error(
            result.value.errors?.at(0)?.message ??
              'There was an error uploading your file.'
          );
        }

        setAsyncUploadState({
          status: 'success',
          message:
            'File uploaded successfully, check status of active uploads below.',
        });

        queryClient.refetchQueries('activeUploads');
      } catch (e) {
        setAsyncUploadState({
          status: 'error',
          message: e.message ?? 'There was an error uploading your file.',
        });
      }
    }
  };

  const handleDownload = async () => {
    window.open(`/documents/swn-batch-upload-template.xlsx`, '_self');
  };

  return (
    <Box>
      <Flex direction="column" boxShadow="base" borderBottomRadius="base">
        <Heading
          size="md"
          p="4"
          bg="brand.primary"
          color="white"
          borderTopRadius="base"
        >
          Upload Instructions
        </Heading>
        <OrderedList p="4">
          <ListItem>
            <Copy>
              Download the facility information template{' '}
              <Button variant="link" onClick={handleDownload}>
                here
              </Button>
            </Copy>
          </ListItem>
          <ListItem>
            <Copy>
              Complete the template by entering your facility information
            </Copy>
          </ListItem>
          <ListItem>
            <Copy>
              Save the Facility Form Questions tab from the template as a .csv
              and upload the file for automatic data entry
            </Copy>
          </ListItem>
          <ListItem>
            <Copy>
              Once you've completed the .csv template, select your file to
              complete the batch facility upload.
            </Copy>
          </ListItem>
        </OrderedList>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          gap="2"
          p="4"
        >
          <TextInput
            type="file"
            onChange={(value) => setCanUpload(!!value)}
            ref={fileRef}
          />
          <Button
            isLoading={asyncUploadState.status === 'loading'}
            width={{ base: 'full', md: 'auto' }}
            onClick={handleUpload}
            disabled={!canUpload}
            variant="brand"
            as={LegacyButton}
          >
            Upload File
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

Uploader.displayName = 'Uploader';
