import classnames from 'classnames/bind';
import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Pressable } from '../Pressable';

import styles from './AppFooter.module.css';

const cx = classnames.bind(styles);

type AppFooterProps = {};

export const AppFooter: FC<AppFooterProps> = () => {
  return (
    <div className={cx('appFooter')}>
      <Flex as={'section'} className={cx('section')} alignItems="center">
        <Text color="white" size="sm">
          Developed by Ecolab in collaboration with S&P Global Sustainable1
        </Text>
      </Flex>
      <section className={cx('section', 'links')}>
        <Pressable to="/terms-of-service" className={cx('footerLink')}>
          <Text color="white" size="sm">
            Terms of Service
          </Text>
        </Pressable>
        <Pressable
          to="https://www.ecolab.com/privacy-policy"
          className={cx('footerLink')}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Text color="white" size="sm">
            Privacy Notice
          </Text>
        </Pressable>
        <Pressable to="/contact" className={cx('footerLink')}>
          <Text color="white" size="sm">
            Contact
          </Text>
        </Pressable>
      </section>
      <section className={cx('section')}>
        &copy; {new Date().getFullYear()} Smart Water Navigator, All Rights
        Reserved
      </section>
    </div>
  );
};

AppFooter.displayName = 'AppFooter';
