import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './RiskAnalysisChart.module.css';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';
import { riskAnalysisChartDataGenerator } from 'src/utils/chart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useFacilityRiskFilter } from 'src/hooks/useFacilityRiskFilter';

const cx = classnames.bind(styles);

type RiskAnalysisChartProps = {};

export const RiskAnalysisChart: FC<RiskAnalysisChartProps> = () => {
  const { filteredAggregates, hiddenAggregates } = useFacilityRiskFilter();

  const chart = useDeepCompareMemo(
    () => riskAnalysisChartDataGenerator(filteredAggregates, hiddenAggregates),
    [filteredAggregates, hiddenAggregates]
  );

  return (
    <div className={cx('riskChart')}>
      <div className={cx('twoByTwo')}>
        <div className={cx('chartLabels')}>
          <div className={cx('label', 'borderRight', 'labelBg')}>
            Monitor basin conditions and regional growth
          </div>
          <div className={cx('label', 'borderLeft', 'labelBg')}>
            Prioritize these locations for water reduction investment
          </div>
        </div>
        <p className={cx('yAxisLabel', 'vertical')}>
          User-defined revenue or output (over 3 years)
        </p>
        <div className={cx('chartLabels', 'vertical')}>
          <div className={cx('label')}>
            <h5>Low Growth</h5>
          </div>
          <div className={cx('label')}>
            <h5>High Growth</h5>
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={chart} />
        <div className={cx('chartLabels')}>
          <div className={cx('label', 'borderRight', 'labelBg')}>
            <span>Maintain Operational Efficiency</span>
          </div>
          <div className={cx('label', 'borderLeft', 'labelBg')}>
            <span>
              Facilities for engagement with local water users and stakeholders
              to ensure continued license to operate.
            </span>
          </div>
        </div>
        <div className={cx('chartLabels')}>
          <div className={cx('label')}>
            <h5>Low water scarcity</h5>
          </div>
          <div className={cx('label')}>
            <h5>High water scarcity</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

RiskAnalysisChart.displayName = 'RiskAnalysisChart';
