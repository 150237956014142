import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './RadioList.module.css';
import { FieldError } from 'react-hook-form';

const cx = classnames.bind(styles);

type RadioListProps = {
  items: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
  error?: FieldError;
};

export const RadioList: FC<RadioListProps> = ({
  items,
  value,
  onChange,
  error,
}) => {
  return (
    <div className={cx('radioList')}>
      {items.map(({ value: v, label }) => (
        <label key={v} className={cx('radioListItem')}>
          <input
            type="radio"
            value={v}
            checked={v === value}
            onChange={(e) => onChange(e.target.value)}
            className={cx('radioListInput')}
          />
          <span className={cx('radioListLabel')}>{label}</span>
        </label>
      ))}
      {error && <span className={cx('error')}>{error.message}</span>}
    </div>
  );
};

RadioList.displayName = 'RadioList';
