import { WrappedResponse } from '../types';
import request, {
  requestError,
  requestSuccess,
  getAuthHeaders,
} from '../request';
import { urlWithAccountId } from '../helpers';
import { ActiveUpload, ActiveUploadWithRows } from 'src/types/upload';

export type SaveWrmBatchUploadRequestType = {};
export type SaveWrmBatchUploadResponseType = WrappedResponse<{}>;
export function postWrmBatchUpload(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', file.name);

  const headers = new Headers({
    ...getAuthHeaders(),
  });
  headers.delete('Content-Type');

  return request
    .raw(urlWithAccountId(`batch-upload/facilities`), {
      method: 'POST',
      headers,
      body: formData,
    })
    .then(requestSuccess)
    .catch(requestError);
}

export type GetActiveBatchUploadsResponseType = WrappedResponse<
  Array<ActiveUpload>
>;
export function getActiveBatchUploads() {
  return request.get<GetActiveBatchUploadsResponseType>(
    urlWithAccountId(`uploads`)
  );
}

export type GetActiveBatchUploadResponseType = WrappedResponse<
  ActiveUploadWithRows
>;
export function getActiveBatchUpload(uploadId: string) {
  return request.get<GetActiveBatchUploadResponseType>(
    urlWithAccountId(`uploads/${uploadId}`)
  );
}

export type DeleteActiveBatchUploadResponseType = WrappedResponse<{}>;
export function deleteActiveBatchUpload(uploadId: string) {
  return request.del<DeleteActiveBatchUploadResponseType>(
    urlWithAccountId(`uploads/${uploadId}`)
  );
}

export type DeleteActiveBatchUploadRowResponseType = WrappedResponse<{}>;
export function deleteActiveBatchUploadRow(uploadId: string, rowId: string) {
  return request.del<DeleteActiveBatchUploadRowResponseType>(
    urlWithAccountId(`uploads/${uploadId}/rows/${rowId}`)
  );
}
