import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

type EnterpriseProps = {};

export const Enterprise: FC<EnterpriseProps> = () => (
  <Redirect to="/app/enterprise/risk-analysis" />
);

Enterprise.displayName = 'Enterprise';
