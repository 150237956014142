import React, { FC } from 'react';
import classnames from 'classnames/bind';

import styles from './RouteContentScrollable.module.css';

const cx = classnames.bind(styles);

export interface RouteContentScrollableProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const RouteContentScrollable: FC<RouteContentScrollableProps> = ({
  className,
  ...props
}) => {
  return <div {...props} className={cx('routeContentScrollable', className)} />;
};

RouteContentScrollable.displayName = 'RouteContentScrollable';
