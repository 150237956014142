import natsort from 'natsort';
import React, { ReactNode, useState } from 'react';
import { AggregateResults } from 'src/types/aggregates';
import { useDeepCompareMemo } from './useDeepCompare';

type FacilityRiskFilterContextMethods = {
  filter: () => void;
  toggleFacilityVisibility: (id: number) => void;
};

type FacilityRiskFilterContextPropsType = {
  hiddenAggregates: Set<number>;
  allAggregates: AggregateResults;
  filteredAggregates: AggregateResults;
  toggleVisibility: (state?: VISIBILITY_STATE) => void;
};

type FacilityRiskFilterContextProps = FacilityRiskFilterContextMethods &
  FacilityRiskFilterContextPropsType;

const FacilityRiskFilterContext = React.createContext<
  Partial<FacilityRiskFilterContextProps>
>({});

const sorter = natsort();

enum VISIBILITY_STATE {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

function FacilityRiskFilterProvider({
  children,
  aggregates,
}: {
  children: ReactNode;
  aggregates: AggregateResults;
}) {
  const [hiddenAggregates, setHiddenAggregates] = useState<Set<number>>(
    new Set()
  );

  const toggleVisibility = (state?: VISIBILITY_STATE) => {
    if (state) {
      return state === VISIBILITY_STATE.VISIBLE
        ? setHiddenAggregates(new Set())
        : setHiddenAggregates(new Set(aggregates.map((a) => a.facilityId)));
    }

    if (hiddenAggregates.size === 0) {
      return setHiddenAggregates(new Set(aggregates.map((a) => a.facilityId)));
    }

    return setHiddenAggregates(new Set());
  };

  const toggleFacilityVisibility = (id: number) => {
    const set = new Set(hiddenAggregates);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }

    setHiddenAggregates(new Set(set));
  };

  const sortedAggregates: AggregateResults = useDeepCompareMemo(
    () =>
      aggregates
        .sort((a, b) =>
          sorter(a.facility.facilityName, b.facility.facilityName)
        )
        .map((a, idx) => ({ ...a, idx: idx + 1 })),
    [aggregates]
  );

  const filteredAggregates: AggregateResults = useDeepCompareMemo(() => {
    return sortedAggregates.filter((f) => !hiddenAggregates.has(f.facilityId));
  }, [aggregates, hiddenAggregates]);

  return (
    <FacilityRiskFilterContext.Provider
      value={{
        hiddenAggregates,
        allAggregates: sortedAggregates,
        filteredAggregates,
        toggleFacilityVisibility,
        toggleVisibility,
      }}
    >
      {children}
    </FacilityRiskFilterContext.Provider>
  );
}

const useFacilityRiskFilter = () => React.useContext(FacilityRiskFilterContext);

export { FacilityRiskFilterProvider, useFacilityRiskFilter };
