import { RouteProps } from 'src/components/Route';
import { Benchmarking } from './Benchmarking';
import { Calculator } from './Calculator';
import { Dashboard } from './Dashboard';
import { Facilities } from './Facilities';
// import { Folders } from './modules/Folders';
import { Enterprise } from './Enterprise';
import { Facility } from './Facility';
import { FacilityCreate } from './FacilityCreate';
import { FacilityEdit } from './FacilityEdit';
import { FolderManager } from './FolderManager';
import { ReportingYearCreate } from './ReportingYearCreate';
import { ReportingYearEdit } from './ReportingYearEdit';
import { RiskAnalysis } from './RiskAnalysis';
import { Upload } from './Upload';
import { Uploads } from './Uploads';
import { Redirect } from 'react-router-dom';
import React from 'react';

export const routes: Array<RouteProps> = [
  {
    path: `/app`,
    component: () => {
      return <Redirect to="app/dashboard" />;
    },
  },
  {
    path: `/app/dashboard`,
    component: Dashboard,
    variant: 'protected',
  },
  {
    path: `/app/facilities`,
    component: Facilities,
    variant: 'protected',
  },
  {
    path: `/app/facilities/new`,
    component: FacilityCreate,
    variant: 'protected',
  },
  {
    path: `/app/facilities/:id`,
    component: Facility,
    variant: 'protected',
  },
  {
    path: `/app/facilities/:id/edit`,
    component: FacilityEdit,
    variant: 'protected',
  },
  {
    path: `/app/facilities/:id/reporting-years/new`,
    component: ReportingYearCreate,
    variant: 'protected',
  },
  {
    path: `/app/facilities/:id/reporting-years/:reportingYearId`,
    component: ReportingYearEdit,
    variant: 'protected',
  },
  {
    path: `/app/facilities/:id/calculator`,
    component: Calculator,
    variant: 'protected',
  },
  {
    path: `/app/folders`,
    component: FolderManager,
    variant: 'protected',
  },
  {
    path: `/app/enterprise`,
    component: Enterprise,
    variant: 'protected',
  },
  {
    path: `/app/enterprise/risk-analysis`,
    component: RiskAnalysis,
    variant: 'protected',
  },
  {
    path: `/app/enterprise/benchmarking`,
    component: Benchmarking,
    variant: 'protected',
  },
  {
    path: `/app/uploads`,
    component: Uploads,
    variant: 'protected',
  },
  {
    path: `/app/uploads/:id`,
    component: Upload,
    variant: 'protected',
  },
];
