import { AggregateResults } from 'src/types/aggregates';
import { ChartDataNode } from 'src/types/chart';
import { DEFAULT_CHART, getScatterPlotOptions } from './utils';

export function riskAnalysisChartDataGenerator(
  aggregates?: AggregateResults,
  hiddenFacilities?: Set<number>
) {
  const series = (aggregates ?? []).reduce<{
    first: Array<ChartDataNode>;
    second: Array<ChartDataNode>;
    third: Array<ChartDataNode>;
    fourth: Array<ChartDataNode>;
  }>(
    (acc, f) => {
      if (
        !hiddenFacilities?.has(f.facilityId) &&
        typeof f.twoByTwoXAxis === 'number' &&
        !isNaN(f.twoByTwoXAxis) &&
        typeof f.twoByTwoYAxis === 'number' &&
        !isNaN(f.twoByTwoYAxis)
      ) {
        const node = {
          name: f.facility.facilityName,
          x: f.twoByTwoXAxis,
          y: f.twoByTwoYAxis,
          idx: f.facilityRank,
        };

        switch (f.twoByTwoQuadrant?.toLowerCase()) {
          case 'high-high':
            acc.first.push(node);
            break;
          case 'low-high':
            acc.second.push(node);
            break;
          case 'high-low':
            acc.third.push(node);
            break;
          case 'low-low':
            acc.fourth.push(node);
            break;
        }
      }

      return acc;
    },
    { first: [], second: [], third: [], fourth: [] }
  );

  return {
    ...DEFAULT_CHART,
    chart: {
      type: 'scatter',
      spacing: [0, 0, 0, 0],
      animation: false,
    },
    xAxis: {
      gridLineWidth: 1,
      startOnTick: true,
      endOnTick: true,
      tickLength: 0,
      plotLines: [
        {
          color: '#e6e6e6',
          dashStyle: 'Solid',
          width: 4,
          value: 0,
          zIndex: 2,
        },
      ],
      labels: {
        enabled: false,
      },
      tickPositioner: tickPositioner,
    },
    yAxis: {
      gridLineWidth: 1,
      startOnTick: true,
      endOnTick: true,
      plotLines: [
        {
          color: '#e6e6e6',
          dashStyle: 'Solid',
          width: 4,
          value: 0,
          zIndex: 2,
        },
      ],
      title: {
        text: '',
      },
      labels: {
        enabled: false,
      },
      tickPositioner: tickPositioner,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<b>{point.key}</b><br/>',
      pointFormat: '',
    },
    plotOptions: {
      scatter: getScatterPlotOptions(),
      series: {
        animation: false,
      },
    },
    series: [
      {
        color: '#e64848',
        data: series.first,
        marker: {
          symbol: 'circle',
        },
        zIndex: 4,
      },
      {
        color: '#6ba737',
        data: series.second,
        marker: {
          symbol: 'circle',
        },
        zIndex: 4,
      },
      {
        color: '#dc8f18',
        data: series.third,
        marker: {
          symbol: 'circle',
        },
        zIndex: 4,
      },
      {
        color: '#717171',
        data: series.fourth,
        marker: {
          symbol: 'circle',
        },
        zIndex: 4,
      },
    ],
  };
}

const CHART_BUFFER = 0.2;
const NUM_TICKS = 5;

function tickPositioner(this: { dataMax: number; dataMin: number }) {
  var maxDeviation = Math.max(Math.abs(this.dataMax), Math.abs(this.dataMin));
  if (!maxDeviation) {
    maxDeviation = 10;
  }
  var chartWidth = maxDeviation + maxDeviation * CHART_BUFFER;
  var separation = chartWidth / NUM_TICKS;
  var ticks = [];

  for (var i = 0; i <= 2 * NUM_TICKS; i++) {
    ticks.push(chartWidth * -1 + i * separation);
  }
  return ticks;
}
