import { Event } from '../constants/events';

export const eventBus = {
  on(event: string, callback: () => void) {
    document.addEventListener(event, callback);
  },
  dispatch(event: Event) {
    document.dispatchEvent(
      new CustomEvent(event.type, { detail: event.detail })
    );
  },
  remove(event: string, callback: () => void) {
    document.removeEventListener(event, callback);
  },
};
