import React from 'react';
import { REPORTING_YEARS } from 'src/constants/years';
import { Facility } from 'src/types/facility';
import { Pollutant } from 'src/types/pollutant';
import { ParsedUploadRowResults } from 'src/types/upload';
import { ReportingYear } from 'src/types/v5.reportingYear';
import { FacilityFormSchema } from './validation';
import { Text } from '@chakra-ui/react';
import { TabNavigatorTab } from './Helpers/FacilityTabNavigator/useTabNavigator';

export enum TabStatus {
  Warning = 'warning',
  Error = 'error',
}

export const TAB_MAP: Array<TabNavigatorTab> = [
  {
    label: 'Facility Details',
    urlQueryParam: 'facility-details',
    index: 0,
    component: (
      <Text>
        Facility Details{' '}
        <Text as="span" display="inline" color="red.500" fontSize="medium">
          *
        </Text>
      </Text>
    ),
  },
  {
    label: 'Business Information',
    urlQueryParam: 'business-information',
    index: 1,
    component: <Text>Business Information</Text>,
  },
  {
    label: 'Regulation & Reputation',
    urlQueryParam: 'regulation-reputation',
    index: 2,
    component: (
      <Text>{`Regulation ${String.fromCharCode(0x0026)} Reputation`}</Text>
    ),
  },
  {
    label: 'Water Quality',
    urlQueryParam: 'water-quality',
    index: 3,
    component: <Text>Water Quality</Text>,
  },
  {
    label: 'Pollutants',
    urlQueryParam: 'pollutants',
    index: 4,
    component: <Text>Pollutants</Text>,
  },
  {
    label: 'Water Action Assessment',
    urlQueryParam: 'water-action-assessment',
    index: 5,
    component: <Text>Water Action Assessment</Text>,
  },
];

export const TAB_INPUT_MAP = {
  reportingYear: 0,
  incomingWaterTimePeriod: 0,
  incomingWaterQuantity: 0,
  incomingWaterQuantityUnit: 0,
  incomingWaterPrice: 0,
  incomingWaterPriceCurrencyCode: 0,
  incomingWaterPriceUnit: 0,
  incomingWaterSource: 0,
  waterWithdrawalReplenishment: 0,
  waterWithdrawalAnnualizedVolumetricBenefit: 0,
  waterWithdrawalAnnualizedVolumetricBenefitUOMId: 0,
  waterWithdrawalSupportingReplenishmentWaterFund: 0,
  waterWithdrawalReplenishmentDurationInYears: 0,
  waterWithdrawalThirdPartyVerified: 0,
  waterWithdrawalThirdPartyVerifier: 0,
  outgoingWaterTimePeriod: 0,
  outgoingWaterQuantity: 0,
  outgoingWaterQuantityUnit: 0,
  outgoingWaterPrice: 0,
  outgoingWaterPriceCurrencyCode: 0,
  outgoingWaterPriceUnit: 0,
  outgoingWaterTreatmentSource: 0,
  facilityOutput: 0,
  facilityOutputUnitsOfMeasure: 0,
  projectedFacilityOutputIncrease: 0,

  // Tab Two
  droughScenario: 1,
  projectedIncomingWaterPriceIncrease: 1,
  projectedIncomingWaterQuantityIncrease: 1,
  projectedOutgoingWaterPriceIncrease: 1,
  projectedOutgoingWaterQuantityIncrease: 1,
  revenue: 1,
  revenueCurrencyCode: 1,
  projectedRevenueIncrease: 1,
  revenueLostDueToWaterScarcity: 1,

  // Tab Three
  regulationOfWaterInLocalCommunityQuestion: 2,
  facilityEnvironmentalReputationQuestion: 2,
  dependingSameWaterSourceQuestion: 2,
  exemptWaterRestrictionQuestion: 2,
  ngoLocalPressureQuestion: 2,

  // Tab Four
  incomingWaterQualityComparison: 3,
  alternativeWaterSourceQuestion: 3,
  outgoingWaterOnsiteTreatmentCapacity: 3,
  outgoingWaterOffsiteTreatmentCapacity: 3,
  incomingWaterImpairmentSalinity: 3,
  incomingWaterImpairmentOrganics: 3,
  incomingWaterImpairmentSuspendedSolids: 3,
  incomingWaterImpairmentTaste: 3,
  incomingWaterImpairmentOdor: 3,

  // Tab Five
  facilityPollutants: 4,
  ownOutgoingWaterStandards: 4,
  facilityDilutionFactor: 4,

  // Tab 6
  assessment: 5,
};

function getValueAsNumber(
  first: number | undefined,
  second: string | number | undefined
): number | undefined {
  if (first) {
    return first;
  }

  const converted = Number(second);

  if (!isNaN(converted)) {
    return converted;
  }

  return undefined;
}

function getValueAsBoolean(
  first: boolean | undefined,
  second: string | boolean | undefined
): boolean | undefined {
  if (first) {
    return first;
  }

  if (typeof second === 'string') {
    return second.toLowerCase() === 'yes';
  }

  return false;
}

export function mapDefaultReportingYearFormValues(
  reportingYear?: ReportingYear,
  hydrationData?: ParsedUploadRowResults
) {
  const { input } = reportingYear ?? {};

  const data: Partial<
    FacilityFormSchema & { facilityPollutants: Array<Pollutant> }
  > = {
    // Tab One
    reportingYear: `${
      reportingYear?.reportingYear ??
      hydrationData?.reportingYear ??
      REPORTING_YEARS[0]
    }`,

    incomingWaterTimePeriod:
      input?.incomingWaterTimePeriod ?? hydrationData?.incomingWaterTimePeriod,

    incomingWaterQuantity: getValueAsNumber(
      input?.incomingWaterQuantity,
      hydrationData?.incomingWaterQuantity
    ),

    incomingWaterQuantityUnit: getValueAsNumber(
      input?.incomingWaterQuantityUnit,
      hydrationData?.incomingWaterQuantityUnit
    ),

    incomingWaterPrice: getValueAsNumber(
      input?.incomingWaterPrice,
      hydrationData?.incomingWaterPrice
    ),

    incomingWaterPriceCurrencyCode:
      input?.incomingWaterPriceCurrencyCode ??
      hydrationData?.incomingWaterPriceCurrencyCode,

    incomingWaterPriceUnit: getValueAsNumber(
      input?.incomingWaterPriceUnit,
      hydrationData?.incomingWaterPriceUnit
    ),

    incomingWaterSource:
      input?.incomingWaterSource ?? hydrationData?.incomingWaterSource,

    waterWithdrawalReplenishment: getValueAsBoolean(
      input?.waterWithdrawalReplenishment,
      hydrationData?.waterWithdrawalReplenishment
    ),

    waterWithdrawalAnnualizedVolumetricBenefit: getValueAsNumber(
      input?.waterWithdrawalAnnualizedVolumetricBenefit,
      hydrationData?.waterWithdrawalAnnualizedVolumetricBenefit
    ),

    waterWithdrawalAnnualizedVolumetricBenefitUOMId: getValueAsNumber(
      input?.waterWithdrawalAnnualizedVolumetricBenefitUOMId,
      hydrationData?.waterWithdrawalAnnualizedVolumetricBenefitUOMId
    ),

    waterWithdrawalSupportingReplenishmentWaterFund:
      input?.waterWithdrawalSupportingReplenishmentWaterFund ??
      hydrationData?.waterWithdrawalSupportingReplenishmentWaterFund,

    waterWithdrawalReplenishmentDurationInYears: getValueAsNumber(
      input?.waterWithdrawalReplenishmentDurationInYears,
      hydrationData?.waterWithdrawalReplenishmentDurationInYears
    ),

    waterWithdrawalThirdPartyVerified: getValueAsBoolean(
      input?.waterWithdrawalThirdPartyVerified,
      hydrationData?.waterWithdrawalThirdPartyVerified
    ),

    waterWithdrawalThirdPartyVerifier:
      input?.waterWithdrawalThirdPartyVerifier ??
      hydrationData?.waterWithdrawalThirdPartyVerifier,

    outgoingWaterTimePeriod:
      input?.outgoingWaterTimePeriod ?? hydrationData?.outgoingWaterTimePeriod,

    outgoingWaterQuantity: getValueAsNumber(
      input?.outgoingWaterQuantity,
      hydrationData?.outgoingWaterQuantity
    ),

    outgoingWaterQuantityUnit: getValueAsNumber(
      input?.outgoingWaterQuantityUnit,
      hydrationData?.outgoingWaterQuantityUnit
    ),

    outgoingWaterPrice: getValueAsNumber(
      input?.outgoingWaterPrice,
      hydrationData?.outgoingWaterPrice
    ),

    outgoingWaterPriceCurrencyCode:
      input?.outgoingWaterPriceCurrencyCode ??
      hydrationData?.outgoingWaterPriceCurrencyCode,

    outgoingWaterPriceUnit: getValueAsNumber(
      input?.outgoingWaterPriceUnit,
      hydrationData?.outgoingWaterPriceUnit
    ),

    outgoingWaterTreatmentSource:
      input?.outgoingWaterTreatmentSource ??
      hydrationData?.outgoingWaterTreatmentSource,

    facilityOutput: getValueAsNumber(
      input?.facilityOutput,
      hydrationData?.facilityOutput
    ),

    facilityOutputUnitsOfMeasure:
      input?.facilityOutputUnitsOfMeasure ??
      hydrationData?.facilityOutputUnitsOfMeasure,

    projectedFacilityOutputIncrease: getValueAsNumber(
      input?.projectedFacilityOutputIncrease,
      hydrationData?.projectedFacilityOutputIncrease
    ),

    // Tab Two
    droughScenario: getValueAsBoolean(
      input?.droughScenario,
      hydrationData?.droughScenario
    ),

    projectedIncomingWaterPriceIncrease: getValueAsNumber(
      input?.projectedIncomingWaterPriceIncrease,
      hydrationData?.projectedIncomingWaterPriceIncrease
    ),

    projectedIncomingWaterQuantityIncrease: getValueAsNumber(
      input?.projectedIncomingWaterQuantityIncrease,
      hydrationData?.projectedIncomingWaterQuantityIncrease
    ),

    projectedOutgoingWaterPriceIncrease: getValueAsNumber(
      input?.projectedOutgoingWaterPriceIncrease,
      hydrationData?.projectedOutgoingWaterPriceIncrease
    ),

    projectedOutgoingWaterQuantityIncrease: getValueAsNumber(
      input?.projectedOutgoingWaterQuantityIncrease,
      hydrationData?.projectedOutgoingWaterQuantityIncrease
    ),

    revenue: getValueAsNumber(input?.revenue, hydrationData?.revenue),

    revenueCurrencyCode:
      input?.revenueCurrencyCode ?? hydrationData?.revenueCurrencyCode,

    projectedRevenueIncrease: getValueAsNumber(
      input?.projectedRevenueIncrease,
      hydrationData?.projectedRevenueIncrease
    ),

    revenueLostDueToWaterScarcity:
      input?.revenueLostDueToWaterScarcity ??
      hydrationData?.revenueLostDueToWaterScarcity,

    // Tab Three
    regulationOfWaterInLocalCommunityQuestion:
      input?.regulationOfWaterInLocalCommunityQuestion ??
      hydrationData?.regulationOfWaterInLocalCommunityQuestion,

    facilityEnvironmentalReputationQuestion:
      input?.facilityEnvironmentalReputationQuestion ??
      hydrationData?.facilityEnvironmentalReputationQuestion,

    dependingSameWaterSourceQuestion:
      input?.dependingSameWaterSourceQuestion ??
      hydrationData?.dependingSameWaterSourceQuestion,

    exemptWaterRestrictionQuestion:
      input?.exemptWaterRestrictionQuestion ??
      hydrationData?.exemptWaterRestrictionQuestion,

    ngoLocalPressureQuestion:
      input?.ngoLocalPressureQuestion ??
      hydrationData?.ngoLocalPressureQuestion,

    // Tab Four
    incomingWaterQualityComparison:
      input?.incomingWaterQualityComparison ??
      hydrationData?.incomingWaterQualityComparison,

    alternativeWaterSourceQuestion:
      input?.alternativeWaterSourceQuestion ??
      hydrationData?.alternativeWaterSourceQuestion,

    outgoingWaterOnsiteTreatmentCapacity:
      input?.outgoingWaterOnsiteTreatmentCapacity ??
      hydrationData?.outgoingWaterOnsiteTreatmentCapacity,

    outgoingWaterOffsiteTreatmentCapacity:
      input?.outgoingWaterOffsiteTreatmentCapacity ??
      hydrationData?.outgoingWaterOffsiteTreatmentCapacity,

    incomingWaterImpairmentSalinity: getValueAsBoolean(
      input?.incomingWaterImpairmentSalinity,
      hydrationData?.incomingWaterImpairmentSalinity
    ),

    incomingWaterImpairmentOrganics: getValueAsBoolean(
      input?.incomingWaterImpairmentOrganics,
      hydrationData?.incomingWaterImpairmentOrganics
    ),

    incomingWaterImpairmentSuspendedSolids: getValueAsBoolean(
      input?.incomingWaterImpairmentSuspendedSolids,
      hydrationData?.incomingWaterImpairmentSuspendedSolids
    ),

    incomingWaterImpairmentTaste: getValueAsBoolean(
      input?.incomingWaterImpairmentTaste,
      hydrationData?.incomingWaterImpairmentTaste
    ),

    incomingWaterImpairmentOdor: getValueAsBoolean(
      input?.incomingWaterImpairmentOdor,
      hydrationData?.incomingWaterImpairmentOdor
    ),

    // Tab Five
    ownOutgoingWaterStandards: getValueAsBoolean(
      input?.ownOutgoingWaterStandards,
      hydrationData?.ownOutgoingWaterStandards
    ),
    facilityPollutants: input?.facilityPollutants, // No hydration, cannot be changed on create
    facilityDilutionFactor: getValueAsNumber(
      input?.facilityDilutionFactor,
      hydrationData?.facilityDilutionFactor
    ),

    // Tab Six
    assessment: {
      questionaireId: reportingYear?.input.assessment?.questionnaireId,
      reportingyearId: reportingYear?.input.assessment?.reportingyearId,
      assessmentAnswers: {
        ...(reportingYear?.input.assessment?.assessmentAnswers.reduce<
          Record<string, string>
        >(
          (acc, answer) => ({
            ...acc,
            [answer.questionId]: answer.questionChoiceId,
          }),
          {}
        ) ?? {}),
      },
    },
  };

  return data;
}

export function mergeFacilityAndAssessment(
  reportingYear?: number,
  facility?: Facility
) {
  const data = {
    // Tab One
    facilityName: facility?.facilityName ?? '',
    fkCountry: facility?.fkCountry ?? '',
    fkState: facility?.fkState ?? '',
    fkCity: facility?.fkCity ?? '',
    level1SectorId: `${facility?.level1SectorId ?? ''}`,
    level2SectorId: `${facility?.level2SectorId ?? ''}`,
    fkSector: `${facility?.fkSector ?? ''}`,
    label: facility?.label ?? '',
    active: facility?.active ?? true,
    modelingOnly: facility?.modelingOnly ?? false,
    reportingYear: `${reportingYear ?? REPORTING_YEARS[0]}`,
  };
  return data;
}
