import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

export interface ResourcesProps extends React.HTMLAttributes<HTMLDivElement> {}

type ResourceRef = {
  key: string;
  title: string;
  subtitle?: string;
  image: string;
  href: string;
  order: number;
  light?: boolean;
};

const REPORTS: Array<ResourceRef> = [
  {
    key: 'report-1',
    title: 'You’ve Set Corporate Water Goals. Now What?',
    subtitle: 'Ecolab, S&P Global Sustainable1 & Bluerisk, 2021',
    image: 'corp-water-goals.png',
    href:
      'https://ecolab.widen.net/s/8mlk7dwnsp/smart-water-navigator-working-paper',
    order: 0,
  },
  {
    key: 'report-2',
    title: 'Global Water Report',
    subtitle: 'CDP, 2023',
    image: 'global-water-report.png',
    href:
      'https://www.cdp.net/en/research/global-reports/global-water-report-2023',
    order: 3,
  },
  {
    key: 'report-3',
    title: 'Setting Enterprise Water Targets: A Guide for Companies',
    subtitle:
      'Pacific Institute, CEO Water Mandate, CDP, The Nature Conservancy, World Resources Institute, WWF, 2021',
    image: 'setting-water-targets.png',
    href: 'https://pacinst.org/publication/enterprise-water-targets-2021/',
    order: 4,
  },
  {
    key: 'report-4',
    title: 'UN World Water Development Report',
    subtitle: 'United Nations, 2024',
    image: 'un-water-report.png',
    href:
      'https://www.unwater.org/publications/un-world-water-development-report-2024',
    order: 7,
  },
  {
    key: 'report-5',
    title:
      'Beyond Volumes: exploring the societal value of corporate water stewardship projects',
    subtitle:
      'Valuing Impact, Bluerisk & Bonneville Environmental Foundation, 2022',
    image: 'beyond-volumes.png',
    href:
      'https://www.ecolab.com/-/media/Ecolab/Ecolab-Home/Documents/DocumentLibrary/LA-Documents/SROI_WaterStewardship_2022_Final_b-pdf.pdf',
    order: 1,
  },
  {
    key: 'report-6',
    title: 'State of Green Business 2023',
    subtitle: 'GreenBiz, 2023',
    image: 'state-of-green.png',
    href: 'https://www.greenbiz.com/report/state-green-business-2023',
    order: 8,
  },
  {
    key: 'report-7',
    title: 'The Global Risks Report 2024',
    subtitle:
      'World Economic Forum & Marsh McLennan and Zurich Insurance Group, 2024',
    image: 'global-risks-report.png',
    href: 'https://www.weforum.org/publications/global-risks-report-2024/',
    order: 9,
  },
  {
    key: 'report-8',
    title:
      'Setting Enterprise Targets: Modeling Downstream Water Use and Consumption',
    subtitle: 'World Resources Institute, 2023',
    image: 'setting-enterprise-targets.png',
    href:
      'https://www.wri.org/research/setting-enterprise-targets-modeling-downstream-water-use-consumption',
    order: 2,
  },
  {
    key: 'report-9',
    title: 'Development of a Company-Level Cost-Benefit Analysis Framework',
    subtitle: 'Ceres & Bluerisk, 2023',
    image: 'cost-benefit-analysis.png',
    href:
      'https://www.ceres.org/resources/reports/development-company-level-cost-benefit-analysis-framework',
    order: 5,
  },
  {
    key: 'report-10',
    title: 'Water Stewardship 101: Starting Your Water Journey',
    subtitle: 'Figbytes & GreenBiz, 2022',
    image: 'water-stewardship.png',
    href:
      'https://www.greenbiz.com/whitepaper/water-stewardship-101-starting-your-water-journey',
    order: 6,
  },
].sort((a, b) => a.order - b.order);

const ARTICLES: Array<ResourceRef> = [
  {
    key: 'article-1',
    title: 'Is Water the ‘Forgotten Piece’ of the Climate Puzzle?',
    subtitle: 'Forbes, 2023',
    image: 'forgotten-piece.png',
    href:
      'https://www.forbes.com/sites/jamiehailstone/2023/05/22/is-water-is-the-forgotten-piece-of-the-climate-puzzle/',
    order: 0,
  },
  {
    key: 'article-3',
    title: 'How Companies Can Pursue a Positive Impact on Watersheds',
    subtitle: 'Forbes, 2023',
    image: 'positive-watershed-impacts.png',
    href:
      'https://www.forbes.com/sites/insights-ecolab/2023/05/08/how-companies-can-pursue-a-positive-impact-on-watersheds/?sh=6b5389a467ca',
    order: 1,
  },
  {
    key: 'article-4',
    title:
      'Cost of water risks to business five times higher than cost of taking action',
    subtitle: 'CDP, 2021',
    image: 'cost-of-water-risks.png',
    href:
      'https://www.cdp.net/en/articles/media/cost-of-water-risks-to-business-five-times-higher-than-cost-of-taking-action',
    order: 2,
  },
  {
    key: 'article-5',
    title: 'Water and Climate Change',
    subtitle: 'United Nations Water, 2023',
    image: 'water-and-climate-change.png',
    href: 'https://www.unwater.org/water-facts/water-and-climate-change',
    order: 3,
  },
  {
    key: 'article-7',
    title: 'Drought is on the verge of becoming the next pandemic',
    subtitle: 'The Guardian, 2023',
    image: 'verge-of-drought.png',
    href:
      'https://www.theguardian.com/news/2023/jun/15/drought-is-on-the-verge-of-becoming-the-next-pandemic?CMP=Share_iOSApp_Other',
    order: 4,
  },
  {
    key: 'article-6',
    title: 'Water Quality and Wastewater',
    subtitle: 'United Nations Water, 2023',
    image: 'water-quality-wastewater.png',
    href: 'https://www.unwater.org/water-facts/water-quality-and-wastewater',
    order: 5,
  },
  {
    key: 'article-2',
    title: 'Water: A human and business priority',
    subtitle: 'McKinsey, 2020',
    image: 'human-and-bis-priority.png',
    href:
      'https://www.mckinsey.com/capabilities/sustainability/our-insights/water-a-human-and-business-priority',
    order: 6,
  },
].sort((a, b) => a.order - b.order);

const TOOLS: Array<ResourceRef> = [
  {
    key: 'tool-1',
    title: 'World Resources Institute Aqueduct',
    image: 'aqueduct.png',
    href: 'https://www.wri.org/aqueduct',
    order: 0,
  },
  {
    key: 'tool-2',
    title: 'WWF Water Risk Filter',
    image: 'waterriskfilter.png',
    href: 'https://riskfilter.org/',
    order: 1,
  },
  {
    key: 'tool-3',
    title: 'UN CEO Water Mandate Water Stewardship Toolbox',
    image: 'watermandate.png',
    href: 'https://ceowatermandate.org/toolbox/',
    order: 2,
    light: true,
  },
  {
    key: 'tool-4',
    title: 'UN Global Compact Water Action Hub',
    image: 'wateractionhub.png',
    href: 'https://wateractionhub.org/',
    order: 3,
  },
  {
    key: 'tool-5',
    title: 'United States Environmental Protection Agency WATERS Tools',
    image: 'epa.png',
    href: 'https://www.epa.gov/waterdata/waters-tools',
    order: 4,
  },
];

const SIZES = {
  sm: '200px',
  md: '300px',
  lg: '400px',
} as const;

const Resource: FC<{
  resource: ResourceRef & { subtitle?: string };
  size?: 'sm' | 'md' | 'lg';
  variant?: 'horizontal' | 'vertical';
}> = ({
  resource: { image, title, subtitle, href, light = false },
  size = 'lg',
  variant = 'vertical',
}) => {
  const height = SIZES[size];

  return (
    <Stack
      direction="column"
      border="1px solid #eee"
      transition="all .2s ease-in-out"
      _hover={{ transform: 'translate(0, -10px)', boxShadow: 'lg' }}
      as="a"
      href={href}
      target="_blank"
    >
      {variant === 'horizontal' ? (
        <Flex
          height={height}
          w="full"
          alignItems="center"
          justifyContent="center"
          p="6"
        >
          <Box
            bg={light ? 'gray.900' : 'white'}
            p="4"
            borderRadius={light ? 'sm' : 'none'}
          >
            <Image src={`/images/resources/${image}`} />
          </Box>
        </Flex>
      ) : (
        <Flex
          backgroundImage={`/images/resources/${image}`}
          border="none"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          height={height}
          alignItems="flex-end"
          flex={`0 0 ${height}`}
          backgroundClip="content-box"
        >
          <Box
            bgGradient="linear(transparent 0%, white 100%)"
            width="full"
            height="50%"
          />
        </Flex>
      )}
      <VStack textAlign="center" p="4" pb="10" flex="1 1 auto">
        <Heading as="h4" fontSize="xl">
          {title}
        </Heading>
        {subtitle ? (
          <Text fontSize="md" color="gray.500" fontWeight="light">
            {subtitle}
          </Text>
        ) : null}
      </VStack>
    </Stack>
  );
};

export const Resources: FC<ResourcesProps> = () => {
  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/power_plant.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Resources
          </Heading>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(10vh)' }}
        p="20px"
        maxW="1920px"
      >
        <Box>
          <Heading as="h3" size="2xl" textAlign="center" mb="20px">
            Resources and Tools
          </Heading>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Text fontSize="lg">
                The Ecolab Smart Water Navigator encourages global efforts to
                help industry reuse, recycle and reduce water, supporting
                resilient operations and healthy communities. There are a number
                of resources and tools businesses can use to understand and
                address water-related risks and opportunities to better develop
                strategies for managing water.
              </Text>
            </Container>
          </Box>
        </Box>

        <Heading as="h3" size="2xl" textAlign="center">
          Reports
        </Heading>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={10}>
          {REPORTS.map((report) => (
            <Resource key={report.key} resource={report} />
          ))}
        </SimpleGrid>

        <Heading as="h3" size="2xl" textAlign="center">
          Articles
        </Heading>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={10}>
          {ARTICLES.map((article) => (
            <Resource key={article.key} resource={article} />
          ))}
        </SimpleGrid>

        <Heading as="h3" size="2xl" textAlign="center">
          Tools
        </Heading>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={10}>
          {TOOLS.map((tool) => (
            <Resource
              key={tool.key}
              resource={tool}
              size="sm"
              variant="horizontal"
            />
          ))}
        </SimpleGrid>
      </Stack>

      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
