import React, { FC } from 'react';
import {
  Redirect,
  Route as RouterRoute,
  RouteProps as RouterRouteProps,
} from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
export interface RouteProps extends RouterRouteProps {
  path: string;
  variant?: 'protected' | 'public' | undefined;
}

export const Route: FC<RouteProps> = ({ variant, location, ...props }) => {
  const { isAuthenticated } = useAuth();

  if (variant === 'protected' && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location?.pathname },
        }}
      />
    );
  }

  if (variant === 'public' && isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { referrer: location?.pathname },
        }}
      />
    );
  }

  return <RouterRoute location={location} {...props} />;
};

Route.displayName = 'Route';
