import { Env } from 'src/constants/env';

export enum LOCAL_STORAGE_KEYS {
  TOKEN = '__ECOLAB_WAP_TOKEN__',
  USER = '__ECOLAB_WAP_USER__',
  ACCOUNT = '__ECOLAB_WAP_ACCOUNT__',
}

export function getTokenInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
}

export function setTokenInStorage(id_token: string) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, id_token);
  return getTokenInStorage();
}

export function removeTokenInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
}

export function getUserIdInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
}

export function setUserIdInStorage(id: string) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER, `${id}`);
  return getUserIdInStorage();
}

export function removeUserIdInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
}

export function getAccountIdInStorage() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT);
}

export function setAccountIdInStorage(id: string) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT, `${id}`);
  return getAccountIdInStorage();
}

export function removeAccountIdInStorage() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCOUNT);
}

export function clearStorage() {
  removeAccountIdInStorage();
  removeUserIdInStorage();
  removeTokenInStorage();
}

export function urlWithAccountId(url: string, accountIdOverride?: string) {
  const accountId = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT);

  return `${Env.apiUrl}/v5/accounts/${accountIdOverride ?? accountId}/${url}`;
}
