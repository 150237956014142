import {
  Box,
  Container,
  Heading,
  ListItem,
  Link as ChakraLink,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Helmet } from 'src/components/Helmet';
import { PageHeader } from 'src/components/PageHeader';
import { RouteContentScrollable } from 'src/components/RouteContentScrollable';
import { SiteFooter } from 'src/components/SiteFooter';

export interface TermsOfServiceProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const TermsOfService: FC<TermsOfServiceProps> = () => {
  return (
    <RouteContentScrollable>
      <Helmet>
        <meta
          name="description"
          content="Achieve business resilience through smart water management. Determine how water impacts your bottom line, and how to develop a smarter water strategy today."
        />
      </Helmet>
      <PageHeader
        image="/images/waterfall.jpg"
        title={() => (
          <Heading as="h1" size="2xl">
            Terms of Service
          </Heading>
        )}
      />
      <Stack
        direction="column"
        spacing={{ base: '60px', md: 'calc(5vh)' }}
        p="20px"
        pb="40px"
      >
        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>Terms of Service</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              These Terms of Service ("Agreement") is made between Ecolab USA
              Inc. ("Ecolab") and you, or, if your use of the service is on
              behalf of an entity or other organization, that entity or
              organization (in either case "You").
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              The{' '}
              <ChakraLink
                isExternal
                textDecoration="underline"
                href="https://www.smartwaternavigator.com"
              >
                www.smartwaternavigator.com
              </ChakraLink>{' '}
              website (the "Site") is owned by Ecolab and provides You with the
              ability to access and use information, data, and other resources
              made available through the Site ("Services"). In addition to the
              terms of this Agreement, Your access to and use of the Site and
              Services is subject to Ecolab’s Privacy Policy. You are
              responsible for compliance with these terms and policies
              applicable to the access and use of the Services and Site.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              By accessing or using the Site or Services, You agree that You
              have read and agree to be bound by the Terms of Service.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>1. Account</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              In order to access some of the Site and Services, You are required
              to register as an authorized user and select a username and
              password to create an account ("Account"). You are responsible for
              ensuring the security and confidentiality of Your username and
              password and agree to notify Ecolab immediately if any username is
              lost, stolen, or otherwise compromised.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              By creating and registering for Your Account, You represent and
              warrant that all information You submit to us is truthful and
              accurate. You are responsible for updating Your user data in a
              timely manner to ensure its accuracy and completeness. By
              providing Your Account information to Ecolab , You authorize
              Ecolab to use, copy, sublicense and disclose all such information
              to: ensure the operation, functionality, and optimization of the
              Site, including by aggregating and de-identifying data; provide
              data analytics and administration, including through the use of
              third parties and subprocessors; comply with applicable federal,
              state or local law or a valid order issued by a court or
              governmental agency of competent jurisdiction; enforce these terms
              and/or to Ecolab or its third party providers to protect their
              legal rights, as necessary.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Ecolab reserves the right to terminate Your access to Your Account
              and the Site or any portion of this Site without notice in the
              event that You violate this Agreement or for any reason
              whatsoever.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>2. Third Party Products and Services</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              The Services include products, offerings, services, or
              functionality developed, provided, or maintained by third party
              providers, including S&P Global Sustainable1. Third party products
              and services will be subject to the terms of this Agreement. You
              are granted no licenses or rights, whether by implication,
              estoppel, or otherwise, in or to any products or services from any
              third-party provider as a result of this Agreement. Moreover,
              Services may include or be based on data or information from
              third-party providers and other users of the Site. Ecolab lacks an
              independent basis to verify or contradict the accuracy or
              completeness of the data and information provided by third parties
              and therefore is not responsible for any inaccuracies in that
              information and data.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>3. Disclaimer of Warranties</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              This Site and its content are provided by Ecolab "as is", and "as
              available", "and Ecolab and its third party providers expressly
              disclaim all warranties and representations of any kind with
              regard to any subject matter of this agreement, express, statutory
              or implied, including, without limitation, warranties of
              merchantability, title, non-infringement, fitness for a particular
              purpose, and warranties that may arise out of a course of dealing
              or performance, usage or trade practice. Ecolab and its
              third-party providers provide no warranty and make no
              representations of any kind that the Smart Sater Navigator tool
              will meet the user’s requirements, achieve any intended results,
              be compatible or work with any other software applications,
              systems or services, operate without interruption, meet any
              performance or reliability standards or be error free or that any
              errors or defects can or will be corrected. No oral or written
              information, advice, or results provided by Ecolab, including
              through the Smart Water Navigator tool, will increase the scope of
              this agreement or create any new warranties.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              For the avoidance of doubt, Ecolab makes no representations or
              warranties as to the completeness or accuracy of information
              provided on the site, including any results or output generated by
              the Smart Water Navigator tool. The Smart Water Navigator tool
              relies on a series of assumptions and the information provided by
              the user and provides proposed solutions and projected outcomes
              based on the same. Information provided using the Smart Water
              Navigator is not a guarantee of results. Actual results will vary
              depending on each user’s circumstances. While we endeavor to keep
              the information up to date and correct, we make no representations
              or warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the website or the information, services,
              assessments, results, reports, mitigation actions, maps or related
              graphics contained on the website for any purpose. Any reliance
              You place on such information is therefore strictly at Your own
              risk.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>4. Limitation of Liability</Heading>
          </Container>

          <Container maxW={1920 / 1.5}>
            <Text>
              In no event will Ecolab or its third party providers be liable for
              any indirect, incidental, special, exemplary, punitive, or
              consequential damages, however caused, under any theory of
              liability, whether in contract, strict liability or tort
              (including negligence or otherwise), arising in any way in
              connection with or out of the use of the platform, services (or
              any service content provided through the platform or services
              whether directly or on behalf of any user or other third party),
              even if Ecolab has been advised of the possibility of such
              damages, including, without limitation, any loss of data,
              opportunity, revenues or profits, business interruption, or
              procurement of substitute goods or services. In jurisdictions
              where limitation of liability for consequential or incidental
              damages is not permitted, Ecolab’s liability is limited to the
              maximum extent permitted by law.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>5. External Content</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              There are links on this website that take You to other websites
              outside of our service. Any links provided to third party websites
              outside of this site are provided solely for Your convenience. The
              operation and content of such third party websites is beyond our
              control, and we do not endorse in any manner whatsoever or accept
              any responsibility for the content or other material that may be
              contained on such websites, the use of such websites, or any
              products or services advertised on or sold through any such
              websites. We have no control over the nature, content and
              availability of those sites. The inclusion of any links does not
              necessarily imply a recommendation or endorse the views expressed
              within them.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>6. Facility Locations</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              The tool asks users to designate the location of their facility.
              The terms "country," "state," or "province" or "city" do not imply
              any judgment on the legal status of any territory, or any
              endorsement or acceptance of disputed boundaries, on behalf of
              Ecolab or its third-party providers.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Moreover, Ecolab makes no representation that information or
              Services available through the Site are appropriate or available
              for use under the laws of locations outside of the United States.
            </Text>
          </Container>
        </Stack>
        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>7. Intellectual Property</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              All material contained in this Site is protected by law, including
              but not limited to, United States copyright and trademark laws, as
              well as other state, national and international laws and
              regulations.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Except as indicated, the entire content of the Site is the sole
              and exclusive property of Ecolab, and any commercial use requires
              written permission of Ecolab. Ecolab also owns a copyright in this
              Site as a collective work and/or compilation, and in the
              selection, coordination, arrangement, organization and enhancement
              of such content. You may use, reproduce, distribute or reprint
              materials available from this Site only for Your personal and
              non-commercial use, provided (i) proper copyright notices appear
              in all reproductions, (ii) no documents or related images are
              modified in any way; and (iii) no graphic images available from
              this Site are used, copied or distributed separate from
              accompanying text.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Any text materials on the Site may not be modified in any way. All
              marks designating Ecolab’s products and services are proprietary
              trademarks of Ecolab. Use or misuse of these trademarks is
              expressly prohibited and may violate international, federal and
              state trademark laws.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Please be advised that Ecolab enforces its intellectual property
              rights to the fullest extent of the law. If a consultant or other
              user would like to use any content or service available on the
              Site for commercial purposes, please contact{' '}
              <ChakraLink
                isExternal
                textDecoration="underline"
                href="mailto:SmartWaterNavigator@ecolab.com"
              >
                SmartWaterNavigator@ecolab.com
              </ChakraLink>{' '}
              for information about licensing.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>8. Provision of Services</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Every effort is made to keep the website up and running smoothly.
              However, Ecolab takes no responsibility for, and will not be
              liable for, the website being virus-free or temporarily
              unavailable due to technical issues beyond our control.
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              Ecolab reserves the exclusive right to change, complement, delete
              or halt publication of, completely or for a limited period, parts
              of the webpages or the entire offer without separate notification.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>9. Applicable law and jurisdiction</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              By accessing the Site, You agree that this Agreement is governed
              by and construed in accordance with the laws of the United States,
              without regard to conflict of law principles. Any disputes,
              controversies, or claims arising out of or in connection to this
              Agreement, including those with third party providers, shall be
              heard in the federal courts of Hennepin County, Minnesota or
              Ramsey County, Minnesota. You expressly consent to the exclusive
              jurisdiction of the federals courts of Hennepin County, Minnesota
              and Ramsey County, Minnesota for all disputes arising out of or in
              connection with this Agreement, including those with third party
              providers, and expressly waive any objection to the jurisdiction
              of these courts, whether based on convenience or otherwise.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>10. Data Privacy</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              In addition, the Parties work with other companies to provide the
              Smart Water Navigator, including third-party software providers
              and cloud-hosting service providers. The Parties may share certain
              information with these companies for the purpose of providing
              Services through the Site, including information about Your use of
              the Smart Water Navigator tool. By accessing the Site, You
              expressly consent to the use and disclosure of personally
              identifiable and other data and information as described in
              Ecolab’s then current privacy policy ("Privacy Policy") and agree
              that as required by applicable laws You will obtain the consent of
              all Account users to such use and disclosure. Notwithstanding the
              Privacy Policy, Ecolab and its third party providers have the
              right to collect, extract, transfer, compile, aggregate, and/or
              archive nonpersonally identifiable data resulting from Your access
              to the Site and use of the Services provided, including the Smart
              Water Navigator tool. To the extent any such data or information
              is collected or generated by Ecolab, the data and information will
              be solely owned by Ecolab and may be used by Ecolab for any lawful
              business purpose without a duty of accounting to You, provided
              that the data and information is used only in an aggregated form,
              without directly identifying You, any Account user or customer, or
              any other entity or natural person as the source thereof.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>11. Prohibited uses</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              In addition to uses otherwise prohibited by Ecolab, these Terms,
              or applicable law, You will not, and will not allow any third
              party, to:
            </Text>
          </Container>
          <Container maxW={1920 / 1.5}>
            <UnorderedList pl="10">
              <ListItem>
                Use the Smart Water Navigator and Site for any purpose other
                than its intended purpose or in any way beyond the scope of the
                license in these Terms.
              </ListItem>
              <ListItem>
                Copy or distribute the Smart Water Navigator and Site without
                prior written approval of the Parties.
              </ListItem>
              <ListItem>
                Circumvent, disable or otherwise interfere with security-related
                features or features that prevent or restrict use of copying
                content or limiting the use of such content.
              </ListItem>
              <ListItem>
                Download, access, use or register for the Smart Water Navigator
                and Site if You are under eighteen (18) years old.
              </ListItem>
              <ListItem>
                Access or use the content by artificial means or in a manner
                that, in the Parties’ discretion, would inflict an excessive
                load on Ecolab’s or its suppliers’ hardware or software.
              </ListItem>
              <ListItem>
                Disclose information or analysis regarding the quality or
                performance of the program.
              </ListItem>
              <ListItem>
                Upload or transmit: (i) any content or data that infringe or
                violate any rights of any party, including, but not limited to,
                any patent, copyright, trademark, trade secret or any
                proprietary or other right of Ecolab or a third party, (ii) any
                content or data that are, or Ecolab deems to be, unlawful,
                illegal, threatening, obscene, defamatory, libelous, profane,
                harmful, abusive, racially or ethnically offensive, harassing,
                humiliating, invasive of privacy or publicity rights, otherwise
                objectionable, or otherwise violate the rights of Ecolab or a
                third party, or (iii) any disabling code (defined as computer
                code designed to interfere with the normal operation of the
                program or Ecolab’s or a third party’s hardware or software) or
                any program routine, device or other undisclosed feature,
                including, but not limited to, a time bomb, virus, software
                lock, drop-dead device, malicious logic, worm, Trojan horse or
                trap door which is designed to delete, disable, deactivate,
                interfere with or otherwise harm the Program or Ecolab’s or a
                third party’s hardware or software.
              </ListItem>
              <ListItem>
                Use the program for benchmarking, monitoring or testing
                availability or performance, or any other competitive use,
                including, but not limited to, creating programs, products,
                services, technology or software that are substantially similar
                to the program.
              </ListItem>
              <ListItem>
                Modify, adapt, alter, disassemble, decompile, decode, translate
                or convert into human readable form, or reverse engineer, all or
                any part of the program or any software, or attempt to
                reconstruct or derive any source code (or underlying ideas,
                algorithms, structure or organization), modify or create a
                derivative work of the program by any means whatsoever.
              </ListItem>
              <ListItem>
                Use or access any data, content or materials for any purpose or
                in any manner other than real-time viewing (which prohibits, for
                example, screenshots, downloading and storing) unless expressly
                permitted by the features of the program.
              </ListItem>
              <ListItem>
                Engage in any other conduct that restricts or inhibits anyone’s
                use or enjoyment of the program, or which, as determined by
                Ecolab, may harm Ecolab or program users or expose them to
                liability.
              </ListItem>
            </UnorderedList>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Heading>12. Changes to the Terms</Heading>
          </Container>
          <Container maxW={1920 / 1.5}>
            <Text>
              The Parties reserve the right to modify these terms from time to
              time at our sole discretion, for any reason or no reason, and
              without any notice by posting them on the website. Changes to our
              terms become effective on the date they are posted and Your
              continued use of the Site after any changes to the terms will
              signify Your agreement to be bound by them.
            </Text>
          </Container>
        </Stack>

        <Stack as="section" spacing="5">
          <Container maxW={1920 / 1.5}>
            <Text>August 2024</Text>
          </Container>
          <Box>
            <Container maxW={1920 / 1.5}>
              <Text>Contact Information:</Text>
            </Container>
            <Container maxW={1920 / 1.5}>
              <Text>
                Ecolab:{' '}
                <ChakraLink
                  isExternal
                  textDecoration="underline"
                  href="https://www.ecolab.com"
                >
                  www.ecolab.com
                </ChakraLink>{' '}
                or contact{' '}
                <ChakraLink
                  isExternal
                  textDecoration="underline"
                  href="mailto:SmartWaterNavigator@ecolab.com"
                >
                  SmartWaterNavigator@ecolab.com
                </ChakraLink>
              </Text>
            </Container>
          </Box>
        </Stack>
      </Stack>
      <SiteFooter solid style={{ width: '100%', maxWidth: '1920px' }} />
    </RouteContentScrollable>
  );
};
