import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeManagerProvider } from 'src/hooks/useThemeManager';
import { AuthProvider } from '../../hooks/useAuth';
import { ChakraProvider } from '../ChakraProvider';
import { EmotionCacheProvider } from '../EmotionProvider';
import { Env } from '../../constants/env';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 61, // 61 min native TTL
    },
  },
});

export const AppProviders: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <EmotionCacheProvider
          nonce={
            Env.styleNonce ??
            'wwtafaqysyrtiogubrrrwicyyvicayfeupmxytlwhnytwrbjknzbbxrhszlquqyswblzdqaipqblhzkdwnlntvrzfadhxvyprnvsvxpakmsbcjziftwpssdboxerawry'
          }
        >
          <ChakraProvider>
            <ThemeManagerProvider>{children}</ThemeManagerProvider>
          </ChakraProvider>
        </EmotionCacheProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
